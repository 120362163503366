import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { ARTICLE_CONTENT_REDUCER_TOKEN, articleContentReducerProvider, rootReducerKey } from './content.reducer';
import { EffectsModule } from '@ngrx/effects';
import { ArticleContentEffects } from './content.effects';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature(rootReducerKey, ARTICLE_CONTENT_REDUCER_TOKEN),
    EffectsModule.forFeature([ArticleContentEffects])
  ],
  providers: [
    articleContentReducerProvider
  ]
})
export class ArticleContentStoreModule {
}
