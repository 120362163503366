import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SendByIdPayload,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { OfferItemPayload } from '../models/item-payload';

export const OfferItemEditActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[offer/item] Edit'),
  requestFail: uniqueActionType('[offer/item] Edit fail'),
  requestSuccess: uniqueActionType('[offer/item] Edit success'),
  requestClearErrors: uniqueActionType('[offer/item] Edit clear errors'),
  requestClear: uniqueActionType('[offer/item] Edit clear'),
};

export class OfferItemEditAction implements PayloadAction {
  readonly type = OfferItemEditActionTypes.request;

  constructor(public payload: SendByIdPayload<OfferItemPayload>) {
  }
}

export class OfferItemEditFailAction implements PayloadAction {
  readonly type = OfferItemEditActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class OfferItemEditSuccessAction implements PayloadAction {
  readonly type = OfferItemEditActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class OfferItemEditClearAction implements PayloadAction {
  readonly type = OfferItemEditActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}

export class OfferItemEditClearErrorsAction implements PayloadAction {
  readonly type = OfferItemEditActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}
