import { IdType, SetValuePayload, SetValueReducerTypes, uniqueActionType } from '@industi/ngx-common';

export const ItemSelectLastAddedIdActionTypes: SetValueReducerTypes = {
  setValue: uniqueActionType('[item] Select last added id'),
  clearValue: uniqueActionType('[item] Clear last added id'),
};

export class ItemSelectLastAddedIdAction {
  readonly type = ItemSelectLastAddedIdActionTypes.setValue;

  constructor(public payload: SetValuePayload<IdType>) {
  }
}

export class ItemClearLastAddedIdAction {
  readonly type = ItemSelectLastAddedIdActionTypes.setValue;
  readonly payload = null;
}
