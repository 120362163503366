import { createSelector } from '@ngrx/store';
import { ByKey, createListStateIdsSelector, IdType, ListState } from '@industi/ngx-common';
import { denormalize } from 'normalizr';
import { EntityTypes } from '../../../entity/entity-types';
import { createEntitiesSelector } from '../../../entity/entities.reducer';
import { Entities } from '../../../entity/entities';
import { EntityState } from '../../../entity/entity.reducer';
import { getArticleImageContentAddPendingList } from '../image-content.reducer';
import { ArticleImageContent } from '../models/image-content';

const createStateSelector = (id: IdType) => createSelector(
  getArticleImageContentAddPendingList,
  (state: ByKey<ListState>) => state && state[id]
);

const createIdsSelector = (id: IdType) => createListStateIdsSelector(createStateSelector(id));

export const createArticleImageContentAddPendingList = (id: IdType) => createSelector(
  createIdsSelector(id),
  createEntitiesSelector([
    EntityTypes.articleImageContent
  ]),
  (
    ids: IdType[],
    entities: EntityState
  ) => denormalize(ids, [Entities[EntityTypes.articleImageContent]], entities) as ArticleImageContent[]
);
