import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';

export const DiagramPartLoadDetailsActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[diagram/part] Load details'),
  requestFail: uniqueActionType('[diagram/part] Load details fail'),
  requestSuccess: uniqueActionType('[diagram/part] Load details success'),
  requestClearErrors: uniqueActionType('[diagram/part] Load details clear errors'),
  requestClear: uniqueActionType('[diagram/part] Load details clear')
};

export class DiagramPartLoadDetailsAction implements PayloadAction {
  readonly type = DiagramPartLoadDetailsActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class DiagramPartLoadDetailsFailAction implements PayloadAction {
  readonly type = DiagramPartLoadDetailsActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class DiagramPartLoadDetailsSuccessAction implements PayloadAction {
  readonly type = DiagramPartLoadDetailsActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class DiagramPartLoadDetailsClearAction implements PayloadAction {
  readonly type = DiagramPartLoadDetailsActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}

export class DiagramPartLoadDetailsClearErrorsAction implements PayloadAction {
  readonly type = DiagramPartLoadDetailsActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}
