import { ByKey, listByIdReducer, ListState, operationByIdReducer, SingleState } from '@industi/ngx-common';
import { ActionReducer, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import { InjectionToken, Provider } from '@angular/core';
import { DictionaryBrandLoadListActionTypes } from './actions/list.actions';
import { DictionaryBrandAddActionTypes } from './actions/add.actions';
import { DictionaryBrandLoadDetailsActionTypes } from './actions/load-details.actions';
import { DictionaryBrandEditActionTypes } from './actions/edit.actions';
import { DictionaryBrandDeleteActionTypes } from './actions/delete.actions';
import { DictionaryBrandLogoAddActionTypes } from './actions/add-logo.actions';
import { DictionaryBrandImageDeleteActionTypes } from './actions/delete-image.actions';
import { DictionaryBrandCategoryImageAddActionTypes } from './actions/add-category-image.actions';

export const rootReducerKey = 'brand';

export interface DictionaryBrandOperationByIdState {
  add: SingleState;
  edit: SingleState;
  details: SingleState;
  delete: SingleState;
  addLogo: SingleState;
  addCategoryImage: SingleState;
  deleteImage: SingleState;
}

export interface DictionaryBrandState {
  operationsById: ByKey<DictionaryBrandOperationByIdState>;
  list: ByKey<ListState>;
}

const initialState: DictionaryBrandState = {
  operationsById: {},
  list: {}
};

const reducer: ActionReducer<DictionaryBrandState> = combineReducers<DictionaryBrandState>({
  operationsById: operationByIdReducer<DictionaryBrandOperationByIdState>({
    mapActionToId: (a) => a && a.payload && a.payload.id,
    operations: {
      add: DictionaryBrandAddActionTypes,
      edit: DictionaryBrandEditActionTypes,
      details: DictionaryBrandLoadDetailsActionTypes,
      delete: DictionaryBrandDeleteActionTypes,
      addLogo: DictionaryBrandLogoAddActionTypes,
      addCategoryImage: DictionaryBrandCategoryImageAddActionTypes,
      deleteImage: DictionaryBrandImageDeleteActionTypes
    }
  }),
  list: listByIdReducer({ types: DictionaryBrandLoadListActionTypes })
}, initialState);

export function getReducers(): ActionReducer<DictionaryBrandState> {
  return reducer;
}

export const DICTIONARY_BRAND_REDUCER_TOKEN = new InjectionToken<ActionReducer<DictionaryBrandState>>(rootReducerKey);

export const dictionaryBrandReducerProvider: Provider = { provide: DICTIONARY_BRAND_REDUCER_TOKEN, useFactory: getReducers };

const getState = createFeatureSelector<DictionaryBrandState>(rootReducerKey);

export const getDictionaryBrandOperationsById = createSelector(
  getState,
  (state: DictionaryBrandState) => state && state.operationsById
);

export const getDictionaryBrandListState = createSelector(
  getState,
  (state: DictionaryBrandState) => state && state.list
);
