import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SendByIdPayload,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { ArticleBrandImagePayload } from '../models/article-brand-image-payload';

export const ArticleBrandImageAddActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[article-brand] Add image'),
  requestFail: uniqueActionType('[article-brand] Add image fail'),
  requestSuccess: uniqueActionType('[article-brand] Add image success'),
  requestClearErrors: uniqueActionType('[article-brand] Add image clear errors'),
  requestClear: uniqueActionType('[article-brand] Add image clear'),
};

export class ArticleBrandImageAddAction implements PayloadAction {
  readonly type = ArticleBrandImageAddActionTypes.request;

  constructor(public payload: SendByIdPayload<ArticleBrandImagePayload>) {
  }
}

export class ArticleBrandImageAddFailAction implements PayloadAction {
  readonly type = ArticleBrandImageAddActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class ArticleBrandImageAddSuccessAction implements PayloadAction {
  readonly type = ArticleBrandImageAddActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class ArticleBrandImageAddClearErrorsAction implements PayloadAction {
  readonly type = ArticleBrandImageAddActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class ArticleBrandImageAddClearAction implements PayloadAction {
  readonly type = ArticleBrandImageAddActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
