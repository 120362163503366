import {
  ByIdPayload,
  ChangeParamsByIdPayload,
  FailByIdPayload,
  ListReducerTypes,
  NormalizedList,
  PayloadAction,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { SymfonyPaginationParams } from '../../../symfony/list/models/symfony-pagination-params';

export const DictionaryTagLoadListActionTypes: ListReducerTypes = {
  request: uniqueActionType('[dictionary/tag] Load list'),
  requestFail: uniqueActionType('[dictionary/tag] Load list fail'),
  requestSuccess: uniqueActionType('[dictionary/tag] Load list success'),
  requestClear: uniqueActionType('[dictionary/tag] Load list clear'),
  changeParams: uniqueActionType('[dictionary/tag] Load list change params'),
  clearParams: uniqueActionType('[dictionary/tag] Load list clear params'),
  clearIds: uniqueActionType('[dictionary/tag] Load list clear ids')
};

export class DictionaryTagLoadListAction implements PayloadAction {
  readonly type = DictionaryTagLoadListActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class DictionaryTagLoadListFailAction implements PayloadAction {
  readonly type = DictionaryTagLoadListActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class DictionaryTagLoadListSuccessAction implements PayloadAction {
  readonly type = DictionaryTagLoadListActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedList>) {
  }
}

export class DictionaryTagLoadListClearAction implements PayloadAction {
  readonly type = DictionaryTagLoadListActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}

export class DictionaryTagLoadListChangeParamsAction implements PayloadAction {
  readonly type = DictionaryTagLoadListActionTypes.changeParams;

  constructor(public payload: ChangeParamsByIdPayload<SymfonyPaginationParams>) {
  }
}

export class DictionaryTagLoadListClearParamsAction implements PayloadAction {
  readonly type = DictionaryTagLoadListActionTypes.clearParams;

  constructor(public payload: ByIdPayload) {
  }
}


export class DictionaryTagLoadListClearIdsAction implements PayloadAction {
  readonly type = DictionaryTagLoadListActionTypes.clearIds;

  constructor(public payload: ByIdPayload) {
  }
}
