import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ItemImageEffects } from './image.effects';
import { ITEM_IMAGE_REDUCER_TOKEN, itemImageReducerProvider, rootReducerKey } from './image.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(rootReducerKey, ITEM_IMAGE_REDUCER_TOKEN),
    EffectsModule.forFeature([ItemImageEffects])
  ],
  providers: [
    itemImageReducerProvider
  ]
})
export class ItemImageStoreModule { }
