import {
  ByIdPayload,
  FailByIdPayload,
  OperationReducerTypes,
  PayloadAction,
  SendByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { OfferImagePayload } from '../models/offer-image-payload';

export const OfferImageAddActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[offer/image] Add photo'),
  requestFail: uniqueActionType('[offer/image] Add photo fail'),
  requestSuccess: uniqueActionType('[offer/image] Add photo success'),
  requestClearErrors: uniqueActionType('[offer/image] Add photo clear errors'),
  requestClear: uniqueActionType('[offer/image] Add photo clear'),
};

export class OfferImageAddAction implements PayloadAction {
  readonly type = OfferImageAddActionTypes.request;

  constructor(public payload: SendByIdPayload<OfferImagePayload>) {
  }
}

export class OfferImageAddFailAction implements PayloadAction {
  readonly type = OfferImageAddActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class OfferImageAddSuccessAction implements PayloadAction {
  readonly type = OfferImageAddActionTypes.requestSuccess;

  constructor(public payload: ByIdPayload) {
  }
}

export class OfferImageAddClearErrorsAction implements PayloadAction {
  readonly type = OfferImageAddActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class OfferImageAddClearAction implements PayloadAction {
  readonly type = OfferImageAddActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
