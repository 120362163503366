import {
  ByIdPayload,
  FailByIdPayload,
  OperationReducerTypes,
  PayloadAction,
  uniqueActionType
} from '@industi/ngx-common';

export const OfferDeleteActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[offer] Delete'),
  requestFail: uniqueActionType('[offer] Delete fail'),
  requestSuccess: uniqueActionType('[offer] Delete success'),
  requestClearErrors: uniqueActionType('[offer] Delete clear errors'),
  requestClear: uniqueActionType('[offer] Delete clear')
};

export class OfferDeleteAction implements PayloadAction {
  readonly type = OfferDeleteActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class OfferDeleteFailAction implements PayloadAction {
  readonly type = OfferDeleteActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class OfferDeleteSuccessAction implements PayloadAction {
  readonly type = OfferDeleteActionTypes.requestSuccess;

  constructor(public payload: ByIdPayload) {
  }
}

export class OfferDeleteClearErrorsAction implements PayloadAction {
  readonly type = OfferDeleteActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class OfferDeleteClearAction implements PayloadAction {
  readonly type = OfferDeleteActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
