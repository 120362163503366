import { ByKey, listByIdReducer, ListState, operationByIdReducer, SingleState } from '@industi/ngx-common';
import { ActionReducer, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import { InjectionToken, Provider } from '@angular/core';
import { ServiceProjectPartLoadListActionTypes } from './actions/list.actions';
import { ServiceProjectPartLoadDetailsActionTypes } from './actions/details.actions';
import { ServiceProjectPartAddActionTypes } from './actions/add.actions';
import { ServiceProjectPartEditActionTypes } from './actions/edit.actions';
import { ServiceProjectPartDeleteActionTypes } from './actions/delete.actions';

export const rootReducerKey = 'projectPart';

export interface ServiceProjectPartOperationByIdState {
  add: SingleState;
  edit: SingleState;
  delete: SingleState;
  details: SingleState;
}

export interface ServiceProjectPartState {
  operationsById: ByKey<ServiceProjectPartOperationByIdState>;
  list: ByKey<ListState>;
}

const initialState: ServiceProjectPartState = {
  operationsById: {},
  list: {}
};

const reducer: ActionReducer<ServiceProjectPartState> = combineReducers<ServiceProjectPartState>({
  operationsById: operationByIdReducer<ServiceProjectPartOperationByIdState>({
    mapActionToId: (a) => a && a.payload && a.payload.id,
    operations: {
      add: ServiceProjectPartAddActionTypes,
      edit: ServiceProjectPartEditActionTypes,
      delete: ServiceProjectPartDeleteActionTypes,
      details: ServiceProjectPartLoadDetailsActionTypes
    }
  }),
  list: listByIdReducer({ types: ServiceProjectPartLoadListActionTypes })
}, initialState);

export function getReducers(): ActionReducer<ServiceProjectPartState> {
  return reducer;
}

export const SERVICE_PROJECT_PART_REDUCER_TOKEN = new InjectionToken<ActionReducer<ServiceProjectPartState>>(rootReducerKey);

export const serviceProjectPartReducerProvider: Provider = { provide: SERVICE_PROJECT_PART_REDUCER_TOKEN, useFactory: getReducers };

const getState = createFeatureSelector<ServiceProjectPartState>(rootReducerKey);

export const getServiceProjectPartOperationsById = createSelector(
  getState,
  (state: ServiceProjectPartState) => state && state.operationsById
);

export const getServiceProjectPartListState = createSelector(
  getState,
  (state: ServiceProjectPartState) => state && state.list
);
