import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SendByIdPayload,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { CarbonFormPayload } from '../models/form-payload';

export const CarbonFormAddActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[carbon/form] Add'),
  requestFail: uniqueActionType('[carbon/form] Add fail'),
  requestSuccess: uniqueActionType('[carbon/form] Add success'),
  requestClearErrors: uniqueActionType('[carbon/form] Add clear errors'),
  requestClear: uniqueActionType('[carbon/form] Add clear'),
};

export class CarbonFormAddAction implements PayloadAction {
  readonly type = CarbonFormAddActionTypes.request;

  constructor(public payload: SendByIdPayload<CarbonFormPayload>) {
  }
}

export class CarbonFormAddFailAction implements PayloadAction {
  readonly type = CarbonFormAddActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class CarbonFormAddSuccessAction implements PayloadAction {
  readonly type = CarbonFormAddActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class CarbonFormAddClearErrorsAction implements PayloadAction {
  readonly type = CarbonFormAddActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class CarbonFormAddClearAction implements PayloadAction {
  readonly type = CarbonFormAddActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
