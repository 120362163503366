import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, first, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { ByIdPayload, ByKey, IdType, NormalizedList, NormalizedSingle } from '@industi/ngx-common';
import { select, Store } from '@ngrx/store';
import { SymfonyPaginationParams } from '../../symfony/list/models/symfony-pagination-params';
import { getInstanceLocale } from '../../instance/instance.reducer';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import {
  DictionaryTagAddAction,
  DictionaryTagAddActionTypes,
  DictionaryTagAddFailAction,
  DictionaryTagAddSuccessAction
} from './actions/add.actions';
import {
  DictionaryTagLoadListAction,
  DictionaryTagLoadListActionTypes,
  DictionaryTagLoadListClearIdsAction,
  DictionaryTagLoadListFailAction,
  DictionaryTagLoadListSuccessAction
} from './actions/list.actions';
import {
  DictionaryTagEditAction,
  DictionaryTagEditActionTypes,
  DictionaryTagEditFailAction,
  DictionaryTagEditSuccessAction
} from './actions/edit.actions';
import {
  DictionaryTagLoadDetailsAction,
  DictionaryTagLoadDetailsActionTypes,
  DictionaryTagLoadDetailsFailAction,
  DictionaryTagLoadDetailsSuccessAction
} from './actions/load-details.actions';
import { DictionaryTagService } from './tag.service';
import { createDictionaryTagListParams } from './selectors/get-load-list.selectors';
import {
  DictionaryTagDeleteAction,
  DictionaryTagDeleteActionTypes,
  DictionaryTagDeleteFailAction,
  DictionaryTagDeleteSuccessAction
} from './actions/delete.actions';
import { ResponseStatus } from '../../../shared/enums/response-status.enum';
import { LibSnackbarComponent } from '@industi/ngx-modules';
import { AppGlobal } from '../../../shared/app-global';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlertUtil } from '../../../shared/utils/alert.util';

@Injectable()
export class DictionaryTagEffects {

  @Effect()
  loadList$ = this.actions$.pipe(
    ofType(DictionaryTagLoadListActionTypes.request),
    map((action: DictionaryTagLoadListAction) => action.payload),
    switchMap((payload: ByIdPayload) => this.store.pipe(
      select(createDictionaryTagListParams(payload.id)),
      map((params: SymfonyPaginationParams) => [payload.id, params] as [IdType, SymfonyPaginationParams]),
      first()
    )),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([[ id, params ], locale]) => this.service.loadList(locale, params).pipe(
      mergeMap((res: NormalizedList) => [
        new DictionaryTagLoadListClearIdsAction({ id }),
        new DictionaryTagLoadListSuccessAction({ id, res })]),
      catchError((errors: HttpErrorResponse) => of(new DictionaryTagLoadListFailAction({ id, errors })))
    ))
  );

  @Effect()
  add$ = this.actions$.pipe(
    ofType(DictionaryTagAddActionTypes.request),
    map((action: DictionaryTagAddAction) => action.payload),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([{ id, data }, locale]) => this.service.add(locale, data).pipe(
      map((res: NormalizedSingle) => new DictionaryTagAddSuccessAction({ res, id })),
      catchError((errors: HttpErrorResponse) => of(new DictionaryTagAddFailAction({ errors, id })))
    ))
  );

  @Effect()
  edit$ = this.actions$.pipe(
    ofType(DictionaryTagEditActionTypes.request),
    map((action: DictionaryTagEditAction) => action.payload),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([{ id, data }, locale]) => this.service.edit(locale, data, id).pipe(
      map((res: NormalizedSingle) => new DictionaryTagEditSuccessAction({ res, id })),
      catchError((errors: HttpErrorResponse) => of(new DictionaryTagEditFailAction({ errors, id })))
    ))
  );

  @Effect()
  loadDetails$ = this.actions$.pipe(
    ofType(DictionaryTagLoadDetailsActionTypes.request),
    map((action: DictionaryTagLoadDetailsAction) => action.payload.id),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([id, locale]) => this.service.details(locale, id).pipe(
      map((res: NormalizedSingle) => new DictionaryTagLoadDetailsSuccessAction({ id, res })),
      catchError((errors: HttpErrorResponse) => of(new DictionaryTagLoadDetailsFailAction({ id, errors })))
    ))
  );

  @Effect()
  delete$ = this.actions$.pipe(
    ofType(DictionaryTagDeleteActionTypes.request),
    map((action: DictionaryTagDeleteAction) => action.payload.id),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([id, locale]) => this.service.delete(locale, id).pipe(
      map(() => new DictionaryTagDeleteSuccessAction({ id })),
      catchError((errors: HttpErrorResponse) => of(new DictionaryTagDeleteFailAction({ id, errors })))
    ))
  );

  @Effect({ dispatch: false })
  crudFail$ = this.actions$.pipe(
    ofType(
      DictionaryTagAddActionTypes.requestFail,
      DictionaryTagEditActionTypes.requestFail
    ),
    map((action: DictionaryTagAddFailAction | DictionaryTagEditFailAction) => action.payload.errors),
    tap((data: HttpErrorResponse) => {
      if (data?.status === ResponseStatus.BAD_REQUEST || data?.status === ResponseStatus.UNPROCESSABLE_ENTITY) {
        this.snackBar.openFromComponent(LibSnackbarComponent, {
          data: {
            message: AlertUtil.getControlTranslation(data.error.detail),
            type: 'error'
          },
          duration: AppGlobal.defaultSnackBarDuration
        });
      }
    })
  );

  constructor(
    private actions$: Actions,
    private store: Store<ByKey>,
    private service: DictionaryTagService,
    private snackBar: MatSnackBar
  ) {
  }
}
