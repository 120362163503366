import { ByKey, listByIdReducer, ListState, operationByIdReducer, SingleState } from '@industi/ngx-common';
import { ActionReducer, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import { InjectionToken, Provider } from '@angular/core';
import { ArticleLoadListActionTypes } from './actions/list.actions';
import { ArticleEditActionTypes } from './actions/edit.actions';
import { ArticleAddActionTypes } from './actions/add.actions';
import { ArticleLoadDetailsActionTypes } from './actions/details.actions';
import { ArticleDeleteActionTypes } from './actions/delete.actions';

export const rootReducerKey = 'article';

export interface ArticleOperationByIdState {
  add: SingleState;
  edit: SingleState;
  details: SingleState;
  delete: SingleState;
}

export interface ArticleState {
  operationsById: ByKey<ArticleOperationByIdState>;
  list: ByKey<ListState>;
}

const initialState: ArticleState = {
  operationsById: {},
  list: {}
};

const reducer: ActionReducer<ArticleState> = combineReducers<ArticleState>({
  operationsById: operationByIdReducer<ArticleOperationByIdState>({
    mapActionToId: (a) => a?.payload?.id,
    operations: {
      add: ArticleAddActionTypes,
      edit: ArticleEditActionTypes,
      details: ArticleLoadDetailsActionTypes,
      delete: ArticleDeleteActionTypes
    }
  }),
  list: listByIdReducer({ types: ArticleLoadListActionTypes })
}, initialState);

export function getReducers(): ActionReducer<ArticleState> {
  return reducer;
}

export const ARTICLE_COMMON_REDUCER_TOKEN = new InjectionToken<ActionReducer<ArticleState>>(rootReducerKey);

export const articleCommonReducerProvider: Provider = { provide: ARTICLE_COMMON_REDUCER_TOKEN, useFactory: getReducers };

const getState = createFeatureSelector<ArticleState>(rootReducerKey);

export const getArticleOperationsById = createSelector(
  getState,
  (state: ArticleState) => state && state.operationsById
);

export const getArticleListState = createSelector(
  getState,
  (state: ArticleState) => state && state.list
);
