import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SendByIdPayload,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { CarbonFormCommissionPayload } from '../models/form-commission-payload';

export const CarbonFormCommissionAddActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[carbon/form-commission] Add'),
  requestFail: uniqueActionType('[carbon/form-commission] Add fail'),
  requestSuccess: uniqueActionType('[carbon/form-commission] Add success'),
  requestClearErrors: uniqueActionType('[carbon/form-commission] Add clear errors'),
  requestClear: uniqueActionType('[carbon/form-commission] Add clear'),
};

export class CarbonFormCommissionAddAction implements PayloadAction {
  readonly type = CarbonFormCommissionAddActionTypes.request;

  constructor(public payload: SendByIdPayload<CarbonFormCommissionPayload>) {
  }
}

export class CarbonFormCommissionAddFailAction implements PayloadAction {
  readonly type = CarbonFormCommissionAddActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class CarbonFormCommissionAddSuccessAction implements PayloadAction {
  readonly type = CarbonFormCommissionAddActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class CarbonFormCommissionAddClearErrorsAction implements PayloadAction {
  readonly type = CarbonFormCommissionAddActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class CarbonFormCommissionAddClearAction implements PayloadAction {
  readonly type = CarbonFormCommissionAddActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
