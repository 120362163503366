import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SendByIdPayload,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { ServiceProjectPartPayload } from '../models/part-payload';

export const ServiceProjectPartEditActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[service/project-part] Edit'),
  requestFail: uniqueActionType('[service/project-part] Edit fail'),
  requestSuccess: uniqueActionType('[service/project-part] Edit success'),
  requestClearErrors: uniqueActionType('[service/project-part] Edit clear errors'),
  requestClear: uniqueActionType('[service/project-part] Edit clear')
};

export class ServiceProjectPartEditAction implements PayloadAction {
  readonly type = ServiceProjectPartEditActionTypes.request;

  constructor(public payload: SendByIdPayload<ServiceProjectPartPayload>) {
  }
}

export class ServiceProjectPartEditFailAction implements PayloadAction {
  readonly type = ServiceProjectPartEditActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class ServiceProjectPartEditSuccessAction implements PayloadAction {
  readonly type = ServiceProjectPartEditActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class ServiceProjectPartEditClearErrorsAction implements PayloadAction {
  readonly type = ServiceProjectPartEditActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class ServiceProjectPartEditClearAction implements PayloadAction {
  readonly type = ServiceProjectPartEditActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
