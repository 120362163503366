import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';

export const EbayLoadOfferConfigurationActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[ebay-configuration/offer] Load Offer configuration'),
  requestFail: uniqueActionType('[ebay-configuration/offer] Load Offer configuration fail'),
  requestSuccess: uniqueActionType('[ebay-configuration/offer] Load Offer configuration success'),
  requestClearErrors: uniqueActionType('[ebay-configuration/offer] Load Offer configuration clear errors'),
  requestClear: uniqueActionType('[ebay-configuration/offer] Load Offer configuration clear')
};

export class EbayLoadOfferConfigurationAction implements PayloadAction {
  readonly type = EbayLoadOfferConfigurationActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class EbayLoadOfferConfigurationFailAction implements PayloadAction {
  readonly type = EbayLoadOfferConfigurationActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class EbayLoadOfferConfigurationSuccessAction implements PayloadAction {
  readonly type = EbayLoadOfferConfigurationActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class EbayLoadOfferConfigurationClearAction implements PayloadAction {
  readonly type = EbayLoadOfferConfigurationActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}

export class EbayLoadOfferConfigurationClearErrorsAction implements PayloadAction {
  readonly type = EbayLoadOfferConfigurationActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}
