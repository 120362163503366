import {
  ByIdPayload,
  ChangeParamsByIdPayload,
  FailByIdPayload,
  ListReducerTypes,
  NormalizedList,
  PayloadAction,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { DiagramPartListParams } from '../models/part-list-params';

export const DiagramPartLoadListActionTypes: ListReducerTypes = {
  request: uniqueActionType('[diagram/part] Load list'),
  requestFail: uniqueActionType('[diagram/part] Load list fail'),
  requestSuccess: uniqueActionType('[diagram/part] Load list success'),
  requestClear: uniqueActionType('[diagram/part] Load list clear'),
  changeParams: uniqueActionType('[diagram/part] Load list change params'),
  clearParams: uniqueActionType('[diagram/part] Load list clear params'),
  clearIds: uniqueActionType('[diagram/part] Load list clear ids')
};

export class DiagramPartLoadListAction implements PayloadAction {
  readonly type = DiagramPartLoadListActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class DiagramPartLoadListFailAction implements PayloadAction {
  readonly type = DiagramPartLoadListActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class DiagramPartLoadListSuccessAction implements PayloadAction {
  readonly type = DiagramPartLoadListActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedList>) {
  }
}

export class DiagramPartLoadListClearAction implements PayloadAction {
  readonly type = DiagramPartLoadListActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}

export class DiagramPartLoadListChangeParamsAction implements PayloadAction {
  readonly type = DiagramPartLoadListActionTypes.changeParams;

  constructor(public payload: ChangeParamsByIdPayload<DiagramPartListParams>) {
  }
}

export class DiagramPartLoadListClearParamsAction implements PayloadAction {
  readonly type = DiagramPartLoadListActionTypes.clearParams;

  constructor(public payload: ByIdPayload) {
  }
}


export class DiagramPartLoadListClearIdsAction implements PayloadAction {
  readonly type = DiagramPartLoadListActionTypes.clearIds;

  constructor(public payload: ByIdPayload) {
  }
}
