import { ActionReducer } from '@ngrx/store';
import { ByKey, PayloadAction } from '@industi/ngx-common';

export type EntityState<T = any> = ByKey<T>;

const getEntitiesFromAction = (action: PayloadAction, entityType: string): EntityState => {
  const payload = action && action.payload;
  const entities = (payload && payload.entities) || (payload && payload.res && payload.res.entities);
  return entities && entities[entityType];
};

export function entityReducer(entityType: string): ActionReducer<EntityState, PayloadAction> {
  return function (state: EntityState = {}, action: PayloadAction): EntityState {
    const entitiesFromAction = getEntitiesFromAction(action, entityType);
    if (entitiesFromAction && Object.keys(entitiesFromAction).length > 0) {
      return {
        ...state,
        ...Object
          .keys(entitiesFromAction)
          .reduce((result: EntityState, currentId: string) => <EntityState> {
            ...result,
            [currentId]: {
              ...(state[currentId] || {}),
              ...(entitiesFromAction[currentId] || {})
            }
          }, {})
      };
    }
    return state;
  };
}
