import {
  ByKey,
  createListStateIdsSelector,
  createListStateParamsSelector,
  createStateErrorsSelector,
  createStateLoadingSelector,
  createStateSuccessSelector,
  IdType,
  ListState
} from '@industi/ngx-common';
import { createSelector } from '@ngrx/store';
import { getOrderItemListState } from '../item.reducer';
import { createEntitiesSelector } from '../../../entity/entities.reducer';
import { EntityTypes } from '../../../entity/entity-types';
import { EntityState } from '../../../entity/entity.reducer';
import { denormalize } from 'normalizr';
import { Entities } from '../../../entity/entities';
import { OrderItem } from '../models/item';
import { createSymfonyTotalCountSelector } from '../../../symfony/list/selectors/list.selectors';

const createState = (id: IdType) => createSelector(
  getOrderItemListState,
  (state: ByKey<ListState>) => state && state[id] && state[id]
);

const createStateById = (id: IdType) => createListStateIdsSelector(createState(id));

export const createOrderItemListData = (id: IdType) => createSelector(
  createStateById(id),
  createEntitiesSelector([
    EntityTypes.orderItem
  ]),
  (
    ids: IdType[],
    entities: EntityState
  ) => denormalize(ids, [Entities[EntityTypes.orderItem]], entities) as OrderItem[]
);

export const createOrderItemListLoading = (id: IdType) => createStateLoadingSelector(
  createState(id));

export const createOrderItemListSuccess = (id: IdType) => createStateSuccessSelector(
  createState(id));

export const createOrderItemListErrors = (id: IdType) => createStateErrorsSelector(
  createState(id));

export const createOrderItemListTotalCount = (id: IdType) => createSymfonyTotalCountSelector(
  createState(id));

export const createOrderItemListParams = (id: IdType) => createListStateParamsSelector(
  createState(id));
