import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SendByIdPayload,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { OrderItemPayload } from '../models/item-payload';

export const OrderItemAddActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[order/item] Add'),
  requestFail: uniqueActionType('[order/item] Add fail'),
  requestSuccess: uniqueActionType('[order/item] Add success'),
  requestClearErrors: uniqueActionType('[order/item] Add clear errors'),
  requestClear: uniqueActionType('[order/item] Add clear'),
};

export class OrderItemAddAction implements PayloadAction {
  readonly type = OrderItemAddActionTypes.request;

  constructor(public payload: SendByIdPayload<OrderItemPayload>) {
  }
}

export class OrderItemAddFailAction implements PayloadAction {
  readonly type = OrderItemAddActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class OrderItemAddSuccessAction implements PayloadAction {
  readonly type = OrderItemAddActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class OrderItemAddClearErrorsAction implements PayloadAction {
  readonly type = OrderItemAddActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class OrderItemAddClearAction implements PayloadAction {
  readonly type = OrderItemAddActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
