import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, first, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { ByIdPayload, ByKey, IdType, NormalizedList, NormalizedSingle } from '@industi/ngx-common';
import { select, Store } from '@ngrx/store';
import { getInstanceLocale } from '../../instance/instance.reducer';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import {
  DictionaryCarbonMaterialEditAction,
  DictionaryCarbonMaterialEditActionTypes,
  DictionaryCarbonMaterialEditFailAction,
  DictionaryCarbonMaterialEditSuccessAction
} from './actions/edit.actions';
import {
  DictionaryCarbonMaterialLoadListAction,
  DictionaryCarbonMaterialLoadListActionTypes,
  DictionaryCarbonMaterialLoadListClearIdsAction,
  DictionaryCarbonMaterialLoadListFailAction,
  DictionaryCarbonMaterialLoadListSuccessAction
} from './actions/list.actions';
import {
  DictionaryCarbonMaterialAddAction,
  DictionaryCarbonMaterialAddActionTypes,
  DictionaryCarbonMaterialAddFailAction,
  DictionaryCarbonMaterialAddSuccessAction
} from './actions/add.actions';
import {
  DictionaryCarbonMaterialLoadDetailsAction,
  DictionaryCarbonMaterialLoadDetailsActionTypes,
  DictionaryCarbonMaterialLoadDetailsFailAction,
  DictionaryCarbonMaterialLoadDetailsSuccessAction
} from './actions/load-details.actions';
import { DictionaryCarbonMaterialService } from './carbon-material.service';
import { SymfonyPaginationParams } from '../../symfony/list/models/symfony-pagination-params';
import { createDictionaryCarbonMaterialListParams } from './selectors/get-load-list.selectors';
import {
  DictionaryCarbonMaterialDeleteAction,
  DictionaryCarbonMaterialDeleteActionTypes,
  DictionaryCarbonMaterialDeleteFailAction,
  DictionaryCarbonMaterialDeleteSuccessAction
} from './actions/delete.actions';
import { ResponseStatus } from '../../../shared/enums/response-status.enum';
import { LibSnackbarComponent } from '@industi/ngx-modules';
import { AppGlobal } from '../../../shared/app-global';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlertUtil } from '../../../shared/utils/alert.util';

@Injectable()
export class DictionaryCarbonMaterialEffects {

  @Effect()
  loadList$ = this.actions$.pipe(
    ofType(DictionaryCarbonMaterialLoadListActionTypes.request),
    map((action: DictionaryCarbonMaterialLoadListAction) => action.payload),
    switchMap((payload: ByIdPayload) => this.store.pipe(
      select(createDictionaryCarbonMaterialListParams(payload.id)),
      map((params: SymfonyPaginationParams) => [payload.id, params] as [IdType, SymfonyPaginationParams]),
      first()
    )),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([[ id, params ], locale]) => this.service.loadList(locale, params).pipe(
      mergeMap((res: NormalizedList) => [
        new DictionaryCarbonMaterialLoadListClearIdsAction({ id }),
        new DictionaryCarbonMaterialLoadListSuccessAction({ id, res })]),
      catchError((errors: HttpErrorResponse) => of(new DictionaryCarbonMaterialLoadListFailAction({ id, errors })))
    ))
  );

  @Effect()
  add$ = this.actions$.pipe(
    ofType(DictionaryCarbonMaterialAddActionTypes.request),
    map((action: DictionaryCarbonMaterialAddAction) => action.payload),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([{ id, data }, locale]) => this.service.add(locale, data).pipe(
      map((res: NormalizedSingle) => new DictionaryCarbonMaterialAddSuccessAction({ res, id })),
      catchError((errors: HttpErrorResponse) => of(new DictionaryCarbonMaterialAddFailAction({ errors, id })))
    ))
  );

  @Effect()
  edit$ = this.actions$.pipe(
    ofType(DictionaryCarbonMaterialEditActionTypes.request),
    map((action: DictionaryCarbonMaterialEditAction) => action.payload),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([{ id, data }, locale]) => this.service.edit(locale, data, id).pipe(
      map((res: NormalizedSingle) => new DictionaryCarbonMaterialEditSuccessAction({ res, id })),
      catchError((errors: HttpErrorResponse) => of(new DictionaryCarbonMaterialEditFailAction({ errors, id })))
    ))
  );

  @Effect()
  loadDetails$ = this.actions$.pipe(
    ofType(DictionaryCarbonMaterialLoadDetailsActionTypes.request),
    map((action: DictionaryCarbonMaterialLoadDetailsAction) => action.payload.id),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([id, locale]) => this.service.details(locale, id).pipe(
      map((res: NormalizedSingle) => new DictionaryCarbonMaterialLoadDetailsSuccessAction({ id, res })),
      catchError((errors: HttpErrorResponse) => of(new DictionaryCarbonMaterialLoadDetailsFailAction({ id, errors })))
    ))
  );

  @Effect()
  delete$ = this.actions$.pipe(
    ofType(DictionaryCarbonMaterialDeleteActionTypes.request),
    map((action: DictionaryCarbonMaterialDeleteAction) => action.payload.id),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([id, locale]) => this.service.delete(locale, id).pipe(
      map(() => new DictionaryCarbonMaterialDeleteSuccessAction({ id })),
      catchError((errors: HttpErrorResponse) => of(new DictionaryCarbonMaterialDeleteFailAction({ id, errors })))
    ))
  );

  @Effect({ dispatch: false })
  crudFail$ = this.actions$.pipe(
    ofType(
      DictionaryCarbonMaterialAddActionTypes.requestFail,
      DictionaryCarbonMaterialEditActionTypes.requestFail
    ),
    map((action: DictionaryCarbonMaterialAddFailAction | DictionaryCarbonMaterialEditFailAction) => action.payload.errors),
    tap((data: HttpErrorResponse) => {
      if (data?.status === ResponseStatus.BAD_REQUEST || data?.status === ResponseStatus.UNPROCESSABLE_ENTITY) {
        this.snackBar.openFromComponent(LibSnackbarComponent, {
          data: {
            message: AlertUtil.getControlTranslation(data.error.detail),
            type: 'error'
          },
          duration: AppGlobal.defaultSnackBarDuration
        });
      }
    })
  );

  constructor(
    private actions$: Actions,
    private store: Store<ByKey>,
    private service: DictionaryCarbonMaterialService,
    private snackBar: MatSnackBar
  ) {
  }
}
