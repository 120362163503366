import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, timer } from 'rxjs';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit, OnDestroy {

  protected destroyed$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private router: Router
  ) {
  }

  ngOnInit() {
    timer(3000).pipe(
      takeUntil(this.destroyed$)
    ).subscribe(() => {
      this.router.navigate(['/']);
    });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
