import { Inject, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import {
  LibAuthGuardActionTypes,
  LibAuthLoginActionTypes,
  LibAuthLoginFailAction,
  LibAuthSetTokenAction,
  LibAuthSetTokenActionTypes,
  TokenResponse
} from 'app-store-auth';
import { delay, map, tap } from 'rxjs/operators';
import { AuthAfterLoginRouteInjectionToken, StartRouteInjectionToken } from '../../app.providers';
import { HttpErrorResponse } from '@angular/common/http';
import { AppGlobal } from '../../shared/app-global';
import { ResponseStatus } from '../../shared/enums/response-status.enum';
import { LibSnackbarComponent } from '@industi/ngx-modules';

@Injectable()
export class AuthEffects {

  @Effect({ dispatch: false })
  accessTokenGuardReject$ = this.actions$.pipe(
    ofType(LibAuthGuardActionTypes.accessTokenReject),
    tap(() => {
      this.router.navigate(this.startRoute);
    })
  );

  @Effect({ dispatch: false })
  loginGuardReject$ = this.actions$.pipe(
    ofType(LibAuthGuardActionTypes.loginReject),
    tap(() => {
      this.router.navigate(this.afterLoginRoute);
    })
  );

  // @Effect({ dispatch: false })
  // setToken$ = this.actions$.pipe(
  //   ofType(LibAuthSetTokenActionTypes.setValue),
  //   map((action: LibAuthSetTokenAction) => action.payload.value),
  //   tap((token: TokenResponse) => {
  //     if (!token) {
  //       this.router.navigate(this.startRoute);
  //     }
  //   })
  // );

  @Effect({ dispatch: false })
  loginSuccess$ = this.actions$.pipe(
    ofType(LibAuthLoginActionTypes.requestSuccess),
    delay(1),
    tap(() => {
      this.router.navigate(this.afterLoginRoute);
    })
  );

  @Effect({ dispatch: false })
  loginFail$ = this.actions$.pipe(
    ofType(LibAuthLoginActionTypes.requestFail),
    map((action: LibAuthLoginFailAction) => action.payload.errors),
    tap((data: HttpErrorResponse) => {
      if (data?.status === ResponseStatus.BAD_REQUEST || data?.status === ResponseStatus.UNAUTHORIZED) {
        this.snackBar.openFromComponent(LibSnackbarComponent, {
          data: {
            message: 'Nieprawidłowe dane logowania',
            type: 'error'
          },
          duration: AppGlobal.defaultSnackBarDuration
        });
      }
    })
  );

  constructor(
    private router: Router,
    private actions$: Actions,
    private snackBar: MatSnackBar,
    @Inject(StartRouteInjectionToken) private startRoute: string[],
    @Inject(AuthAfterLoginRouteInjectionToken) private afterLoginRoute: string[]
  ) {
  }

}
