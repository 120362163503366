import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SendByIdPayload,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { LayerPayload } from '../models/layer-payload';

export const LayerAddActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[layer] Add'),
  requestFail: uniqueActionType('[layer] Add fail'),
  requestSuccess: uniqueActionType('[layer] Add success'),
  requestClearErrors: uniqueActionType('[layer] Add clear errors'),
  requestClear: uniqueActionType('[layer] Add clear'),
};

export class LayerAddAction implements PayloadAction {
  readonly type = LayerAddActionTypes.request;

  constructor(public payload: SendByIdPayload<LayerPayload>) {
  }
}

export class LayerAddFailAction implements PayloadAction {
  readonly type = LayerAddActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class LayerAddSuccessAction implements PayloadAction {
  readonly type = LayerAddActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class LayerAddClearErrorsAction implements PayloadAction {
  readonly type = LayerAddActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class LayerAddClearAction implements PayloadAction {
  readonly type = LayerAddActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
