import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SendByIdPayload,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { Dictionary } from '../../../../shared/models/dictionary';

export const DictionaryProcessEditActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[dictionary/process] Edit'),
  requestFail: uniqueActionType('[dictionary/process] Edit fail'),
  requestSuccess: uniqueActionType('[dictionary/process] Edit success'),
  requestClearErrors: uniqueActionType('[dictionary/process] Edit clear errors'),
  requestClear: uniqueActionType('[dictionary/process] Edit clear')
};

export class DictionaryProcessEditAction implements PayloadAction {
  readonly type = DictionaryProcessEditActionTypes.request;

  constructor(public payload: SendByIdPayload<Dictionary>) {
  }
}

export class DictionaryProcessEditFailAction implements PayloadAction {
  readonly type = DictionaryProcessEditActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class DictionaryProcessEditSuccessAction implements PayloadAction {
  readonly type = DictionaryProcessEditActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class DictionaryProcessEditClearErrorsAction implements PayloadAction {
  readonly type = DictionaryProcessEditActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class DictionaryProcessEditClearAction implements PayloadAction {
  readonly type = DictionaryProcessEditActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
