import { Component, OnInit } from '@angular/core';
import { VersionUpdateService } from './version-update/version-update.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(
    private updateService: VersionUpdateService
  ) {
  }

  ngOnInit() {
    this.initVersionUpdater();
  }

  private initVersionUpdater(): void {
    this.updateService.updateOnNewVersionAvailable();
  }
}
