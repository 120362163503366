import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, first, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { ByIdPayload, ByKey, IdType, NormalizedList, NormalizedSingle } from '@industi/ngx-common';
import { select, Store } from '@ngrx/store';
import { getInstanceLocale } from '../../instance/instance.reducer';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import {
  DictionaryUnitStateEditAction,
  DictionaryUnitStateEditActionTypes,
  DictionaryUnitStateEditFailAction,
  DictionaryUnitStateEditSuccessAction
} from './actions/edit.actions';
import {
  DictionaryUnitStateAddAction,
  DictionaryUnitStateAddActionTypes,
  DictionaryUnitStateAddFailAction,
  DictionaryUnitStateAddSuccessAction
} from './actions/add.actions';
import { DictionaryUnitStateService } from './unit-state.service';
import {
  DictionaryUnitStateLoadListAction,
  DictionaryUnitStateLoadListActionTypes,
  DictionaryUnitStateLoadListClearIdsAction,
  DictionaryUnitStateLoadListFailAction,
  DictionaryUnitStateLoadListSuccessAction
} from './actions/list.actions';
import {
  DictionaryUnitStateLoadDetailsAction,
  DictionaryUnitStateLoadDetailsActionTypes,
  DictionaryUnitStateLoadDetailsFailAction,
  DictionaryUnitStateLoadDetailsSuccessAction
} from './actions/load-details.actions';
import { DictionaryUnitStateListParams } from './models/unit-state-list-params';
import { createDictionaryUnitStateListParams } from './selectors/get-load-list.selectors';
import {
  DictionaryUnitStateDeleteAction,
  DictionaryUnitStateDeleteActionTypes,
  DictionaryUnitStateDeleteFailAction,
  DictionaryUnitStateDeleteSuccessAction
} from './actions/delete.actions';
import { ResponseStatus } from '../../../shared/enums/response-status.enum';
import { LibSnackbarComponent } from '@industi/ngx-modules';
import { AppGlobal } from '../../../shared/app-global';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlertUtil } from '../../../shared/utils/alert.util';

@Injectable()
export class DictionaryUnitStateEffects {

  @Effect()
  loadList$ = this.actions$.pipe(
    ofType(DictionaryUnitStateLoadListActionTypes.request),
    map((action: DictionaryUnitStateLoadListAction) => action.payload),
    switchMap((payload: ByIdPayload) => this.store.pipe(
      select(createDictionaryUnitStateListParams(payload.id)),
      map((params: DictionaryUnitStateListParams) => [payload.id, params] as [IdType, DictionaryUnitStateListParams]),
      first()
    )),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([[ id, params ], locale]) => this.service.loadList(locale, params).pipe(
      mergeMap((res: NormalizedList) => [
        new DictionaryUnitStateLoadListClearIdsAction({ id }),
        new DictionaryUnitStateLoadListSuccessAction({ id, res })]),
      catchError((errors: HttpErrorResponse) => of(new DictionaryUnitStateLoadListFailAction({ id, errors })))
    ))
  );

  @Effect()
  add$ = this.actions$.pipe(
    ofType(DictionaryUnitStateAddActionTypes.request),
    map((action: DictionaryUnitStateAddAction) => action.payload),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([{ id, data }, locale]) => this.service.add(locale, data).pipe(
      map((res: NormalizedSingle) => new DictionaryUnitStateAddSuccessAction({ res, id })),
      catchError((errors: HttpErrorResponse) => of(new DictionaryUnitStateAddFailAction({ errors, id })))
    ))
  );

  @Effect()
  edit$ = this.actions$.pipe(
    ofType(DictionaryUnitStateEditActionTypes.request),
    map((action: DictionaryUnitStateEditAction) => action.payload),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([{ id, data }, locale]) => this.service.edit(locale, data, id).pipe(
      map((res: NormalizedSingle) => new DictionaryUnitStateEditSuccessAction({ res, id })),
      catchError((errors: HttpErrorResponse) => of(new DictionaryUnitStateEditFailAction({ errors, id })))
    ))
  );

  @Effect()
  loadDetails$ = this.actions$.pipe(
    ofType(DictionaryUnitStateLoadDetailsActionTypes.request),
    map((action: DictionaryUnitStateLoadDetailsAction) => action.payload.id),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([id, locale]) => this.service.details(locale, id).pipe(
      map((res: NormalizedSingle) => new DictionaryUnitStateLoadDetailsSuccessAction({ id, res })),
      catchError((errors: HttpErrorResponse) => of(new DictionaryUnitStateLoadDetailsFailAction({ id, errors })))
    ))
  );

  @Effect()
  delete$ = this.actions$.pipe(
    ofType(DictionaryUnitStateDeleteActionTypes.request),
    map((action: DictionaryUnitStateDeleteAction) => action.payload.id),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([id, locale]) => this.service.delete(locale, id).pipe(
      map(() => new DictionaryUnitStateDeleteSuccessAction({ id })),
      catchError((errors: HttpErrorResponse) => of(new DictionaryUnitStateDeleteFailAction({ id, errors })))
    ))
  );

  @Effect({ dispatch: false })
  crudFail$ = this.actions$.pipe(
    ofType(
      DictionaryUnitStateAddActionTypes.requestFail,
      DictionaryUnitStateEditActionTypes.requestFail
    ),
    map((action: DictionaryUnitStateAddFailAction | DictionaryUnitStateEditFailAction) => action.payload.errors),
    tap((data: HttpErrorResponse) => {
      if (data?.status === ResponseStatus.BAD_REQUEST || data?.status === ResponseStatus.UNPROCESSABLE_ENTITY) {
        this.snackBar.openFromComponent(LibSnackbarComponent, {
          data: {
            message: AlertUtil.getControlTranslation(data.error.detail),
            type: 'error'
          },
          duration: AppGlobal.defaultSnackBarDuration
        });
      }
    })
  );

  constructor(
    private actions$: Actions,
    private store: Store<ByKey>,
    private service: DictionaryUnitStateService,
    private snackBar: MatSnackBar
  ) {
  }
}
