import {
  ByIdPayload,
  ChangeParamsByIdPayload,
  FailByIdPayload,
  ListReducerTypes,
  NormalizedList,
  PayloadAction,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { LayerListParams } from '../models/layer-list-params';

export const LayerLoadListActionTypes: ListReducerTypes = {
  request: uniqueActionType('[layer] Load list'),
  requestFail: uniqueActionType('[layer] Load list fail'),
  requestSuccess: uniqueActionType('[layer] Load list success'),
  requestClear: uniqueActionType('[layer] Load list clear'),
  changeParams: uniqueActionType('[layer] Load list change params'),
  clearParams: uniqueActionType('[layer] Load list clear params'),
  clearIds: uniqueActionType('[layer] Load list clear ids')
};

export class LayerLoadListAction implements PayloadAction {
  readonly type = LayerLoadListActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class LayerLoadListFailAction implements PayloadAction {
  readonly type = LayerLoadListActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class LayerLoadListSuccessAction implements PayloadAction {
  readonly type = LayerLoadListActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedList>) {
  }
}

export class LayerLoadListClearAction implements PayloadAction {
  readonly type = LayerLoadListActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}

export class LayerLoadListChangeParamsAction implements PayloadAction {
  readonly type = LayerLoadListActionTypes.changeParams;

  constructor(public payload: ChangeParamsByIdPayload<LayerListParams>) {
  }
}

export class LayerLoadListClearParamsAction implements PayloadAction {
  readonly type = LayerLoadListActionTypes.clearParams;

  constructor(public payload: ByIdPayload) {
  }
}


export class LayerLoadListClearIdsAction implements PayloadAction {
  readonly type = LayerLoadListActionTypes.clearIds;

  constructor(public payload: ByIdPayload) {
  }
}
