import {
  ByKey,
  IdType,
  listByIdReducer,
  ListState,
  operationByIdReducer,
  setValueReducer,
  SingleState
} from '@industi/ngx-common';
import { ActionReducer, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import { InjectionToken, Provider } from '@angular/core';
import { ArticleBrandAddActionTypes } from './actions/add.actions';
import { ArticleBrandLoadDetailsActionTypes } from './actions/details.actions';
import { ArticleBrandLoadListActionTypes } from './actions/list.actions';
import { ArticleBrandEditActionTypes } from './actions/edit.actions';
import { ArticleBrandDeleteActionTypes } from './actions/delete.actions';
import { ArticleBrandSelectLastAddedIdActionTypes } from './actions/select-last-added-id.actions';
import { ArticleBrandImageAddActionTypes } from './actions/add-image.actions';
import { ArticleBrandImageSelectLastAddedIdActionTypes } from './actions/select-last-added-image-id.actions';
import { ArticleBrandImageDeleteActionTypes } from './actions/delete-image.actions';

export const rootReducerKey = 'articleBrand';

export interface ArticleBrandOperationByIdState {
  add: SingleState;
  edit: SingleState;
  details: SingleState;
  delete: SingleState;
  addImage: SingleState;
  deleteImage: SingleState;
}

export interface ArticleBrandState {
  operationsById: ByKey<ArticleBrandOperationByIdState>;
  list: ByKey<ListState>;
  lastAddedId: IdType;
  lastAddedImageId: IdType;
}

const initialState: ArticleBrandState = {
  operationsById: {},
  list: {},
  lastAddedId: null,
  lastAddedImageId: null
};

const reducer: ActionReducer<ArticleBrandState> = combineReducers<ArticleBrandState>({
  operationsById: operationByIdReducer<ArticleBrandOperationByIdState>({
    mapActionToId: (a) => a?.payload?.id,
    operations: {
      add: ArticleBrandAddActionTypes,
      edit: ArticleBrandEditActionTypes,
      details: ArticleBrandLoadDetailsActionTypes,
      delete: ArticleBrandDeleteActionTypes,
      addImage: ArticleBrandImageAddActionTypes,
      deleteImage: ArticleBrandImageDeleteActionTypes
    }
  }),
  list: listByIdReducer({ types: ArticleBrandLoadListActionTypes }),
  lastAddedId: setValueReducer({ types: ArticleBrandSelectLastAddedIdActionTypes }),
  lastAddedImageId: setValueReducer({ types: ArticleBrandImageSelectLastAddedIdActionTypes })
}, initialState);

export function getReducers(): ActionReducer<ArticleBrandState> {
  return reducer;
}

export const ARTICLE_BRAND_REDUCER_TOKEN = new InjectionToken<ActionReducer<ArticleBrandState>>(rootReducerKey);

export const articleBrandReducerProvider: Provider = { provide: ARTICLE_BRAND_REDUCER_TOKEN, useFactory: getReducers };

const getState = createFeatureSelector<ArticleBrandState>(rootReducerKey);

export const getArticleBrandOperationsById = createSelector(
  getState,
  (state: ArticleBrandState) => state && state.operationsById
);

export const getArticleBrandListState = createSelector(
  getState,
  (state: ArticleBrandState) => state && state.list
);

export const getArticleBrandLastAddedId = createSelector(
  getState,
  (state: ArticleBrandState) => state && state.lastAddedId
);

export const getArticleBrandImageLastAddedId = createSelector(
  getState,
  (state: ArticleBrandState) => state && state.lastAddedImageId
);
