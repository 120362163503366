import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { rootReducerKey, USER_REDUCER_TOKEN, userReducerProvider } from './user.reducer';
import { EffectsModule } from '@ngrx/effects';
import { UserEffects } from './user.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(rootReducerKey, USER_REDUCER_TOKEN),
    EffectsModule.forFeature([UserEffects])
  ],
  providers: [userReducerProvider]
})
export class UserStoreModule { }
