import { Inject, Injectable } from '@angular/core';
import { API_URL } from '../../../app-store-providers';
import { HttpClient } from '@angular/common/http';
import { EntityTypes } from '../../../entity/entity-types';
import { ServiceProject } from './models/project';
import { ServiceProjectListParams } from './models/project-list-params';
import { ServiceProjectPayload } from './models/project-payload';
import { StoreBaseService } from '../../../common/base.service';

@Injectable({
  providedIn: 'root'
})
export class ServiceProjectCommonService
  extends StoreBaseService<ServiceProject, ServiceProjectPayload, ServiceProjectListParams> {

  get entityType(): string {
    return EntityTypes.project;
  }

  get resourceName(): string {
    return 'service/project';
  }

  constructor(
    @Inject(API_URL) apiUrl: string,
    http: HttpClient
  ) {
    super(apiUrl, http);
  }
}
