import { ByKey, listByIdReducer, ListState, operationByIdReducer, SingleState } from '@industi/ngx-common';
import { ActionReducer, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import { InjectionToken, Provider } from '@angular/core';
import { LayerLoadListActionTypes } from './actions/list.actions';
import { LayerAddActionTypes } from './actions/add.actions';
import { LayerEditActionTypes } from './actions/edit.actions';
import { LayerLoadDetailsActionTypes } from './actions/details.actions';
import { LayerDeleteActionTypes } from './actions/delete.actions';

export const rootReducerKey = 'layer';

export interface LayerOperationByIdState {
  add: SingleState;
  edit: SingleState;
  details: SingleState;
  delete: SingleState;
}

export interface LayerState {
  list: ByKey<ListState>;
  operationsById: ByKey<LayerOperationByIdState>;
}

const initialState: LayerState = {
  list: {},
  operationsById: {}
};

const reducer: ActionReducer<LayerState> = combineReducers<LayerState>({
  operationsById: operationByIdReducer<LayerOperationByIdState>({
    mapActionToId: (a) => a?.payload?.id,
    operations: {
      add: LayerAddActionTypes,
      edit: LayerEditActionTypes,
      details: LayerLoadDetailsActionTypes,
      delete: LayerDeleteActionTypes
    }
  }),
  list: listByIdReducer({ types: LayerLoadListActionTypes })
}, initialState);

export function getReducers(): ActionReducer<LayerState> {
  return reducer;
}

export const LAYER_REDUCER_TOKEN = new InjectionToken<ActionReducer<LayerState>>(rootReducerKey);

export const layerReducerProvider: Provider = { provide: LAYER_REDUCER_TOKEN, useFactory: getReducers };

const getState = createFeatureSelector<LayerState>(rootReducerKey);

export const getLayerListState = createSelector(
  getState,
  (state: LayerState) => state && state.list
);

export const getLayerOperationsById = createSelector(
  getState,
  (state: LayerState) => state && state.operationsById
);
