import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';

export const EbayLoadConfigurationActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[ebay-configuration] Load configuration'),
  requestFail: uniqueActionType('[ebay-configuration] Load configuration fail'),
  requestSuccess: uniqueActionType('[ebay-configuration] Load configuration success'),
  requestClearErrors: uniqueActionType('[ebay-configuration] Load configuration clear errors'),
  requestClear: uniqueActionType('[ebay-configuration] Load configuration clear')
};

export class EbayLoadConfigurationAction implements PayloadAction {
  readonly type = EbayLoadConfigurationActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class EbayLoadConfigurationFailAction implements PayloadAction {
  readonly type = EbayLoadConfigurationActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class EbayLoadConfigurationSuccessAction implements PayloadAction {
  readonly type = EbayLoadConfigurationActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class EbayLoadConfigurationClearAction implements PayloadAction {
  readonly type = EbayLoadConfigurationActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}

export class EbayLoadConfigurationClearErrorsAction implements PayloadAction {
  readonly type = EbayLoadConfigurationActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}
