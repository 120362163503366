import { schema } from 'normalizr';
import { EntityTypes } from './entity-types';

const idAttribute = 'id';
const Entities: { [schemaName: string]: schema.Entity } = {};

[
  EntityTypes.acquisition,
  EntityTypes.application,
  EntityTypes.article,
  EntityTypes.articleBrand,
  EntityTypes.articleContent,
  EntityTypes.articleGalleryContent,
  EntityTypes.articleImageContent,
  EntityTypes.articleTextContent,
  EntityTypes.articleVideoContent,
  EntityTypes.brand,
  EntityTypes.carbonCommissionTask,
  EntityTypes.carbonMaterial,
  EntityTypes.carbonMaterialType,
  EntityTypes.carbonForm,
  EntityTypes.carbonFormCommission,
  EntityTypes.carbonPartCommission,
  EntityTypes.courier,
  EntityTypes.client,
  EntityTypes.diagram,
  EntityTypes.diagramPart,
  EntityTypes.discountGroup,
  EntityTypes.ebay,
  EntityTypes.ebayCompatibility,
  EntityTypes.ebayCompatibilityValue,
  EntityTypes.icsConversation,
  EntityTypes.image,
  EntityTypes.item,
  EntityTypes.layer,
  EntityTypes.materialExploitation,
  EntityTypes.model,
  EntityTypes.offer,
  EntityTypes.offerItem,
  EntityTypes.order,
  EntityTypes.orderItem,
  EntityTypes.operation,
  EntityTypes.part,
  EntityTypes.process,
  EntityTypes.project,
  EntityTypes.projectItem,
  EntityTypes.projectOperation,
  EntityTypes.projectPart,
  EntityTypes.tag,
  EntityTypes.timeline,
  EntityTypes.unitState,
  EntityTypes.user,
  EntityTypes.warehouse,
  EntityTypes.warehouseHall,
].forEach((entityType: string) => {
  Entities[entityType] = new schema.Entity(entityType, {}, { idAttribute });
});

Entities[EntityTypes.item].define({
  itemImages: [Entities[EntityTypes.image]]
});

Entities[EntityTypes.offer].define({
  offerImages: [Entities[EntityTypes.image]],
  offerItems: [Entities[EntityTypes.offerItem]],
});

Entities[EntityTypes.order].define({
  orderItems: [Entities[EntityTypes.orderItem]],
});

Entities[EntityTypes.diagram].define({
  diagramParts: [Entities[EntityTypes.diagramPart]]
});

Entities[EntityTypes.diagramPart].define({
  parts: [Entities[EntityTypes.part]]
});

Entities[EntityTypes.carbonPartCommission].define({
  forms: [Entities[EntityTypes.carbonForm]]
});

Entities[EntityTypes.carbonFormCommission].define({
  item: Entities[EntityTypes.item]
});

Entities[EntityTypes.project].define({
  client: Entities[EntityTypes.client]
});

Entities[EntityTypes.projectItem].define({
  item: Entities[EntityTypes.item]
});

export { Entities };
