import {
  ByKey,
  createListStateIdsSelector,
  createListStateParamsSelector,
  createStateErrorsSelector,
  createStateLoadingSelector,
  createStateSuccessSelector,
  IdType,
  ListState
} from '@industi/ngx-common';
import { createSelector } from '@ngrx/store';
import { createEntitiesSelector } from '../../entity/entities.reducer';
import { EntityTypes } from '../../entity/entity-types';
import { EntityState } from '../../entity/entity.reducer';
import { denormalize } from 'normalizr';
import { Entities } from '../../entity/entities';
import { TimelineItem } from '../models/timeline-item';
import { getTimelineListState } from '../timeline.reducer';
import { createSymfonyTotalCountSelector } from '../../symfony/list/selectors/list.selectors';

const createState = (id: IdType) => createSelector(
  getTimelineListState,
  (state: ByKey<ListState>) => state && state[id]
);

const createStateByContext = (id: IdType) => createListStateIdsSelector(createState(id));

export const createTimelineListData = (id: IdType) => createSelector(
  createStateByContext(id),
  createEntitiesSelector([
    EntityTypes.timeline
  ]),
  (
    ids: IdType[],
    entities: EntityState
  ) => denormalize(ids, [Entities[EntityTypes.timeline]], entities).reverse() as TimelineItem[]
);

export const createTimelineListLoading = (id: IdType) => createStateLoadingSelector(createState(id));
export const createTimelineListSuccess = (id: IdType) => createStateSuccessSelector(createState(id));
export const createTimelineListErrors = (id: IdType) => createStateErrorsSelector(createState(id));
export const createTimelineListTotalCount = (id: IdType) => createSymfonyTotalCountSelector(createState(id));
export const createTimelineListParams = (id: IdType) => createListStateParamsSelector(createState(id));
