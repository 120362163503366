import { Injectable } from '@angular/core';
import { IdType, ListStateToComponentAdapter } from '@industi/ngx-common';
import { Action, Selector } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import * as _ from 'lodash';
import {
  DiagramLoadListAction,
  DiagramLoadListChangeParamsAction,
  DiagramLoadListClearAction,
  DiagramLoadListClearIdsAction,
  DiagramLoadListClearParamsAction
} from '../actions/list.actions';
import { AppGlobal } from '../../../../shared/app-global';
import {
  createDiagramListData,
  createDiagramListErrors,
  createDiagramListLoading,
  createDiagramListParams,
  createDiagramListSuccess,
  createDiagramListTotalCount
} from '../selectors/get-load-list.selectors';
import { DiagramListParams } from '../models/diagram-list-params';
import { Diagram } from '../models/diagram';

@Injectable({
  providedIn: 'root'
})
export class DiagramListAdapterService
  extends ListStateToComponentAdapter<Diagram, DiagramListParams> {

  apiItemToTableItem(apiModel: Diagram): Diagram {
    return apiModel;
  }

  changeParamsAction(id: IdType, params: DiagramListParams): Action {
    return new DiagramLoadListChangeParamsAction({ id, params });
  }

  clearAction(id: IdType): Action {
    return new DiagramLoadListClearAction({ id });
  }

  clearIdsAction(id: IdType): Action {
    return new DiagramLoadListClearIdsAction({ id });
  }

  clearParamsAction(id: IdType): Action {
    return new DiagramLoadListClearParamsAction({ id });
  }

  getDataSelector(id: IdType): Selector<any, Diagram[]> {
    return createDiagramListData(id);
  }

  getErrorsSelector(id: IdType): Selector<any, HttpErrorResponse> {
    return createDiagramListErrors(id);
  }

  getLoadingSelector(id: IdType): Selector<any, boolean> {
    return createDiagramListLoading(id);
  }

  getParamsSelector(id: IdType): Selector<any, DiagramListParams> {
    return createDiagramListParams(id);
  }

  getSuccessSelector(id: IdType): Selector<any, boolean> {
    return createDiagramListSuccess(id);
  }

  getTotalCountSelector(id: IdType): Selector<any, number> {
    return createDiagramListTotalCount(id);
  }

  loadListAction(id: IdType): Action {
    return new DiagramLoadListAction({ id });
  }

  tableParamsToApiParams(tableParams: DiagramListParams): DiagramListParams {
    return _.omitBy({
      ...tableParams,
      page: tableParams?.page || AppGlobal.listParams.page,
    } as DiagramListParams, _.isNil);
  }

}
