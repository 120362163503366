import { ByKey, listByIdReducer, ListState, operationByIdReducer, SingleState } from '@industi/ngx-common';
import { ActionReducer, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import { OrderItemAddActionTypes } from './actions/add.action';
import { OrderItemEditActionTypes } from './actions/edit.action';
import { OrderItemDeleteActionTypes } from './actions/delete.action';
import { OrderItemLoadDetailsActionTypes } from './actions/details.action';
import { OrderItemLoadListActionTypes } from './actions/list.action';
import { InjectionToken, Provider } from '@angular/core';

export const rootReducerKey = 'orderItem';

export interface OrderItemOperationsByIdState {
  add: SingleState;
  edit: SingleState;
  delete: SingleState;
  details: SingleState;
}

export interface OrderItemState {
  operationsById: ByKey<OrderItemOperationsByIdState>;
  list: ByKey<ListState>;
}

const initialState: OrderItemState = {
  operationsById: {},
  list: {}
};

const reducer: ActionReducer<OrderItemState> = combineReducers<OrderItemState>({
  operationsById: operationByIdReducer<OrderItemOperationsByIdState>({
    mapActionToId: (a) => a && a.payload && a.payload.id,
    operations: {
      add: OrderItemAddActionTypes,
      edit: OrderItemEditActionTypes,
      delete: OrderItemDeleteActionTypes,
      details: OrderItemLoadDetailsActionTypes
    }
  }),
  list: listByIdReducer({ types: OrderItemLoadListActionTypes })
}, initialState);

export function getReducers(): ActionReducer<OrderItemState> {
  return reducer;
}

export const ORDER_ITEM_REDUCER_TOKEN = new InjectionToken<ActionReducer<OrderItemState>>(rootReducerKey);

export const orderItemReducerProvider: Provider = {
  provide: ORDER_ITEM_REDUCER_TOKEN,
  useFactory: getReducers
};

const getState = createFeatureSelector<OrderItemState>(rootReducerKey);

export const getOrderItemOperationsById = createSelector(
  getState,
  (state: OrderItemState) => state && state.operationsById
);

export const getOrderItemListState = createSelector(
  getState,
  (state: OrderItemState) => state && state.list
);
