import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';

export const ItemImageLoadDetailsActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[item/image] Load details'),
  requestFail: uniqueActionType('[item/image] Load details fail'),
  requestSuccess: uniqueActionType('[item/image] Load details success'),
  requestClearErrors: uniqueActionType('[item/image] Load details clear errors'),
  requestClear: uniqueActionType('[item/image] Load details clear')
};

export class ItemImageLoadDetailsAction implements PayloadAction {
  readonly type = ItemImageLoadDetailsActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class ItemImageLoadDetailsFailAction implements PayloadAction {
  readonly type = ItemImageLoadDetailsActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class ItemImageLoadDetailsSuccessAction implements PayloadAction {
  readonly type = ItemImageLoadDetailsActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class ItemImageLoadDetailsClearAction implements PayloadAction {
  readonly type = ItemImageLoadDetailsActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}

export class ItemImageLoadDetailsClearErrorsAction implements PayloadAction {
  readonly type = ItemImageLoadDetailsActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}
