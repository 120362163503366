import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';

export const ArticleImageContentLoadDetailsActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[article/image-content] Load details'),
  requestFail: uniqueActionType('[article/image-content] Load details fail'),
  requestSuccess: uniqueActionType('[article/image-content] Load details success'),
  requestClearErrors: uniqueActionType('[article/image-content] Load details clear errors'),
  requestClear: uniqueActionType('[article/image-content] Load details clear')
};

export class ArticleImageContentLoadDetailsAction implements PayloadAction {
  readonly type = ArticleImageContentLoadDetailsActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class ArticleImageContentLoadDetailsFailAction implements PayloadAction {
  readonly type = ArticleImageContentLoadDetailsActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class ArticleImageContentLoadDetailsSuccessAction implements PayloadAction {
  readonly type = ArticleImageContentLoadDetailsActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class ArticleImageContentLoadDetailsClearAction implements PayloadAction {
  readonly type = ArticleImageContentLoadDetailsActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}

export class ArticleImageContentLoadDetailsClearErrorsAction implements PayloadAction {
  readonly type = ArticleImageContentLoadDetailsActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}
