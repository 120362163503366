import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { ByIdPayload, ByKey, IdType, NormalizedList, NormalizedSingle } from '@industi/ngx-common';
import { PartService } from './part.service';
import { catchError, first, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { getInstanceLocale } from '../instance/instance.reducer';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import {
  PartLoadListAction,
  PartLoadListActionTypes,
  PartLoadListClearIdsAction,
  PartLoadListFailAction,
  PartLoadListSuccessAction
} from './actions/list.actions';
import { createPartListParams } from './selectors/get-load-list.selectors';
import { PartListParams } from './models/part-list-params';
import {
  PartLoadDetailsAction,
  PartLoadDetailsActionTypes,
  PartLoadDetailsFailAction,
  PartLoadDetailsSuccessAction
} from './actions/load-details.actions';
import { PartAddAction, PartAddActionTypes, PartAddFailAction, PartAddSuccessAction } from './actions/add.actions';
import { PartEditAction, PartEditActionTypes, PartEditFailAction, PartEditSuccessAction } from './actions/edit.actions';
import {
  PartDeleteAction,
  PartDeleteActionTypes,
  PartDeleteFailAction,
  PartDeleteSuccessAction
} from './actions/delete.actions';
import { ResponseStatus } from '../../shared/enums/response-status.enum';
import { LibSnackbarComponent } from '@industi/ngx-modules';
import { AppGlobal } from '../../shared/app-global';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlertUtil } from '../../shared/utils/alert.util';
import {
  PartImportAction,
  PartImportActionTypes,
  PartImportFailAction,
  PartImportSuccessAction
} from './actions/import.actions';

@Injectable()
export class PartEffects {

  @Effect()
  loadList$ = this.actions$.pipe(
    ofType(PartLoadListActionTypes.request),
    map((action: PartLoadListAction) => action.payload),
    switchMap((payload: ByIdPayload) => this.store.pipe(
      select(createPartListParams(payload.id)),
      map((params: PartListParams) => [payload.id, params] as [IdType, PartListParams]),
      first()
    )),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([[ id, params ], locale]) => this.service.loadList(locale, params).pipe(
      mergeMap((res: NormalizedList) => [
        new PartLoadListClearIdsAction({ id }),
        new PartLoadListSuccessAction({ id, res })]),
      catchError((errors: HttpErrorResponse) => of(new PartLoadListFailAction({ id, errors })))
    ))
  );

  @Effect()
  add$ = this.actions$.pipe(
    ofType(PartAddActionTypes.request),
    map((action: PartAddAction) => action.payload),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([{ id, data }, locale]) => this.service.add(locale, data).pipe(
      map((res: NormalizedSingle) => new PartAddSuccessAction({ res, id })),
      catchError((errors: HttpErrorResponse) => of(new PartAddFailAction({ errors, id })))
    ))
  );

  @Effect()
  edit$ = this.actions$.pipe(
    ofType(PartEditActionTypes.request),
    map((action: PartEditAction) => action.payload),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([{ id, data }, locale]) => this.service.edit(locale, data, id).pipe(
      map((res: NormalizedSingle) => new PartEditSuccessAction({ res, id })),
      catchError((errors: HttpErrorResponse) => of(new PartEditFailAction({ errors, id })))
    ))
  );

  @Effect()
  loadDetails$ = this.actions$.pipe(
    ofType(PartLoadDetailsActionTypes.request),
    map((action: PartLoadDetailsAction) => action.payload.id),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([id, locale]) => this.service.details(locale, id).pipe(
      map((res: NormalizedSingle) => new PartLoadDetailsSuccessAction({ id, res })),
      catchError((errors: HttpErrorResponse) => of(new PartLoadDetailsFailAction({ id, errors })))
    ))
  );

  @Effect()
  delete$ = this.actions$.pipe(
    ofType(PartDeleteActionTypes.request),
    map((action: PartDeleteAction) => action.payload.id),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([id, locale]) => this.service.delete(locale, id).pipe(
      map(() => new PartDeleteSuccessAction({ id })),
      catchError((errors: HttpErrorResponse) => of(new PartDeleteFailAction({ id, errors })))
    ))
  );

  @Effect()
  import$ = this.actions$.pipe(
    ofType(PartImportActionTypes.request),
    map((action: PartImportAction) => action.payload),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([{ id, data }, locale]) => this.service.import(locale, data).pipe(
      map(() => new PartImportSuccessAction({ id })),
      catchError((errors: HttpErrorResponse) => of(new PartImportFailAction({ errors, id })))
    ))
  );

  @Effect({ dispatch: false })
  importSuccess$ = this.actions$.pipe(
    ofType(PartImportActionTypes.requestSuccess),
    tap(() => {
      this.snackBar.openFromComponent(LibSnackbarComponent, {
        data: {
          message: 'Pomyślnie wgrano plik z częściami',
          type: 'success'
        }
      });
    })
  );

  @Effect({ dispatch: false })
  crudFail$ = this.actions$.pipe(
    ofType(
      PartAddActionTypes.requestFail,
      PartEditActionTypes.requestFail,
      PartImportActionTypes.requestFail
    ),
    map((action: PartAddFailAction | PartEditFailAction | PartImportFailAction) => action.payload.errors),
    tap((data: HttpErrorResponse) => {
      if (data?.status === ResponseStatus.BAD_REQUEST || data?.status === ResponseStatus.UNPROCESSABLE_ENTITY) {
        this.snackBar.openFromComponent(LibSnackbarComponent, {
          data: {
            message: AlertUtil.getControlTranslation(data.error.detail),
            type: 'error'
          },
          duration: AppGlobal.defaultSnackBarDuration
        });
      }
    })
  );

  constructor(
    private actions$: Actions,
    private store: Store<ByKey>,
    private service: PartService,
    private snackBar: MatSnackBar
  ) {
  }
}
