import { TimelineItem } from './models/timeline-item';
import * as _ from 'lodash';
import { TimelineItemChange } from './models/timeline-item-change';
import { TimelineFieldMap } from './resources/timeline-field.map';
import { TimelineItemTypesEnum } from './enums/timeline-item-types.enum';

export class TimelineUtil {

  static entityTimelineToAppTimeline(timeline: TimelineItem[]): TimelineItem[] {
    return [
      ..._
        .chain(timeline)
        .map((timelineItem: TimelineItem) => {
          switch (timelineItem.referencedObjectType) {
            case TimelineItemTypesEnum.HISTORY:
              return {
                ...timelineItem,
                changeSet: TimelineUtil.getHistoryItemChanges(timelineItem.changeSet),
              };
            default:
              return timelineItem;
          }
        })
        .value() as any[]
    ];
  }

  private static getHistoryItemChanges(diffs: any): TimelineItemChange[] {
    return _
      .chain(diffs)
      .keys()
      .map((key: string) => ({
        fieldName: TimelineFieldMap[key],
        oldValue: _.get(diffs, `${key}`, [])?.[0],
        newValue: _.get(diffs, `${key}`, [])?.[1]
      }))
      .filter((data: TimelineItemChange) => !!data)
      .value();
  }
}
