import {
  ByIdPayload,
  FailByIdPayload,
  OperationReducerTypes,
  PayloadAction,
  uniqueActionType
} from '@industi/ngx-common';

export const IcsConversationDeleteActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[ics/conversation] Delete'),
  requestFail: uniqueActionType('[ics/conversation] Delete fail'),
  requestSuccess: uniqueActionType('[ics/conversation] Delete success'),
  requestClearErrors: uniqueActionType('[ics/conversation] Delete clear errors'),
  requestClear: uniqueActionType('[ics/conversation] Delete clear')
};

export class IcsConversationDeleteAction implements PayloadAction {
  readonly type = IcsConversationDeleteActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class IcsConversationDeleteFailAction implements PayloadAction {
  readonly type = IcsConversationDeleteActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class IcsConversationDeleteSuccessAction implements PayloadAction {
  readonly type = IcsConversationDeleteActionTypes.requestSuccess;

  constructor(public payload: ByIdPayload) {
  }
}

export class IcsConversationDeleteClearErrorsAction implements PayloadAction {
  readonly type = IcsConversationDeleteActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class IcsConversationDeleteClearAction implements PayloadAction {
  readonly type = IcsConversationDeleteActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
