import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SendByIdPayload,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { IcsConversationTaskPayload } from '../models/conversation-task-payload';

export const IcsConversationTaskAddActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[ics/conversation-task] Add'),
  requestFail: uniqueActionType('[ics/conversation-task] Add fail'),
  requestSuccess: uniqueActionType('[ics/conversation-task] Add success'),
  requestClearErrors: uniqueActionType('[ics/conversation-task] Add clear errors'),
  requestClear: uniqueActionType('[ics/conversation-task] Add clear'),
};

export class IcsConversationTaskAddAction implements PayloadAction {
  readonly type = IcsConversationTaskAddActionTypes.request;

  constructor(public payload: SendByIdPayload<IcsConversationTaskPayload>) {
  }
}

export class IcsConversationTaskAddFailAction implements PayloadAction {
  readonly type = IcsConversationTaskAddActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class IcsConversationTaskAddSuccessAction implements PayloadAction {
  readonly type = IcsConversationTaskAddActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class IcsConversationTaskAddClearErrorsAction implements PayloadAction {
  readonly type = IcsConversationTaskAddActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class IcsConversationTaskAddClearAction implements PayloadAction {
  readonly type = IcsConversationTaskAddActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
