import {
  ByIdPayload,
  FailByIdPayload,
  OperationReducerTypes,
  PayloadAction,
  uniqueActionType
} from '@industi/ngx-common';

export const DictionaryAcquisitionDeleteActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[dictionary/acquisition] Delete'),
  requestFail: uniqueActionType('[dictionary/acquisition] Delete fail'),
  requestSuccess: uniqueActionType('[dictionary/acquisition] Delete success'),
  requestClearErrors: uniqueActionType('[dictionary/acquisition] Delete clear errors'),
  requestClear: uniqueActionType('[dictionary/acquisition] Delete clear'),
};

export class DictionaryAcquisitionDeleteAction implements PayloadAction {
  readonly type = DictionaryAcquisitionDeleteActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class DictionaryAcquisitionDeleteFailAction implements PayloadAction {
  readonly type = DictionaryAcquisitionDeleteActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class DictionaryAcquisitionDeleteSuccessAction implements PayloadAction {
  readonly type = DictionaryAcquisitionDeleteActionTypes.requestSuccess;

  constructor(public payload: ByIdPayload) {
  }
}

export class DictionaryAcquisitionDeleteClearErrorsAction implements PayloadAction {
  readonly type = DictionaryAcquisitionDeleteActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class DictionaryAcquisitionDeleteClearAction implements PayloadAction {
  readonly type = DictionaryAcquisitionDeleteActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
