import {
  ByIdPayload,
  FailByIdPayload, NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SendByIdPayload, SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { ClientPayload } from '../models/client-payload';

export const ClientEditActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[client] Edit'),
  requestFail: uniqueActionType('[client] Edit fail'),
  requestSuccess: uniqueActionType('[client] Edit success'),
  requestClearErrors: uniqueActionType('[client] Edit clear errors'),
  requestClear: uniqueActionType('[client] Edit clear')
};

export class ClientEditAction implements PayloadAction {
  readonly type = ClientEditActionTypes.request;

  constructor(public payload: SendByIdPayload<ClientPayload>) {
  }
}

export class ClientEditFailAction implements PayloadAction {
  readonly type = ClientEditActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class ClientEditSuccessAction implements PayloadAction {
  readonly type = ClientEditActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class ClientEditClearErrorsAction implements PayloadAction {
  readonly type = ClientEditActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class ClientEditClearAction implements PayloadAction {
  readonly type = ClientEditActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
