import {
  ByIdPayload,
  ChangeParamsByIdPayload,
  FailByIdPayload,
  ListReducerTypes, NormalizedList,
  NormalizedSingle,
  PayloadAction,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { OfferListParams } from '../models/offer-list-params';

export const OfferLoadListActionTypes: ListReducerTypes = {
  request: uniqueActionType('[offer] Load list'),
  requestFail: uniqueActionType('[offer] Load list fail'),
  requestSuccess: uniqueActionType('[offer] Load list success'),
  requestClear: uniqueActionType('[offer] Load list clear'),
  changeParams: uniqueActionType('[offer] Load list change params'),
  clearParams: uniqueActionType('[offer] Load list clear params'),
  clearIds: uniqueActionType('[offer] Load list clear ids')
};

export class OfferLoadListAction implements PayloadAction {
  readonly type = OfferLoadListActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class OfferLoadListFailAction implements PayloadAction {
  readonly type = OfferLoadListActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class OfferLoadListSuccessAction implements PayloadAction {
  readonly type = OfferLoadListActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedList>) {
  }
}

export class OfferLoadListClearAction implements PayloadAction {
  readonly type = OfferLoadListActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}

export class OfferLoadListChangeParamsAction implements PayloadAction {
  readonly type = OfferLoadListActionTypes.changeParams;

  constructor(public payload: ChangeParamsByIdPayload<OfferListParams>) {
  }
}

export class OfferLoadListClearParamsAction implements PayloadAction {
  readonly type = OfferLoadListActionTypes.clearParams;

  constructor(public payload: ByIdPayload) {
  }
}

export class OfferLoadListClearIdsAction implements PayloadAction {
  readonly type = OfferLoadListActionTypes.clearIds;

  constructor(public payload: ByIdPayload) {
  }
}
