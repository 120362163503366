import { ByKey, listByIdReducer, ListState, operationByIdReducer, SingleState } from '@industi/ngx-common';
import { ActionReducer, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import { InjectionToken, Provider } from '@angular/core';
import { DictionaryMaterialExploitationEditActionTypes } from './actions/edit.actions';
import { DictionaryMaterialExploitationLoadListActionTypes } from './actions/list.actions';
import { DictionaryMaterialExploitationLoadDetailsActionTypes } from './actions/load-details.actions';
import { DictionaryMaterialExploitationAddActionTypes } from './actions/add.actions';
import { DictionaryMaterialExploitationDeleteActionTypes } from './actions/delete.actions';

export const rootReducerKey = 'materialExploitation';

export interface DictionaryMaterialExploitationOperationByIdState {
  add: SingleState;
  edit: SingleState;
  details: SingleState;
  delete: SingleState;
}

export interface DictionaryMaterialExploitationState {
  operationsById: ByKey<DictionaryMaterialExploitationOperationByIdState>;
  list: ByKey<ListState>;
}

const initialState: DictionaryMaterialExploitationState = {
  operationsById: {},
  list: {}
};

const reducer: ActionReducer<DictionaryMaterialExploitationState> = combineReducers<DictionaryMaterialExploitationState>({
  operationsById: operationByIdReducer<DictionaryMaterialExploitationOperationByIdState>({
    mapActionToId: (a) => a && a.payload && a.payload.id,
    operations: {
      add: DictionaryMaterialExploitationAddActionTypes,
      edit: DictionaryMaterialExploitationEditActionTypes,
      details: DictionaryMaterialExploitationLoadDetailsActionTypes,
      delete: DictionaryMaterialExploitationDeleteActionTypes
    }
  }),
  list: listByIdReducer({ types: DictionaryMaterialExploitationLoadListActionTypes })
}, initialState);

export function getReducers(): ActionReducer<DictionaryMaterialExploitationState> {
  return reducer;
}

export const DICTIONARY_MATERIAL_EXPLOITATION_REDUCER_TOKEN =
  new InjectionToken<ActionReducer<DictionaryMaterialExploitationState>>(rootReducerKey);

export const dictionaryMaterialExploitationReducerProvider: Provider = {
  provide: DICTIONARY_MATERIAL_EXPLOITATION_REDUCER_TOKEN, useFactory: getReducers
};

const getState = createFeatureSelector<DictionaryMaterialExploitationState>(rootReducerKey);

export const getDictionaryMaterialExploitationOperationsById = createSelector(
  getState,
  (state: DictionaryMaterialExploitationState) => state && state.operationsById
);

export const getDictionaryMaterialExploitationListState = createSelector(
  getState,
  (state: DictionaryMaterialExploitationState) => state && state.list
);
