import { ByKey, listByIdReducer, ListState, operationByIdReducer, SingleState } from '@industi/ngx-common';
import { ActionReducer, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import { InjectionToken, Provider } from '@angular/core';
import { OfferImageDeleteActionTypes } from './actions/delete.actions';
import { OfferImageGalleryAddActionTypes } from './actions/add-gallery.actions';
import { OfferImageAddDoneListActionTypes } from './actions/add-done-list.actions';
import { OfferImageAddPendingListActionTypes } from './actions/add-pending-list.actions';
import { OfferImageAddActionTypes } from './actions/add.actions';

export const rootReducerKey = 'offerImage';

export interface OfferImageOperationByIdState {
  delete: SingleState;
  add: SingleState;
  addGallery: SingleState;
}

export interface OfferImageState {
  operationsById: ByKey<OfferImageOperationByIdState>;
  addPendingList: ByKey<ListState>;
  addDoneList: ByKey<ListState>;
}

const initialState: OfferImageState = {
  operationsById: {},
  addPendingList: {},
  addDoneList: {}
};

const reducer: ActionReducer<OfferImageState> = combineReducers<OfferImageState>({
  operationsById: operationByIdReducer<OfferImageOperationByIdState>({
    mapActionToId: (a) => a && a.payload && a.payload.id,
    operations: {
      add: OfferImageAddActionTypes,
      delete: OfferImageDeleteActionTypes,
      addGallery: OfferImageGalleryAddActionTypes
    }
  }),
  addPendingList: listByIdReducer({ types: OfferImageAddPendingListActionTypes }),
  addDoneList: listByIdReducer({ types: OfferImageAddDoneListActionTypes })
}, initialState);

export function getReducers(): ActionReducer<OfferImageState> {
  return reducer;
}

export const OFFER_IMAGE_REDUCER_TOKEN = new InjectionToken<ActionReducer<OfferImageState>>(rootReducerKey);

export const OfferImageReducerProvider: Provider = { provide: OFFER_IMAGE_REDUCER_TOKEN, useFactory: getReducers };

const getState = createFeatureSelector<OfferImageState>(rootReducerKey);

export const getOfferImageAddPendingList = createSelector(
  getState,
  (state: OfferImageState) => state && state.addPendingList
);

export const getOfferImageAddDoneList = createSelector(
  getState,
  (state: OfferImageState) => state && state.addDoneList
);

export const getOfferImageOperationsById = createSelector(
  getState,
  (state: OfferImageState) => state && state.operationsById
);
