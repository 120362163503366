import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { CommonModule } from '@angular/common';
import { ARTICLE_BRAND_REDUCER_TOKEN, articleBrandReducerProvider, rootReducerKey } from './brand.reducer';
import { ArticleBrandEffects } from './brand.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(rootReducerKey, ARTICLE_BRAND_REDUCER_TOKEN),
    EffectsModule.forFeature([ArticleBrandEffects])
  ],
  providers: [
    articleBrandReducerProvider
  ]
})
export class ArticleBrandStoreModule {
}
