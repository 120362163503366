import {
  ByIdPayload,
  FailByIdPayload,
  OperationReducerTypes,
  PayloadAction,
  uniqueActionType
} from '@industi/ngx-common';

export const CarbonPartCommissionDeleteActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[carbon/part-commission] Delete'),
  requestFail: uniqueActionType('[carbon/part-commission] Delete fail'),
  requestSuccess: uniqueActionType('[carbon/part-commission] Delete success'),
  requestClearErrors: uniqueActionType('[carbon/part-commission] Delete clear errors'),
  requestClear: uniqueActionType('[carbon/part-commission] Delete clear')
};

export class CarbonPartCommissionDeleteAction implements PayloadAction {
  readonly type = CarbonPartCommissionDeleteActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class CarbonPartCommissionDeleteFailAction implements PayloadAction {
  readonly type = CarbonPartCommissionDeleteActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class CarbonPartCommissionDeleteSuccessAction implements PayloadAction {
  readonly type = CarbonPartCommissionDeleteActionTypes.requestSuccess;

  constructor(public payload: ByIdPayload) {
  }
}

export class CarbonPartCommissionDeleteClearErrorsAction implements PayloadAction {
  readonly type = CarbonPartCommissionDeleteActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class CarbonPartCommissionDeleteClearAction implements PayloadAction {
  readonly type = CarbonPartCommissionDeleteActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
