import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';

export const CarbonFormLoadDetailsActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[carbon/form] Load details'),
  requestFail: uniqueActionType('[carbon/form] Load details fail'),
  requestSuccess: uniqueActionType('[carbon/form] Load details success'),
  requestClearErrors: uniqueActionType('[carbon/form] Load details clear errors'),
  requestClear: uniqueActionType('[carbon/form] Load details clear')
};

export class CarbonFormLoadDetailsAction implements PayloadAction {
  readonly type = CarbonFormLoadDetailsActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class CarbonFormLoadDetailsFailAction implements PayloadAction {
  readonly type = CarbonFormLoadDetailsActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class CarbonFormLoadDetailsSuccessAction implements PayloadAction {
  readonly type = CarbonFormLoadDetailsActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class CarbonFormLoadDetailsClearAction implements PayloadAction {
  readonly type = CarbonFormLoadDetailsActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}

export class CarbonFormLoadDetailsClearErrorsAction implements PayloadAction {
  readonly type = CarbonFormLoadDetailsActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}
