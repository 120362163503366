import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';

export const OrderPaidActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[order-paid] Paid'),
  requestFail: uniqueActionType('[order-paid] Paid fail'),
  requestSuccess: uniqueActionType('[order-paid] Paid success'),
  requestClearErrors: uniqueActionType('[order-paid] Paid clear errors'),
  requestClear: uniqueActionType('[order-paid] Paid clear')
};

export class OrderPaidAction implements PayloadAction {
  readonly type = OrderPaidActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class OrderPaidFailAction implements PayloadAction {
  readonly type = OrderPaidActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class OrderPaidSuccessAction implements PayloadAction {
  readonly type = OrderPaidActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class OrderPaidClearErrorsAction implements PayloadAction {
  readonly type = OrderPaidActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class OrderPaidClearAction implements PayloadAction {
  readonly type = OrderPaidActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
