import { Routes } from '@angular/router';
import { AccessTokenGuard } from './store/auth/guards/access-token.guard';
import { ToolbarContentLayoutComponent } from './app-layout/toolbar-content-layout/toolbar-content-layout.component';
import { Role, Roles } from './store/auth/auth-roles';
import { RoleGuard } from './store/auth/guards/role-guard.service';
import { ApplicationCurrentComponent } from './application/current/current.component';
import { NotFoundComponent } from './app-layout/404/not-found.component';

export const appRoutes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'auth' },
  {
    path: '',
    canActivate: [AccessTokenGuard],
    component: ToolbarContentLayoutComponent,
    children: [
      { path: '', redirectTo: 'item', pathMatch: 'full' },
      {
        path: 'article',
        loadChildren: () => import('./article/article.module').then(m => m.ArticleModule),
        canActivate: [RoleGuard],
        data: {
          roles: [Roles.admin, Roles.accessOffice] as Role[]
        }
      },
      {
        path: 'settings',
        loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule),
        canActivate: [RoleGuard],
        data: {
          roles: [Roles.admin, Roles.accessOffice] as Role[]
        }
      },
      {
        path: 'model',
        loadChildren: () => import('./model/model.module').then(m => m.ModelModule),
        canActivate: [RoleGuard],
        data: {
          roles: [Roles.admin, Roles.accessOffice] as Role[]
        }
      },
      {
        path: 'part',
        loadChildren: () => import('./part/part.module').then(m => m.PartModule),
        canActivate: [RoleGuard],
        data: {
          roles: [Roles.admin, Roles.accessOffice, Roles.accessWarehouse] as Role[]
        }
      },
      {
        path: 'item',
        loadChildren: () => import('./item/item.module').then(m => m.ItemModule),
        canActivate: [RoleGuard],
        data: {
          roles: [Roles.admin, Roles.accessOffice, Roles.accessWarehouse] as Role[]
        }
      },
      {
        path: 'carbon',
        loadChildren: () => import('./carbon/carbon.module').then(m => m.CarbonModule),
        canActivate: [RoleGuard],
        data: {
          roles: [Roles.admin, Roles.accessCarbonHouse] as Role[]
        }
      },
      {
        path: 'service',
        loadChildren: () => import('./service/service.module').then(m => m.ServiceModule),
        canActivate: [RoleGuard],
        data: {
          roles: [Roles.admin, Roles.accessService, Roles.accessOffice, Roles.valuer] as Role[]
        }
      },
      {
        path: 'diagram',
        loadChildren: () => import('./diagram/diagram.module').then(m => m.DiagramModule),
        canActivate: [RoleGuard],
        data: {
          roles: [Roles.admin, Roles.accessOffice] as Role[]
        }
      },
      {
        path: 'offer',
        loadChildren: () => import('./offer/offer.module').then(m => m.OfferModule),
        canActivate: [RoleGuard],
        data: {
          roles: [Roles.admin, Roles.accessOffice] as Role[]
        }
      },
      {
        path: 'order',
        loadChildren: () => import('./order/order.module').then(m => m.OrderModule),
        canActivate: [RoleGuard],
        data: {
          roles: [Roles.admin, Roles.accessOffice] as Role[]
        }
      },
      {
        path: 'client',
        loadChildren: () => import('./client/client.module').then(m => m.ClientModule),
      },
      {
        path: 'warehouse',
        loadChildren: () => import('./warehouse/warehouse.module').then(m => m.WarehouseItemsModule),
        canActivate: [RoleGuard],
        data: {
          roles: [Roles.admin, Roles.accessWarehouse] as Role[]
        }
      },
      {
        path: 'android-app',
        loadChildren: () => import('./application/application.module').then(m => m.ApplicationModule),
        canActivate: [RoleGuard],
        data: {
          roles: [Roles.superAdmin] as Role[]
        }
      }
    ]
  },
  {
    path: 'android-app/current',
    component: ApplicationCurrentComponent
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: '404',
    component: NotFoundComponent,
    pathMatch: 'full'
  },
  { path: '**', redirectTo: '404', pathMatch: 'full' }
];
