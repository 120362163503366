import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';

export const ServiceProjectOperationLoadDetailsActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[service/project-operation] Load details'),
  requestFail: uniqueActionType('[service/project-operation] Load details fail'),
  requestSuccess: uniqueActionType('[service/project-operation] Load details success'),
  requestClearErrors: uniqueActionType('[service/project-operation] Load details clear errors'),
  requestClear: uniqueActionType('[service/project-operation] Load details clear')
};

export class ServiceProjectOperationLoadDetailsAction implements PayloadAction {
  readonly type = ServiceProjectOperationLoadDetailsActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class ServiceProjectOperationLoadDetailsFailAction implements PayloadAction {
  readonly type = ServiceProjectOperationLoadDetailsActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class ServiceProjectOperationLoadDetailsSuccessAction implements PayloadAction {
  readonly type = ServiceProjectOperationLoadDetailsActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class ServiceProjectOperationLoadDetailsClearAction implements PayloadAction {
  readonly type = ServiceProjectOperationLoadDetailsActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}

export class ServiceProjectOperationLoadDetailsClearErrorsAction implements PayloadAction {
  readonly type = ServiceProjectOperationLoadDetailsActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}
