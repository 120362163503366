import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SendByIdPayload,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { ArticlePayload } from '../models/article-payload';

export const ArticleAddActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[article] Add'),
  requestFail: uniqueActionType('[article] Add fail'),
  requestSuccess: uniqueActionType('[article] Add success'),
  requestClearErrors: uniqueActionType('[article] Add clear errors'),
  requestClear: uniqueActionType('[article] Add clear'),
};

export class ArticleAddAction implements PayloadAction {
  readonly type = ArticleAddActionTypes.request;

  constructor(public payload: SendByIdPayload<ArticlePayload>) {
  }
}

export class ArticleAddFailAction implements PayloadAction {
  readonly type = ArticleAddActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class ArticleAddSuccessAction implements PayloadAction {
  readonly type = ArticleAddActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class ArticleAddClearErrorsAction implements PayloadAction {
  readonly type = ArticleAddActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class ArticleAddClearAction implements PayloadAction {
  readonly type = ArticleAddActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
