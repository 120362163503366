import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SendByIdPayload,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { IcsConversationAttachmentPayload } from '../models/conversation-attachment-payload';

export const IcsConversationAttachmentAddActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[ics/conversation-attachment] Add'),
  requestFail: uniqueActionType('[ics/conversation-attachment] Add fail'),
  requestSuccess: uniqueActionType('[ics/conversation-attachment] Add success'),
  requestClearErrors: uniqueActionType('[ics/conversation-attachment] Add clear errors'),
  requestClear: uniqueActionType('[ics/conversation-attachment] Add clear'),
};

export class IcsConversationAttachmentAddAction implements PayloadAction {
  readonly type = IcsConversationAttachmentAddActionTypes.request;

  constructor(public payload: SendByIdPayload<IcsConversationAttachmentPayload>) {
  }
}

export class IcsConversationAttachmentAddFailAction implements PayloadAction {
  readonly type = IcsConversationAttachmentAddActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class IcsConversationAttachmentAddSuccessAction implements PayloadAction {
  readonly type = IcsConversationAttachmentAddActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class IcsConversationAttachmentAddClearErrorsAction implements PayloadAction {
  readonly type = IcsConversationAttachmentAddActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class IcsConversationAttachmentAddClearAction implements PayloadAction {
  readonly type = IcsConversationAttachmentAddActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
