export type Role =
  'ROLE_ADMIN'
  | 'ROLE_SUPER_ADMIN'
  | 'ROLE_VALUER'
  | 'ROLE_SELLER'
  | 'ROLE_ACCESS_OFFICE'
  | 'ROLE_ACCESS_SERVICE'
  | 'ROLE_ACCESS_WAREHOUSE'
  | 'ROLE_ACCESS_CARBON_HOUSE';

export type AppRole =
  'admin'
  | 'superAdmin'
  | 'seller'
  | 'valuer'
  | 'accessOffice'
  | 'accessService'
  | 'accessWarehouse'
  | 'accessCarbonHouse';

export const Roles: { [key in AppRole]: Role } = {
  admin: 'ROLE_ADMIN',
  superAdmin: 'ROLE_SUPER_ADMIN',
  seller: 'ROLE_SELLER',
  valuer: 'ROLE_VALUER',
  accessOffice: 'ROLE_ACCESS_OFFICE',
  accessService: 'ROLE_ACCESS_SERVICE',
  accessWarehouse: 'ROLE_ACCESS_WAREHOUSE',
  accessCarbonHouse: 'ROLE_ACCESS_CARBON_HOUSE'
};
