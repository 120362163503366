import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SendByIdPayload,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { WarehousePayload } from '../models/warehouse-payload';

export const WarehouseAddActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[warehouse] Add'),
  requestFail: uniqueActionType('[warehouse] Add fail'),
  requestSuccess: uniqueActionType('[warehouse] Add success'),
  requestClearErrors: uniqueActionType('[warehouse] Add clear errors'),
  requestClear: uniqueActionType('[warehouse] Add clear'),
};

export class WarehouseAddAction implements PayloadAction {
  readonly type = WarehouseAddActionTypes.request;

  constructor(public payload: SendByIdPayload<WarehousePayload>) {
  }
}

export class WarehouseAddFailAction implements PayloadAction {
  readonly type = WarehouseAddActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class WarehouseAddSuccessAction implements PayloadAction {
  readonly type = WarehouseAddActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class WarehouseAddClearErrorsAction implements PayloadAction {
  readonly type = WarehouseAddActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class WarehouseAddClearAction implements PayloadAction {
  readonly type = WarehouseAddActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
