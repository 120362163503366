import { ChangeDetectionStrategy, Component } from '@angular/core';
import { LibTableDirective } from '@industi/ngx-modules';
import { DimensionTableColumnsMap } from '../../../store/common/maps/dimension-data-table-columns.map';
import { DimensionTableColumnsType } from './dimension-data-table-columns.type';
import { Dimension } from '../../../store/common/models/dimension';

@Component({
  selector: 'app-dimension-data-table',
  templateUrl: './dimension-data-table.component.html',
  styleUrls: ['./dimension-data-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DimensionDataTableComponent
  extends LibTableDirective<Dimension> {

  get columnNames(): { [key in DimensionTableColumnsType]: string } {
    return DimensionTableColumnsMap;
  }

}
