import { ByKey, operationByIdReducer, SingleState } from '@industi/ngx-common';
import { ActionReducer, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import { InjectionToken, Provider } from '@angular/core';
import { ArticleVideoContentEditActionTypes } from './actions/edit.actions';
import { ArticleVideoContentAddActionTypes } from './actions/add.actions';
import { ArticleVideoContentLoadDetailsActionTypes } from './actions/details.actions';

export const rootReducerKey = 'articleVideoContent';

export interface ArticleVideoContentOperationByIdState {
  add: SingleState;
  edit: SingleState;
  details: SingleState;
}

export interface ArticleVideoContentState {
  operationsById: ByKey<ArticleVideoContentOperationByIdState>;
}

const initialState: ArticleVideoContentState = {
  operationsById: {}
};

const reducer: ActionReducer<ArticleVideoContentState> = combineReducers<ArticleVideoContentState>({
  operationsById: operationByIdReducer<ArticleVideoContentOperationByIdState>({
    mapActionToId: (a) => a?.payload?.id,
    operations: {
      add: ArticleVideoContentAddActionTypes,
      edit: ArticleVideoContentEditActionTypes,
      details: ArticleVideoContentLoadDetailsActionTypes,
    }
  })
}, initialState);

export function getReducers(): ActionReducer<ArticleVideoContentState> {
  return reducer;
}

export const ARTICLE_VIDEO_CONTENT_REDUCER_TOKEN = new InjectionToken<ActionReducer<ArticleVideoContentState>>(rootReducerKey);

export const articleVideoContentReducerProvider: Provider = { provide: ARTICLE_VIDEO_CONTENT_REDUCER_TOKEN, useFactory: getReducers };

const getState = createFeatureSelector<ArticleVideoContentState>(rootReducerKey);

export const getArticleVideoContentOperationsById = createSelector(
  getState,
  (state: ArticleVideoContentState) => state && state.operationsById
);
