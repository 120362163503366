import { Inject, Injectable } from '@angular/core';
import { API_URL } from '../../app-store-providers';
import { HttpClient } from '@angular/common/http';
import { EntityTypes } from '../../entity/entity-types';
import { StoreBaseService } from '../../common/base.service';
import { ArticleBrand } from './models/article-brand';
import { ArticleBrandPayload } from './models/article-brand-payload';
import { ArticleBrandListParams } from './models/article-brand-list-params';
import { Observable } from 'rxjs';
import { IdType, NormalizedSingle } from '@industi/ngx-common';
import { EncodeUtil } from '../../../shared/utils/encode.util';
import { map } from 'rxjs/operators';
import { normalize } from 'normalizr';
import { Entities } from '../../entity/entities';
import { ArticleBrandImagePayload } from './models/article-brand-image-payload';
import { ArticleBrandImage } from './models/article-brand-image';

@Injectable({
  providedIn: 'root'
})
export class ArticleBrandService
  extends StoreBaseService<ArticleBrand, ArticleBrandPayload, ArticleBrandListParams> {

  get entityType(): string {
    return EntityTypes.articleBrand;
  }

  get resourceName(): string {
    return 'article-brand';
  }

  constructor(
    @Inject(API_URL) apiUrl: string,
    http: HttpClient
  ) {
    super(apiUrl, http);
  }

  addImage(locale: string, data: ArticleBrandImagePayload): Observable<NormalizedSingle> {
    const url = `${this.apiUrl}/${locale}/api/${this.resourceName}/image`;
    return this.http.post<ArticleBrandImagePayload>(url, EncodeUtil.prepareMultipartFormData(data)).pipe(
      map((res: ArticleBrandImage) => normalize(res, Entities[EntityTypes.image]))
    );
  }

  deleteImage(locale: string, id: IdType): Observable<void> {
    const url = `${this.apiUrl}/${locale}/api/${this.resourceName}/image/${id}`;
    return this.http.delete<void>(url);
  }
}
