import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import {
  DICTIONARY_MATERIAL_EXPLOITATION_REDUCER_TOKEN,
  dictionaryMaterialExploitationReducerProvider,
  rootReducerKey
} from './material-exploitation.reducer';
import { EffectsModule } from '@ngrx/effects';
import { DictionaryMaterialExploitationEffects } from './material-exploitation.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(rootReducerKey, DICTIONARY_MATERIAL_EXPLOITATION_REDUCER_TOKEN),
    EffectsModule.forFeature([DictionaryMaterialExploitationEffects])
  ],
  providers: [
    dictionaryMaterialExploitationReducerProvider
  ]
})
export class DictionaryMaterialExploitationStoreModule { }
