import { ByKey, listByIdReducer, ListState, operationByIdReducer, SingleState } from '@industi/ngx-common';
import { ActionReducer, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import { InjectionToken, Provider } from '@angular/core';
import { ArticleImageContentLoadDetailsActionTypes } from './actions/load-details.actions';
import { ArticleImageContentDeleteActionTypes } from './actions/delete.actions';
import { ArticleImageContentAddActionTypes } from './actions/add.actions';
import { ArticleImageContentAddPendingListActionTypes } from './actions/add-pending-list.actions';
import { ArticleImageContentAddDoneListActionTypes } from './actions/add-done-list.actions';

export const rootReducerKey = 'articleImageContent';

export interface ArticleImageContentOperationByIdState {
  add: SingleState;
  details: SingleState;
  delete: SingleState;
}

export interface ArticleImageContentState {
  operationsById: ByKey<ArticleImageContentOperationByIdState>;
  addPendingList: ByKey<ListState>;
  addDoneList: ByKey<ListState>;
}

const initialState: ArticleImageContentState = {
  operationsById: {},
  addPendingList: {},
  addDoneList: {}
};

const reducer: ActionReducer<ArticleImageContentState> = combineReducers<ArticleImageContentState>({
  operationsById: operationByIdReducer<ArticleImageContentOperationByIdState>({
    mapActionToId: (a) => a?.payload?.id,
    operations: {
      add: ArticleImageContentAddActionTypes,
      details: ArticleImageContentLoadDetailsActionTypes,
      delete: ArticleImageContentDeleteActionTypes,
    }
  }),
  addPendingList: listByIdReducer({ types: ArticleImageContentAddPendingListActionTypes }),
  addDoneList: listByIdReducer({ types: ArticleImageContentAddDoneListActionTypes })
}, initialState);

export function getReducers(): ActionReducer<ArticleImageContentState> {
  return reducer;
}

export const ARTICLE_IMAGE_CONTENT_REDUCER_TOKEN = new InjectionToken<ActionReducer<ArticleImageContentState>>(rootReducerKey);

export const articleImageContentReducerProvider: Provider = { provide: ARTICLE_IMAGE_CONTENT_REDUCER_TOKEN, useFactory: getReducers };

const getState = createFeatureSelector<ArticleImageContentState>(rootReducerKey);

export const getArticleImageContentOperationsById = createSelector(
  getState,
  (state: ArticleImageContentState) => state && state.operationsById
);

export const getArticleImageContentAddPendingList = createSelector(
  getState,
  (state: ArticleImageContentState) => state && state.addPendingList
);

export const getArticleImageContentAddDoneList = createSelector(
  getState,
  (state: ArticleImageContentState) => state && state.addDoneList
);
