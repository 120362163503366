import { IdType, SetValuePayload, SetValueReducerTypes, uniqueActionType } from '@industi/ngx-common';

export const ServiceProjectSelectLastAddedIdActionTypes: SetValueReducerTypes = {
  setValue: uniqueActionType('[service/project] Select last added id'),
  clearValue: uniqueActionType('[service/project] Clear last added id'),
};

export class ServiceProjectSelectLastAddedIdAction {
  readonly type = ServiceProjectSelectLastAddedIdActionTypes.setValue;

  constructor(public payload: SetValuePayload<IdType>) {
  }
}

export class ServiceProjectClearLastAddedIdAction {
  readonly type = ServiceProjectSelectLastAddedIdActionTypes.setValue;
  readonly payload = null;
}
