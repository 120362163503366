import { PayloadAction, SetValuePayload, SetValueReducerTypes, uniqueActionType } from '@industi/ngx-common';

export const InstanceSetLocaleActionTypes: SetValueReducerTypes = {
  setValue: uniqueActionType('[instance] Set locale'),
  clearValue: uniqueActionType('[instance] Clear locale')
};

export class InstanceSetLocaleAction implements PayloadAction {
  readonly type = InstanceSetLocaleActionTypes.setValue;

  constructor(public payload: SetValuePayload<string>) {
  }
}

export class InstanceSetLocaleClearAction implements PayloadAction {
  readonly type = InstanceSetLocaleActionTypes.clearValue;
  readonly payload = null;
}
