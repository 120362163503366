import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Credentials, LibAuthService, TokenResponse } from 'app-store-auth';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthUtil } from './auth.util';
import { OutputTokenResponse } from './models/output-token-response';
import { API_URL } from '../app-store-providers';

@Injectable({ providedIn: 'root' })
export class AuthService extends LibAuthService {

  private get tokenPath(): string {
    return `${this.apiUrl}/api/login`;
  }

  private get refreshTokenPath(): string {
    return `${this.apiUrl}/api/token/refresh`;
  }

  constructor(
    @Inject(API_URL) protected apiUrl: string,
    private http: HttpClient
  ) {
    super();
  }

  login(credentials: Credentials): Observable<TokenResponse> {
    return this.http.post<OutputTokenResponse>(this.tokenPath, credentials).pipe(
      map(this.outputTokenToTokenResponse)
    );
  }

  refreshToken(refreshToken: string): Observable<TokenResponse> {
    return this.http.post<OutputTokenResponse>(this.refreshTokenPath, {
      refresh_token: refreshToken
    }).pipe(
      map((response) => this.outputTokenToTokenResponse(response))
    );
  }

  forgotPassword(payload: any): Observable<any> {
    return undefined;
  }

  registration(payload: any): Observable<any> {
    return undefined;
  }

  private outputTokenToTokenResponse(outputToken: OutputTokenResponse): TokenResponse {
    const parsedToken = AuthUtil.parseTokenString(outputToken.token);
    return {
      access_token: outputToken.token,
      expires_in: parsedToken.exp - (+new Date() / 1000),
      refresh_token: outputToken.refresh_token,
      scope: '',
      'not-before-policy': 0,
      refresh_expires_in: 0,
      session_state: '',
      token_type: 'Bearer'
    };
  }

}
