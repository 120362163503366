import {
  ByIdPayload,
  ChangeParamsByIdPayload,
  FailByIdPayload,
  ListReducerTypes,
  NormalizedList,
  PayloadAction,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { OrderListParams } from '../models/order-list-params';

export const OrderLoadListActionTypes: ListReducerTypes = {
  request: uniqueActionType('[order] Load list'),
  requestFail: uniqueActionType('[order] Load list fail'),
  requestSuccess: uniqueActionType('[order] Load list success'),
  requestClear: uniqueActionType('[order] Load list clear'),
  changeParams: uniqueActionType('[order] Load list change params'),
  clearParams: uniqueActionType('[order] Load list clear params'),
  clearIds: uniqueActionType('[order] Load list clear ids')
};

export class OrderLoadListAction implements PayloadAction {
  readonly type = OrderLoadListActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class OrderLoadListFailAction implements PayloadAction {
  readonly type = OrderLoadListActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class OrderLoadListSuccessAction implements PayloadAction {
  readonly type = OrderLoadListActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedList>) {
  }
}

export class OrderLoadListClearAction implements PayloadAction {
  readonly type = OrderLoadListActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}

export class OrderLoadListChangeParamsAction implements PayloadAction {
  readonly type = OrderLoadListActionTypes.changeParams;

  constructor(public payload: ChangeParamsByIdPayload<OrderListParams>) {
  }
}

export class OrderLoadListClearParamsAction implements PayloadAction {
  readonly type = OrderLoadListActionTypes.clearParams;

  constructor(public payload: ByIdPayload) {
  }
}

export class OrderLoadListClearIdsAction implements PayloadAction {
  readonly type = OrderLoadListActionTypes.clearIds;

  constructor(public payload: ByIdPayload) {
  }
}
