import { createSelector } from '@ngrx/store';
import { ByKey, createListStateIdsSelector, IdType, ListState } from '@industi/ngx-common';
import { denormalize } from 'normalizr';
import { ItemImage } from '../models/item-image';
import { getItemImageAddPendingList } from '../image.reducer';
import { EntityTypes } from '../../../entity/entity-types';
import { createEntitiesSelector } from '../../../entity/entities.reducer';
import { Entities } from '../../../entity/entities';
import { EntityState } from '../../../entity/entity.reducer';

const createStateSelector = (id: IdType) => createSelector(
  getItemImageAddPendingList,
  (state: ByKey<ListState>) => state && state[id]
);

const createIdsSelector = (id: IdType) => createListStateIdsSelector(createStateSelector(id));

export const createItemImageAddPendingList = (id: IdType) => createSelector(
  createIdsSelector(id),
  createEntitiesSelector([
    EntityTypes.image
  ]),
  (
    ids: IdType[],
    entities: EntityState
  ) => denormalize(ids, [Entities[EntityTypes.image]], entities) as ItemImage[]
);
