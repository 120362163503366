import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { WarehouseEffects } from './warehouse.effects';
import { StoreModule } from '@ngrx/store';
import {
  WAREHOUSE_REDUCER_TOKEN,
  warehouseReducerProvider,
  rootReducerKey
} from './warehouse.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(rootReducerKey, WAREHOUSE_REDUCER_TOKEN),
    EffectsModule.forFeature([WarehouseEffects])
  ],
  providers: [
    warehouseReducerProvider
  ]
})
export class WarehouseStoreModule { }
