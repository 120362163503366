import { ByKey, operationByIdReducer, SingleState } from '@industi/ngx-common';
import { ActionReducer, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import { InjectionToken, Provider } from '@angular/core';
import { ApplicationUpdateActionTypes } from './actions/update.actions';
import { ApplicationLoadCurrentActionTypes } from './actions/load-current.actions';

export const rootReducerKey = 'application';

export interface ApplicationOperationByIdState {
  update: SingleState;
  current: SingleState;
}

export interface ApplicationState {
  operationsById: ByKey<ApplicationOperationByIdState>;
}

const initialState: ApplicationState = {
  operationsById: {}
};

const reducer: ActionReducer<ApplicationState> = combineReducers<ApplicationState>({
  operationsById: operationByIdReducer<ApplicationOperationByIdState>({
    mapActionToId: (a) => a && a.payload && a.payload.id,
    operations: {
      update: ApplicationUpdateActionTypes,
      current: ApplicationLoadCurrentActionTypes
    }
  })
}, initialState);

export function getReducer(): ActionReducer<ApplicationState> {
  return reducer;
}

export const APPLICATION_REDUCER_TOKEN = new InjectionToken<ActionReducer<ApplicationState>>(rootReducerKey);

export const applicationReducerProvider: Provider = { provide: APPLICATION_REDUCER_TOKEN, useFactory: getReducer };

const getState = createFeatureSelector<ApplicationState>(rootReducerKey);

export const getApplicationOperationsById = createSelector(
  getState,
  (state: ApplicationState) => state && state.operationsById
);
