import {
  ByIdPayload,
  FailByIdPayload,
  ListReducerTypes,
  NormalizedList,
  PayloadAction,
  RemoveIdByIdPayload,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';

export const OfferImageAddDoneListActionTypes: ListReducerTypes = {
  requestFail: uniqueActionType('[offer/image] Add done list fail'),
  requestSuccess: uniqueActionType('[offer/image] Add done list success'),
  requestClearErrors: uniqueActionType('[offer/image] Add done list clear errors'),
  requestClear: uniqueActionType('[offer/image] Add done list clear'),
  removeId: uniqueActionType('[offer/image] Add done list remove id')
};

export class OfferImageAddDoneListFailAction implements PayloadAction {
  readonly type = OfferImageAddDoneListActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class OfferImageAddDoneListSuccessAction implements PayloadAction {
  readonly type = OfferImageAddDoneListActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedList>) {
  }
}

export class OfferImageAddDoneListClearErrorsAction implements PayloadAction {
  readonly type = OfferImageAddDoneListActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class OfferImageAddDoneListClearAction implements PayloadAction {
  readonly type = OfferImageAddDoneListActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}

export class OfferImageAddDoneListRemoveIdAction implements PayloadAction {
  readonly type = OfferImageAddDoneListActionTypes.removeId;

  constructor(public payload: RemoveIdByIdPayload) {
  }
}
