import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { DICTIONARY_COURIER_REDUCER_TOKEN, dictionaryCourierReducerProvider, rootReducerKey } from './courier.reducer';
import { EffectsModule } from '@ngrx/effects';
import { DictionaryCourierEffects } from './courier.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(rootReducerKey, DICTIONARY_COURIER_REDUCER_TOKEN),
    EffectsModule.forFeature([DictionaryCourierEffects])
  ],
  providers: [
    dictionaryCourierReducerProvider
  ]
})
export class DictionaryCourierStoreModule { }
