import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { OFFER_ITEM_REDUCER_TOKEN, offerItemReducerProvider, rootReducerKey } from './item.reducer';
import { EffectsModule } from '@ngrx/effects';
import { OfferItemEffects } from './item.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(rootReducerKey, OFFER_ITEM_REDUCER_TOKEN),
    EffectsModule.forFeature([OfferItemEffects])
  ],
  providers: [
    offerItemReducerProvider
  ]
})
export class OfferItemStoreModule {
}
