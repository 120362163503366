<div class="app-search-result">
  <div class="d-flex align-items-center mb-2">
    <div class="mat-display-1 m-0">Części</div>
    <mat-spinner *ngIf="partListLoading$ | async"
                 [diameter]="16"
                 color="primary"
                 class="ml-2"></mat-spinner>
  </div>
  <ul *ngIf="partList$ | async as list"
      class="app-disable-style pl-3"
      role="list">
    <li *ngFor="let listItem of list; trackBy: trackById"
        class="mb-2">
      <a [routerLink]="'/part/details/' + listItem?.id"
         class="link">{{ listItem?.name }}</a>
    </li>
  </ul>
  <mat-divider></mat-divider>

  <div class="d-flex align-items-center mb-2 mt-4">
    <div class="mat-display-1 m-0">Egzemplarze</div>
    <mat-spinner *ngIf="itemListLoading$ | async"
                 [diameter]="16"
                 color="primary"
                 class="ml-2"></mat-spinner>
  </div>
  <ul *ngIf="itemList$ | async as list"
      class="app-disable-style pl-3"
      role="list">
    <li *ngFor="let listItem of list; trackBy: trackById"
        class="mb-2">
      <a [routerLink]="'/item/details/' + listItem?.id"
         class="link">{{ listItem?.title }}</a>
    </li>
  </ul>
  <mat-divider></mat-divider>

  <div class="d-flex align-items-center mb-2 mt-4">
    <div class="mat-display-1 m-0">Diagramy</div>
    <mat-spinner *ngIf="diagramListLoading$ | async"
                 [diameter]="16"
                 color="primary"
                 class="ml-2"></mat-spinner>
  </div>
  <ul *ngIf="diagramList$ | async as list"
      class="app-disable-style pl-3"
      role="list">
    <li *ngFor="let listItem of list; trackBy: trackById"
        class="mb-2">
      <a [routerLink]="'/diagram/details/' + listItem?.id"
         class="link">{{ listItem?.name }}</a>
    </li>
  </ul>
</div>
