import {
  ByIdPayload,
  FailByIdPayload,
  OperationReducerTypes,
  PayloadAction,
  SendByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { ApplicationPayload } from '../models/application-payload';

export const ApplicationUpdateActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[application] Update'),
  requestFail: uniqueActionType('[application] Update fail'),
  requestSuccess: uniqueActionType('[application] Update success'),
  requestClearErrors: uniqueActionType('[application] Update clear errors'),
  requestClear: uniqueActionType('[application] Update clear'),
};

export class ApplicationUpdateAction implements PayloadAction {
  readonly type = ApplicationUpdateActionTypes.request;

  constructor(public payload: SendByIdPayload<ApplicationPayload>) {
  }
}

export class ApplicationUpdateFailAction implements PayloadAction {
  readonly type = ApplicationUpdateActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class ApplicationUpdateSuccessAction implements PayloadAction {
  readonly type = ApplicationUpdateActionTypes.requestSuccess;

  constructor(public payload: ByIdPayload) {
  }
}

export class ApplicationUpdateClearErrorsAction implements PayloadAction {
  readonly type = ApplicationUpdateActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class ApplicationUpdateClearAction implements PayloadAction {
  readonly type = ApplicationUpdateActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
