import { createSelector } from '@ngrx/store';
import { denormalize } from 'normalizr';
import {
  ByKey,
  createListStateIdsSelector,
  createListStateParamsSelector,
  createStateErrorsSelector,
  createStateLoadingSelector,
  createStateSuccessSelector,
  IdType,
  ListState
} from '@industi/ngx-common';
import { EntityState } from '../../../entity/entity.reducer';
import { EntityTypes } from '../../../entity/entity-types';
import { createEntitiesSelector } from '../../../entity/entities.reducer';
import { createSymfonyTotalCountSelector } from '../../../symfony/list/selectors/list.selectors';
import { Entities } from '../../../entity/entities';
import { getArticleListState } from '../common.reducer';
import { Article } from '../models/article';

const createState = (id: IdType) => createSelector(
  getArticleListState,
  (state: ByKey<ListState>) => state && state[id]
);

const createStateByContext = (id: IdType) => createListStateIdsSelector(createState(id));

export const createArticleListData = (id: IdType) => createSelector(
  createStateByContext(id),
  createEntitiesSelector([
    EntityTypes.article
  ]),
  (
    ids: IdType[],
    entities: EntityState
  ) => denormalize(ids, [Entities[EntityTypes.article]], entities) as Article[]
);

export const createArticleListLoading = (id: IdType) => createStateLoadingSelector(
  createState(id));

export const createArticleListSuccess = (id: IdType) => createStateSuccessSelector(
  createState(id));

export const createArticleListErrors = (id: IdType) => createStateErrorsSelector(
  createState(id));

export const createArticleListTotalCount = (id: IdType) => createSymfonyTotalCountSelector(
  createState(id));

export const createArticleListParams = (id: IdType) => createListStateParamsSelector(
  createState(id));
