import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import {
  ClientLoadListAction,
  ClientLoadListActionTypes,
  ClientLoadListClearIdsAction,
  ClientLoadListFailAction,
  ClientLoadListSuccessAction
} from './actions/list.actions';
import { catchError, first, map, mergeMap, switchMap, withLatestFrom, tap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { ByIdPayload, ByKey, IdType, NormalizedList, NormalizedSingle } from '@industi/ngx-common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { createClientListParams } from './selectors/get-load-list.selectors';
import { ClientListParams } from './models/client-list-params';
import { getInstanceLocale } from '../instance/instance.reducer';
import { ClientService } from './client.service';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import {
  ClientAddAction,
  ClientAddActionTypes,
  ClientAddFailAction,
  ClientAddSuccessAction
} from './actions/add.actions';
import {
  ClientEditAction,
  ClientEditActionTypes,
  ClientEditFailAction,
  ClientEditSuccessAction
} from './actions/edit.actions';
import {
  ClientLoadDetailsAction,
  ClientLoadDetailsActionTypes,
  ClientLoadDetailsFailAction,
  ClientLoadDetailsSuccessAction
} from './actions/load-details.actions';
import {
  ClientDeleteAction,
  ClientDeleteActionTypes,
  ClientDeleteFailAction,
  ClientDeleteSuccessAction
} from './actions/delete.actions';
import { ResponseStatus } from '../../shared/enums/response-status.enum';
import { LibSnackbarComponent } from '@industi/ngx-modules';
import { AppGlobal } from '../../shared/app-global';
import { AlertUtil } from '../../shared/utils/alert.util';
import { ClientSetLastAddedAdapterService } from './adapters/set-last-added-adapter.service';

@Injectable()
export class ClientEffects {

  @Effect()
  loadList$ = this.actions$.pipe(
    ofType(ClientLoadListActionTypes.request),
    map((action: ClientLoadListAction) => action.payload),
    switchMap((payload: ByIdPayload) => this.store.pipe(
      select(createClientListParams(payload.id)),
      map((params: ClientListParams) => [payload.id, params] as [IdType, ClientListParams]),
      first()
    )),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([[id, params], locale]) => this.service.loadList(locale, params).pipe(
      mergeMap((res: NormalizedList) => [
        new ClientLoadListClearIdsAction({ id }),
        new ClientLoadListSuccessAction({ id, res })
      ]),
      catchError((errors: HttpErrorResponse) => of(new ClientLoadListFailAction({ id, errors })))
    ))
  );

  @Effect()
  add$ = this.actions$.pipe(
    ofType(ClientAddActionTypes.request),
    map((action: ClientAddAction) => action.payload),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([{ id, data }, locale]) => this.service.add(locale, data).pipe(
      mergeMap((res: NormalizedSingle) => [
        this.lastAddedAdapter.setValueAction(res.result),
        new ClientAddSuccessAction({ res, id })
      ]),
      catchError((errors: HttpErrorResponse) => of(new ClientAddFailAction({ errors, id })))
    ))
  );

  @Effect()
  edit$ = this.actions$.pipe(
    ofType(ClientEditActionTypes.request),
    map((action: ClientEditAction) => action.payload),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([{ id, data }, locale]) => this.service.edit(locale, data, id).pipe(
      map((res: NormalizedSingle) => new ClientEditSuccessAction({ res, id })),
      catchError((errors: HttpErrorResponse) => of(new ClientEditFailAction({ errors, id })))
    ))
  );

  @Effect()
  loadDetails$ = this.actions$.pipe(
    ofType(ClientLoadDetailsActionTypes.request),
    map((action: ClientLoadDetailsAction) => action.payload.id),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([id, locale]) => this.service.details(locale, id).pipe(
      map((res: NormalizedSingle) => new ClientLoadDetailsSuccessAction({ id, res })),
      catchError((errors: HttpErrorResponse) => of(new ClientLoadDetailsFailAction({ id, errors })))
    ))
  );

  @Effect()
  delete$ = this.actions$.pipe(
    ofType(ClientDeleteActionTypes.request),
    map((action: ClientDeleteAction) => action.payload.id),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([id, locale]) => this.service.delete(locale, id).pipe(
      map(() => new ClientDeleteSuccessAction({ id })),
      catchError((errors: HttpErrorResponse) => of(new ClientDeleteFailAction({ id, errors })))
    ))
  );

  @Effect({ dispatch: false })
  crudFail$ = this.actions$.pipe(
    ofType(
      ClientAddActionTypes.requestFail,
      ClientEditActionTypes.requestFail
    ),
    map((action: ClientAddFailAction | ClientEditFailAction) => action.payload.errors),
    tap((data: HttpErrorResponse) => {
      if (data?.status === ResponseStatus.BAD_REQUEST || data?.status === ResponseStatus.UNPROCESSABLE_ENTITY) {
        this.snackBar.openFromComponent(LibSnackbarComponent, {
          data: {
            message: AlertUtil.getControlTranslation(data.error.detail),
            type: 'error'
          },
          duration: AppGlobal.defaultSnackBarDuration
        });
      }
    })
  );

  constructor(
    private actions$: Actions,
    private store: Store<ByKey>,
    private service: ClientService,
    private lastAddedAdapter: ClientSetLastAddedAdapterService,
    private snackBar: MatSnackBar
  ) {
  }
}
