import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { ByIdPayload, ByKey, IdType, NormalizedList, NormalizedSingle } from '@industi/ngx-common';
import { CarbonFormService } from './form.service';
import { catchError, first, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { getInstanceLocale } from '../../instance/instance.reducer';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import {
  CarbonFormLoadListAction,
  CarbonFormLoadListActionTypes,
  CarbonFormLoadListClearIdsAction,
  CarbonFormLoadListFailAction,
  CarbonFormLoadListSuccessAction
} from './actions/list.actions';
import { CarbonFormListParams } from './models/form-list-params';
import { createCarbonFormListParams } from './selectors/get-load-list.selectors';
import {
  CarbonFormLoadDetailsAction,
  CarbonFormLoadDetailsActionTypes,
  CarbonFormLoadDetailsFailAction,
  CarbonFormLoadDetailsSuccessAction
} from './actions/load-details.actions';
import {
  CarbonFormAddAction,
  CarbonFormAddActionTypes,
  CarbonFormAddFailAction,
  CarbonFormAddSuccessAction
} from './actions/add.actions';
import { ResponseStatus } from '../../../shared/enums/response-status.enum';
import { LibSnackbarComponent } from '@industi/ngx-modules';
import { AppGlobal } from '../../../shared/app-global';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  CarbonFormDeleteAction,
  CarbonFormDeleteActionTypes,
  CarbonFormDeleteFailAction,
  CarbonFormDeleteSuccessAction
} from './actions/delete.actions';
import {
  CarbonFormLoadPdfAction,
  CarbonFormLoadPdfActionTypes,
  CarbonFormLoadPdfFailAction,
  CarbonFormLoadPdfSuccessAction
} from './actions/load-pdf.actions';
import { AppFile } from '../../../shared/models/file';
import { FileUtil } from '../../../shared/utils/file.util';
import { AlertUtil } from '../../../shared/utils/alert.util';
import {
  CarbonFormEditAction,
  CarbonFormEditActionTypes,
  CarbonFormEditFailAction,
  CarbonFormEditSuccessAction
} from './actions/edit.actions';

@Injectable()
export class CarbonFormEffects {

  @Effect()
  loadList$ = this.actions$.pipe(
    ofType(CarbonFormLoadListActionTypes.request),
    map((action: CarbonFormLoadListAction) => action.payload),
    switchMap((payload: ByIdPayload) => this.store.pipe(
      select(createCarbonFormListParams(payload.id)),
      map((params: CarbonFormListParams) => [payload.id, params] as [IdType, CarbonFormListParams]),
      first()
    )),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([[id, params], locale]) => this.service.loadList(locale, params).pipe(
      mergeMap((res: NormalizedList) => [
        new CarbonFormLoadListClearIdsAction({ id }),
        new CarbonFormLoadListSuccessAction({ id, res })]),
      catchError((errors: HttpErrorResponse) => of(new CarbonFormLoadListFailAction({ id, errors })))
    ))
  );

  @Effect()
  add$ = this.actions$.pipe(
    ofType(CarbonFormAddActionTypes.request),
    map((action: CarbonFormAddAction) => action.payload),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([{ id, data }, locale]) => this.service.add(locale, data).pipe(
      map((res: NormalizedSingle) => new CarbonFormAddSuccessAction({ res, id })),
      catchError((errors: HttpErrorResponse) => of(new CarbonFormAddFailAction({ errors, id })))
    ))
  );

  @Effect()
  edit$ = this.actions$.pipe(
    ofType(CarbonFormEditActionTypes.request),
    map((action: CarbonFormEditAction) => action.payload),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([{ id, data }, locale]) => this.service.edit(locale, data, id).pipe(
      map((res: NormalizedSingle) => new CarbonFormEditSuccessAction({ res, id })),
      catchError((errors: HttpErrorResponse) => of(new CarbonFormEditFailAction({ errors, id })))
    ))
  );

  @Effect()
  loadDetails$ = this.actions$.pipe(
    ofType(CarbonFormLoadDetailsActionTypes.request),
    map((action: CarbonFormLoadDetailsAction) => action.payload.id),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([id, locale]) => this.service.details(locale, id).pipe(
      map((res: NormalizedSingle) => new CarbonFormLoadDetailsSuccessAction({ id, res })),
      catchError((errors: HttpErrorResponse) => of(new CarbonFormLoadDetailsFailAction({ id, errors })))
    ))
  );

  @Effect()
  delete$ = this.actions$.pipe(
    ofType(CarbonFormDeleteActionTypes.request),
    map((action: CarbonFormDeleteAction) => action.payload.id),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([id, locale]) => this.service.delete(locale, id).pipe(
      map(() => new CarbonFormDeleteSuccessAction({ id })),
      catchError((errors: HttpErrorResponse) => of(new CarbonFormDeleteFailAction({ id, errors })))
    ))
  );

  @Effect()
  pdf$ = this.actions$.pipe(
    ofType(CarbonFormLoadPdfActionTypes.request),
    map((action: CarbonFormLoadPdfAction) => action.payload),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([{ id }, locale]) => this.service.pdf(locale).pipe(
      map((res: any) => new CarbonFormLoadPdfSuccessAction({ id, res })),
      catchError((errors: HttpErrorResponse) => of(new CarbonFormLoadPdfFailAction({ id, errors })))
    ))
  );

  @Effect({ dispatch: false })
  pdfSuccess$ = this.actions$.pipe(
    ofType(CarbonFormLoadPdfActionTypes.requestSuccess),
    map((action: CarbonFormLoadPdfSuccessAction) => action.payload.res),
    tap((data: AppFile) => {
      FileUtil.saveFileInSameTab(data.file, 'Karbonownia - kody kreskowe.pdf');
      this.snackBar.openFromComponent(LibSnackbarComponent, {
        data: {
          message: 'Pobrano pdf',
          type: 'success'
        },
        duration: AppGlobal.defaultSnackBarDuration
      });
    })
  );

  @Effect({ dispatch: false })
  crudFail$ = this.actions$.pipe(
    ofType(
      CarbonFormAddActionTypes.requestFail,
      CarbonFormEditActionTypes.requestFail
    ),
    map((action: CarbonFormAddFailAction | CarbonFormEditFailAction) => action.payload.errors),
    tap((data: HttpErrorResponse) => {
      if (data?.status === ResponseStatus.BAD_REQUEST || data?.status === ResponseStatus.UNPROCESSABLE_ENTITY) {
        this.snackBar.openFromComponent(LibSnackbarComponent, {
          data: {
            message: AlertUtil.getControlTranslation(data.error.detail),
            type: 'error'
          },
          duration: AppGlobal.defaultSnackBarDuration
        });
      }
    })
  );

  constructor(
    private actions$: Actions,
    private store: Store<ByKey>,
    private service: CarbonFormService,
    private snackBar: MatSnackBar
  ) {
  }
}
