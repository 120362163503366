import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { ByIdPayload, ByKey, IdType, NormalizedList, NormalizedSingle } from '@industi/ngx-common';
import { LayerService } from './layer.service';
import { catchError, first, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { getInstanceLocale } from '../instance/instance.reducer';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import {
  LayerLoadListAction,
  LayerLoadListActionTypes,
  LayerLoadListClearIdsAction,
  LayerLoadListFailAction,
  LayerLoadListSuccessAction
} from './actions/list.actions';
import { createLayerListParams } from './selectors/get-load-list.selectors';
import { LayerListParams } from './models/layer-list-params';
import {
  LayerAddAction,
  LayerAddActionTypes,
  LayerAddFailAction,
  LayerAddSuccessAction
} from './actions/add.actions';
import {
  LayerEditAction,
  LayerEditActionTypes,
  LayerEditFailAction,
  LayerEditSuccessAction
} from './actions/edit.actions';
import {
  LayerLoadDetailsAction,
  LayerLoadDetailsActionTypes,
  LayerLoadDetailsFailAction,
  LayerLoadDetailsSuccessAction
} from './actions/details.actions';
import { ResponseStatus } from '../../shared/enums/response-status.enum';
import { LibSnackbarComponent } from '@industi/ngx-modules';
import { AppGlobal } from '../../shared/app-global';
import {
  LayerDeleteAction,
  LayerDeleteActionTypes,
  LayerDeleteFailAction,
  LayerDeleteSuccessAction
} from './actions/delete.actions';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlertUtil } from '../../shared/utils/alert.util';

@Injectable()
export class LayerEffects {

  @Effect()
  loadList$ = this.actions$.pipe(
    ofType(LayerLoadListActionTypes.request),
    map((action: LayerLoadListAction) => action.payload),
    switchMap((payload: ByIdPayload) => this.store.pipe(
      select(createLayerListParams(payload.id)),
      map((params: LayerListParams) => [payload.id, params] as [IdType, LayerListParams]),
      first()
    )),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([[ id, params ], locale]) => this.service.loadList(locale, params).pipe(
      mergeMap((res: NormalizedList) => [
        new LayerLoadListClearIdsAction({ id }),
        new LayerLoadListSuccessAction({ id, res })]),
      catchError((errors: HttpErrorResponse) => of(new LayerLoadListFailAction({ id, errors })))
    ))
  );

  @Effect()
  add$ = this.actions$.pipe(
    ofType(LayerAddActionTypes.request),
    map((action: LayerAddAction) => action.payload),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([{ id, data }, locale]) => this.service.add(locale, data).pipe(
      map((res: NormalizedSingle) => new LayerAddSuccessAction({ res, id })),
      catchError((errors: HttpErrorResponse) => of(new LayerAddFailAction({ errors, id })))
    ))
  );

  @Effect()
  edit$ = this.actions$.pipe(
    ofType(LayerEditActionTypes.request),
    map((action: LayerEditAction) => action.payload),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([{ id, data }, locale]) => this.service.edit(locale, data, id).pipe(
      map((res: NormalizedSingle) => new LayerEditSuccessAction({ res, id })),
      catchError((errors: HttpErrorResponse) => of(new LayerEditFailAction({ errors, id })))
    ))
  );

  @Effect()
  loadDetails$ = this.actions$.pipe(
    ofType(LayerLoadDetailsActionTypes.request),
    map((action: LayerLoadDetailsAction) => action.payload.id),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([id, locale]) => this.service.details(locale, id).pipe(
      map((res: NormalizedSingle) => new LayerLoadDetailsSuccessAction({ id, res })),
      catchError((errors: HttpErrorResponse) => of(new LayerLoadDetailsFailAction({ id, errors })))
    ))
  );

  @Effect()
  delete$ = this.actions$.pipe(
    ofType(LayerDeleteActionTypes.request),
    map((action: LayerDeleteAction) => action.payload.id),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([id, locale]) => this.service.delete(locale, id).pipe(
      map(() => new LayerDeleteSuccessAction({ id })),
      catchError((errors: HttpErrorResponse) => of(new LayerDeleteFailAction({ id, errors })))
    ))
  );

  @Effect({ dispatch: false })
  crudFail$ = this.actions$.pipe(
    ofType(
      LayerAddActionTypes.requestFail,
      LayerEditActionTypes.requestFail
    ),
    map((action: LayerAddFailAction | LayerEditFailAction) => action.payload.errors),
    tap((data: HttpErrorResponse) => {
      if (data?.status === ResponseStatus.BAD_REQUEST || data?.status === ResponseStatus.UNPROCESSABLE_ENTITY) {
        this.snackBar.openFromComponent(LibSnackbarComponent, {
          data: {
            message: AlertUtil.getControlTranslation(data.error.detail),
            type: 'error'
          },
          duration: AppGlobal.defaultSnackBarDuration
        });
      }
    })
  );

  constructor(
    private actions$: Actions,
    private store: Store<ByKey>,
    private service: LayerService,
    private snackBar: MatSnackBar
  ) {
  }
}
