import { ByKey, listByIdReducer, ListState, operationByIdReducer, SingleState } from '@industi/ngx-common';
import { ActionReducer, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import { UserLoadDetailsActionTypes } from './actions/load-details.actions';
import { UserEditActionTypes } from './actions/edit.actions';
import { UserLoadListActionTypes } from './actions/list.actions';
import { UserAddActionTypes } from './actions/add.actions';
import { InjectionToken, Provider } from '@angular/core';
import { UserDeleteActionTypes } from './actions/delete.actions';

export const rootReducerKey = 'user';

export interface UserOperationByIdState {
  add: SingleState;
  edit: SingleState;
  details: SingleState;
  delete: SingleState;
}

export interface UserState {
  operationsById: ByKey<UserOperationByIdState>;
  list: ByKey<ListState>;
}

const initialState: UserState = {
  operationsById: {},
  list: {}
};

const reducer: ActionReducer<UserState> = combineReducers<UserState>({
  operationsById: operationByIdReducer<UserOperationByIdState>({
    mapActionToId: (a) => a && a.payload && a.payload.id,
    operations: {
      add: UserAddActionTypes,
      edit: UserEditActionTypes,
      details: UserLoadDetailsActionTypes,
      delete: UserDeleteActionTypes
    }
  }),
  list: listByIdReducer({ types: UserLoadListActionTypes })
}, initialState);

export function getReducers(): ActionReducer<UserState> {
  return reducer;
}

export const USER_REDUCER_TOKEN = new InjectionToken<ActionReducer<UserState>>(rootReducerKey);

export const userReducerProvider: Provider = { provide: USER_REDUCER_TOKEN, useFactory: getReducers };

const getState = createFeatureSelector<UserState>(rootReducerKey);

export const getUserOperationsById = createSelector(
  getState,
  (state: UserState) => state && state.operationsById
);

export const getUserListState = createSelector(
  getState,
  (state: UserState) => state && state.list
);
