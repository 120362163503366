import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarbonFormStoreModule } from './form/form-store.module';
import { CarbonFormCommissionStoreModule } from './form-commission/form-commission-store.module';
import { CarbonPartCommissionStoreModule } from './part-commission/part-commission-store.module';
import { CarbonCommissionTaskStoreModule } from './commission-task/commission-task-store.module';

@NgModule({
  imports: [
    CommonModule,
    CarbonFormStoreModule,
    CarbonFormCommissionStoreModule,
    CarbonPartCommissionStoreModule,
    CarbonCommissionTaskStoreModule
  ]
})
export class CarbonStoreModule { }
