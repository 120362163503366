import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { DIAGRAM_PART_REDUCER_TOKEN, diagramPartReducerProvider, rootReducerKey } from './part.reducer';
import { DiagramPartEffects } from './part.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(rootReducerKey, DIAGRAM_PART_REDUCER_TOKEN),
    EffectsModule.forFeature([DiagramPartEffects])
  ],
  providers: [
    diagramPartReducerProvider
  ]
})
export class DiagramPartStoreModule { }
