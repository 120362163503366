import {
  ByIdPayload,
  FailByIdPayload,
  OperationReducerTypes,
  PayloadAction,
  uniqueActionType
} from '@industi/ngx-common';

export const ClientDeleteActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[client] Delete'),
  requestFail: uniqueActionType('[client] Delete fail'),
  requestSuccess: uniqueActionType('[client] Delete success'),
  requestClearErrors: uniqueActionType('[client] Delete clear errors'),
  requestClear: uniqueActionType('[client] Delete clear')
};

export class ClientDeleteAction implements PayloadAction {
  readonly type = ClientDeleteActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class ClientDeleteFailAction implements PayloadAction {
  readonly type = ClientDeleteActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class ClientDeleteSuccessAction implements PayloadAction {
  readonly type = ClientDeleteActionTypes.requestSuccess;

  constructor(public payload: ByIdPayload) {
  }
}


export class ClientDeleteClearErrorsAction implements PayloadAction {
  readonly type = ClientDeleteActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class ClientDeleteClearAction implements PayloadAction {
  readonly type = ClientDeleteActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}

