import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { ByKey, IdType, NormalizedSingle } from '@industi/ngx-common';
import { EbayService } from './ebay.service';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { getInstanceLocale } from '../instance/instance.reducer';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import {
  EbayLoadConfigurationAction,
  EbayLoadConfigurationActionTypes,
  EbayLoadConfigurationFailAction,
  EbayLoadConfigurationSuccessAction
} from './actions/configuration.actions';
import {
  EbayRefreshConfigurationAction,
  EbayRefreshConfigurationActionTypes,
  EbayRefreshConfigurationFailAction,
  EbayRefreshConfigurationSuccessAction
} from './actions/refresh-configuration.actions';
import {
  EbayLoadOfferConfigurationAction,
  EbayLoadOfferConfigurationActionTypes,
  EbayLoadOfferConfigurationFailAction,
  EbayLoadOfferConfigurationSuccessAction
} from './actions/configuration-offer.actions';
import {
  EbayAddConfigurationAction,
  EbayAddConfigurationActionTypes,
  EbayAddConfigurationFailAction,
  EbayAddConfigurationSuccessAction
} from './actions/configuration-add.actions';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LibSnackbarComponent } from '@industi/ngx-modules';
import { AppGlobal } from '../../shared/app-global';
import {
  EbayLoadTokenAction,
  EbayLoadTokenActionTypes,
  EbayLoadTokenFailAction,
  EbayLoadTokenSuccessAction
} from './actions/token.actions';
import { OutputTokenResponse } from '../auth/models/output-token-response';
import { EbaySetTokenAction } from './actions/set-token.actions';
import {
  EbayLoadCompatibilityPropertiesAction,
  EbayLoadCompatibilityPropertiesActionTypes,
  EbayLoadCompatibilityPropertiesFailAction,
  EbayLoadCompatibilityPropertiesSuccessAction
} from './actions/load-compatibility-properties.actions';
import {
  EbayLoadCompatibilityPropertyValuesAction,
  EbayLoadCompatibilityPropertyValuesActionTypes,
  EbayLoadCompatibilityPropertyValuesFailAction,
  EbayLoadCompatibilityPropertyValuesSuccessAction
} from './actions/load-compatibility-property-values.actions';

@Injectable()
export class EbayEffects {

  @Effect()
  loadConfiguration$ = this.actions$.pipe(
    ofType(EbayLoadConfigurationActionTypes.request),
    map((action: EbayLoadConfigurationAction) => action.payload.id),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([id, locale]) => this.service.configuration(locale, id).pipe(
      map((res: NormalizedSingle) => new EbayLoadConfigurationSuccessAction({ id, res })),
      catchError((errors: HttpErrorResponse) => of(new EbayLoadConfigurationFailAction({ id, errors })))
    ))
  );

  @Effect()
  loadCompatibilityProperties$ = this.actions$.pipe(
    ofType(EbayLoadCompatibilityPropertiesActionTypes.request),
    map((action: EbayLoadCompatibilityPropertiesAction) => action.payload.id),
    switchMap((id: IdType) => this.service.loadCompatibilityProperties(id).pipe(
      map((res: NormalizedSingle) => new EbayLoadCompatibilityPropertiesSuccessAction({ id, res })),
      catchError((errors: HttpErrorResponse) => of(new EbayLoadCompatibilityPropertiesFailAction({ id, errors })))
    ))
  );

  @Effect()
  loadCompatibilityPropertyValues$ = this.actions$.pipe(
    ofType(EbayLoadCompatibilityPropertyValuesActionTypes.request),
    map((action: EbayLoadCompatibilityPropertyValuesAction) => action.payload),
    switchMap(({ id, context }) => this.service.loadCompatibilityPropertyValues(id, context).pipe(
      map((res: NormalizedSingle) => new EbayLoadCompatibilityPropertyValuesSuccessAction({ id, context, res })),
      catchError((errors: HttpErrorResponse) => of(new EbayLoadCompatibilityPropertyValuesFailAction({ id, context, errors })))
    ))
  );

  @Effect()
  configurationAdd$ = this.actions$.pipe(
    ofType(EbayAddConfigurationActionTypes.request),
    map((action: EbayAddConfigurationAction) => action.payload),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([{ id, data }, locale]) => this.service.configurationAdd(locale, data).pipe(
      map(() => new EbayAddConfigurationSuccessAction({ id })),
      catchError((errors: HttpErrorResponse) => of(new EbayAddConfigurationFailAction({ errors, id })))
    ))
  );

  @Effect()
  loadOfferConfiguration$ = this.actions$.pipe(
    ofType(EbayLoadOfferConfigurationActionTypes.request),
    map((action: EbayLoadOfferConfigurationAction) => action.payload.id),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([id, locale]) => this.service.offerConfiguration(locale, id).pipe(
      map((res: NormalizedSingle) => new EbayLoadOfferConfigurationSuccessAction({ id, res })),
      catchError((errors: HttpErrorResponse) => of(new EbayLoadOfferConfigurationFailAction({ id, errors })))
    ))
  );

  @Effect()
  loadToken$ = this.actions$.pipe(
    ofType(EbayLoadTokenActionTypes.request),
    map((action: EbayLoadTokenAction) => action.payload.id),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([id, locale]) => this.service.token(locale).pipe(
      map((res: OutputTokenResponse) => new EbayLoadTokenSuccessAction({ id, res })),
      catchError((errors: HttpErrorResponse) => of(new EbayLoadTokenFailAction({ id, errors })))
    ))
  );

  @Effect()
  loadTokenSuccess$ = this.actions$.pipe(
    ofType(EbayLoadTokenActionTypes.requestSuccess),
    map((action: EbayLoadTokenSuccessAction) => action.payload.res),
    map((value: OutputTokenResponse) => new EbaySetTokenAction({ value }))
  );

  @Effect()
  refreshConfiguration$ = this.actions$.pipe(
    ofType(EbayRefreshConfigurationActionTypes.request),
    map((action: EbayRefreshConfigurationAction) => action.payload.id),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([id, locale]) => this.service.configurationRefresh(locale).pipe(
      map(() => new EbayRefreshConfigurationSuccessAction({ id })),
      catchError((errors: HttpErrorResponse) => of(new EbayRefreshConfigurationFailAction({ id, errors })))
    ))
  );

  @Effect({ dispatch: false })
  refreshConfigurationSuccess$ = this.actions$.pipe(
    ofType(EbayRefreshConfigurationActionTypes.requestSuccess),
    map((action: EbayRefreshConfigurationSuccessAction) => action.payload.id),
    tap((id: IdType) => {
      this.store.dispatch(new EbayLoadConfigurationAction({ id }));

      // Show snackbar
      this.snackBar.openFromComponent(LibSnackbarComponent, {
        data: {
          message: 'Pomyślnie zaktualizowano dane eBay',
          type: 'success'
        },
        duration: AppGlobal.defaultSnackBarDuration
      });
    })
  );

  constructor(
    private actions$: Actions,
    private store: Store<ByKey>,
    private service: EbayService,
    private snackBar: MatSnackBar
  ) {
  }
}
