import { ByKey, listByIdReducer, ListState, operationByIdReducer, SingleState } from '@industi/ngx-common';
import { ActionReducer, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import { InjectionToken, Provider } from '@angular/core';
import { DictionaryCourierLoadDetailsActionTypes } from './actions/load-details.actions';
import { DictionaryCourierLoadListActionTypes } from './actions/list.actions';
import { DictionaryCourierEditActionTypes } from './actions/edit.actions';
import { DictionaryCourierDeleteActionTypes } from './actions/delete.actions';
import { DictionaryCourierAddActionTypes } from './actions/add.actions';

export const rootReducerKey = 'courier';

export interface DictionaryCourierOperationByIdState {
  add: SingleState;
  edit: SingleState;
  details: SingleState;
  delete: SingleState;
}

export interface DictionaryCourierState {
  operationsById: ByKey<DictionaryCourierOperationByIdState>;
  list: ByKey<ListState>;
}

const initialState: DictionaryCourierState = {
  operationsById: {},
  list: {}
};

const reducer: ActionReducer<DictionaryCourierState> = combineReducers<DictionaryCourierState>({
  operationsById: operationByIdReducer<DictionaryCourierOperationByIdState>({
    mapActionToId: (a) => a && a.payload && a.payload.id,
    operations: {
      add: DictionaryCourierAddActionTypes,
      edit: DictionaryCourierEditActionTypes,
      details: DictionaryCourierLoadDetailsActionTypes,
      delete: DictionaryCourierDeleteActionTypes
    }
  }),
  list: listByIdReducer({ types: DictionaryCourierLoadListActionTypes })
}, initialState);

export function getReducers(): ActionReducer<DictionaryCourierState> {
  return reducer;
}

export const DICTIONARY_COURIER_REDUCER_TOKEN = new InjectionToken<ActionReducer<DictionaryCourierState>>(rootReducerKey);

export const dictionaryCourierReducerProvider: Provider = { provide: DICTIONARY_COURIER_REDUCER_TOKEN, useFactory: getReducers };

const getState = createFeatureSelector<DictionaryCourierState>(rootReducerKey);

export const getDictionaryCourierOperationsById = createSelector(
  getState,
  (state: DictionaryCourierState) => state && state.operationsById
);

export const getDictionaryCourierListState = createSelector(
  getState,
  (state: DictionaryCourierState) => state && state.list
);
