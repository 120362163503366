import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { APPLICATION_REDUCER_TOKEN, applicationReducerProvider, rootReducerKey } from './application.reducer';
import { EffectsModule } from '@ngrx/effects';
import { ApplicationEffects } from './application.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(rootReducerKey, APPLICATION_REDUCER_TOKEN),
    EffectsModule.forFeature([ApplicationEffects])
  ],
  providers: [
    applicationReducerProvider
  ]
})
export class ApplicationStoreModule { }
