import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';

export const PartLoadDetailsActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[part] Load details'),
  requestFail: uniqueActionType('[part] Load details fail'),
  requestSuccess: uniqueActionType('[part] Load details success'),
  requestClearErrors: uniqueActionType('[part] Load details clear errors'),
  requestClear: uniqueActionType('[part] Load details clear')
};

export class PartLoadDetailsAction implements PayloadAction {
  readonly type = PartLoadDetailsActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class PartLoadDetailsFailAction implements PayloadAction {
  readonly type = PartLoadDetailsActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class PartLoadDetailsSuccessAction implements PayloadAction {
  readonly type = PartLoadDetailsActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class PartLoadDetailsClearAction implements PayloadAction {
  readonly type = PartLoadDetailsActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}

export class PartLoadDetailsClearErrorsAction implements PayloadAction {
  readonly type = PartLoadDetailsActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}
