import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import {
  DICTIONARY_UNIT_STATE_REDUCER_TOKEN,
  dictionaryUnitStateReducerProvider,
  rootReducerKey
} from './unit-state.reducer';
import { EffectsModule } from '@ngrx/effects';
import { DictionaryUnitStateEffects } from './unit-state.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(rootReducerKey, DICTIONARY_UNIT_STATE_REDUCER_TOKEN),
    EffectsModule.forFeature([DictionaryUnitStateEffects])
  ],
  providers: [
    dictionaryUnitStateReducerProvider
  ]
})
export class DictionaryUnitStateStoreModule { }
