import { ByKey, operationByIdReducer, SingleState } from '@industi/ngx-common';
import { ActionReducer, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import { InjectionToken, Provider } from '@angular/core';
import { CarbonCommissionTaskAddActionTypes } from './actions/add.actions';
import { CarbonCommissionTaskEditActionTypes } from './actions/edit.actions';
import { CarbonCommissionTaskLoadDetailsActionTypes } from './actions/load-details.actions';
import { CarbonCommissionTaskDeleteActionTypes } from './actions/delete.actions';

export const rootReducerKey = 'carbonCommissionTask';

export interface CarbonCommissionTaskOperationByIdState {
  add: SingleState;
  edit: SingleState;
  details: SingleState;
  delete: SingleState;
}

export interface CarbonCommissionTaskState {
  operationsById: ByKey<CarbonCommissionTaskOperationByIdState>;
}

const initialState: CarbonCommissionTaskState = {
  operationsById: {}
};

const reducer: ActionReducer<CarbonCommissionTaskState> = combineReducers<CarbonCommissionTaskState>({
  operationsById: operationByIdReducer<CarbonCommissionTaskOperationByIdState>({
    mapActionToId: (a) => a && a.payload && a.payload.id,
    operations: {
      add: CarbonCommissionTaskAddActionTypes,
      edit: CarbonCommissionTaskEditActionTypes,
      details: CarbonCommissionTaskLoadDetailsActionTypes,
      delete: CarbonCommissionTaskDeleteActionTypes
    }
  })
}, initialState);

export function getReducers(): ActionReducer<CarbonCommissionTaskState> {
  return reducer;
}

export const CARBON_COMMISSION_TASK_REDUCER_TOKEN = new InjectionToken<ActionReducer<CarbonCommissionTaskState>>(rootReducerKey);

export const carbonCommissionTaskReducerProvider: Provider = { provide: CARBON_COMMISSION_TASK_REDUCER_TOKEN, useFactory: getReducers };

const getState = createFeatureSelector<CarbonCommissionTaskState>(rootReducerKey);

export const getCarbonCommissionTaskOperationsById = createSelector(
  getState,
  (state: CarbonCommissionTaskState) => state && state.operationsById
);
