import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { ByIdPayload, ByKey, IdType, NormalizedList, NormalizedSingle } from '@industi/ngx-common';
import { catchError, first, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { getInstanceLocale } from '../../instance/instance.reducer';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import { CarbonFormCommissionService } from './form-commission.service';
import {
  CarbonFormCommissionLoadListAction,
  CarbonFormCommissionLoadListActionTypes,
  CarbonFormCommissionLoadListClearIdsAction,
  CarbonFormCommissionLoadListFailAction,
  CarbonFormCommissionLoadListSuccessAction
} from './actions/list.actions';
import { createCarbonFormCommissionListParams } from './selectors/get-load-list.selectors';
import { CarbonFormCommissionListParams } from './models/form-commission-list-params';
import {
  CarbonFormCommissionLoadDetailsAction,
  CarbonFormCommissionLoadDetailsActionTypes,
  CarbonFormCommissionLoadDetailsFailAction,
  CarbonFormCommissionLoadDetailsSuccessAction
} from './actions/load-details.actions';
import {
  CarbonFormCommissionEditAction,
  CarbonFormCommissionEditActionTypes,
  CarbonFormCommissionEditFailAction,
  CarbonFormCommissionEditSuccessAction
} from './actions/edit.actions';
import { ResponseStatus } from '../../../shared/enums/response-status.enum';
import { LibSnackbarComponent } from '@industi/ngx-modules';
import { AppGlobal } from '../../../shared/app-global';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  CarbonFormCommissionAddAction,
  CarbonFormCommissionAddActionTypes,
  CarbonFormCommissionAddFailAction,
  CarbonFormCommissionAddSuccessAction
} from './actions/add.actions';
import { AlertUtil } from '../../../shared/utils/alert.util';
import {
  CarbonFormCommissionDeleteAction,
  CarbonFormCommissionDeleteActionTypes,
  CarbonFormCommissionDeleteFailAction,
  CarbonFormCommissionDeleteSuccessAction
} from './actions/delete.actions';

@Injectable()
export class CarbonFormCommissionEffects {

  @Effect()
  loadList$ = this.actions$.pipe(
    ofType(CarbonFormCommissionLoadListActionTypes.request),
    map((action: CarbonFormCommissionLoadListAction) => action.payload),
    switchMap((payload: ByIdPayload) => this.store.pipe(
      select(createCarbonFormCommissionListParams(payload.id)),
      map((params: CarbonFormCommissionListParams) => [payload.id, params] as [IdType, CarbonFormCommissionListParams]),
      first()
    )),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([[ id, params ], locale]) => this.service.loadList(locale, params).pipe(
      mergeMap((res: NormalizedList) => [
        new CarbonFormCommissionLoadListClearIdsAction({ id }),
        new CarbonFormCommissionLoadListSuccessAction({ id, res })]),
      catchError((errors: HttpErrorResponse) => of(new CarbonFormCommissionLoadListFailAction({ id, errors })))
    ))
  );

  @Effect()
  add$ = this.actions$.pipe(
    ofType(CarbonFormCommissionAddActionTypes.request),
    map((action: CarbonFormCommissionAddAction) => action.payload),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([{ id, data }, locale]) => this.service.add(locale, data).pipe(
      map((res: NormalizedSingle) => new CarbonFormCommissionAddSuccessAction({ res, id })),
      catchError((errors: HttpErrorResponse) => of(new CarbonFormCommissionAddFailAction({ errors, id })))
    ))
  );

  @Effect()
  edit$ = this.actions$.pipe(
    ofType(CarbonFormCommissionEditActionTypes.request),
    map((action: CarbonFormCommissionEditAction) => action.payload),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([{ id, data }, locale]) => this.service.edit(locale, data, id).pipe(
      map((res: NormalizedSingle) => new CarbonFormCommissionEditSuccessAction({ res, id })),
      catchError((errors: HttpErrorResponse) => of(new CarbonFormCommissionEditFailAction({ errors, id })))
    ))
  );

  @Effect()
  loadDetails$ = this.actions$.pipe(
    ofType(CarbonFormCommissionLoadDetailsActionTypes.request),
    map((action: CarbonFormCommissionLoadDetailsAction) => action.payload.id),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([id, locale]) => this.service.details(locale, id).pipe(
      map((res: NormalizedSingle) => new CarbonFormCommissionLoadDetailsSuccessAction({ id, res })),
      catchError((errors: HttpErrorResponse) => of(new CarbonFormCommissionLoadDetailsFailAction({ id, errors })))
    ))
  );

  @Effect()
  delete$ = this.actions$.pipe(
    ofType(CarbonFormCommissionDeleteActionTypes.request),
    map((action: CarbonFormCommissionDeleteAction) => action.payload.id),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([id, locale]) => this.service.delete(locale, id).pipe(
      map(() => new CarbonFormCommissionDeleteSuccessAction({ id })),
      catchError((errors: HttpErrorResponse) => of(new CarbonFormCommissionDeleteFailAction({ id, errors })))
    ))
  );

  @Effect({ dispatch: false })
  crudFail$ = this.actions$.pipe(
    ofType(
      CarbonFormCommissionAddActionTypes.requestFail,
      CarbonFormCommissionEditActionTypes.requestFail
    ),
    map((action: CarbonFormCommissionAddFailAction | CarbonFormCommissionEditFailAction) => action.payload.errors),
    tap((data: HttpErrorResponse) => {
      if (data?.status === ResponseStatus.BAD_REQUEST || data?.status === ResponseStatus.UNPROCESSABLE_ENTITY) {
        this.snackBar.openFromComponent(LibSnackbarComponent, {
          data: {
            message: AlertUtil.getControlTranslation(data.error.detail),
            type: 'error'
          },
          duration: AppGlobal.defaultSnackBarDuration
        });
      }
    })
  );

  constructor(
    private actions$: Actions,
    private store: Store<ByKey>,
    private service: CarbonFormCommissionService,
    private snackBar: MatSnackBar
  ) {
  }
}
