import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Inject, Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as _ from 'lodash';
import { Role } from '../auth-roles';
import { ByKey } from '@industi/ngx-common';
import { getUserRoles } from '../selectors/get-auth-role.selectors';
import { combineLatest, Observable } from 'rxjs';
import { AuthAfterLoginRouteInjectionToken } from '../../../app.providers';
import { map } from 'rxjs/operators';

@Injectable()
export class RoleGuard implements CanActivate {

  constructor(
    private store: Store<ByKey>,
    private router: Router,
    @Inject(AuthAfterLoginRouteInjectionToken) private afterLoginRoute: string[]
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot
  ): Observable<boolean> {
    const requiredRoles = _.get(route, 'data.roles', []) as Role[];

    return combineLatest([
      this.store.pipe(select(getUserRoles))
    ]).pipe(
      map(([roles]) => {
        const intersectedRoles = _.intersection<Role>(roles, requiredRoles);

        if (!requiredRoles.length) {
          console.warn(this.getHelpGuardConfigWarnMessage(route.routeConfig.path));
        }

        if (requiredRoles.length !== 0 && intersectedRoles.length === 0) {
          this.router.navigate(this.afterLoginRoute);
          return false;
        }

        return true;
      })
    );
  }

  private getHelpGuardConfigWarnMessage(path: string): string {
    return `If you want prevent access to route "${path}" then you should provide a "data.roles" parameter for that route`;
  }
}
