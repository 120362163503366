import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { ByIdPayload, ByKey, IdType, NormalizedList } from '@industi/ngx-common';
import { TimelineService } from './timeline.service';
import {
  TimelineLoadListAction,
  TimelineLoadListActionTypes,
  TimelineLoadListClearIdsAction,
  TimelineLoadListFailAction,
  TimelineLoadListSuccessAction
} from './actions/list.actions';
import { catchError, first, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { createTimelineListParams } from './selectors/get-load-list.selectors';
import { TimelineListParams } from './models/timeline-list-params';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import { getInstanceLocale } from '../instance/instance.reducer';

@Injectable()
export class TimelineEffects {

  @Effect()
  loadList$ = this.actions$.pipe(
    ofType(TimelineLoadListActionTypes.request),
    map((action: TimelineLoadListAction) => action.payload),
    switchMap((payload: ByIdPayload) => this.store.pipe(
      select(createTimelineListParams(payload.id)),
      map((params: TimelineListParams) => [payload.id, params] as [IdType, TimelineListParams]),
      first()
    )),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([[id, params], locale]) => this.service.loadList(locale, params).pipe(
      mergeMap((res: NormalizedList) => [
        new TimelineLoadListClearIdsAction({ id }),
        new TimelineLoadListSuccessAction({ id, res })
      ]),
      catchError((errors: HttpErrorResponse) => of(new TimelineLoadListFailAction({ id, errors })))
    ))
  );

  constructor(
    private actions$: Actions,
    private store: Store<ByKey>,
    private service: TimelineService
  ) {
  }
}
