import { Inject, Injectable } from '@angular/core';
import { API_URL } from '../../app-store-providers';
import { HttpClient } from '@angular/common/http';
import { EntityTypes } from '../../entity/entity-types';
import { CarbonFormCommission } from './models/form-commission';
import { CarbonFormCommissionListParams } from './models/form-commission-list-params';
import { CarbonFormCommissionPayload } from './models/form-commission-payload';
import { StoreBaseService } from '../../common/base.service';

@Injectable({
  providedIn: 'root'
})
export class CarbonFormCommissionService
  extends StoreBaseService<CarbonFormCommission, CarbonFormCommissionPayload, CarbonFormCommissionListParams> {

  get entityType(): string {
    return EntityTypes.carbonFormCommission;
  }

  get resourceName(): string {
    return 'carbon/form-commission';
  }

  constructor(
    @Inject(API_URL) apiUrl: string,
    http: HttpClient
  ) {
    super(apiUrl, http);
  }
}
