import { OFFER_IMAGE_REDUCER_TOKEN, OfferImageReducerProvider, rootReducerKey } from './image.reducer';
import { EffectsModule } from '@ngrx/effects';
import { CommonModule } from '@angular/common';
import { OfferImageEffects } from './image.effects';
import { StoreModule } from '@ngrx/store';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(rootReducerKey, OFFER_IMAGE_REDUCER_TOKEN),
    EffectsModule.forFeature([OfferImageEffects])
  ],
  providers: [
    OfferImageReducerProvider
  ]
})
export class OfferImageStoreModule {
}
