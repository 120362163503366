import {
  ByIdPayload,
  ChangeParamsByIdPayload,
  FailByIdPayload,
  ListReducerTypes,
  NormalizedList,
  PayloadAction,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { SymfonyPaginationParams } from '../../../symfony/list/models/symfony-pagination-params';

export const DictionaryMaterialExploitationLoadListActionTypes: ListReducerTypes = {
  request: uniqueActionType('[dictionary/material-exploitation] Load list'),
  requestFail: uniqueActionType('[dictionary/material-exploitation] Load list fail'),
  requestSuccess: uniqueActionType('[dictionary/material-exploitation] Load list success'),
  requestClear: uniqueActionType('[dictionary/material-exploitation] Load list clear'),
  changeParams: uniqueActionType('[dictionary/material-exploitation] Load list change params'),
  clearParams: uniqueActionType('[dictionary/material-exploitation] Load list clear params'),
  clearIds: uniqueActionType('[dictionary/material-exploitation] Load list clear ids')
};

export class DictionaryMaterialExploitationLoadListAction implements PayloadAction {
  readonly type = DictionaryMaterialExploitationLoadListActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class DictionaryMaterialExploitationLoadListFailAction implements PayloadAction {
  readonly type = DictionaryMaterialExploitationLoadListActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class DictionaryMaterialExploitationLoadListSuccessAction implements PayloadAction {
  readonly type = DictionaryMaterialExploitationLoadListActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedList>) {
  }
}

export class DictionaryMaterialExploitationLoadListClearAction implements PayloadAction {
  readonly type = DictionaryMaterialExploitationLoadListActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}

export class DictionaryMaterialExploitationLoadListChangeParamsAction implements PayloadAction {
  readonly type = DictionaryMaterialExploitationLoadListActionTypes.changeParams;

  constructor(public payload: ChangeParamsByIdPayload<SymfonyPaginationParams>) {
  }
}

export class DictionaryMaterialExploitationLoadListClearParamsAction implements PayloadAction {
  readonly type = DictionaryMaterialExploitationLoadListActionTypes.clearParams;

  constructor(public payload: ByIdPayload) {
  }
}


export class DictionaryMaterialExploitationLoadListClearIdsAction implements PayloadAction {
  readonly type = DictionaryMaterialExploitationLoadListActionTypes.clearIds;

  constructor(public payload: ByIdPayload) {
  }
}
