import { Inject, Injectable } from '@angular/core';
import { API_URL } from '../../app-store-providers';
import { HttpClient } from '@angular/common/http';
import { EntityTypes } from '../../entity/entity-types';
import { Dictionary } from '../../../shared/models/dictionary';
import { DictionaryBrandListParams } from './models/brand-list-params';
import { StoreBaseService } from '../../common/base.service';
import { ItemImagePayload } from '../../item/image/models/item-image-payload';
import { Observable } from 'rxjs';
import { IdType, NormalizedSingle } from '@industi/ngx-common';
import { EncodeUtil } from '../../../shared/utils/encode.util';
import { map } from 'rxjs/operators';
import { ItemImage } from '../../item/image/models/item-image';
import { normalize } from 'normalizr';
import { Entities } from '../../entity/entities';
import { DictionaryBrandImagePayload } from './models/brand-image-payload';

@Injectable({
  providedIn: 'root'
})
export class DictionaryBrandService
  extends StoreBaseService<Dictionary, Dictionary, DictionaryBrandListParams> {

  get entityType(): string {
    return EntityTypes.brand;
  }

  get resourceName(): string {
    return 'brand';
  }

  constructor(
    @Inject(API_URL) apiUrl: string,
    http: HttpClient
  ) {
    super(apiUrl, http);
  }

  addLogo(locale: string, data: DictionaryBrandImagePayload): Observable<NormalizedSingle> {
    const url = `${this.apiUrl}/${locale}/api/brand/logo`;
    return this.http.post<ItemImagePayload>(url, EncodeUtil.prepareMultipartFormData(data)).pipe(
      map((res: ItemImage) => normalize(res, Entities[EntityTypes.image]))
    );
  }

  addCategoryImage(locale: string, data: DictionaryBrandImagePayload): Observable<NormalizedSingle> {
    const url = `${this.apiUrl}/${locale}/api/brand/category-image`;
    return this.http.post<ItemImagePayload>(url, EncodeUtil.prepareMultipartFormData(data)).pipe(
      map((res: ItemImage) => normalize(res, Entities[EntityTypes.image]))
    );
  }

  deleteImage(locale: string, id: IdType): Observable<void> {
    const url = `${this.apiUrl}/${locale}/api/image/brand/${id}`;
    return this.http.delete<void>(url);
  }
}
