import {
  ByIdPayload,
  ChangeParamsByIdPayload,
  FailByIdPayload,
  ListReducerTypes,
  NormalizedList,
  PayloadAction,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { ServiceProjectListParams } from '../models/project-list-params';

export const ServiceProjectLoadListActionTypes: ListReducerTypes = {
  request: uniqueActionType('[service/project] Load list'),
  requestFail: uniqueActionType('[service/project] Load list fail'),
  requestSuccess: uniqueActionType('[service/project] Load list success'),
  requestClear: uniqueActionType('[service/project] Load list clear'),
  changeParams: uniqueActionType('[service/project] Load list change params'),
  clearParams: uniqueActionType('[service/project] Load list clear params'),
  clearIds: uniqueActionType('[service/project] Load list clear ids')
};

export class ServiceProjectLoadListAction implements PayloadAction {
  readonly type = ServiceProjectLoadListActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class ServiceProjectLoadListFailAction implements PayloadAction {
  readonly type = ServiceProjectLoadListActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class ServiceProjectLoadListSuccessAction implements PayloadAction {
  readonly type = ServiceProjectLoadListActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedList>) {
  }
}

export class ServiceProjectLoadListClearAction implements PayloadAction {
  readonly type = ServiceProjectLoadListActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}

export class ServiceProjectLoadListChangeParamsAction implements PayloadAction {
  readonly type = ServiceProjectLoadListActionTypes.changeParams;

  constructor(public payload: ChangeParamsByIdPayload<ServiceProjectListParams>) {
  }
}

export class ServiceProjectLoadListClearParamsAction implements PayloadAction {
  readonly type = ServiceProjectLoadListActionTypes.clearParams;

  constructor(public payload: ByIdPayload) {
  }
}


export class ServiceProjectLoadListClearIdsAction implements PayloadAction {
  readonly type = ServiceProjectLoadListActionTypes.clearIds;

  constructor(public payload: ByIdPayload) {
  }
}
