import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SendByIdPayload,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { Dictionary } from '../../../../shared/models/dictionary';

export const DictionaryCourierAddActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[dictionary/courier] Add'),
  requestFail: uniqueActionType('[dictionary/courier] Add fail'),
  requestSuccess: uniqueActionType('[dictionary/courier] Add success'),
  requestClearErrors: uniqueActionType('[dictionary/courier] Add clear errors'),
  requestClear: uniqueActionType('[dictionary/courier] Add clear'),
};

export class DictionaryCourierAddAction implements PayloadAction {
  readonly type = DictionaryCourierAddActionTypes.request;

  constructor(public payload: SendByIdPayload<Dictionary>) {
  }
}

export class DictionaryCourierAddFailAction implements PayloadAction {
  readonly type = DictionaryCourierAddActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class DictionaryCourierAddSuccessAction implements PayloadAction {
  readonly type = DictionaryCourierAddActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class DictionaryCourierAddClearErrorsAction implements PayloadAction {
  readonly type = DictionaryCourierAddActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class DictionaryCourierAddClearAction implements PayloadAction {
  readonly type = DictionaryCourierAddActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
