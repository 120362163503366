import { ActionReducer, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import { setValueReducer } from '@industi/ngx-common';
import { InstanceSetLocaleActionTypes } from './actions/set-locale.actions';
import { InjectionToken } from '@angular/core';

export interface InstanceState {
  locale: string;
}

const initialState: InstanceState = {
  locale: ''
};

const reducer = combineReducers<InstanceState>({
  locale: setValueReducer({ types: InstanceSetLocaleActionTypes })
}, initialState);

export const INSTANCE_REDUCER_TOKEN = new InjectionToken<ActionReducer<InstanceState>>('instance');

export const instanceReducerProvider = { provide: INSTANCE_REDUCER_TOKEN, useFactory: getReducers };

export function getReducers(): ActionReducer<InstanceState> {
  return reducer;
}

const getState = createFeatureSelector<InstanceState>('instance');

export const getInstanceLocale = createSelector(
  getState,
  (state: InstanceState) => state.locale
);
