import { IdType, SetStateAdapter } from '@industi/ngx-common';
import { Injectable } from '@angular/core';
import { Action, Selector } from '@ngrx/store';
import {
  ArticleBrandImageClearLastAddedIdAction,
  ArticleBrandImageSelectLastAddedIdAction
} from '../actions/select-last-added-image-id.actions';
import { getArticleBrandImageLastAddedId } from '../brand.reducer';

@Injectable({ providedIn: 'root' })
export class ArticleBrandImageSetLastAddedAdapterService extends SetStateAdapter<IdType> {

  clearAction(): Action {
    return new ArticleBrandImageClearLastAddedIdAction();
  }

  getValueSelector(): Selector<any, IdType> {
    return getArticleBrandImageLastAddedId;
  }

  setValueAction(value: IdType): Action {
    return new ArticleBrandImageSelectLastAddedIdAction({ value });
  }

}
