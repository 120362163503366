import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';

export const OfferItemLoadDetailsActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[offer/item] Load details'),
  requestFail: uniqueActionType('[offer/item] Load details fail'),
  requestSuccess: uniqueActionType('[offer/item] Load details success'),
  requestClearErrors: uniqueActionType('[offer/item] Load details clear errors'),
  requestClear: uniqueActionType('[offer/item] Load details clear'),
};

export class OfferItemLoadDetailsAction implements PayloadAction {
  readonly type = OfferItemLoadDetailsActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class OfferItemLoadDetailsFailAction implements PayloadAction {
  readonly type = OfferItemLoadDetailsActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class OfferItemLoadDetailsSuccessAction implements PayloadAction {
  readonly type = OfferItemLoadDetailsActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class OfferItemLoadDetailsClearAction implements PayloadAction {
  readonly type = OfferItemLoadDetailsActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}

export class OfferItemLoadDetailsClearErrorsAction implements PayloadAction {
  readonly type = OfferItemLoadDetailsActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}
