import {
  ByIdPayload,
  FailByIdPayload,
  OperationReducerTypes,
  PayloadAction,
  uniqueActionType
} from '@industi/ngx-common';

export const LayerDeleteActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[layer] Delete'),
  requestFail: uniqueActionType('[layer] Delete fail'),
  requestSuccess: uniqueActionType('[layer] Delete success'),
  requestClearErrors: uniqueActionType('[layer] Delete clear errors'),
  requestClear: uniqueActionType('[layer] Delete clear')
};

export class LayerDeleteAction implements PayloadAction {
  readonly type = LayerDeleteActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class LayerDeleteFailAction implements PayloadAction {
  readonly type = LayerDeleteActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class LayerDeleteSuccessAction implements PayloadAction {
  readonly type = LayerDeleteActionTypes.requestSuccess;

  constructor(public payload: ByIdPayload) {
  }
}

export class LayerDeleteClearErrorsAction implements PayloadAction {
  readonly type = LayerDeleteActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class LayerDeleteClearAction implements PayloadAction {
  readonly type = LayerDeleteActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
