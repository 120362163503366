import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { ByIdPayload, ByKey, IdType, NormalizedList, NormalizedSingle } from '@industi/ngx-common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IcsConversationService } from './conversation.service';
import { catchError, first, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { getInstanceLocale } from '../../instance/instance.reducer';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import {
  IcsConversationLoadListAction,
  IcsConversationLoadListActionTypes,
  IcsConversationLoadListClearIdsAction,
  IcsConversationLoadListFailAction,
  IcsConversationLoadListSuccessAction
} from './actions/list.actions';
import { createIcsConversationListParams } from './selectors/get-load-list.selectors';
import { IcsConversationListParams } from './models/conversation-list-params';
import {
  IcsConversationMessageAddAction,
  IcsConversationMessageAddActionTypes,
  IcsConversationMessageAddFailAction,
  IcsConversationMessageAddSuccessAction
} from './actions/add-message.actions';
import { ResponseStatus } from '../../../shared/enums/response-status.enum';
import { LibSnackbarComponent } from '@industi/ngx-modules';
import { AppGlobal } from '../../../shared/app-global';
import {
  IcsConversationAttachmentAddAction,
  IcsConversationAttachmentAddActionTypes,
  IcsConversationAttachmentAddFailAction, IcsConversationAttachmentAddSuccessAction
} from './actions/add-attachment.actions';
import {
  IcsConversationTaskAddAction,
  IcsConversationTaskAddActionTypes,
  IcsConversationTaskAddFailAction, IcsConversationTaskAddSuccessAction
} from './actions/add-task.actions';
import { AlertUtil } from '../../../shared/utils/alert.util';

@Injectable()
export class IcsConversationEffects {

  @Effect()
  loadList$ = this.actions$.pipe(
    ofType(IcsConversationLoadListActionTypes.request),
    map((action: IcsConversationLoadListAction) => action.payload),
    switchMap((payload: ByIdPayload) => this.store.pipe(
      select(createIcsConversationListParams(payload.id)),
      map((params: IcsConversationListParams) => [payload.id, params] as [IdType, IcsConversationListParams]),
      first()
    )),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([[ id, params ], locale]) => this.service.loadList(locale, params).pipe(
      mergeMap((res: NormalizedList) => [
        new IcsConversationLoadListClearIdsAction({ id }),
        new IcsConversationLoadListSuccessAction({ id, res })]),
      catchError((errors: HttpErrorResponse) => of(new IcsConversationLoadListFailAction({ id, errors })))
    ))
  );

  @Effect()
  addMessage$ = this.actions$.pipe(
    ofType(IcsConversationMessageAddActionTypes.request),
    map((action: IcsConversationMessageAddAction) => action.payload),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([{ id, data }, locale]) => this.service.addMessage(locale, data).pipe(
      map((res: NormalizedSingle) => new IcsConversationMessageAddSuccessAction({ res, id })),
      catchError((errors: HttpErrorResponse) => of(new IcsConversationMessageAddFailAction({ errors, id })))
    ))
  );

  @Effect()
  addAttachment$ = this.actions$.pipe(
    ofType(IcsConversationAttachmentAddActionTypes.request),
    map((action: IcsConversationAttachmentAddAction) => action.payload),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([{ id, data }, locale]) => this.service.addAttachment(locale, data).pipe(
      map((res: NormalizedSingle) => new IcsConversationAttachmentAddSuccessAction({ res, id })),
      catchError((errors: HttpErrorResponse) => of(new IcsConversationAttachmentAddFailAction({ errors, id })))
    ))
  );

  @Effect()
  addTask$ = this.actions$.pipe(
    ofType(IcsConversationTaskAddActionTypes.request),
    map((action: IcsConversationTaskAddAction) => action.payload),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([{ id, data }, locale]) => this.service.addTask(locale, data).pipe(
      map((res: NormalizedSingle) => new IcsConversationTaskAddSuccessAction({ res, id })),
      catchError((errors: HttpErrorResponse) => of(new IcsConversationTaskAddFailAction({ errors, id })))
    ))
  );

  @Effect({ dispatch: false })
  crudFail$ = this.actions$.pipe(
    ofType(
      IcsConversationMessageAddActionTypes.requestFail,
      IcsConversationAttachmentAddActionTypes.requestFail,
      IcsConversationTaskAddActionTypes.requestFail,
    ),
    map((
      action: IcsConversationMessageAddFailAction | IcsConversationAttachmentAddFailAction | IcsConversationTaskAddFailAction
    ) => action.payload.errors),
    tap((data: HttpErrorResponse) => {
      if (data?.status === ResponseStatus.BAD_REQUEST || data?.status === ResponseStatus.UNPROCESSABLE_ENTITY) {
        this.snackBar.openFromComponent(LibSnackbarComponent, {
          data: {
            message: AlertUtil.getControlTranslation(data.error.detail),
            type: 'error'
          },
          duration: AppGlobal.defaultSnackBarDuration
        });
      }
    })
  );

  constructor(
    private actions$: Actions,
    private store: Store<ByKey>,
    private service: IcsConversationService,
    private snackBar: MatSnackBar
  ) {
  }
}
