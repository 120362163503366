import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SendByIdPayload,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { DictionaryOperation } from '../models/operation';

export const DictionaryOperationEditActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[dictionary/operation] Edit'),
  requestFail: uniqueActionType('[dictionary/operation] Edit fail'),
  requestSuccess: uniqueActionType('[dictionary/operation] Edit success'),
  requestClearErrors: uniqueActionType('[dictionary/operation] Edit clear errors'),
  requestClear: uniqueActionType('[dictionary/operation] Edit clear')
};

export class DictionaryOperationEditAction implements PayloadAction {
  readonly type = DictionaryOperationEditActionTypes.request;

  constructor(public payload: SendByIdPayload<DictionaryOperation>) {
  }
}

export class DictionaryOperationEditFailAction implements PayloadAction {
  readonly type = DictionaryOperationEditActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class DictionaryOperationEditSuccessAction implements PayloadAction {
  readonly type = DictionaryOperationEditActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class DictionaryOperationEditClearErrorsAction implements PayloadAction {
  readonly type = DictionaryOperationEditActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class DictionaryOperationEditClearAction implements PayloadAction {
  readonly type = DictionaryOperationEditActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
