import { Inject, Injectable } from '@angular/core';
import { API_URL } from '../../../app-store-providers';
import { HttpClient } from '@angular/common/http';
import { EntityTypes } from '../../../entity/entity-types';
import { ServiceProjectPart } from './models/part';
import { ServiceProjectPartPayload } from './models/part-payload';
import { ServiceProjectPartListParams } from './models/part-list-params';
import { StoreBaseService } from '../../../common/base.service';

@Injectable({
  providedIn: 'root'
})
export class ServiceProjectPartService
  extends StoreBaseService<ServiceProjectPart, ServiceProjectPartPayload, ServiceProjectPartListParams> {

  get entityType(): string {
    return EntityTypes.projectPart;
  }

  get resourceName(): string {
    return 'service/project-part';
  }

  constructor(
    @Inject(API_URL) apiUrl: string,
    http: HttpClient
  ) {
    super(apiUrl, http);
  }
}
