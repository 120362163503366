<div class="container app-sm py-5 text-center border-box">
  <div class="d-flex align-items-center mb-4">
    <button mat-icon-button type="button"
            *ngIf="userId$ | async"
            (click)="back()"
            class="mr-3">
      <lib-icon icon="chevron_left"></lib-icon>
    </button>

    <h1 class="mat-display-3 m-0">Pobieranie aplikacji</h1>
  </div>

  <mat-card>
    <mat-card-title></mat-card-title>
    <mat-card-content class="pt-3 text-center">
      <p>Pobierz aktualną wersję aplikacji</p>
      <a mat-raised-button
         class="app-lg"
         color="primary"
         [href]="(details$ | async)?.fileName | sanitizeUrl"
         [disabled]="loading$ | async"
         download>Pobierz</a>
    </mat-card-content>
  </mat-card>
</div>
