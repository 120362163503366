import { Inject, Injectable } from '@angular/core';
import { API_URL } from '../../app-store-providers';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { NormalizedSingle } from '@industi/ngx-common';
import { map } from 'rxjs/operators';
import { normalize } from 'normalizr';
import { Entities } from '../../entity/entities';
import { EntityTypes } from '../../entity/entity-types';
import { DiagramListParams } from './models/diagram-list-params';
import { Diagram } from './models/diagram';
import { DiagramPayload } from './models/diagram-payload';
import { EncodeUtil } from '../../../shared/utils/encode.util';
import { FilesPayload } from '../../common/models/files-payload';
import { StoreBaseService } from '../../common/base.service';

@Injectable({
  providedIn: 'root'
})
export class DiagramCommonService
  extends StoreBaseService<Diagram, DiagramPayload, DiagramListParams> {

  get entityType(): string {
    return EntityTypes.diagram;
  }

  get resourceName(): string {
    return 'diagram';
  }

  constructor(
    @Inject(API_URL) apiUrl: string,
    http: HttpClient
  ) {
    super(apiUrl, http);
  }

  add(locale: string, data: FilesPayload<DiagramPayload> | DiagramPayload): Observable<NormalizedSingle> {
    const url = this.getDictionaryAddPath(locale, this.resourceName);
    return this.http.post<Diagram>(url, EncodeUtil.prepareMultipartFormData(data)).pipe(
      map((res: Diagram) => normalize(res, Entities[this.entityType]))
    );
  }
}
