import { ByKey, listByIdReducer, ListState, operationByIdReducer, SingleState } from '@industi/ngx-common';
import { ActionReducer, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import { InjectionToken, Provider } from '@angular/core';
import { DictionaryTagAddActionTypes } from './actions/add.actions';
import { DictionaryTagEditActionTypes } from './actions/edit.actions';
import { DictionaryTagLoadListActionTypes } from './actions/list.actions';
import { DictionaryTagLoadDetailsActionTypes } from './actions/load-details.actions';
import { DictionaryTagDeleteActionTypes } from './actions/delete.actions';

export const rootReducerKey = 'tag';

export interface DictionaryTagOperationByIdState {
  add: SingleState;
  edit: SingleState;
  details: SingleState;
  delete: SingleState;
}

export interface DictionaryTagState {
  operationsById: ByKey<DictionaryTagOperationByIdState>;
  list: ByKey<ListState>;
}

const initialState: DictionaryTagState = {
  operationsById: {},
  list: {}
};

const reducer: ActionReducer<DictionaryTagState> = combineReducers<DictionaryTagState>({
  operationsById: operationByIdReducer<DictionaryTagOperationByIdState>({
    mapActionToId: (a) => a && a.payload && a.payload.id,
    operations: {
      add: DictionaryTagAddActionTypes,
      edit: DictionaryTagEditActionTypes,
      details: DictionaryTagLoadDetailsActionTypes,
      delete: DictionaryTagDeleteActionTypes
    }
  }),
  list: listByIdReducer({ types: DictionaryTagLoadListActionTypes })
}, initialState);

export function getReducers(): ActionReducer<DictionaryTagState> {
  return reducer;
}

export const DICTIONARY_TAG_REDUCER_TOKEN = new InjectionToken<ActionReducer<DictionaryTagState>>(rootReducerKey);

export const dictionaryTagReducerProvider: Provider = { provide: DICTIONARY_TAG_REDUCER_TOKEN, useFactory: getReducers };

const getState = createFeatureSelector<DictionaryTagState>(rootReducerKey);

export const getDictionaryTagOperationsById = createSelector(
  getState,
  (state: DictionaryTagState) => state && state.operationsById
);

export const getDictionaryTagListState = createSelector(
  getState,
  (state: DictionaryTagState) => state && state.list
);
