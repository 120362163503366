import { createSelector } from '@ngrx/store';
import { denormalize } from 'normalizr';
import {
  ByKey,
  createListStateIdsSelector,
  createListStateParamsSelector,
  createStateErrorsSelector,
  createStateLoadingSelector,
  createStateSuccessSelector,
  IdType,
  ListState
} from '@industi/ngx-common';
import { EntityTypes } from '../../../entity/entity-types';
import { createEntitiesSelector } from '../../../entity/entities.reducer';
import { createSymfonyTotalCountSelector } from '../../../symfony/list/selectors/list.selectors';
import { Entities } from '../../../entity/entities';
import { EntityState } from '../../../entity/entity.reducer';
import { getDictionaryProcessListState } from '../process.reducer';
import { DictionaryProcess } from '../models/process';
import { createServiceProjectOperationListData } from '../../../service/project/operation/selectors/get-list.selectors';
import { ServiceProjectOperation } from '../../../service/project/operation/models/operation';
import * as _ from 'lodash';
import { DictionaryOperation } from '../../operation/models/operation';
import { createCarbonFormCommissionTasksList } from '../../../carbon/form-commission/selectors/get-details.selectors';
import { CarbonCommissionTask } from '../../../carbon/commission-task/models/task';
import { createCarbonPartCommissionTasksList } from '../../../carbon/part-commission/selectors/get-details.selectors';

const createState = (id: IdType) => createSelector(
  getDictionaryProcessListState,
  (state: ByKey<ListState>) => state && state[id]
);

const createStateByContext = (id: IdType) => createListStateIdsSelector(createState(id));

export const createDictionaryProcessListData = (id: IdType) => createSelector(
  createStateByContext(id),
  createEntitiesSelector([
    EntityTypes.process
  ]),
  (
    ids: IdType[],
    entities: EntityState
  ) => denormalize(ids, [Entities[EntityTypes.process]], entities) as DictionaryProcess[]
);

export const createDictionaryProcessListLoading = (id: IdType) => createStateLoadingSelector(
  createState(id));

export const createDictionaryProcessListSuccess = (id: IdType) => createStateSuccessSelector(
  createState(id));

export const createDictionaryProcessListErrors = (id: IdType) => createStateErrorsSelector(
  createState(id));

export const createDictionaryProcessListTotalCount = (id: IdType) => createSymfonyTotalCountSelector(
  createState(id));

export const createDictionaryProcessListParams = (id: IdType) => createListStateParamsSelector(
  createState(id));

export const createProcessListWithProjectOperationData = (id: IdType) => createSelector(
  createDictionaryProcessListData(id),
  createServiceProjectOperationListData(id),
  (
    processList: DictionaryProcess[],
    operationList: ServiceProjectOperation[]
  ) => processList.map((process: DictionaryProcess) => ({
    ...process,
    operations: !_.isEmpty(process?.operations) && process?.operations.map((operation: DictionaryOperation) => ({
      ...operation,
      _operationId: operationList.find((i: ServiceProjectOperation) => i?.operation?.id === operation?.id)?.id
    }))
  })) as DictionaryProcess[]
);

export const createServiceProjectOperationListGroupByProcessId = (id: IdType) => createSelector(
  createDictionaryProcessListData(id),
  createServiceProjectOperationListData(id),
  (
    processList: DictionaryProcess[],
    operationList: ServiceProjectOperation[]
  ) => {
    const obj = _.groupBy(operationList, 'processId');
    return Object.keys(obj).map(key => ({
      id: key,
      operations: obj[key]
    }));
  }
);

/**
 * Form commission tasks
 */
export const createProcessListWithFormCommissionTaskData = (id: IdType) => createSelector(
  createDictionaryProcessListData(id),
  createCarbonFormCommissionTasksList(id),
  (
    processList: DictionaryProcess[],
    operationList: CarbonCommissionTask[]
  ) => processList.map((process: DictionaryProcess) => ({
      ...process,
      operations: !_.isEmpty(process?.operations) && process?.operations.map((operation: DictionaryOperation) => {
        const _relation = operationList?.find((i: CarbonCommissionTask) => i?.operation?.id === operation?.id) || null;
        return ({
          ...operation,
          _operationId: _relation?.id || null,
          _relation
        });
      })
    })) as DictionaryProcess[]
);


/**
 * Part commission tasks
 */
export const createProcessListWithPartCommissionTaskData = (id: IdType) => createSelector(
  createDictionaryProcessListData(id),
  createCarbonPartCommissionTasksList(id),
  (
    processList: DictionaryProcess[],
    operationList: CarbonCommissionTask[]
  ) => processList.map((process: DictionaryProcess) => ({
      ...process,
      operations: !_.isEmpty(process?.operations) && process?.operations.map((operation: DictionaryOperation) => {
        const _relation = operationList?.find((i: CarbonCommissionTask) => i?.operation?.id === operation?.id) || null;
        return ({
          ...operation,
          _operationId: _relation?.id || null,
          _relation
        });
      })
    })) as DictionaryProcess[]
);
