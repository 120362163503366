import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SendByIdPayload,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { OfferItemPayload } from '../models/item-payload';

export const OfferItemAddActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[offer/item] Add'),
  requestFail: uniqueActionType('[offer/item] Add fail'),
  requestSuccess: uniqueActionType('[offer/item] Add success'),
  requestClearErrors: uniqueActionType('[offer/item] Add clear errors'),
  requestClear: uniqueActionType('[offer/item] Add clear'),
};

export class OfferItemAddAction implements PayloadAction {
  readonly type = OfferItemAddActionTypes.request;

  constructor(public payload: SendByIdPayload<OfferItemPayload>) {
  }
}

export class OfferItemAddFailAction implements PayloadAction {
  readonly type = OfferItemAddActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class OfferItemAddSuccessAction implements PayloadAction {
  readonly type = OfferItemAddActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class OfferItemAddClearErrorsAction implements PayloadAction {
  readonly type = OfferItemAddActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class OfferItemAddClearAction implements PayloadAction {
  readonly type = OfferItemAddActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
