import {
  ByIdPayload,
  FailByIdPayload,
  OperationReducerTypes,
  PayloadAction,
  uniqueActionType
} from '@industi/ngx-common';

export const DictionaryCourierDeleteActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[dictionary/courier] Delete'),
  requestFail: uniqueActionType('[dictionary/courier] Delete fail'),
  requestSuccess: uniqueActionType('[dictionary/courier] Delete success'),
  requestClearErrors: uniqueActionType('[dictionary/courier] Delete clear errors'),
  requestClear: uniqueActionType('[dictionary/courier] Delete clear')
};

export class DictionaryCourierDeleteAction implements PayloadAction {
  readonly type = DictionaryCourierDeleteActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class DictionaryCourierDeleteFailAction implements PayloadAction {
  readonly type = DictionaryCourierDeleteActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class DictionaryCourierDeleteSuccessAction implements PayloadAction {
  readonly type = DictionaryCourierDeleteActionTypes.requestSuccess;

  constructor(public payload: ByIdPayload) {
  }
}

export class DictionaryCourierDeleteClearErrorsAction implements PayloadAction {
  readonly type = DictionaryCourierDeleteActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class DictionaryCourierDeleteClearAction implements PayloadAction {
  readonly type = DictionaryCourierDeleteActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
