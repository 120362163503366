import { IdType, SetValuePayload, SetValueReducerTypes, uniqueActionType } from '@industi/ngx-common';

export const ArticleBrandSelectLastAddedIdActionTypes: SetValueReducerTypes = {
  setValue: uniqueActionType('[article-brand] Select last added id'),
  clearValue: uniqueActionType('[article-brand] Clear last added id'),
};

export class ArticleBrandSelectLastAddedIdAction {
  readonly type = ArticleBrandSelectLastAddedIdActionTypes.setValue;

  constructor(public payload: SetValuePayload<IdType>) {
  }
}

export class ArticleBrandClearLastAddedIdAction {
  readonly type = ArticleBrandSelectLastAddedIdActionTypes.setValue;
  readonly payload = null;
}
