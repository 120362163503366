import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SendByIdPayload,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { ServiceProjectItemPayload } from '../models/item-payload';

export const ServiceProjectItemAddActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[service/project-item] Add'),
  requestFail: uniqueActionType('[service/project-item] Add fail'),
  requestSuccess: uniqueActionType('[service/project-item] Add success'),
  requestClearErrors: uniqueActionType('[service/project-item] Add clear errors'),
  requestClear: uniqueActionType('[service/project-item] Add clear'),
};

export class ServiceProjectItemAddAction implements PayloadAction {
  readonly type = ServiceProjectItemAddActionTypes.request;

  constructor(public payload: SendByIdPayload<ServiceProjectItemPayload>) {
  }
}

export class ServiceProjectItemAddFailAction implements PayloadAction {
  readonly type = ServiceProjectItemAddActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class ServiceProjectItemAddSuccessAction implements PayloadAction {
  readonly type = ServiceProjectItemAddActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class ServiceProjectItemAddClearErrorsAction implements PayloadAction {
  readonly type = ServiceProjectItemAddActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class ServiceProjectItemAddClearAction implements PayloadAction {
  readonly type = ServiceProjectItemAddActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
