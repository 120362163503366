import {
  ByIdPayload,
  FailByIdPayload,
  OperationReducerTypes,
  PayloadAction,
  uniqueActionType
} from '@industi/ngx-common';

export const DictionaryBrandDeleteActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[dictionary/brand] Delete'),
  requestFail: uniqueActionType('[dictionary/brand] Delete fail'),
  requestSuccess: uniqueActionType('[dictionary/brand] Delete success'),
  requestClearErrors: uniqueActionType('[dictionary/brand] Delete clear errors'),
  requestClear: uniqueActionType('[dictionary/brand] Delete clear')
};

export class DictionaryBrandDeleteAction implements PayloadAction {
  readonly type = DictionaryBrandDeleteActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class DictionaryBrandDeleteFailAction implements PayloadAction {
  readonly type = DictionaryBrandDeleteActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class DictionaryBrandDeleteSuccessAction implements PayloadAction {
  readonly type = DictionaryBrandDeleteActionTypes.requestSuccess;

  constructor(public payload: ByIdPayload) {
  }
}

export class DictionaryBrandDeleteClearErrorsAction implements PayloadAction {
  readonly type = DictionaryBrandDeleteActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class DictionaryBrandDeleteClearAction implements PayloadAction {
  readonly type = DictionaryBrandDeleteActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
