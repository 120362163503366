import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { ByKey, NormalizedSingle } from '@industi/ngx-common';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { getInstanceLocale } from '../../instance/instance.reducer';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  ArticleGalleryContentEditAction,
  ArticleGalleryContentEditActionTypes,
  ArticleGalleryContentEditFailAction,
  ArticleGalleryContentEditSuccessAction
} from './actions/edit.actions';
import {
  ArticleGalleryContentLoadDetailsAction,
  ArticleGalleryContentLoadDetailsActionTypes,
  ArticleGalleryContentLoadDetailsFailAction,
  ArticleGalleryContentLoadDetailsSuccessAction
} from './actions/details.actions';
import {
  ArticleGalleryContentAddAction,
  ArticleGalleryContentAddActionTypes,
  ArticleGalleryContentAddFailAction,
  ArticleGalleryContentAddSuccessAction
} from './actions/add.actions';
import { LibSnackbarComponent } from '@industi/ngx-modules';
import { ResponseStatus } from '../../../shared/enums/response-status.enum';
import { AlertUtil } from '../../../shared/utils/alert.util';
import { AppGlobal } from '../../../shared/app-global';
import { ArticleGalleryContentService } from './gallery-content.service';

@Injectable()
export class ArticleGalleryContentEffects {

  @Effect()
  add$ = this.actions$.pipe(
    ofType(ArticleGalleryContentAddActionTypes.request),
    map((action: ArticleGalleryContentAddAction) => action.payload),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([{ id, data }, locale]) => this.service.add(locale, data).pipe(
      map((res: NormalizedSingle) => new ArticleGalleryContentAddSuccessAction({ res, id })),
      catchError((errors: HttpErrorResponse) => of(new ArticleGalleryContentAddFailAction({ errors, id })))
    ))
  );

  @Effect()
  edit$ = this.actions$.pipe(
    ofType(ArticleGalleryContentEditActionTypes.request),
    map((action: ArticleGalleryContentEditAction) => action.payload),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([{ id, data }, locale]) => this.service.edit(locale, data, id).pipe(
      map((res: NormalizedSingle) => new ArticleGalleryContentEditSuccessAction({ res, id })),
      catchError((errors: HttpErrorResponse) => of(new ArticleGalleryContentEditFailAction({ errors, id })))
    ))
  );

  @Effect()
  loadDetails$ = this.actions$.pipe(
    ofType(ArticleGalleryContentLoadDetailsActionTypes.request),
    map((action: ArticleGalleryContentLoadDetailsAction) => action.payload.id),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([id, locale]) => this.service.details(locale, id).pipe(
      map((res: NormalizedSingle) => new ArticleGalleryContentLoadDetailsSuccessAction({ id, res })),
      catchError((errors: HttpErrorResponse) => of(new ArticleGalleryContentLoadDetailsFailAction({ id, errors })))
    ))
  );

  @Effect({ dispatch: false })
  crudFail$ = this.actions$.pipe(
    ofType(
      ArticleGalleryContentAddActionTypes.requestFail,
      ArticleGalleryContentEditActionTypes.requestFail
    ),
    map((action: ArticleGalleryContentAddFailAction | ArticleGalleryContentEditFailAction) => action.payload.errors),
    tap((data: HttpErrorResponse) => {
      if (data?.status === ResponseStatus.BAD_REQUEST || data?.status === ResponseStatus.UNPROCESSABLE_ENTITY) {
        this.snackBar.openFromComponent(LibSnackbarComponent, {
          data: {
            message: AlertUtil.getControlTranslation(data?.error?.detail),
            type: 'error'
          },
          duration: AppGlobal.defaultSnackBarDuration
        });
      }
    })
  );

  constructor(
    private actions$: Actions,
    private store: Store<ByKey>,
    private service: ArticleGalleryContentService,
    private snackBar: MatSnackBar
  ) {
  }
}
