import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';

export const CarbonFormCommissionLoadDetailsActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[carbon/form-commission] Load details'),
  requestFail: uniqueActionType('[carbon/form-commission] Load details fail'),
  requestSuccess: uniqueActionType('[carbon/form-commission] Load details success'),
  requestClearErrors: uniqueActionType('[carbon/form-commission] Load details clear errors'),
  requestClear: uniqueActionType('[carbon/form-commission] Load details clear')
};

export class CarbonFormCommissionLoadDetailsAction implements PayloadAction {
  readonly type = CarbonFormCommissionLoadDetailsActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class CarbonFormCommissionLoadDetailsFailAction implements PayloadAction {
  readonly type = CarbonFormCommissionLoadDetailsActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class CarbonFormCommissionLoadDetailsSuccessAction implements PayloadAction {
  readonly type = CarbonFormCommissionLoadDetailsActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class CarbonFormCommissionLoadDetailsClearAction implements PayloadAction {
  readonly type = CarbonFormCommissionLoadDetailsActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}

export class CarbonFormCommissionLoadDetailsClearErrorsAction implements PayloadAction {
  readonly type = CarbonFormCommissionLoadDetailsActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}
