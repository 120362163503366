import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SendByIdPayload,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { DictionaryMaterialExploitation } from '../models/material-exploitation';

export const DictionaryMaterialExploitationAddActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[dictionary/material-exploitation] Add'),
  requestFail: uniqueActionType('[dictionary/material-exploitation] Add fail'),
  requestSuccess: uniqueActionType('[dictionary/material-exploitation] Add success'),
  requestClearErrors: uniqueActionType('[dictionary/material-exploitation] Add clear errors'),
  requestClear: uniqueActionType('[dictionary/material-exploitation] Add clear'),
};

export class DictionaryMaterialExploitationAddAction implements PayloadAction {
  readonly type = DictionaryMaterialExploitationAddActionTypes.request;

  constructor(public payload: SendByIdPayload<DictionaryMaterialExploitation>) {
  }
}

export class DictionaryMaterialExploitationAddFailAction implements PayloadAction {
  readonly type = DictionaryMaterialExploitationAddActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class DictionaryMaterialExploitationAddSuccessAction implements PayloadAction {
  readonly type = DictionaryMaterialExploitationAddActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class DictionaryMaterialExploitationAddClearErrorsAction implements PayloadAction {
  readonly type = DictionaryMaterialExploitationAddActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class DictionaryMaterialExploitationAddClearAction implements PayloadAction {
  readonly type = DictionaryMaterialExploitationAddActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
