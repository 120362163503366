import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ToolbarContentLayoutComponent } from './toolbar-content-layout/toolbar-content-layout.component';
import { AppMenuModule } from '../shared/modules/app-menu/app-menu.module';
import { LibBreadcrumbsModule, LibIconModule, LibMaterialSharedModule, LibViewSizeModule } from '@industi/ngx-modules';
import { SharedModule } from '../shared/shared.module';
import { NotFoundComponent } from './404/not-found.component';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    ToolbarContentLayoutComponent,
    NotFoundComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    LibMaterialSharedModule,
    LibIconModule,
    LibBreadcrumbsModule,
    AppMenuModule,
    SharedModule,
    LibViewSizeModule,
    ReactiveFormsModule
  ]
})
export class AppLayoutModule {
}
