import { Inject, Injectable } from '@angular/core';
import { API_URL } from '../app-store-providers';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EncodeUtil } from '../../shared/utils/encode.util';
import { ApplicationPayload } from './models/application-payload';
import { map } from 'rxjs/operators';
import { normalize } from 'normalizr';
import { Entities } from '../entity/entities';
import { EntityTypes } from '../entity/entity-types';
import { Application } from './models/application';
import { NormalizedSingle } from '@industi/ngx-common';
import { AppGlobal } from '../../shared/app-global';

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {

  constructor(
    @Inject(API_URL) private apiUrl: string,
    private http: HttpClient
  ) {
  }

  update(locale: string, data: ApplicationPayload): Observable<void> {
    const url = `${this.apiUrl}/${locale}/api/file/application`;
    return this.http.post<void>(url, EncodeUtil.prepareMultipartFormData(data));
  }

  current(locale: string): Observable<NormalizedSingle> {
    const url = `${this.apiUrl}/${locale}/api/file/application/current`;
    return this.http.get<Application>(url).pipe(
      // TODO: usunac nadpisywanie ID-ka, jak wejdzie lista z wersjami + szczegóły
      map((res: Application) => normalize({ ...res, id: AppGlobal.addId }, Entities[EntityTypes.application]))
    );
  }
}
