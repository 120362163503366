import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SendByIdPayload,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { ArticleGalleryContentPayload } from '../models/gallery-content-payload';

export const ArticleGalleryContentEditActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[article/gallery-content] Edit'),
  requestFail: uniqueActionType('[article/gallery-content] Edit fail'),
  requestSuccess: uniqueActionType('[article/gallery-content] Edit success'),
  requestClearErrors: uniqueActionType('[article/gallery-content] Edit clear errors'),
  requestClear: uniqueActionType('[article/gallery-content] Edit clear')
};

export class ArticleGalleryContentEditAction implements PayloadAction {
  readonly type = ArticleGalleryContentEditActionTypes.request;

  constructor(public payload: SendByIdPayload<ArticleGalleryContentPayload>) {
  }
}

export class ArticleGalleryContentEditFailAction implements PayloadAction {
  readonly type = ArticleGalleryContentEditActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class ArticleGalleryContentEditSuccessAction implements PayloadAction {
  readonly type = ArticleGalleryContentEditActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class ArticleGalleryContentEditClearErrorsAction implements PayloadAction {
  readonly type = ArticleGalleryContentEditActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class ArticleGalleryContentEditClearAction implements PayloadAction {
  readonly type = ArticleGalleryContentEditActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
