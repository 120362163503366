import {
  ByIdPayload,
  ChangeParamsByIdPayload,
  FailByIdPayload,
  ListReducerTypes,
  NormalizedList,
  PayloadAction,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { SymfonyPaginationParams } from '../../../symfony/list/models/symfony-pagination-params';

export const DictionaryCourierLoadListActionTypes: ListReducerTypes = {
  request: uniqueActionType('[dictionary/courier] Load list'),
  requestFail: uniqueActionType('[dictionary/courier] Load list fail'),
  requestSuccess: uniqueActionType('[dictionary/courier] Load list success'),
  requestClear: uniqueActionType('[dictionary/courier] Load list clear'),
  changeParams: uniqueActionType('[dictionary/courier] Load list change params'),
  clearParams: uniqueActionType('[dictionary/courier] Load list clear params'),
  clearIds: uniqueActionType('[dictionary/courier] Load list clear ids')
};

export class DictionaryCourierLoadListAction implements PayloadAction {
  readonly type = DictionaryCourierLoadListActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class DictionaryCourierLoadListFailAction implements PayloadAction {
  readonly type = DictionaryCourierLoadListActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class DictionaryCourierLoadListSuccessAction implements PayloadAction {
  readonly type = DictionaryCourierLoadListActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedList>) {
  }
}

export class DictionaryCourierLoadListClearAction implements PayloadAction {
  readonly type = DictionaryCourierLoadListActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}

export class DictionaryCourierLoadListChangeParamsAction implements PayloadAction {
  readonly type = DictionaryCourierLoadListActionTypes.changeParams;

  constructor(public payload: ChangeParamsByIdPayload<SymfonyPaginationParams>) {
  }
}

export class DictionaryCourierLoadListClearParamsAction implements PayloadAction {
  readonly type = DictionaryCourierLoadListActionTypes.clearParams;

  constructor(public payload: ByIdPayload) {
  }
}


export class DictionaryCourierLoadListClearIdsAction implements PayloadAction {
  readonly type = DictionaryCourierLoadListActionTypes.clearIds;

  constructor(public payload: ByIdPayload) {
  }
}
