import {
  ByIdPayload,
  ChangeParamsByIdPayload,
  FailByIdPayload,
  ListReducerTypes,
  NormalizedList,
  PayloadAction,
  RemoveIdByIdPayload,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { ArticleContentListParams } from '../models/content-list-params';

export const ArticleContentLoadListActionTypes: ListReducerTypes = {
  request: uniqueActionType('[article-content] Load list'),
  requestFail: uniqueActionType('[article-content] Load list fail'),
  requestSuccess: uniqueActionType('[article-content] Load list success'),
  requestClear: uniqueActionType('[article-content] Load list clear'),
  changeParams: uniqueActionType('[article-content] Load list change params'),
  clearParams: uniqueActionType('[article-content] Load list clear params'),
  clearIds: uniqueActionType('[article-content] Load list clear ids'),
  removeId: uniqueActionType('[article-content] Load list remove id'),
};

export class ArticleContentLoadListAction implements PayloadAction {
  readonly type = ArticleContentLoadListActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class ArticleContentLoadListFailAction implements PayloadAction {
  readonly type = ArticleContentLoadListActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class ArticleContentLoadListSuccessAction implements PayloadAction {
  readonly type = ArticleContentLoadListActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedList>) {
  }
}

export class ArticleContentLoadListClearAction implements PayloadAction {
  readonly type = ArticleContentLoadListActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}

export class ArticleContentLoadListChangeParamsAction implements PayloadAction {
  readonly type = ArticleContentLoadListActionTypes.changeParams;

  constructor(public payload: ChangeParamsByIdPayload<ArticleContentListParams>) {
  }
}

export class ArticleContentLoadListClearParamsAction implements PayloadAction {
  readonly type = ArticleContentLoadListActionTypes.clearParams;

  constructor(public payload: ByIdPayload) {
  }
}

export class ArticleContentLoadListClearIdsAction implements PayloadAction {
  readonly type = ArticleContentLoadListActionTypes.clearIds;

  constructor(public payload: ByIdPayload) {
  }
}

export class ArticleContentLoadListRemoveIdAction implements PayloadAction {
  readonly type = ArticleContentLoadListActionTypes.removeId;

  constructor(public payload: RemoveIdByIdPayload) {
  }
}
