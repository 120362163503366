import { createSelector } from '@ngrx/store';
import { denormalize } from 'normalizr';
import {
  ByKey,
  createListStateIdsSelector,
  createListStateParamsSelector,
  createStateErrorsSelector,
  createStateLoadingSelector,
  createStateSuccessSelector,
  IdType,
  ListState
} from '@industi/ngx-common';
import { EntityState } from '../../entity/entity.reducer';
import { EntityTypes } from '../../entity/entity-types';
import { createEntitiesSelector } from '../../entity/entities.reducer';
import { createSymfonyTotalCountSelector } from '../../symfony/list/selectors/list.selectors';
import { Entities } from '../../entity/entities';
import { Layer } from '../models/layer';
import { getLayerListState } from '../layer.reducer';
import * as _ from 'lodash';

const createState = (id: IdType) => createSelector(
  getLayerListState,
  (state: ByKey<ListState>) => state && state[id]
);

const createStateById = (id: IdType) => createListStateIdsSelector(createState(id));

export const createLayerListData = (id: IdType) => createSelector(
  createStateById(id),
  createEntitiesSelector([
    EntityTypes.layer
  ]),
  (
    ids: IdType[],
    entities: EntityState
  ) => denormalize(ids, [Entities[EntityTypes.layer]], entities) as Layer[]
);

export const createLayerListLoading = (id: IdType) => createStateLoadingSelector(
  createState(id));

export const createLayerListSuccess = (id: IdType) => createStateSuccessSelector(
  createState(id));

export const createLayerListErrors = (id: IdType) => createStateErrorsSelector(
  createState(id));

export const createLayerListTotalCount = (id: IdType) => createSymfonyTotalCountSelector(
  createState(id));

export const createLayerListParams = (id: IdType) => createListStateParamsSelector(
  createState(id));

export const createLayerMaterialListData = (id: IdType) => createSelector(
  createLayerListData(id),
  (
    list: Layer[]
  ) => list.reduce((acc, curr) => {
    if (!_.isEmpty(curr?.materialsConsumed)) {
      acc.push(...curr?.materialsConsumed);
    }
    return acc;
  }, [])
);
