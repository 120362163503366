import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import {
  DICTIONARY_OPERATION_REDUCER_TOKEN,
  dictionaryOperationReducerProvider,
  rootReducerKey
} from './operation.reducer';
import { EffectsModule } from '@ngrx/effects';
import { DictionaryOperationEffects } from './operation.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(rootReducerKey, DICTIONARY_OPERATION_REDUCER_TOKEN),
    EffectsModule.forFeature([DictionaryOperationEffects])
  ],
  providers: [
    dictionaryOperationReducerProvider
  ]
})
export class DictionaryOperationStoreModule { }
