import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SendByIdPayload,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { Dictionary } from '../../../../shared/models/dictionary';

export const DictionaryAcquisitionEditActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[dictionary/acquisition] Edit'),
  requestFail: uniqueActionType('[dictionary/acquisition] Edit fail'),
  requestSuccess: uniqueActionType('[dictionary/acquisition] Edit success'),
  requestClearErrors: uniqueActionType('[dictionary/acquisition] Edit clear errors'),
  requestClear: uniqueActionType('[dictionary/acquisition] Edit clear'),
};

export class DictionaryAcquisitionEditAction implements PayloadAction {
  readonly type = DictionaryAcquisitionEditActionTypes.request;

  constructor(public payload: SendByIdPayload<Dictionary>) {
  }
}

export class DictionaryAcquisitionEditFailAction implements PayloadAction {
  readonly type = DictionaryAcquisitionEditActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class DictionaryAcquisitionEditSuccessAction implements PayloadAction {
  readonly type = DictionaryAcquisitionEditActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class DictionaryAcquisitionEditClearErrorsAction implements PayloadAction {
  readonly type = DictionaryAcquisitionEditActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class DictionaryAcquisitionEditClearAction implements PayloadAction {
  readonly type = DictionaryAcquisitionEditActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
