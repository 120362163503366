import {
  ByIdPayload,
  FailByIdPayload,
  OperationReducerTypes,
  PayloadAction,
  uniqueActionType
} from '@industi/ngx-common';

export const ArticleDeleteActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[article] Delete'),
  requestFail: uniqueActionType('[article] Delete fail'),
  requestSuccess: uniqueActionType('[article] Delete success'),
  requestClearErrors: uniqueActionType('[article] Delete clear errors'),
  requestClear: uniqueActionType('[article] Delete clear')
};

export class ArticleDeleteAction implements PayloadAction {
  readonly type = ArticleDeleteActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class ArticleDeleteFailAction implements PayloadAction {
  readonly type = ArticleDeleteActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class ArticleDeleteSuccessAction implements PayloadAction {
  readonly type = ArticleDeleteActionTypes.requestSuccess;

  constructor(public payload: ByIdPayload) {
  }
}

export class ArticleDeleteClearErrorsAction implements PayloadAction {
  readonly type = ArticleDeleteActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class ArticleDeleteClearAction implements PayloadAction {
  readonly type = ArticleDeleteActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
