import {
  ByIdPayload,
  FailByIdPayload,
  OperationReducerTypes,
  PayloadAction,
  SendByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { ItemImagePayload } from '../models/item-image-payload';

export const ItemImageGalleryAddActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[item/image] Add gallery photo'),
  requestFail: uniqueActionType('[item/image] Add gallery photo fail'),
  requestSuccess: uniqueActionType('[item/image] Add gallery photo success'),
  requestClearErrors: uniqueActionType('[item/image] Add gallery photo clear errors'),
  requestClear: uniqueActionType('[item/image] Add gallery photo clear'),
};

export class ItemImageGalleryAddAction implements PayloadAction {
  readonly type = ItemImageGalleryAddActionTypes.request;

  constructor(public payload: SendByIdPayload<ItemImagePayload>) {
  }
}

export class ItemImageGalleryAddFailAction implements PayloadAction {
  readonly type = ItemImageGalleryAddActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class ItemImageGalleryAddSuccessAction implements PayloadAction {
  readonly type = ItemImageGalleryAddActionTypes.requestSuccess;

  constructor(public payload: ByIdPayload) {
  }
}

export class ItemImageGalleryAddClearErrorsAction implements PayloadAction {
  readonly type = ItemImageGalleryAddActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class ItemImageGalleryAddClearAction implements PayloadAction {
  readonly type = ItemImageGalleryAddActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
