import * as _ from 'lodash';

export class EncodeUtil {

  /** Przetwarza podane dane w dane formularza o typie `multipart/form-data` */
  static prepareMultipartFormData(data: any, filename?: string): FormData {
    if (!_.isObject(data)) {
      throw new Error('EncodeUtil.recursivelyOmitByNullId -> Parameter "data" should be an object');
    }
    const res = new FormData();
    const keys = _.keys(data);
    for (const key of keys) {
      const val = data[key];
      if (_.isNumber(val) || _.isString(val) || _.isBoolean(val)) {
        res.append(key, `${val}`);
      } else if (_.isArray(val) && !_.isEmpty(val) && (val[0] instanceof Blob)) {
        /** Specjalnie pod File[] */
        for (let i = 0; i < val?.length; i++) {
          res.append(`${key}[]`, val[i]);
        }
      } else if (val instanceof Blob) {
        filename ? res.append(key, val, filename) : res.append(key, val);
      } else {
        res.append(key, JSON.stringify(val));
      }
    }
    return res;
  }
}
