import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';

export const ArticleLoadDetailsActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[article] Load details'),
  requestFail: uniqueActionType('[article] Load details fail'),
  requestSuccess: uniqueActionType('[article] Load details success'),
  requestClearErrors: uniqueActionType('[article] Load details clear errors'),
  requestClear: uniqueActionType('[article] Load details clear')
};

export class ArticleLoadDetailsAction implements PayloadAction {
  readonly type = ArticleLoadDetailsActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class ArticleLoadDetailsFailAction implements PayloadAction {
  readonly type = ArticleLoadDetailsActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class ArticleLoadDetailsSuccessAction implements PayloadAction {
  readonly type = ArticleLoadDetailsActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class ArticleLoadDetailsClearAction implements PayloadAction {
  readonly type = ArticleLoadDetailsActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}

export class ArticleLoadDetailsClearErrorsAction implements PayloadAction {
  readonly type = ArticleLoadDetailsActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}
