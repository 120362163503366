import { createSelector } from '@ngrx/store';
import { denormalize } from 'normalizr';
import {
  ByKey,
  createListStateIdsSelector,
  createListStateParamsSelector,
  createStateErrorsSelector,
  createStateLoadingSelector,
  createStateSuccessSelector,
  IdType,
  ListState
} from '@industi/ngx-common';
import { EntityState } from '../../entity/entity.reducer';
import { EntityTypes } from '../../entity/entity-types';
import { createEntitiesSelector } from '../../entity/entities.reducer';
import { createSymfonyTotalCountSelector } from '../../symfony/list/selectors/list.selectors';
import { Entities } from '../../entity/entities';
import { getPartListState } from '../part.reducer';
import { Part } from '../models/part';

const createState = (id: IdType) => createSelector(
  getPartListState,
  (state: ByKey<ListState>) => state && state[id]
);

const createStateById = (id: IdType) => createListStateIdsSelector(createState(id));

export const createPartListData = (id: IdType) => createSelector(
  createStateById(id),
  createEntitiesSelector([
    EntityTypes.part
  ]),
  (
    ids: IdType[],
    entities: EntityState
  ) => denormalize(ids, [Entities[EntityTypes.part]], entities) as Part[]
);

export const createPartListDataByIds = (ids: IdType[]) => createSelector(
  createEntitiesSelector([
    EntityTypes.part
  ]),
  (
    entities: EntityState
  ) => denormalize(ids, [Entities[EntityTypes.part]], entities) as Part[]
);

export const createPartListLoading = (id: IdType) => createStateLoadingSelector(
  createState(id));

export const createPartListSuccess = (id: IdType) => createStateSuccessSelector(
  createState(id));

export const createPartListErrors = (id: IdType) => createStateErrorsSelector(
  createState(id));

export const createPartListTotalCount = (id: IdType) => createSymfonyTotalCountSelector(
  createState(id));

export const createPartListParams = (id: IdType) => createListStateParamsSelector(
  createState(id));
