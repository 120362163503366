import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LoginGuard } from './guards/login.guard';
import { AccessTokenGuard } from './guards/access-token.guard';
import { APP_AUTH_REDUCER_TOKEN, appAuthReducerProvider, rootReducerKey } from './auth.reducer';
import { StoreModule } from '@ngrx/store';
import { LibAuthStateModule } from 'app-store-auth';
import { RoleGuard } from './guards/role-guard.service';

@NgModule({
  imports: [
    CommonModule,
    LibAuthStateModule,
    StoreModule.forFeature(rootReducerKey, APP_AUTH_REDUCER_TOKEN)
  ],
  providers: [
    appAuthReducerProvider,
    AccessTokenGuard,
    RoleGuard,
    LoginGuard
  ]
})
export class AuthStoreModule {
}
