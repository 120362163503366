import { PayloadAction, SetValuePayload, SetValueReducerTypes, uniqueActionType } from '@industi/ngx-common';

export const TemplateSetSideNavOpenedActionTypes: SetValueReducerTypes = {
  setValue: uniqueActionType('[template] Set side nav opened')
};

export class TemplateOpenSideNavAction implements PayloadAction {
  readonly type = TemplateSetSideNavOpenedActionTypes.setValue;
  readonly payload: SetValuePayload<boolean> = { value: true };
}

export class TemplateCloseSideNavAction implements PayloadAction {
  readonly type = TemplateSetSideNavOpenedActionTypes.setValue;
  readonly payload: SetValuePayload<boolean> = { value: false };
}

export class TemplateToggleSideNavAction implements PayloadAction {
  readonly type = TemplateSetSideNavOpenedActionTypes.setValue;
  payload: SetValuePayload<boolean>;

  constructor(prevState: boolean) {
    this.payload = { value: !prevState };
  }
}
