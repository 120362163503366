import { ByKey, listByIdReducer, ListState, operationByIdReducer, SingleState } from '@industi/ngx-common';
import { ActionReducer, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import { InjectionToken, Provider } from '@angular/core';
import { DiscountGroupDeleteActionTypes } from './actions/delete.actions';
import { DiscountGroupLoadListActionTypes } from './actions/list.actions';
import { DiscountGroupAddActionTypes } from './actions/add.actions';
import { DiscountGroupEditActionTypes } from './actions/edit.actions';
import { DiscountGroupLoadDetailsActionTypes } from './actions/details.actions';

export const rootReducerKey = 'discountGroup';

export interface DiscountGroupOperationByIdState {
  add: SingleState;
  edit: SingleState;
  details: SingleState;
  delete: SingleState;
}

export interface DiscountGroupState {
  list: ByKey<ListState>;
  operationsById: ByKey<DiscountGroupOperationByIdState>;
}

const initialState: DiscountGroupState = {
  list: {},
  operationsById: {}
};

const reducer: ActionReducer<DiscountGroupState> = combineReducers<DiscountGroupState>({
  operationsById: operationByIdReducer<DiscountGroupOperationByIdState>({
    mapActionToId: (a) => a?.payload?.id,
    operations: {
      add: DiscountGroupAddActionTypes,
      edit: DiscountGroupEditActionTypes,
      details: DiscountGroupLoadDetailsActionTypes,
      delete: DiscountGroupDeleteActionTypes
    }
  }),
  list: listByIdReducer({ types: DiscountGroupLoadListActionTypes })
}, initialState);

export function getReducers(): ActionReducer<DiscountGroupState> {
  return reducer;
}

export const DISCOUNT_GROUP_REDUCER_TOKEN = new InjectionToken<ActionReducer<DiscountGroupState>>(rootReducerKey);

export const discountGroupReducerProvider: Provider = {
  provide: DISCOUNT_GROUP_REDUCER_TOKEN,
  useFactory: getReducers
};

const getState = createFeatureSelector<DiscountGroupState>(rootReducerKey);

export const getDiscountGroupListState = createSelector(
  getState,
  (state: DiscountGroupState) => state && state.list
);

export const getDiscountGroupOperationsById = createSelector(
  getState,
  (state: DiscountGroupState) => state && state.operationsById
);
