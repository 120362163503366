import { Inject, Injectable } from '@angular/core';
import { API_URL } from '../app-store-providers';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EntityTypes } from '../entity/entity-types';
import { PartListParams } from './models/part-list-params';
import { Part } from './models/part';
import { PartPayload } from './models/part-payload';
import { PartImportPayload } from './models/part-import-payload';
import { EncodeUtil } from '../../shared/utils/encode.util';
import { StoreBaseService } from '../common/base.service';

@Injectable({
  providedIn: 'root'
})
export class PartService
  extends StoreBaseService<Part, PartPayload, PartListParams> {

  get entityType(): string {
    return EntityTypes.part;
  }

  get resourceName(): string {
    return 'part';
  }

  constructor(
    @Inject(API_URL) apiUrl: string,
    http: HttpClient
  ) {
    super(apiUrl, http);
  }

  import(locale: string, data: PartImportPayload): Observable<void> {
    const url = `${this.apiUrl}/${locale}/api/${this.resourceName}/import`;
    return this.http.post<void>(url, EncodeUtil.prepareMultipartFormData(data));
  }
}
