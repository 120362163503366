import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import {
  DICTIONARY_CARBON_MATERIAL_TYPE_REDUCER_TOKEN,
  dictionaryCarbonMaterialTypeReducerProvider,
  rootReducerKey
} from './carbon-material-type.reducer';
import { DictionaryCarbonMaterialTypeEffects } from './carbon-material-type.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(rootReducerKey, DICTIONARY_CARBON_MATERIAL_TYPE_REDUCER_TOKEN),
    EffectsModule.forFeature([DictionaryCarbonMaterialTypeEffects])
  ],
  providers: [
    dictionaryCarbonMaterialTypeReducerProvider
  ]
})
export class DictionaryCarbonMaterialTypeStoreModule { }
