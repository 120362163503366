import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'noValue'
})
export class NoValuePipe implements PipeTransform {

  transform(value: any, customNoValueLabel = '-'): string {
    return !_.isNil(value) && value !== '' ? value : customNoValueLabel;
  }

}
