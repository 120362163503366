import { ByKey, listByIdReducer, ListState, operationByIdReducer, SingleState } from '@industi/ngx-common';
import { ActionReducer, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import { OfferAddActionTypes } from './actions/add.actions';
import { OfferEditActionTypes } from './actions/edit.actions';
import { OfferLoadDetailsActionTypes } from './actions/load-details.actions';
import { OfferDeleteActionTypes } from './actions/delete.actions';
import { OfferLoadListActionTypes } from './actions/list.actions';
import { InjectionToken, Provider } from '@angular/core';

export const rootReducerKey = 'offer';

export interface OfferOperationByIdState {
  add: SingleState;
  edit: SingleState;
  details: SingleState;
  delete: SingleState;
}

export interface OfferState {
  operationsById: ByKey<OfferOperationByIdState>;
  list: ByKey<ListState>;
}

const initialState: OfferState = {
  operationsById: {},
  list: {}
};

const reducer: ActionReducer<OfferState> = combineReducers<OfferState>({
  operationsById: operationByIdReducer<OfferOperationByIdState>({
    mapActionToId: (a) => a && a.payload && a.payload.id,
    operations: {
      add: OfferAddActionTypes,
      edit: OfferEditActionTypes,
      details: OfferLoadDetailsActionTypes,
      delete: OfferDeleteActionTypes
    }
  }),
  list: listByIdReducer({ types: OfferLoadListActionTypes })
}, initialState);

export function getReducers(): ActionReducer<OfferState> {
  return reducer;
}

export const OFFER_COMMON_REDUCER_TOKEN = new InjectionToken<ActionReducer<OfferState>>(rootReducerKey);

export const offerCommonReducerProvider: Provider = { provide: OFFER_COMMON_REDUCER_TOKEN, useFactory: getReducers };

const getState = createFeatureSelector<OfferState>(rootReducerKey);

export const getOfferOperationsById = createSelector(
  getState,
  (state: OfferState) => state && state.operationsById
);

export const getOfferListState = createSelector(
  getState,
  (state: OfferState) => state && state.list
);
