import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { ByIdPayload, ByKey, IdType, NormalizedList, NormalizedSingle } from '@industi/ngx-common';
import { catchError, first, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { getInstanceLocale } from '../../instance/instance.reducer';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import {
  ItemLoadListAction,
  ItemLoadListActionTypes,
  ItemLoadListClearIdsAction,
  ItemLoadListFailAction,
  ItemLoadListSuccessAction
} from './actions/list.actions';
import { createItemListParams } from './selectors/get-load-list.selectors';
import { ItemListParams } from './models/item-list-params';
import { ItemAddAction, ItemAddActionTypes, ItemAddFailAction, ItemAddSuccessAction } from './actions/add.actions';
import {
  ItemLoadDetailsAction,
  ItemLoadDetailsActionTypes,
  ItemLoadDetailsFailAction,
  ItemLoadDetailsSuccessAction
} from './actions/load-details.actions';
import { ItemEditAction, ItemEditActionTypes, ItemEditFailAction, ItemEditSuccessAction } from './actions/edit.actions';
import { ItemCommonService } from './common.service';
import { ResponseStatus } from '../../../shared/enums/response-status.enum';
import { LibSnackbarComponent } from '@industi/ngx-modules';
import { AppGlobal } from '../../../shared/app-global';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  ItemDeleteAction,
  ItemDeleteActionTypes,
  ItemDeleteFailAction,
  ItemDeleteSuccessAction
} from './actions/delete.actions';
import { AlertUtil } from '../../../shared/utils/alert.util';
import { ItemSetLastAddedAdapterService } from './adapters/set-last-added-adapter.service';
import { AppFile } from '../../../shared/models/file';
import { FileUtil } from '../../../shared/utils/file.util';
import {
  ItemLoadPdfAction,
  ItemLoadPdfActionTypes,
  ItemLoadPdfFailAction,
  ItemLoadPdfSuccessAction
} from './actions/load-pdf.actions';

@Injectable()
export class ItemCommonEffects {

  @Effect()
  loadList$ = this.actions$.pipe(
    ofType(ItemLoadListActionTypes.request),
    map((action: ItemLoadListAction) => action.payload),
    switchMap((payload: ByIdPayload) => this.store.pipe(
      select(createItemListParams(payload.id)),
      map((params: ItemListParams) => [payload.id, params] as [IdType, ItemListParams]),
      first()
    )),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([[ id, params ], locale]) => this.service.loadList(locale, params).pipe(
      mergeMap((res: NormalizedList) => [
        new ItemLoadListClearIdsAction({ id }),
        new ItemLoadListSuccessAction({ id, res })]),
      catchError((errors: HttpErrorResponse) => of(new ItemLoadListFailAction({ id, errors })))
    ))
  );

  @Effect()
  add$ = this.actions$.pipe(
    ofType(ItemAddActionTypes.request),
    map((action: ItemAddAction) => action.payload),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([{ id, data }, locale]) => this.service.add(locale, data).pipe(
      mergeMap((res: NormalizedSingle) => [
        this.lastAddedAdapter.setValueAction(res.result),
        new ItemAddSuccessAction({ res, id })
      ]),
      catchError((errors: HttpErrorResponse) => of(new ItemAddFailAction({ errors, id })))
    ))
  );

  @Effect()
  edit$ = this.actions$.pipe(
    ofType(ItemEditActionTypes.request),
    map((action: ItemEditAction) => action.payload),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([{ id, data }, locale]) => this.service.edit(locale, data, id).pipe(
      map((res: NormalizedSingle) => new ItemEditSuccessAction({ res, id })),
      catchError((errors: HttpErrorResponse) => of(new ItemEditFailAction({ errors, id })))
    ))
  );

  @Effect()
  loadDetails$ = this.actions$.pipe(
    ofType(ItemLoadDetailsActionTypes.request),
    map((action: ItemLoadDetailsAction) => action.payload.id),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([id, locale]) => this.service.details(locale, id).pipe(
      map((res: NormalizedSingle) => new ItemLoadDetailsSuccessAction({ id, res })),
      catchError((errors: HttpErrorResponse) => of(new ItemLoadDetailsFailAction({ id, errors })))
    ))
  );

  @Effect()
  delete$ = this.actions$.pipe(
    ofType(ItemDeleteActionTypes.request),
    map((action: ItemDeleteAction) => action.payload.id),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([id, locale]) => this.service.delete(locale, id).pipe(
      map(() => new ItemDeleteSuccessAction({ id })),
      catchError((errors: HttpErrorResponse) => of(new ItemDeleteFailAction({ id, errors })))
    ))
  );

  @Effect()
  pdf$ = this.actions$.pipe(
    ofType(ItemLoadPdfActionTypes.request),
    map((action: ItemLoadPdfAction) => action.payload),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([{ id }, locale]) => this.service.pdf(locale).pipe(
      map((res: any) => new ItemLoadPdfSuccessAction({ id, res })),
      catchError((errors: HttpErrorResponse) => of(new ItemLoadPdfFailAction({ id, errors })))
    ))
  );

  @Effect({ dispatch: false })
  pdfSuccess$ = this.actions$.pipe(
    ofType(ItemLoadPdfActionTypes.requestSuccess),
    map((action: ItemLoadPdfSuccessAction) => action.payload.res),
    tap((data: AppFile) => {
      FileUtil.saveFileInSameTab(data.file, 'Egzemplarze - etykiety.pdf');
      this.snackBar.openFromComponent(LibSnackbarComponent, {
        data: {
          message: 'Pobrano pdf',
          type: 'success'
        },
        duration: AppGlobal.defaultSnackBarDuration
      });
    })
  );

  @Effect({ dispatch: false })
  crudFail$ = this.actions$.pipe(
    ofType(
      ItemAddActionTypes.requestFail,
      ItemEditActionTypes.requestFail
    ),
    map((action: ItemAddFailAction | ItemEditFailAction) => action.payload.errors),
    tap((data: HttpErrorResponse) => {
      if (data?.status === ResponseStatus.BAD_REQUEST || data?.status === ResponseStatus.UNPROCESSABLE_ENTITY) {
        this.snackBar.openFromComponent(LibSnackbarComponent, {
          data: {
            message: AlertUtil.getControlTranslation(data?.error?.detail),
            type: 'error'
          },
          duration: AppGlobal.defaultSnackBarDuration
        });
      }
    })
  );

  @Effect({ dispatch: false })
  deleteFail$ = this.actions$.pipe(
    ofType(
      ItemDeleteActionTypes.requestFail
    ),
    map((action: ItemDeleteFailAction) => action.payload.errors),
    tap((data: HttpErrorResponse) => {
      if (data?.status === ResponseStatus.ACCESS_DENIED) {
        this.snackBar.openFromComponent(LibSnackbarComponent, {
          data: {
            message: 'Nie możesz usunąć tego egzemplarza',
            type: 'error'
          },
          duration: AppGlobal.defaultSnackBarDuration
        });
      }
    })
  );

  constructor(
    private actions$: Actions,
    private store: Store<ByKey>,
    private service: ItemCommonService,
    private lastAddedAdapter: ItemSetLastAddedAdapterService,
    private snackBar: MatSnackBar
  ) {
  }
}
