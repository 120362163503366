import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';

export const DictionaryTagLoadDetailsActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[dictionary/tag] Load details'),
  requestFail: uniqueActionType('[dictionary/tag] Load details fail'),
  requestSuccess: uniqueActionType('[dictionary/tag] Load details success'),
  requestClearErrors: uniqueActionType('[dictionary/tag] Load details clear errors'),
  requestClear: uniqueActionType('[dictionary/tag] Load details clear')
};

export class DictionaryTagLoadDetailsAction implements PayloadAction {
  readonly type = DictionaryTagLoadDetailsActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class DictionaryTagLoadDetailsFailAction implements PayloadAction {
  readonly type = DictionaryTagLoadDetailsActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class DictionaryTagLoadDetailsSuccessAction implements PayloadAction {
  readonly type = DictionaryTagLoadDetailsActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class DictionaryTagLoadDetailsClearAction implements PayloadAction {
  readonly type = DictionaryTagLoadDetailsActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}

export class DictionaryTagLoadDetailsClearErrorsAction implements PayloadAction {
  readonly type = DictionaryTagLoadDetailsActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}
