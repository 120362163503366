import { Inject, Injectable } from '@angular/core';
import { API_URL } from '../../app-store-providers';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IdType, NormalizedSingle } from '@industi/ngx-common';
import { map } from 'rxjs/operators';
import { normalize } from 'normalizr';
import { Entities } from '../../entity/entities';
import { EntityTypes } from '../../entity/entity-types';
import { OrderPayload } from './models/order-payload';
import { OrderListParams } from './models/order-list-params';
import { Order } from './models/order';
import { StoreBaseService } from '../../common/base.service';

@Injectable({
  providedIn: 'root'
})
export class OrderCommonService
  extends StoreBaseService<Order, OrderPayload, OrderListParams> {

  get entityType(): string {
    return EntityTypes.order;
  }

  get resourceName(): string {
    return 'order';
  }

  constructor(
    @Inject(API_URL) apiUrl: string,
    http: HttpClient
  ) {
    super(apiUrl, http);
  }

  paid(locale: string, id: IdType): Observable<NormalizedSingle> {
    const url = `${ this.apiUrl }/${ locale }/api/${this.resourceName}/${ id }/paid`;
    return this.http.put(url, {}).pipe(
      map((res: OrderPayload) => normalize(res, Entities[this.entityType]))
    );
  }

  unpaid(locale: string, id: IdType): Observable<NormalizedSingle> {
    const url = `${ this.apiUrl }/${ locale }/api/${this.resourceName}/${ id }/unpaid`;
    return this.http.put(url, {}).pipe(
      map((res: OrderPayload) => normalize(res, Entities[this.entityType]))
    );
  }

  sent(locale: string, id: IdType): Observable<NormalizedSingle> {
    const url = `${ this.apiUrl }/${ locale }/api/${this.resourceName}/${ id }/sent`;
    return this.http.put(url, {}).pipe(
      map((res: OrderPayload) => normalize(res, Entities[this.entityType]))
    );
  }

  cancel(locale: string, id: IdType): Observable<NormalizedSingle> {
    const url = `${ this.apiUrl }/${ locale }/api/${this.resourceName}/${ id }/cancel`;
    return this.http.put(url, {}).pipe(
      map((res: OrderPayload) => normalize(res, Entities[this.entityType]))
    );
  }
}
