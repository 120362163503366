import {
  ByIdPayload,
  ChangeParamsByIdPayload,
  FailByIdPayload,
  ListReducerTypes,
  NormalizedList,
  PayloadAction,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { SymfonyPaginationParams } from '../../../../symfony/list/models/symfony-pagination-params';

export const ServiceProjectOperationLoadListActionTypes: ListReducerTypes = {
  request: uniqueActionType('[service/project-operation] Load list'),
  requestFail: uniqueActionType('[service/project-operation] Load list fail'),
  requestSuccess: uniqueActionType('[service/project-operation] Load list success'),
  requestClear: uniqueActionType('[service/project-operation] Load list clear'),
  changeParams: uniqueActionType('[service/project-operation] Load list change params'),
  clearParams: uniqueActionType('[service/project-operation] Load list clear params'),
  clearIds: uniqueActionType('[service/project-operation] Load list clear ids')
};

export class ServiceProjectOperationLoadListAction implements PayloadAction {
  readonly type = ServiceProjectOperationLoadListActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class ServiceProjectOperationLoadListFailAction implements PayloadAction {
  readonly type = ServiceProjectOperationLoadListActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class ServiceProjectOperationLoadListSuccessAction implements PayloadAction {
  readonly type = ServiceProjectOperationLoadListActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedList>) {
  }
}

export class ServiceProjectOperationLoadListClearAction implements PayloadAction {
  readonly type = ServiceProjectOperationLoadListActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}

export class ServiceProjectOperationLoadListChangeParamsAction implements PayloadAction {
  readonly type = ServiceProjectOperationLoadListActionTypes.changeParams;

  constructor(public payload: ChangeParamsByIdPayload<SymfonyPaginationParams>) {
  }
}

export class ServiceProjectOperationLoadListClearParamsAction implements PayloadAction {
  readonly type = ServiceProjectOperationLoadListActionTypes.clearParams;

  constructor(public payload: ByIdPayload) {
  }
}


export class ServiceProjectOperationLoadListClearIdsAction implements PayloadAction {
  readonly type = ServiceProjectOperationLoadListActionTypes.clearIds;

  constructor(public payload: ByIdPayload) {
  }
}
