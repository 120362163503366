import {
  ByIdPayload,
  ChangeParamsByIdPayload,
  FailByIdPayload,
  ListReducerTypes,
  NormalizedList,
  PayloadAction,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { SymfonyPaginationParams } from '../../../symfony/list/models/symfony-pagination-params';

export const OfferItemLoadListActionTypes: ListReducerTypes = {
  request: uniqueActionType('[offer/item] Load list'),
  requestFail: uniqueActionType('[offer/item] Load list fail'),
  requestSuccess: uniqueActionType('[offer/item] Load list success'),
  requestClear: uniqueActionType('[offer/item] Load list clear'),
  changeParams: uniqueActionType('[offer/item] Load list change params'),
  clearParams: uniqueActionType('[offer/item] Load list clear params'),
  clearIds: uniqueActionType('[offer/item] Load list clear ids')
};

export class OfferItemLoadListAction implements PayloadAction {
  readonly type = OfferItemLoadListActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class OfferItemLoadListFailAction implements PayloadAction {
  readonly type = OfferItemLoadListActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class OfferItemLoadListSuccessAction implements PayloadAction {
  readonly type = OfferItemLoadListActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedList>) {
  }
}

export class OfferItemLoadListChangeParamsAction implements PayloadAction {
  readonly type = OfferItemLoadListActionTypes.changeParams;

  constructor(public payload: ChangeParamsByIdPayload<SymfonyPaginationParams>) {
  }
}

export class OfferItemLoadListClearAction implements PayloadAction {
  readonly type = OfferItemLoadListActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}

export class OfferItemLoadListClearParamsAction implements PayloadAction {
  readonly type = OfferItemLoadListActionTypes.clearParams;

  constructor(public payload: ByIdPayload) {
  }
}

export class OfferItemLoadListClearIdsAction implements PayloadAction {
  readonly type = OfferItemLoadListActionTypes.clearIds;

  constructor(public payload: ByIdPayload) {
  }
}
