import {
  ByIdPayload,
  ChangeParamsByIdPayload,
  FailByIdPayload,
  ListReducerTypes,
  NormalizedList,
  PayloadAction,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { DiagramListParams } from '../models/diagram-list-params';

export const DiagramLoadListActionTypes: ListReducerTypes = {
  request: uniqueActionType('[diagram] Load list'),
  requestFail: uniqueActionType('[diagram] Load list fail'),
  requestSuccess: uniqueActionType('[diagram] Load list success'),
  requestClear: uniqueActionType('[diagram] Load list clear'),
  changeParams: uniqueActionType('[diagram] Load list change params'),
  clearParams: uniqueActionType('[diagram] Load list clear params'),
  clearIds: uniqueActionType('[diagram] Load list clear ids')
};

export class DiagramLoadListAction implements PayloadAction {
  readonly type = DiagramLoadListActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class DiagramLoadListFailAction implements PayloadAction {
  readonly type = DiagramLoadListActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class DiagramLoadListSuccessAction implements PayloadAction {
  readonly type = DiagramLoadListActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedList>) {
  }
}

export class DiagramLoadListClearAction implements PayloadAction {
  readonly type = DiagramLoadListActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}

export class DiagramLoadListChangeParamsAction implements PayloadAction {
  readonly type = DiagramLoadListActionTypes.changeParams;

  constructor(public payload: ChangeParamsByIdPayload<DiagramListParams>) {
  }
}

export class DiagramLoadListClearParamsAction implements PayloadAction {
  readonly type = DiagramLoadListActionTypes.clearParams;

  constructor(public payload: ByIdPayload) {
  }
}


export class DiagramLoadListClearIdsAction implements PayloadAction {
  readonly type = DiagramLoadListActionTypes.clearIds;

  constructor(public payload: ByIdPayload) {
  }
}
