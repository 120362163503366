import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { ARTICLE_COMMON_REDUCER_TOKEN, articleCommonReducerProvider, rootReducerKey } from './common.reducer';
import { EffectsModule } from '@ngrx/effects';
import { ArticleCommonEffects } from './common.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(rootReducerKey, ARTICLE_COMMON_REDUCER_TOKEN),
    EffectsModule.forFeature([ArticleCommonEffects])
  ],
  providers: [
    articleCommonReducerProvider
  ]
})
export class ArticleCommonStoreModule {
}
