import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, first, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { ByIdPayload, ByKey, IdType, NormalizedList, NormalizedSingle } from '@industi/ngx-common';
import { select, Store } from '@ngrx/store';
import { getInstanceLocale } from '../../instance/instance.reducer';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import { ResponseStatus } from '../../../shared/enums/response-status.enum';
import { LibSnackbarComponent } from '@industi/ngx-modules';
import { AlertUtil } from '../../../shared/utils/alert.util';
import { AppGlobal } from '../../../shared/app-global';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OrderItemService } from './item.service';
import {
  OrderItemLoadListAction,
  OrderItemLoadListActionTypes,
  OrderItemLoadListClearIdsAction,
  OrderItemLoadListFailAction,
  OrderItemLoadListSuccessAction
} from './actions/list.action';
import { SymfonyPaginationParams } from '../../symfony/list/models/symfony-pagination-params';
import {
  OrderItemAddAction,
  OrderItemAddActionTypes,
  OrderItemAddFailAction,
  OrderItemAddSuccessAction
} from './actions/add.action';
import { createOrderItemListParams } from './selectors/get-list.selectors';
import {
  OrderItemEditAction,
  OrderItemEditActionTypes,
  OrderItemEditFailAction,
  OrderItemEditSuccessAction
} from './actions/edit.action';
import {
  OrderItemLoadDetailsAction,
  OrderItemLoadDetailsActionTypes,
  OrderItemLoadDetailsFailAction,
  OrderItemLoadDetailsSuccessAction
} from './actions/details.action';
import {
  OrderItemDeleteAction,
  OrderItemDeleteActionTypes,
  OrderItemDeleteFailAction,
  OrderItemDeleteSuccessAction
} from './actions/delete.action';

@Injectable()
export class OrderItemEffects {

  @Effect()
  loadList$ = this.actions$.pipe(
    ofType(OrderItemLoadListActionTypes.request),
    map((action: OrderItemLoadListAction) => action.payload),
    switchMap((payload: ByIdPayload) => this.store.pipe(
      select(createOrderItemListParams(payload.id)),
      map((params: SymfonyPaginationParams) => [payload.id, params] as [IdType, SymfonyPaginationParams]),
      first()
    )),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([[id, params], locale]) => this.service.loadList(locale, params).pipe(
      mergeMap((res: NormalizedList) => [
        new OrderItemLoadListClearIdsAction({ id }),
        new OrderItemLoadListSuccessAction({ id, res })]),
      catchError((errors: HttpErrorResponse) => of(new OrderItemLoadListFailAction({ id, errors })))
    ))
  );

  @Effect()
  add$ = this.actions$.pipe(
    ofType(OrderItemAddActionTypes.request),
    map((action: OrderItemAddAction) => action.payload),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([{ id, data }, locale]) => this.service.add(locale, data).pipe(
      map((res: NormalizedSingle) => new OrderItemAddSuccessAction({ res, id })),
      catchError((errors: HttpErrorResponse) => of(new OrderItemAddFailAction({ errors, id })))
    ))
  );

  @Effect()
  edit$ = this.actions$.pipe(
    ofType(OrderItemEditActionTypes.request),
    map((action: OrderItemEditAction) => action.payload),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([{ id, data }, locale]) => this.service.edit(locale, data, id).pipe(
      map((res: NormalizedSingle) => new OrderItemEditSuccessAction({ res, id })),
      catchError((errors: HttpErrorResponse) => of(new OrderItemEditFailAction({ errors, id })))
    ))
  );

  @Effect()
  loadDetails$ = this.actions$.pipe(
    ofType(OrderItemLoadDetailsActionTypes.request),
    map((action: OrderItemLoadDetailsAction) => action.payload.id),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([id, locale]) => this.service.details(locale, id).pipe(
      map((res: NormalizedSingle) => new OrderItemLoadDetailsSuccessAction({ id, res })),
      catchError((errors: HttpErrorResponse) => of(new OrderItemLoadDetailsFailAction({ id, errors })))
    ))
  );

  @Effect()
  delete$ = this.actions$.pipe(
    ofType(OrderItemDeleteActionTypes.request),
    map((action: OrderItemDeleteAction) => action.payload.id),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([id, locale]) => this.service.delete(locale, id).pipe(
      map(() => new OrderItemDeleteSuccessAction({ id })),
      catchError((errors: HttpErrorResponse) => of(new OrderItemDeleteFailAction({ id, errors })))
    ))
  );

  @Effect({ dispatch: false })
  crudFail$ = this.actions$.pipe(
    ofType(
      OrderItemAddActionTypes.requestFail,
      OrderItemEditActionTypes.requestFail
    ),
    map((action: OrderItemAddFailAction | OrderItemEditFailAction) => action.payload.errors),
    tap((data: HttpErrorResponse) => {
      if (data?.status === ResponseStatus.BAD_REQUEST || data?.status === ResponseStatus.UNPROCESSABLE_ENTITY) {
        this.snackBar.openFromComponent(LibSnackbarComponent, {
          data: {
            message: AlertUtil.getControlTranslation(data.error.detail),
            type: 'error'
          },
          duration: AppGlobal.defaultSnackBarDuration
        });
      }
    })
  );

  constructor(
    private actions$: Actions,
    private store: Store<ByKey>,
    private service: OrderItemService,
    private snackBar: MatSnackBar
  ) {
  }
}
