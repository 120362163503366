import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import {
  DICTIONARY_ACQUISITION_REDUCER_TOKEN,
  dictionaryAcquisitionReducerProvider,
  rootReducerKey
} from './acquisition.reducer';
import { EffectsModule } from '@ngrx/effects';
import { DictionaryAcquisitionEffects } from './acquisition.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(rootReducerKey, DICTIONARY_ACQUISITION_REDUCER_TOKEN),
    EffectsModule.forFeature([DictionaryAcquisitionEffects])
  ],
  providers: [
    dictionaryAcquisitionReducerProvider
  ]
})
export class DictionaryAcquisitionStoreModule {
}
