import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SendByIdPayload,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { CarbonFormPayload } from '../models/form-payload';

export const CarbonFormEditActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[carbon/form] Edit'),
  requestFail: uniqueActionType('[carbon/form] Edit fail'),
  requestSuccess: uniqueActionType('[carbon/form] Edit success'),
  requestClearErrors: uniqueActionType('[carbon/form] Edit clear errors'),
  requestClear: uniqueActionType('[carbon/form] Edit clear')
};

export class CarbonFormEditAction implements PayloadAction {
  readonly type = CarbonFormEditActionTypes.request;

  constructor(public payload: SendByIdPayload<CarbonFormPayload>) {
  }
}

export class CarbonFormEditFailAction implements PayloadAction {
  readonly type = CarbonFormEditActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class CarbonFormEditSuccessAction implements PayloadAction {
  readonly type = CarbonFormEditActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class CarbonFormEditClearErrorsAction implements PayloadAction {
  readonly type = CarbonFormEditActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class CarbonFormEditClearAction implements PayloadAction {
  readonly type = CarbonFormEditActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
