export class FileUtil {

  /**
   * Print file in new tab
   *
   * @source https://stackoverflow.com/questions/41510420/javascript-print-blob-content-returned-from-api
   */
  static printInNewTab(data: Blob): void {
    if (window.navigator.msSaveOrOpenBlob) {

      // nasz ulubiony Internet Explorer
      window.navigator.msSaveOrOpenBlob(data, 'PrintedLetters.pdf');

    } else {
      const fileURL = URL.createObjectURL(data);
      const a: HTMLAnchorElement = document.createElement('a');
      a.href = fileURL;
      a.target = '_blank';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  }

  static saveFileInSameTab(data: Blob, customFileName?: string): void {
    if (window.navigator.msSaveOrOpenBlob) {

      // nasz ulubiony Internet Explorer
      window.navigator.msSaveOrOpenBlob(data, customFileName || 'PrintedLetters.pdf');

    } else {
      const fileURL = URL.createObjectURL(data);
      const a: HTMLAnchorElement = document.createElement('a');
      a.href = fileURL;
      a.download = customFileName || '';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  }

  static getFileNameFromContentDisposition(contentDisposition: string): string {
    const rawFileNameHeader = contentDisposition
      .split(';')
      .find((item: string) => item.indexOf('filename=') !== -1);

    return rawFileNameHeader && rawFileNameHeader.trim()
      .split('filename=')[1]
      .trim()
      .split('.')[0]
      .trim();
  }

  static getExtensionFromContentType(contentType: string): string {
    return contentType.indexOf('/') !== -1 ? '.' + contentType.split('/')[1] : '';
  }
}
