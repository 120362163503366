import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, first, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { ByIdPayload, ByKey, IdType, NormalizedList, NormalizedSingle } from '@industi/ngx-common';
import { select, Store } from '@ngrx/store';
import { getInstanceLocale } from '../../instance/instance.reducer';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import {
  DictionaryCarbonMaterialTypeEditAction,
  DictionaryCarbonMaterialTypeEditActionTypes,
  DictionaryCarbonMaterialTypeEditFailAction,
  DictionaryCarbonMaterialTypeEditSuccessAction
} from './actions/edit.actions';
import {
  DictionaryCarbonMaterialTypeLoadListAction,
  DictionaryCarbonMaterialTypeLoadListActionTypes,
  DictionaryCarbonMaterialTypeLoadListClearIdsAction,
  DictionaryCarbonMaterialTypeLoadListFailAction,
  DictionaryCarbonMaterialTypeLoadListSuccessAction
} from './actions/list.actions';
import {
  DictionaryCarbonMaterialTypeAddAction,
  DictionaryCarbonMaterialTypeAddActionTypes,
  DictionaryCarbonMaterialTypeAddFailAction,
  DictionaryCarbonMaterialTypeAddSuccessAction
} from './actions/add.actions';
import {
  DictionaryCarbonMaterialTypeLoadDetailsAction,
  DictionaryCarbonMaterialTypeLoadDetailsActionTypes,
  DictionaryCarbonMaterialTypeLoadDetailsFailAction,
  DictionaryCarbonMaterialTypeLoadDetailsSuccessAction
} from './actions/load-details.actions';
import { SymfonyPaginationParams } from '../../symfony/list/models/symfony-pagination-params';
import { DictionaryCarbonMaterialTypeService } from './carbon-material-type.service';
import { createDictionaryCarbonMaterialTypeListParams } from './selectors/get-load-list.selectors';
import {
  DictionaryCarbonMaterialTypeDeleteAction,
  DictionaryCarbonMaterialTypeDeleteActionTypes,
  DictionaryCarbonMaterialTypeDeleteFailAction,
  DictionaryCarbonMaterialTypeDeleteSuccessAction
} from './actions/delete.actions';
import { ResponseStatus } from '../../../shared/enums/response-status.enum';
import { LibSnackbarComponent } from '@industi/ngx-modules';
import { AppGlobal } from '../../../shared/app-global';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlertUtil } from '../../../shared/utils/alert.util';

@Injectable()
export class DictionaryCarbonMaterialTypeEffects {

  @Effect()
  loadList$ = this.actions$.pipe(
    ofType(DictionaryCarbonMaterialTypeLoadListActionTypes.request),
    map((action: DictionaryCarbonMaterialTypeLoadListAction) => action.payload),
    switchMap((payload: ByIdPayload) => this.store.pipe(
      select(createDictionaryCarbonMaterialTypeListParams(payload.id)),
      map((params: SymfonyPaginationParams) => [payload.id, params] as [IdType, SymfonyPaginationParams]),
      first()
    )),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([[ id, params ], locale]) => this.service.loadList(locale, params).pipe(
      mergeMap((res: NormalizedList) => [
        new DictionaryCarbonMaterialTypeLoadListClearIdsAction({ id }),
        new DictionaryCarbonMaterialTypeLoadListSuccessAction({ id, res })]),
      catchError((errors: HttpErrorResponse) => of(new DictionaryCarbonMaterialTypeLoadListFailAction({ id, errors })))
    ))
  );

  @Effect()
  add$ = this.actions$.pipe(
    ofType(DictionaryCarbonMaterialTypeAddActionTypes.request),
    map((action: DictionaryCarbonMaterialTypeAddAction) => action.payload),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([{ id, data }, locale]) => this.service.add(locale, data).pipe(
      map((res: NormalizedSingle) => new DictionaryCarbonMaterialTypeAddSuccessAction({ res, id })),
      catchError((errors: HttpErrorResponse) => of(new DictionaryCarbonMaterialTypeAddFailAction({ errors, id })))
    ))
  );

  @Effect()
  edit$ = this.actions$.pipe(
    ofType(DictionaryCarbonMaterialTypeEditActionTypes.request),
    map((action: DictionaryCarbonMaterialTypeEditAction) => action.payload),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([{ id, data }, locale]) => this.service.edit(locale, data, id).pipe(
      map((res: NormalizedSingle) => new DictionaryCarbonMaterialTypeEditSuccessAction({ res, id })),
      catchError((errors: HttpErrorResponse) => of(new DictionaryCarbonMaterialTypeEditFailAction({ errors, id })))
    ))
  );

  @Effect()
  loadDetails$ = this.actions$.pipe(
    ofType(DictionaryCarbonMaterialTypeLoadDetailsActionTypes.request),
    map((action: DictionaryCarbonMaterialTypeLoadDetailsAction) => action.payload.id),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([id, locale]) => this.service.details(locale, id).pipe(
      map((res: NormalizedSingle) => new DictionaryCarbonMaterialTypeLoadDetailsSuccessAction({ id, res })),
      catchError((errors: HttpErrorResponse) => of(new DictionaryCarbonMaterialTypeLoadDetailsFailAction({ id, errors })))
    ))
  );

  @Effect()
  delete$ = this.actions$.pipe(
    ofType(DictionaryCarbonMaterialTypeDeleteActionTypes.request),
    map((action: DictionaryCarbonMaterialTypeDeleteAction) => action.payload.id),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([id, locale]) => this.service.delete(locale, id).pipe(
      map(() => new DictionaryCarbonMaterialTypeDeleteSuccessAction({ id })),
      catchError((errors: HttpErrorResponse) => of(new DictionaryCarbonMaterialTypeDeleteFailAction({ id, errors })))
    ))
  );

  @Effect({ dispatch: false })
  crudFail$ = this.actions$.pipe(
    ofType(
      DictionaryCarbonMaterialTypeAddActionTypes.requestFail,
      DictionaryCarbonMaterialTypeEditActionTypes.requestFail
    ),
    map((action: DictionaryCarbonMaterialTypeAddFailAction | DictionaryCarbonMaterialTypeEditFailAction) => action.payload.errors),
    tap((data: HttpErrorResponse) => {
      if (data?.status === ResponseStatus.BAD_REQUEST || data?.status === ResponseStatus.UNPROCESSABLE_ENTITY) {
        this.snackBar.openFromComponent(LibSnackbarComponent, {
          data: {
            message: AlertUtil.getControlTranslation(data.error.detail),
            type: 'error'
          },
          duration: AppGlobal.defaultSnackBarDuration
        });
      }
    })
  );

  constructor(
    private actions$: Actions,
    private store: Store<ByKey>,
    private service: DictionaryCarbonMaterialTypeService,
    private snackBar: MatSnackBar
  ) {
  }
}
