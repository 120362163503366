import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';

export const DictionaryAcquisitionLoadDetailsActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[dictionary/acquisition] Load details'),
  requestFail: uniqueActionType('[dictionary/acquisition] Load details fail'),
  requestSuccess: uniqueActionType('[dictionary/acquisition] Load details success'),
  requestClearErrors: uniqueActionType('[dictionary/acquisition] Load details clear errors'),
  requestClear: uniqueActionType('[dictionary/acquisition] Load details clear'),
};

export class DictionaryAcquisitionLoadDetailsAction implements PayloadAction {
  readonly type = DictionaryAcquisitionLoadDetailsActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class DictionaryAcquisitionLoadDetailsFailAction implements PayloadAction {
  readonly type = DictionaryAcquisitionLoadDetailsActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class DictionaryAcquisitionLoadDetailsSuccessAction implements PayloadAction {
  readonly type = DictionaryAcquisitionLoadDetailsActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class DictionaryAcquisitionLoadDetailsClearErrorsAction implements PayloadAction {
  readonly type = DictionaryAcquisitionLoadDetailsActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class DictionaryAcquisitionLoadDetailsClearAction implements PayloadAction {
  readonly type = DictionaryAcquisitionLoadDetailsActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
