import {
  ByIdPayload,
  ChangeParamsByIdPayload,
  FailByIdPayload,
  ListReducerTypes,
  NormalizedList,
  PayloadAction,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { CarbonFormCommissionListParams } from '../models/form-commission-list-params';

export const CarbonFormCommissionLoadListActionTypes: ListReducerTypes = {
  request: uniqueActionType('[carbon/form-commission] Load list'),
  requestFail: uniqueActionType('[carbon/form-commission] Load list fail'),
  requestSuccess: uniqueActionType('[carbon/form-commission] Load list success'),
  requestClear: uniqueActionType('[carbon/form-commission] Load list clear'),
  changeParams: uniqueActionType('[carbon/form-commission] Load list change params'),
  clearParams: uniqueActionType('[carbon/form-commission] Load list clear params'),
  clearIds: uniqueActionType('[carbon/form-commission] Load list clear ids')
};

export class CarbonFormCommissionLoadListAction implements PayloadAction {
  readonly type = CarbonFormCommissionLoadListActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class CarbonFormCommissionLoadListFailAction implements PayloadAction {
  readonly type = CarbonFormCommissionLoadListActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class CarbonFormCommissionLoadListSuccessAction implements PayloadAction {
  readonly type = CarbonFormCommissionLoadListActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedList>) {
  }
}

export class CarbonFormCommissionLoadListClearAction implements PayloadAction {
  readonly type = CarbonFormCommissionLoadListActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}

export class CarbonFormCommissionLoadListChangeParamsAction implements PayloadAction {
  readonly type = CarbonFormCommissionLoadListActionTypes.changeParams;

  constructor(public payload: ChangeParamsByIdPayload<CarbonFormCommissionListParams>) {
  }
}

export class CarbonFormCommissionLoadListClearParamsAction implements PayloadAction {
  readonly type = CarbonFormCommissionLoadListActionTypes.clearParams;

  constructor(public payload: ByIdPayload) {
  }
}


export class CarbonFormCommissionLoadListClearIdsAction implements PayloadAction {
  readonly type = CarbonFormCommissionLoadListActionTypes.clearIds;

  constructor(public payload: ByIdPayload) {
  }
}
