import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SendByIdPayload,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { DictionaryBrandImagePayload } from '../models/brand-image-payload';

export const DictionaryBrandLogoAddActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[dictionary/brand] Add logo'),
  requestFail: uniqueActionType('[dictionary/brand] Add logo fail'),
  requestSuccess: uniqueActionType('[dictionary/brand] Add logo success'),
  requestClearErrors: uniqueActionType('[dictionary/brand] Add logo clear errors'),
  requestClear: uniqueActionType('[dictionary/brand] Add logo clear'),
};

export class DictionaryBrandLogoAddAction implements PayloadAction {
  readonly type = DictionaryBrandLogoAddActionTypes.request;

  constructor(public payload: SendByIdPayload<DictionaryBrandImagePayload>) {
  }
}

export class DictionaryBrandLogoAddFailAction implements PayloadAction {
  readonly type = DictionaryBrandLogoAddActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class DictionaryBrandLogoAddSuccessAction implements PayloadAction {
  readonly type = DictionaryBrandLogoAddActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class DictionaryBrandLogoAddClearErrorsAction implements PayloadAction {
  readonly type = DictionaryBrandLogoAddActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class DictionaryBrandLogoAddClearAction implements PayloadAction {
  readonly type = DictionaryBrandLogoAddActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
