import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { INSTANCE_REDUCER_TOKEN, instanceReducerProvider } from './instance.reducer';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { InstanceEffects } from './instance.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('instance', INSTANCE_REDUCER_TOKEN),
    EffectsModule.forFeature([InstanceEffects])
  ],
  providers: [
    instanceReducerProvider
  ]
})
export class InstanceStoreModule { }
