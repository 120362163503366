import { ByKey, listByIdReducer, ListState, operationByIdReducer, SingleState } from '@industi/ngx-common';
import { ActionReducer, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import { InjectionToken, Provider } from '@angular/core';
import { ItemImageDeleteActionTypes } from './actions/delete.actions';
import { ItemImageGalleryAddActionTypes } from './actions/add-gallery.actions';
import { ItemImageAddPendingListActionTypes } from './actions/add-pending-list.actions';
import { ItemImageAddDoneListActionTypes } from './actions/add-done-list.actions';
import { ItemImageLoadDetailsActionTypes } from './actions/load-details.actions';

export const rootReducerKey = 'itemImage';

export interface ItemImageOperationByIdState {
  details: SingleState;
  delete: SingleState;
  addGallery: SingleState;
}

export interface ItemImageState {
  operationsById: ByKey<ItemImageOperationByIdState>;
  addPendingList: ByKey<ListState>;
  addDoneList: ByKey<ListState>;
}

const initialState: ItemImageState = {
  operationsById: {},
  addPendingList: {},
  addDoneList: {}
};

const reducer: ActionReducer<ItemImageState> = combineReducers<ItemImageState>({
  operationsById: operationByIdReducer<ItemImageOperationByIdState>({
    mapActionToId: (a) => a && a.payload && a.payload.id,
    operations: {
      details: ItemImageLoadDetailsActionTypes,
      delete: ItemImageDeleteActionTypes,
      addGallery: ItemImageGalleryAddActionTypes
    }
  }),
  addPendingList: listByIdReducer({ types: ItemImageAddPendingListActionTypes }),
  addDoneList: listByIdReducer({ types: ItemImageAddDoneListActionTypes })
}, initialState);

export function getReducers(): ActionReducer<ItemImageState> {
  return reducer;
}

export const ITEM_IMAGE_REDUCER_TOKEN = new InjectionToken<ActionReducer<ItemImageState>>(rootReducerKey);

export const itemImageReducerProvider: Provider = { provide: ITEM_IMAGE_REDUCER_TOKEN, useFactory: getReducers };

const getState = createFeatureSelector<ItemImageState>(rootReducerKey);

export const getItemImageAddPendingList = createSelector(
  getState,
  (state: ItemImageState) => state && state.addPendingList
);

export const getItemImageAddDoneList = createSelector(
  getState,
  (state: ItemImageState) => state && state.addDoneList
);

export const getItemImageOperationsById = createSelector(
  getState,
  (state: ItemImageState) => state && state.operationsById
);
