import { InjectionToken } from '@angular/core';
import { ActionReducer, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import { TemplateSetSideNavOpenedActionTypes } from './actions/set-side-nav-opened.actions';
import { setValueReducer } from '@industi/ngx-common';

export interface TemplateState {
  sidenavOpened: boolean;
}

const initialState: TemplateState = {
  sidenavOpened: false
};

const reducer = combineReducers<TemplateState>({
  sidenavOpened: setValueReducer({ types: TemplateSetSideNavOpenedActionTypes })
}, initialState);

export const TEMPLATE_REDUCER_TOKEN = new InjectionToken<ActionReducer<TemplateState>>('template');

export const templateReducerProvider = { provide: TEMPLATE_REDUCER_TOKEN, useFactory: getReducers };

export function getReducers(): ActionReducer<TemplateState> {
  return reducer;
}

const getTemplateState = createFeatureSelector<TemplateState>('template');

export const getTemplateSideNavOpened = createSelector(
  getTemplateState,
  (state: TemplateState) => state.sidenavOpened
);
