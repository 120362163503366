import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';

export const EbayLoadCompatibilityPropertiesActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[ebay-compatibility] Load compatibility'),
  requestFail: uniqueActionType('[ebay-compatibility] Load compatibility fail'),
  requestSuccess: uniqueActionType('[ebay-compatibility] Load compatibility success'),
  requestClearErrors: uniqueActionType('[ebay-compatibility] Load compatibility clear errors'),
  requestClear: uniqueActionType('[ebay-compatibility] Load compatibility clear')
};

export class EbayLoadCompatibilityPropertiesAction implements PayloadAction {
  readonly type = EbayLoadCompatibilityPropertiesActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class EbayLoadCompatibilityPropertiesFailAction implements PayloadAction {
  readonly type = EbayLoadCompatibilityPropertiesActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class EbayLoadCompatibilityPropertiesSuccessAction implements PayloadAction {
  readonly type = EbayLoadCompatibilityPropertiesActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class EbayLoadCompatibilityPropertiesClearAction implements PayloadAction {
  readonly type = EbayLoadCompatibilityPropertiesActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}

export class EbayLoadCompatibilityPropertiesClearErrorsAction implements PayloadAction {
  readonly type = EbayLoadCompatibilityPropertiesActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}
