import { createSelector } from '@ngrx/store';
import { denormalize } from 'normalizr';
import {
  ByKey,
  createListStateIdsSelector,
  createListStateParamsSelector,
  createStateErrorsSelector,
  createStateLoadingSelector,
  createStateSuccessSelector,
  IdType,
  ListState
} from '@industi/ngx-common';
import { getServiceProjectListState } from '../common.reducer';
import { createEntitiesSelector } from '../../../../entity/entities.reducer';
import { EntityTypes } from '../../../../entity/entity-types';
import { EntityState } from '../../../../entity/entity.reducer';
import { Entities } from '../../../../entity/entities';
import { ServiceProject } from '../models/project';
import { createSymfonyTotalCountSelector } from '../../../../symfony/list/selectors/list.selectors';

const createState = (id: IdType) => createSelector(
  getServiceProjectListState,
  (state: ByKey<ListState>) => state && state[id]
);

const createStateById = (id: IdType) => createListStateIdsSelector(createState(id));

export const createServiceProjectListData = (id: IdType) => createSelector(
  createStateById(id),
  createEntitiesSelector([
    EntityTypes.project,
    EntityTypes.client
  ]),
  (
    ids: IdType[],
    entities: EntityState
  ) => denormalize(ids, [Entities[EntityTypes.project]], entities) as ServiceProject[]
);

export const createServiceProjectListLoading = (id: IdType) => createStateLoadingSelector(
  createState(id));

export const createServiceProjectListSuccess = (id: IdType) => createStateSuccessSelector(
  createState(id));

export const createServiceProjectListErrors = (id: IdType) => createStateErrorsSelector(
  createState(id));

export const createServiceProjectListTotalCount = (id: IdType) => createSymfonyTotalCountSelector(
  createState(id));

export const createServiceProjectListParams = (id: IdType) => createListStateParamsSelector(
  createState(id));
