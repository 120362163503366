import {
  ByIdPayload,
  ChangeParamsByIdPayload,
  FailByIdPayload,
  ListReducerTypes,
  NormalizedList,
  PayloadAction,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { DictionaryBrandListParams } from '../models/brand-list-params';

export const DictionaryBrandLoadListActionTypes: ListReducerTypes = {
  request: uniqueActionType('[dictionary/brand] Load list'),
  requestFail: uniqueActionType('[dictionary/brand] Load list fail'),
  requestSuccess: uniqueActionType('[dictionary/brand] Load list success'),
  requestClear: uniqueActionType('[dictionary/brand] Load list clear'),
  changeParams: uniqueActionType('[dictionary/brand] Load list change params'),
  clearParams: uniqueActionType('[dictionary/brand] Load list clear params'),
  clearIds: uniqueActionType('[dictionary/brand] Load list clear ids')
};

export class DictionaryBrandLoadListAction implements PayloadAction {
  readonly type = DictionaryBrandLoadListActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class DictionaryBrandLoadListFailAction implements PayloadAction {
  readonly type = DictionaryBrandLoadListActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class DictionaryBrandLoadListSuccessAction implements PayloadAction {
  readonly type = DictionaryBrandLoadListActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedList>) {
  }
}

export class DictionaryBrandLoadListClearAction implements PayloadAction {
  readonly type = DictionaryBrandLoadListActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}

export class DictionaryBrandLoadListChangeParamsAction implements PayloadAction {
  readonly type = DictionaryBrandLoadListActionTypes.changeParams;

  constructor(public payload: ChangeParamsByIdPayload<DictionaryBrandListParams>) {
  }
}

export class DictionaryBrandLoadListClearParamsAction implements PayloadAction {
  readonly type = DictionaryBrandLoadListActionTypes.clearParams;

  constructor(public payload: ByIdPayload) {
  }
}


export class DictionaryBrandLoadListClearIdsAction implements PayloadAction {
  readonly type = DictionaryBrandLoadListActionTypes.clearIds;

  constructor(public payload: ByIdPayload) {
  }
}
