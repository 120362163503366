import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import {
  CARBON_COMMISSION_TASK_REDUCER_TOKEN,
  carbonCommissionTaskReducerProvider,
  rootReducerKey
} from './commission-task.reducer';
import { EffectsModule } from '@ngrx/effects';
import { CarbonCommissionTaskEffects } from './commission-task.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(rootReducerKey, CARBON_COMMISSION_TASK_REDUCER_TOKEN),
    EffectsModule.forFeature([CarbonCommissionTaskEffects])
  ],
  providers: [
    carbonCommissionTaskReducerProvider
  ]
})
export class CarbonCommissionTaskStoreModule { }
