import {
  ByIdPayload,
  FailByIdPayload,
  ListReducerTypes,
  NormalizedList,
  PayloadAction,
  RemoveIdByIdPayload,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';

export const ItemImageAddDoneListActionTypes: ListReducerTypes = {
  requestFail: uniqueActionType('[item/image] Add done list fail'),
  requestSuccess: uniqueActionType('[item/image] Add done list success'),
  requestClearErrors: uniqueActionType('[item/image] Add done list clear errors'),
  requestClear: uniqueActionType('[item/image] Add done list clear'),
  removeId: uniqueActionType('[item/image] Add done list remove id')
};

export class ItemImageAddDoneListFailAction implements PayloadAction {
  readonly type = ItemImageAddDoneListActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class ItemImageAddDoneListSuccessAction implements PayloadAction {
  readonly type = ItemImageAddDoneListActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedList>) {
  }
}

export class ItemImageAddDoneListClearErrorsAction implements PayloadAction {
  readonly type = ItemImageAddDoneListActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class ItemImageAddDoneListClearAction implements PayloadAction {
  readonly type = ItemImageAddDoneListActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}

export class ItemImageAddDoneListRemoveIdAction implements PayloadAction {
  readonly type = ItemImageAddDoneListActionTypes.removeId;

  constructor(public payload: RemoveIdByIdPayload) {
  }
}
