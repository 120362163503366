import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';

export const OrderSentActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[order-sent] Sent'),
  requestFail: uniqueActionType('[order-sent] Sent fail'),
  requestSuccess: uniqueActionType('[order-sent] Sent success'),
  requestClearErrors: uniqueActionType('[order-sent] Sent clear errors'),
  requestClear: uniqueActionType('[order-sent] Sent clear')
};

export class OrderSentAction implements PayloadAction {
  readonly type = OrderSentActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class OrderSentFailAction implements PayloadAction {
  readonly type = OrderSentActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class OrderSentSuccessAction implements PayloadAction {
  readonly type = OrderSentActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class OrderSentClearErrorsAction implements PayloadAction {
  readonly type = OrderSentActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class OrderSentClearAction implements PayloadAction {
  readonly type = OrderSentActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
