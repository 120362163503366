import {
  ByIdPayload,
  FailByIdPayload,
  OperationReducerTypes,
  PayloadAction,
  uniqueActionType
} from '@industi/ngx-common';

export const DiscountGroupDeleteActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[discount-group] Delete'),
  requestFail: uniqueActionType('[discount-group] Delete fail'),
  requestSuccess: uniqueActionType('[discount-group] Delete success'),
  requestClearErrors: uniqueActionType('[discount-group] Delete clear errors'),
  requestClear: uniqueActionType('[discount-group] Delete clear')
};

export class DiscountGroupDeleteAction implements PayloadAction {
  readonly type = DiscountGroupDeleteActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class DiscountGroupDeleteFailAction implements PayloadAction {
  readonly type = DiscountGroupDeleteActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class DiscountGroupDeleteSuccessAction implements PayloadAction {
  readonly type = DiscountGroupDeleteActionTypes.requestSuccess;

  constructor(public payload: ByIdPayload) {
  }
}

export class DiscountGroupDeleteClearErrorsAction implements PayloadAction {
  readonly type = DiscountGroupDeleteActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class DiscountGroupDeleteClearAction implements PayloadAction {
  readonly type = DiscountGroupDeleteActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
