import {
  ByIdPayload,
  ChangeParamsByIdPayload,
  FailByIdPayload,
  ListReducerTypes,
  NormalizedList,
  PayloadAction,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { CarbonFormListParams } from '../models/form-list-params';

export const CarbonFormLoadListActionTypes: ListReducerTypes = {
  request: uniqueActionType('[carbon/form] Load list'),
  requestFail: uniqueActionType('[carbon/form] Load list fail'),
  requestSuccess: uniqueActionType('[carbon/form] Load list success'),
  requestClear: uniqueActionType('[carbon/form] Load list clear'),
  changeParams: uniqueActionType('[carbon/form] Load list change params'),
  clearParams: uniqueActionType('[carbon/form] Load list clear params'),
  clearIds: uniqueActionType('[carbon/form] Load list clear ids')
};

export class CarbonFormLoadListAction implements PayloadAction {
  readonly type = CarbonFormLoadListActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class CarbonFormLoadListFailAction implements PayloadAction {
  readonly type = CarbonFormLoadListActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class CarbonFormLoadListSuccessAction implements PayloadAction {
  readonly type = CarbonFormLoadListActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedList>) {
  }
}

export class CarbonFormLoadListClearAction implements PayloadAction {
  readonly type = CarbonFormLoadListActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}

export class CarbonFormLoadListChangeParamsAction implements PayloadAction {
  readonly type = CarbonFormLoadListActionTypes.changeParams;

  constructor(public payload: ChangeParamsByIdPayload<CarbonFormListParams>) {
  }
}

export class CarbonFormLoadListClearParamsAction implements PayloadAction {
  readonly type = CarbonFormLoadListActionTypes.clearParams;

  constructor(public payload: ByIdPayload) {
  }
}


export class CarbonFormLoadListClearIdsAction implements PayloadAction {
  readonly type = CarbonFormLoadListActionTypes.clearIds;

  constructor(public payload: ByIdPayload) {
  }
}
