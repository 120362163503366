import { ByKey, listByIdReducer, ListState, operationByIdReducer, SingleState } from '@industi/ngx-common';
import { ActionReducer, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import { InjectionToken, Provider } from '@angular/core';
import { CarbonPartCommissionLoadListActionTypes } from './actions/list.actions';
import { CarbonPartCommissionLoadDetailsActionTypes } from './actions/load-details.actions';
import { CarbonPartCommissionAddActionTypes } from './actions/add.actions';
import { CarbonPartCommissionEditActionTypes } from './actions/edit.actions';
import { CarbonPartCommissionDeleteActionTypes } from './actions/delete.actions';

export const rootReducerKey = 'partCommission';

export interface CarbonPartCommissionOperationByIdState {
  add: SingleState;
  edit: SingleState;
  details: SingleState;
  delete: SingleState;
}

export interface CarbonPartCommissionState {
  operationsById: ByKey<CarbonPartCommissionOperationByIdState>;
  list: ByKey<ListState>;
}

const initialState: CarbonPartCommissionState = {
  operationsById: {},
  list: {}
};

const reducer: ActionReducer<CarbonPartCommissionState> = combineReducers<CarbonPartCommissionState>({
  operationsById: operationByIdReducer<CarbonPartCommissionOperationByIdState>({
    mapActionToId: (a) => a && a.payload && a.payload.id,
    operations: {
      add: CarbonPartCommissionAddActionTypes,
      edit: CarbonPartCommissionEditActionTypes,
      details: CarbonPartCommissionLoadDetailsActionTypes,
      delete: CarbonPartCommissionDeleteActionTypes
    }
  }),
  list: listByIdReducer({ types: CarbonPartCommissionLoadListActionTypes })
}, initialState);

export function getReducers(): ActionReducer<CarbonPartCommissionState> {
  return reducer;
}

export const CARBON_PART_COMMISSION_REDUCER_TOKEN = new InjectionToken<ActionReducer<CarbonPartCommissionState>>(rootReducerKey);

export const carbonPartCommissionReducerProvider: Provider = { provide: CARBON_PART_COMMISSION_REDUCER_TOKEN, useFactory: getReducers };

const getState = createFeatureSelector<CarbonPartCommissionState>(rootReducerKey);

export const getCarbonPartCommissionOperationsById = createSelector(
  getState,
  (state: CarbonPartCommissionState) => state && state.operationsById
);

export const getCarbonPartCommissionListState = createSelector(
  getState,
  (state: CarbonPartCommissionState) => state && state.list
);
