import { createSelector } from '@ngrx/store';
import {
  ByKey,
  createStateErrorsSelector,
  createStateLoadingSelector,
  createStateSuccessSelector,
  IdType
} from '@industi/ngx-common';
import { denormalize } from 'normalizr';
import { EntityTypes } from '../../../entity/entity-types';
import { createEntitiesSelector } from '../../../entity/entities.reducer';
import { Entities } from '../../../entity/entities';
import { EntityState } from '../../../entity/entity.reducer';
import {
  CarbonFormCommissionOperationByIdState,
  getCarbonFormCommissionOperationsById
} from '../form-commission.reducer';
import { CarbonFormCommission } from '../models/form-commission';

const createState = (id: IdType) => createSelector(
  getCarbonFormCommissionOperationsById,
  (state: ByKey<CarbonFormCommissionOperationByIdState>) => state && state[id] && state[id].details
);

export const createCarbonFormCommissionDetailsLoading = (id: IdType) => createStateLoadingSelector(createState(id));
export const createCarbonFormCommissionDetailsErrors = (id: IdType) => createStateErrorsSelector(createState(id));
export const createCarbonFormCommissionDetailsSuccess = (id: IdType) => createStateSuccessSelector(createState(id));

export const createCarbonFormCommissionDetails = (id: IdType) => createSelector(
  createEntitiesSelector([
    EntityTypes.carbonFormCommission,
    EntityTypes.item
  ]),
  (entities: EntityState) =>
    denormalize(id, Entities[EntityTypes.carbonFormCommission], entities) as CarbonFormCommission
);

export const createCarbonFormCommissionTasksList = (id: IdType) => createSelector(
  createCarbonFormCommissionDetails(id),
  (details: CarbonFormCommission) => details?.tasks
);
