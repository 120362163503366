import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SendByIdPayload,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { ServiceProjectOperationPayload } from '../models/operation-payload';

export const ServiceProjectOperationEditActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[service/project-operation] Edit'),
  requestFail: uniqueActionType('[service/project-operation] Edit fail'),
  requestSuccess: uniqueActionType('[service/project-operation] Edit success'),
  requestClearErrors: uniqueActionType('[service/project-operation] Edit clear errors'),
  requestClear: uniqueActionType('[service/project-operation] Edit clear')
};

export class ServiceProjectOperationEditAction implements PayloadAction {
  readonly type = ServiceProjectOperationEditActionTypes.request;

  constructor(public payload: SendByIdPayload<ServiceProjectOperationPayload>) {
  }
}

export class ServiceProjectOperationEditFailAction implements PayloadAction {
  readonly type = ServiceProjectOperationEditActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class ServiceProjectOperationEditSuccessAction implements PayloadAction {
  readonly type = ServiceProjectOperationEditActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class ServiceProjectOperationEditClearErrorsAction implements PayloadAction {
  readonly type = ServiceProjectOperationEditActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class ServiceProjectOperationEditClearAction implements PayloadAction {
  readonly type = ServiceProjectOperationEditActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
