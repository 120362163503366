import {
  ByIdAndContext,
  FailByIdAndContextPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SuccessByIdAndContextPayload,
  uniqueActionType
} from '@industi/ngx-common';

export const EbayLoadCompatibilityPropertyValuesActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[ebay-compatibility/value] Load compatibility values'),
  requestFail: uniqueActionType('[ebay-compatibility/value] Load compatibility values fail'),
  requestSuccess: uniqueActionType('[ebay-compatibility/value] Load compatibility values success'),
  requestClearErrors: uniqueActionType('[ebay-compatibility/value] Load compatibility values clear errors'),
  requestClear: uniqueActionType('[ebay-compatibility/value] Load compatibility values clear')
};

export class EbayLoadCompatibilityPropertyValuesAction implements PayloadAction {
  readonly type = EbayLoadCompatibilityPropertyValuesActionTypes.request;

  constructor(public payload: ByIdAndContext) {
  }
}

export class EbayLoadCompatibilityPropertyValuesFailAction implements PayloadAction {
  readonly type = EbayLoadCompatibilityPropertyValuesActionTypes.requestFail;

  constructor(public payload: FailByIdAndContextPayload) {
  }
}

export class EbayLoadCompatibilityPropertyValuesSuccessAction implements PayloadAction {
  readonly type = EbayLoadCompatibilityPropertyValuesActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdAndContextPayload<NormalizedSingle>) {
  }
}

export class EbayLoadCompatibilityPropertyValuesClearAction implements PayloadAction {
  readonly type = EbayLoadCompatibilityPropertyValuesActionTypes.requestClear;

  constructor(public payload: ByIdAndContext) {
  }
}

export class EbayLoadCompatibilityPropertyValuesClearErrorsAction implements PayloadAction {
  readonly type = EbayLoadCompatibilityPropertyValuesActionTypes.requestClearErrors;

  constructor(public payload: ByIdAndContext) {
  }
}
