import { IdType, SetStateAdapter } from '@industi/ngx-common';
import { Injectable } from '@angular/core';
import { Action, Selector } from '@ngrx/store';
import { ServiceProjectClearLastAddedIdAction, ServiceProjectSelectLastAddedIdAction } from '../actions/select-last-added-id.actions';
import { getServiceProjectLastAddedId } from '../common.reducer';

@Injectable({ providedIn: 'root' })
export class ServiceProjectSetLastAddedAdapterService extends SetStateAdapter<IdType> {

  clearAction(): Action {
    return new ServiceProjectClearLastAddedIdAction();
  }

  getValueSelector(): Selector<any, IdType> {
    return getServiceProjectLastAddedId;
  }

  setValueAction(value: IdType): Action {
    return new ServiceProjectSelectLastAddedIdAction({ value });
  }

}
