import { ByKey, listByIdReducer, ListState, operationByIdReducer, SingleState } from '@industi/ngx-common';
import { ActionReducer, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import { InjectionToken, Provider } from '@angular/core';
import { ServiceProjectOperationEditActionTypes } from './actions/edit.actions';
import { ServiceProjectOperationLoadListActionTypes } from './actions/list.actions';
import { ServiceProjectOperationLoadDetailsActionTypes } from './actions/details.actions';
import { ServiceProjectOperationAddActionTypes } from './actions/add.actions';
import { ServiceProjectOperationDeleteActionTypes } from './actions/delete.actions';

export const rootReducerKey = 'projectOperation';

export interface ServiceProjectOperationOperationByIdState {
  add: SingleState;
  edit: SingleState;
  delete: SingleState;
  details: SingleState;
}

export interface ServiceProjectOperationState {
  operationsById: ByKey<ServiceProjectOperationOperationByIdState>;
  list: ByKey<ListState>;
}

const initialState: ServiceProjectOperationState = {
  operationsById: {},
  list: {}
};

const reducer: ActionReducer<ServiceProjectOperationState> = combineReducers<ServiceProjectOperationState>({
  operationsById: operationByIdReducer<ServiceProjectOperationOperationByIdState>({
    mapActionToId: (a) => a && a.payload && a.payload.id,
    operations: {
      add: ServiceProjectOperationAddActionTypes,
      edit: ServiceProjectOperationEditActionTypes,
      delete: ServiceProjectOperationDeleteActionTypes,
      details: ServiceProjectOperationLoadDetailsActionTypes
    }
  }),
  list: listByIdReducer({ types: ServiceProjectOperationLoadListActionTypes })
}, initialState);

export function getReducers(): ActionReducer<ServiceProjectOperationState> {
  return reducer;
}

export const SERVICE_PROJECT_OPERATION_REDUCER_TOKEN = new InjectionToken<ActionReducer<ServiceProjectOperationState>>(rootReducerKey);

export const serviceProjectOperationReducerProvider: Provider = {
  provide: SERVICE_PROJECT_OPERATION_REDUCER_TOKEN,
  useFactory: getReducers
};

const getState = createFeatureSelector<ServiceProjectOperationState>(rootReducerKey);

export const getServiceProjectOperationOperationsById = createSelector(
  getState,
  (state: ServiceProjectOperationState) => state && state.operationsById
);

export const getServiceProjectOperationListState = createSelector(
  getState,
  (state: ServiceProjectOperationState) => state && state.list
);
