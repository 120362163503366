import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SendByIdPayload,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { CarbonFormCommissionPayload } from '../models/form-commission-payload';

export const CarbonFormCommissionEditActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[carbon/form-commission] Edit'),
  requestFail: uniqueActionType('[carbon/form-commission] Edit fail'),
  requestSuccess: uniqueActionType('[carbon/form-commission] Edit success'),
  requestClearErrors: uniqueActionType('[carbon/form-commission] Edit clear errors'),
  requestClear: uniqueActionType('[carbon/form-commission] Edit clear')
};

export class CarbonFormCommissionEditAction implements PayloadAction {
  readonly type = CarbonFormCommissionEditActionTypes.request;

  constructor(public payload: SendByIdPayload<CarbonFormCommissionPayload>) {
  }
}

export class CarbonFormCommissionEditFailAction implements PayloadAction {
  readonly type = CarbonFormCommissionEditActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class CarbonFormCommissionEditSuccessAction implements PayloadAction {
  readonly type = CarbonFormCommissionEditActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class CarbonFormCommissionEditClearErrorsAction implements PayloadAction {
  readonly type = CarbonFormCommissionEditActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class CarbonFormCommissionEditClearAction implements PayloadAction {
  readonly type = CarbonFormCommissionEditActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
