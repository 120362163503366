import {
  ByIdPayload,
  FailByIdPayload,
  OperationReducerTypes,
  PayloadAction,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { OutputTokenResponse } from '../../auth/models/output-token-response';

export const EbayLoadTokenActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[ebay-token] Load token'),
  requestFail: uniqueActionType('[ebay-token] Load token fail'),
  requestSuccess: uniqueActionType('[ebay-token] Load token success'),
  requestClearErrors: uniqueActionType('[ebay-token] Load token clear errors'),
  requestClear: uniqueActionType('[ebay-token] Load token clear')
};

export class EbayLoadTokenAction implements PayloadAction {
  readonly type = EbayLoadTokenActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class EbayLoadTokenFailAction implements PayloadAction {
  readonly type = EbayLoadTokenActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class EbayLoadTokenSuccessAction implements PayloadAction {
  readonly type = EbayLoadTokenActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<OutputTokenResponse>) {
  }
}

export class EbayLoadTokenClearAction implements PayloadAction {
  readonly type = EbayLoadTokenActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}

export class EbayLoadTokenClearErrorsAction implements PayloadAction {
  readonly type = EbayLoadTokenActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}
