import { createSelector } from '@ngrx/store';
import { denormalize } from 'normalizr';
import {
  ByKey,
  createListStateIdsSelector,
  createListStateParamsSelector,
  createStateErrorsSelector,
  createStateLoadingSelector,
  createStateSuccessSelector,
  IdType,
  ListState
} from '@industi/ngx-common';
import { EntityTypes } from '../../entity/entity-types';
import { getWarehouseListState } from '../warehouse.reducer';
import { createEntitiesSelector } from '../../entity/entities.reducer';
import { createSymfonyTotalCountSelector } from '../../symfony/list/selectors/list.selectors';
import { Entities } from '../../entity/entities';
import { EntityState } from '../../entity/entity.reducer';
import { Warehouse } from '../models/warehouse';

const createState = (id: IdType) => createSelector(
  getWarehouseListState,
  (state: ByKey<ListState>) => state && state[id]
);

const createStateByContext = (id: IdType) => createListStateIdsSelector(createState(id));

export const createWarehouseListData = (id: IdType) => createSelector(
  createStateByContext(id),
  createEntitiesSelector([
    EntityTypes.warehouse
  ]),
  (
    ids: IdType[],
    entities: EntityState
  ) => denormalize(ids, [Entities[EntityTypes.warehouse]], entities) as Warehouse[]
);

export const createWarehouseListLoading = (id: IdType) => createStateLoadingSelector(
  createState(id));

export const createWarehouseListSuccess = (id: IdType) => createStateSuccessSelector(
  createState(id));

export const createWarehouseListErrors = (id: IdType) => createStateErrorsSelector(
  createState(id));

export const createWarehouseListTotalCount = (id: IdType) => createSymfonyTotalCountSelector(
  createState(id));

export const createWarehouseListParams = (id: IdType) => createListStateParamsSelector(
  createState(id));
