import { Injectable } from '@angular/core';
import { IdType, ListStateToComponentAdapter } from '@industi/ngx-common';
import { Action, Selector } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import * as _ from 'lodash';
import { ItemListParams } from '../models/item-list-params';
import {
  ItemLoadListAction,
  ItemLoadListChangeParamsAction,
  ItemLoadListClearAction,
  ItemLoadListClearIdsAction,
  ItemLoadListClearParamsAction
} from '../actions/list.actions';
import { AppGlobal } from '../../../../shared/app-global';
import { Item } from '../models/item';
import {
  createItemListData,
  createItemListErrors,
  createItemListLoading,
  createItemListParams,
  createItemListSuccess,
  createItemListTotalCount
} from '../selectors/get-load-list.selectors';

@Injectable({
  providedIn: 'root'
})
export class ItemListAdapterService
  extends ListStateToComponentAdapter<Item, ItemListParams> {

  apiItemToTableItem(apiModel: Item): Item {
    return apiModel;
  }

  changeParamsAction(id: IdType, params: ItemListParams): Action {
    return new ItemLoadListChangeParamsAction({ id, params });
  }

  clearAction(id: IdType): Action {
    return new ItemLoadListClearAction({ id });
  }

  clearIdsAction(id: IdType): Action {
    return new ItemLoadListClearIdsAction({ id });
  }

  clearParamsAction(id: IdType): Action {
    return new ItemLoadListClearParamsAction({ id });
  }

  getDataSelector(id: IdType): Selector<any, Item[]> {
    return createItemListData(id);
  }

  getErrorsSelector(id: IdType): Selector<any, HttpErrorResponse> {
    return createItemListErrors(id);
  }

  getLoadingSelector(id: IdType): Selector<any, boolean> {
    return createItemListLoading(id);
  }

  getParamsSelector(id: IdType): Selector<any, ItemListParams> {
    return createItemListParams(id);
  }

  getSuccessSelector(id: IdType): Selector<any, boolean> {
    return createItemListSuccess(id);
  }

  getTotalCountSelector(id: IdType): Selector<any, number> {
    return createItemListTotalCount(id);
  }

  loadListAction(id: IdType): Action {
    return new ItemLoadListAction({ id });
  }

  tableParamsToApiParams(tableParams: ItemListParams): ItemListParams {
    return _.omitBy({
      ...tableParams,
      page: tableParams?.page || AppGlobal.listParams.page,
    } as ItemListParams, _.isNil);
  }

}
