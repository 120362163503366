import { Inject, Injectable } from '@angular/core';
import { API_URL } from '../../app-store-providers';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { NormalizedSingle } from '@industi/ngx-common';
import { map } from 'rxjs/operators';
import { normalize } from 'normalizr';
import { Entities } from '../../entity/entities';
import { EntityTypes } from '../../entity/entity-types';
import { ItemListParams } from './models/item-list-params';
import { Item } from './models/item';
import { EncodeUtil } from '../../../shared/utils/encode.util';
import { ItemPayload } from './models/item-payload';
import { FilesPayload } from '../../common/models/files-payload';
import { AppFile } from '../../../shared/models/file';
import { StoreBaseService } from '../../common/base.service';

@Injectable({
  providedIn: 'root'
})
export class ItemCommonService
  extends StoreBaseService<Item, ItemPayload, ItemListParams> {

  get entityType(): string {
    return EntityTypes.item;
  }

  get resourceName(): string {
    return 'item';
  }

  constructor(
    @Inject(API_URL) apiUrl: string,
    http: HttpClient
  ) {
    super(apiUrl, http);
  }

  add(locale: string, data: FilesPayload<ItemPayload>): Observable<NormalizedSingle> {
    const url = this.getDictionaryAddPath(locale, this.resourceName);
    return this.http.post<Item>(url, EncodeUtil.prepareMultipartFormData(data)).pipe(
      map((res: Item) => normalize(res, Entities[EntityTypes.item]))
    );
  }

  pdf(locale: string): Observable<AppFile> {
    const url = `${this.apiUrl}/${locale}/api/item-id/generate-pdf`;
    return this.http.post<Blob>(url, {
      numberOfIds: 120
    }, {
      responseType: 'blob' as 'json',
      observe: 'response'
    }).pipe(
      map((res: HttpResponse<Blob>) => ({ file: res.body }))
    );
  }
}
