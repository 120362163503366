import { ByKey, operationByIdReducer, SingleState } from '@industi/ngx-common';
import { ActionReducer, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import { InjectionToken, Provider } from '@angular/core';
import { ArticleGalleryContentEditActionTypes } from './actions/edit.actions';
import { ArticleGalleryContentAddActionTypes } from './actions/add.actions';
import { ArticleGalleryContentLoadDetailsActionTypes } from './actions/details.actions';

export const rootReducerKey = 'articleGalleryContent';

export interface ArticleGalleryContentOperationByIdState {
  add: SingleState;
  edit: SingleState;
  details: SingleState;
}

export interface ArticleGalleryContentState {
  operationsById: ByKey<ArticleGalleryContentOperationByIdState>;
}

const initialState: ArticleGalleryContentState = {
  operationsById: {}
};

const reducer: ActionReducer<ArticleGalleryContentState> = combineReducers<ArticleGalleryContentState>({
  operationsById: operationByIdReducer<ArticleGalleryContentOperationByIdState>({
    mapActionToId: (a) => a?.payload?.id,
    operations: {
      add: ArticleGalleryContentAddActionTypes,
      edit: ArticleGalleryContentEditActionTypes,
      details: ArticleGalleryContentLoadDetailsActionTypes,
    }
  })
}, initialState);

export function getReducers(): ActionReducer<ArticleGalleryContentState> {
  return reducer;
}

export const ARTICLE_GALLERY_CONTENT_REDUCER_TOKEN = new InjectionToken<ActionReducer<ArticleGalleryContentState>>(rootReducerKey);

export const articleGalleryContentReducerProvider: Provider = { provide: ARTICLE_GALLERY_CONTENT_REDUCER_TOKEN, useFactory: getReducers };

const getState = createFeatureSelector<ArticleGalleryContentState>(rootReducerKey);

export const getArticleGalleryContentOperationsById = createSelector(
  getState,
  (state: ArticleGalleryContentState) => state && state.operationsById
);
