import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { ByIdPayload, ByKey, IdType, NormalizedList, NormalizedSingle } from '@industi/ngx-common';
import { OfferCommonService } from './common.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  OfferLoadListAction,
  OfferLoadListActionTypes,
  OfferLoadListClearIdsAction,
  OfferLoadListFailAction,
  OfferLoadListSuccessAction
} from './actions/list.actions';
import { catchError, first, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { createOfferListParams } from './selectors/get-load-list.selectors';
import { OfferListParams } from './models/offer-list-params';
import { getInstanceLocale } from '../../instance/instance.reducer';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import { OfferAddAction, OfferAddActionTypes, OfferAddFailAction, OfferAddSuccessAction } from './actions/add.actions';
import {
  OfferEditAction,
  OfferEditActionTypes,
  OfferEditFailAction,
  OfferEditSuccessAction
} from './actions/edit.actions';
import {
  OfferLoadDetailsAction,
  OfferLoadDetailsActionTypes,
  OfferLoadDetailsFailAction,
  OfferLoadDetailsSuccessAction
} from './actions/load-details.actions';
import {
  OfferDeleteAction,
  OfferDeleteActionTypes,
  OfferDeleteFailAction,
  OfferDeleteSuccessAction
} from './actions/delete.actions';
import { ResponseStatus } from '../../../shared/enums/response-status.enum';
import { LibSnackbarComponent } from '@industi/ngx-modules';
import { AppGlobal } from '../../../shared/app-global';
import { AlertUtil } from '../../../shared/utils/alert.util';

@Injectable()
export class OfferCommonEffects {

  @Effect()
  loadList$ = this.actions$.pipe(
    ofType(OfferLoadListActionTypes.request),
    map((action: OfferLoadListAction) => action.payload),
    switchMap((payload: ByIdPayload) => this.store.pipe(
      select(createOfferListParams(payload.id)),
      map((params: OfferListParams) => [payload.id, params] as [IdType, OfferListParams]),
      first()
    )),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([[id, params], locale]) => this.service.loadList(locale, params).pipe(
      mergeMap((res: NormalizedList) => [
        new OfferLoadListClearIdsAction({ id }),
        new OfferLoadListSuccessAction({ id, res })]),
      catchError((errors: HttpErrorResponse) => of(new OfferLoadListFailAction({ id, errors })))
    ))
  );

  @Effect()
  add$ = this.actions$.pipe(
    ofType(OfferAddActionTypes.request),
    map((action: OfferAddAction) => action.payload),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([{ id, data }, locale]) => this.service.add(locale, data).pipe(
      map((res: NormalizedSingle) => new OfferAddSuccessAction({ res, id })),
      catchError((errors: HttpErrorResponse) => of(new OfferAddFailAction({ errors, id })))
    ))
  );

  @Effect()
  edit$ = this.actions$.pipe(
    ofType(OfferEditActionTypes.request),
    map((action: OfferEditAction) => action.payload),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([{ id, data }, locale]) => this.service.edit(locale, data, id).pipe(
      map((res: NormalizedSingle) => new OfferEditSuccessAction({ res, id })),
      catchError((errors: HttpErrorResponse) => of(new OfferEditFailAction({ errors, id })))
    ))
  );

  @Effect()
  loadDetails$ = this.actions$.pipe(
    ofType(OfferLoadDetailsActionTypes.request),
    map((action: OfferLoadDetailsAction) => action.payload.id),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([id, locale]) => this.service.details(locale, id).pipe(
      map((res: NormalizedSingle) => new OfferLoadDetailsSuccessAction({ res, id })),
      catchError((errors: HttpErrorResponse) => of(new OfferLoadDetailsFailAction({ errors, id })))
    ))
  );

  @Effect()
  delete$ = this.actions$.pipe(
    ofType(OfferDeleteActionTypes.request),
    map((action: OfferDeleteAction) => action.payload.id),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([id, locale]) => this.service.delete(locale, id).pipe(
      map(() => new OfferDeleteSuccessAction({ id })),
      catchError((errors: HttpErrorResponse) => of(new OfferDeleteFailAction({ errors, id })))
    ))
  );

  @Effect({ dispatch: false })
  crudFail$ = this.actions$.pipe(
    ofType(
      OfferAddActionTypes.requestFail,
      OfferEditActionTypes.requestFail
    ),
    map((action: OfferAddFailAction | OfferEditFailAction) => action.payload.errors),
    tap((data: HttpErrorResponse) => {
      if (data?.status === ResponseStatus.BAD_REQUEST || data?.status === ResponseStatus.UNPROCESSABLE_ENTITY) {
        this.snackbar.openFromComponent(LibSnackbarComponent, {
          data: {
            message: AlertUtil.getControlTranslation(data.error.detail),
            type: 'error'
          },
          duration: AppGlobal.defaultSnackBarDuration
        });
      }
    })
  );

  constructor(
    private actions$: Actions,
    private store: Store<ByKey>,
    private service: OfferCommonService,
    private snackbar: MatSnackBar
  ) {
  }
}
