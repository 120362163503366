import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_URL } from '../app-store-providers';
import { Observable } from 'rxjs';
import { NormalizedList } from '@industi/ngx-common';
import { map } from 'rxjs/operators';
import { normalize } from 'normalizr';
import { Entities } from '../entity/entities';
import { EntityTypes } from '../entity/entity-types';
import { TimelineItem } from './models/timeline-item';
import { TimelineListParams } from './models/timeline-list-params';
import { TimelineUtil } from './timeline.util';

@Injectable({
  providedIn: 'root'
})
export class TimelineService {

  constructor(
    private http: HttpClient,
    @Inject(API_URL) private apiUrl: string
  ) {
  }

  loadList(locale: string, params: TimelineListParams): Observable<NormalizedList> {
    const url = `${this.apiUrl}/${locale}/api/${params.type}/${params.itemId}/timeline`;
    return this.http.get<TimelineItem[]>(url).pipe(
      map((res: TimelineItem[]) => <NormalizedList>{
        ...normalize(
          TimelineUtil.entityTimelineToAppTimeline(res),
          [Entities[EntityTypes.timeline]]
        ),
        meta: {
          maxResults: res && res.length
        }
      })
    );
  }
}
