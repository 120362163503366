import { ByKey, operationByIdReducer, operationReducer, setValueReducer, SingleState } from '@industi/ngx-common';
import { ActionReducer, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import { InjectionToken, Provider } from '@angular/core';
import { EbayLoadConfigurationActionTypes } from './actions/configuration.actions';
import { EbayRefreshConfigurationActionTypes } from './actions/refresh-configuration.actions';
import { EbayLoadOfferConfigurationActionTypes } from './actions/configuration-offer.actions';
import { EbayAddConfigurationActionTypes } from './actions/configuration-add.actions';
import { EbayLoadTokenActionTypes } from './actions/token.actions';
import { EbaySetTokenActionTypes } from './actions/set-token.actions';
import { OutputTokenResponse } from '../auth/models/output-token-response';
import { EbayLoadCompatibilityPropertiesActionTypes } from './actions/load-compatibility-properties.actions';
import { EbayLoadCompatibilityPropertyValuesActionTypes } from './actions/load-compatibility-property-values.actions';

export const rootReducerKey = 'ebay';

export interface EbayOperationByIdState {
  configuration: SingleState;
  configurationAdd: SingleState;
  configurationRefresh: SingleState;
  offerConfiguration: SingleState;
  compatibilityProperties: SingleState;
}

export interface EbayState {
  operationsById: ByKey<EbayOperationByIdState>;
  token: ByKey<OutputTokenResponse>;
  loadToken: SingleState;
  compatibilityPropertyValues: SingleState;
}

const initialState: EbayState = {
  operationsById: {},
  token: null,
  loadToken: {},
  compatibilityPropertyValues: {}
};

const reducer: ActionReducer<EbayState> = combineReducers<EbayState>({
  operationsById: operationByIdReducer<EbayOperationByIdState>({
    mapActionToId: (a) => a?.payload?.id,
    operations: {
      configuration: EbayLoadConfigurationActionTypes,
      configurationAdd: EbayAddConfigurationActionTypes,
      configurationRefresh: EbayRefreshConfigurationActionTypes,
      offerConfiguration: EbayLoadOfferConfigurationActionTypes,
      compatibilityProperties: EbayLoadCompatibilityPropertiesActionTypes,
    }
  }),
  token: setValueReducer({ types: EbaySetTokenActionTypes }),
  loadToken: operationReducer(EbayLoadTokenActionTypes),
  compatibilityPropertyValues: operationReducer(EbayLoadCompatibilityPropertyValuesActionTypes)
}, initialState);

export function getReducers(): ActionReducer<EbayState> {
  return reducer;
}

export const EBAY_REDUCER_TOKEN = new InjectionToken<ActionReducer<EbayState>>(rootReducerKey);

export const ebayReducerProvider: Provider = { provide: EBAY_REDUCER_TOKEN, useFactory: getReducers };

const getState = createFeatureSelector<EbayState>(rootReducerKey);

export const getEbayOperationsById = createSelector(
  getState,
  (state: EbayState) => state && state.operationsById
);

export const getEbayToken = createSelector(
  getState,
  (state: EbayState) => state && state.token
);
