<mat-table [dataSource]="tableData"
           libTableList>

<!--  [ngClass]="tableClass"-->
  <ng-container [matColumnDef]="columnNames?.currency">
    <mat-header-cell *matHeaderCellDef></mat-header-cell>
    <mat-cell *matCellDef="let element"><b class="font-weight-bold">{{ element?.currency }}</b></mat-cell>
  </ng-container>

  <ng-container [matColumnDef]="columnNames?.retailPrice">
    <mat-header-cell *matHeaderCellDef>Cena detal</mat-header-cell>
    <mat-cell *matCellDef="let element">{{ element?.retailPrice | currency: element?.currency : 'symbol' }}</mat-cell>
  </ng-container>

  <ng-container [matColumnDef]="columnNames?.purchasePrice">
    <mat-header-cell *matHeaderCellDef>Cena zakupu</mat-header-cell>
    <mat-cell *matCellDef="let element">{{ element?.purchasePrice | currency: element?.currency : 'symbol' }}</mat-cell>
  </ng-container>

  <ng-container [matColumnDef]="columnNames?.sellingPrice">
    <mat-header-cell *matHeaderCellDef>Cena sprzedaży</mat-header-cell>
    <mat-cell *matCellDef="let element">{{ element?.sellingPrice | currency: element?.currency : 'symbol' }}</mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>
