import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { CommonModule } from '@angular/common';
import {
  ARTICLE_GALLERY_CONTENT_REDUCER_TOKEN,
  articleGalleryContentReducerProvider,
  rootReducerKey
} from './gallery-content.reducer';
import { ArticleGalleryContentEffects } from './gallery-content.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(rootReducerKey, ARTICLE_GALLERY_CONTENT_REDUCER_TOKEN),
    EffectsModule.forFeature([ArticleGalleryContentEffects])
  ],
  providers: [
    articleGalleryContentReducerProvider
  ]
})
export class ArticleGalleryContentStoreModule {
}
