import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SendByIdPayload,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { DiagramPartPayload } from '../models/part-payload';

export const DiagramPartAddActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[diagram/part] Add'),
  requestFail: uniqueActionType('[diagram/part] Add fail'),
  requestSuccess: uniqueActionType('[diagram/part] Add success'),
  requestClearErrors: uniqueActionType('[diagram/part] Add clear errors'),
  requestClear: uniqueActionType('[diagram/part] Add clear'),
};

export class DiagramPartAddAction implements PayloadAction {
  readonly type = DiagramPartAddActionTypes.request;

  constructor(public payload: SendByIdPayload<DiagramPartPayload>) {
  }
}

export class DiagramPartAddFailAction implements PayloadAction {
  readonly type = DiagramPartAddActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class DiagramPartAddSuccessAction implements PayloadAction {
  readonly type = DiagramPartAddActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class DiagramPartAddClearErrorsAction implements PayloadAction {
  readonly type = DiagramPartAddActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class DiagramPartAddClearAction implements PayloadAction {
  readonly type = DiagramPartAddActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
