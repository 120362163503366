import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import {
  DISCOUNT_GROUP_REDUCER_TOKEN,
  discountGroupReducerProvider,
  rootReducerKey
} from './discount-group.reducer';
import { EffectsModule } from '@ngrx/effects';
import { DiscountGroupEffects } from './discount-group.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(rootReducerKey, DISCOUNT_GROUP_REDUCER_TOKEN),
    EffectsModule.forFeature([
      DiscountGroupEffects
    ])
  ],
  providers: [
    discountGroupReducerProvider
  ]
})
export class DiscountGroupStoreModule { }
