import { createSelector } from '@ngrx/store';
import { denormalize } from 'normalizr';
import {
  ByKey,
  createListStateIdsSelector,
  createListStateParamsSelector,
  createStateErrorsSelector,
  createStateLoadingSelector,
  createStateSuccessSelector,
  IdType,
  ListState
} from '@industi/ngx-common';
import { EntityState } from '../../../entity/entity.reducer';
import { EntityTypes } from '../../../entity/entity-types';
import { createEntitiesSelector } from '../../../entity/entities.reducer';
import { createSymfonyTotalCountSelector } from '../../../symfony/list/selectors/list.selectors';
import { Entities } from '../../../entity/entities';
import { CarbonForm } from '../models/form';
import { getCarbonFormListState } from '../form.reducer';

const createState = (id: IdType) => createSelector(
  getCarbonFormListState,
  (state: ByKey<ListState>) => state && state[id]
);

const createStateByContext = (id: IdType) => createListStateIdsSelector(createState(id));

export const createCarbonFormListData = (id: IdType) => createSelector(
  createStateByContext(id),
  createEntitiesSelector([
    EntityTypes.carbonForm
  ]),
  (
    ids: IdType[],
    entities: EntityState
  ) => denormalize(ids, [Entities[EntityTypes.carbonForm]], entities) as CarbonForm[]
);

export const createCarbonFormListDataByIds = (ids: IdType[]) => createSelector(
  createEntitiesSelector([
    EntityTypes.carbonForm
  ]),
  (
    entities: EntityState
  ) => denormalize(ids, [Entities[EntityTypes.carbonForm]], entities) as CarbonForm[]
);

export const createCarbonFormListLoading = (id: IdType) => createStateLoadingSelector(
  createState(id));

export const createCarbonFormListSuccess = (id: IdType) => createStateSuccessSelector(
  createState(id));

export const createCarbonFormListErrors = (id: IdType) => createStateErrorsSelector(
  createState(id));

export const createCarbonFormListTotalCount = (id: IdType) => createSymfonyTotalCountSelector(
  createState(id));

export const createCarbonFormListParams = (id: IdType) => createListStateParamsSelector(
  createState(id));
