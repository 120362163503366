import { ByKey, listByIdReducer, ListState, operationByIdReducer, SingleState } from '@industi/ngx-common';
import { ActionReducer, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import { InjectionToken, Provider } from '@angular/core';
import { CarbonFormLoadListActionTypes } from './actions/list.actions';
import { CarbonFormLoadDetailsActionTypes } from './actions/load-details.actions';
import { CarbonFormAddActionTypes } from './actions/add.actions';
import { CarbonFormEditActionTypes } from './actions/edit.actions';
import { CarbonFormDeleteActionTypes } from './actions/delete.actions';

export const rootReducerKey = 'form';

export interface CarbonFormOperationByIdState {
  add: SingleState;
  edit: SingleState;
  details: SingleState;
  delete: SingleState;
}

export interface CarbonFormState {
  operationsById: ByKey<CarbonFormOperationByIdState>;
  list: ByKey<ListState>;
}

const initialState: CarbonFormState = {
  operationsById: {},
  list: {}
};

const reducer: ActionReducer<CarbonFormState> = combineReducers<CarbonFormState>({
  operationsById: operationByIdReducer<CarbonFormOperationByIdState>({
    mapActionToId: (a) => a && a.payload && a.payload.id,
    operations: {
      add: CarbonFormAddActionTypes,
      edit: CarbonFormEditActionTypes,
      details: CarbonFormLoadDetailsActionTypes,
      delete: CarbonFormDeleteActionTypes
    }
  }),
  list: listByIdReducer({ types: CarbonFormLoadListActionTypes })
}, initialState);

export function getReducers(): ActionReducer<CarbonFormState> {
  return reducer;
}

export const CARBON_FORM_REDUCER_TOKEN = new InjectionToken<ActionReducer<CarbonFormState>>(rootReducerKey);

export const carbonFormReducerProvider: Provider = { provide: CARBON_FORM_REDUCER_TOKEN, useFactory: getReducers };

const getState = createFeatureSelector<CarbonFormState>(rootReducerKey);

export const getCarbonFormOperationsById = createSelector(
  getState,
  (state: CarbonFormState) => state && state.operationsById
);

export const getCarbonFormListState = createSelector(
  getState,
  (state: CarbonFormState) => state && state.list
);
