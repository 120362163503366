import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SendByIdPayload,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { OfferPayload } from '../models/offer-payload';

export const OfferEditActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[offer] Edit'),
  requestFail: uniqueActionType('[offer] Edit fail'),
  requestSuccess: uniqueActionType('[offer] Edit success'),
  requestClearErrors: uniqueActionType('[offer] Edit clear errors'),
  requestClear: uniqueActionType('[offer] Edit clear')
};

export class OfferEditAction implements PayloadAction {
  readonly type = OfferEditActionTypes.request;

  constructor(public payload: SendByIdPayload<OfferPayload>) {
  }
}

export class OfferEditFailAction implements PayloadAction {
  readonly type = OfferEditActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class OfferEditSuccessAction implements PayloadAction {
  readonly type = OfferEditActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class OfferEditClearErrorsAction implements PayloadAction {
  readonly type = OfferEditActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class OfferEditClearAction implements PayloadAction {
  readonly type = OfferEditActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
