import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { ByKey, NormalizedSingle } from '@industi/ngx-common';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { getInstanceLocale } from '../../instance/instance.reducer';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  ArticleTextContentEditAction,
  ArticleTextContentEditActionTypes,
  ArticleTextContentEditFailAction,
  ArticleTextContentEditSuccessAction
} from './actions/edit.actions';
import {
  ArticleTextContentLoadDetailsAction,
  ArticleTextContentLoadDetailsActionTypes,
  ArticleTextContentLoadDetailsFailAction,
  ArticleTextContentLoadDetailsSuccessAction
} from './actions/details.actions';
import {
  ArticleTextContentAddAction,
  ArticleTextContentAddActionTypes,
  ArticleTextContentAddFailAction,
  ArticleTextContentAddSuccessAction
} from './actions/add.actions';
import { LibSnackbarComponent } from '@industi/ngx-modules';
import { ResponseStatus } from '../../../shared/enums/response-status.enum';
import { AlertUtil } from '../../../shared/utils/alert.util';
import { AppGlobal } from '../../../shared/app-global';
import { ArticleTextContentService } from './text-content.service';

@Injectable()
export class ArticleTextContentEffects {

  @Effect()
  add$ = this.actions$.pipe(
    ofType(ArticleTextContentAddActionTypes.request),
    map((action: ArticleTextContentAddAction) => action.payload),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([{ id, data }, locale]) => this.service.add(locale, data).pipe(
      map((res: NormalizedSingle) => new ArticleTextContentAddSuccessAction({ res, id })),
      catchError((errors: HttpErrorResponse) => of(new ArticleTextContentAddFailAction({ errors, id })))
    ))
  );

  @Effect()
  edit$ = this.actions$.pipe(
    ofType(ArticleTextContentEditActionTypes.request),
    map((action: ArticleTextContentEditAction) => action.payload),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([{ id, data }, locale]) => this.service.edit(locale, data, id).pipe(
      map((res: NormalizedSingle) => new ArticleTextContentEditSuccessAction({ res, id })),
      catchError((errors: HttpErrorResponse) => of(new ArticleTextContentEditFailAction({ errors, id })))
    ))
  );

  @Effect()
  loadDetails$ = this.actions$.pipe(
    ofType(ArticleTextContentLoadDetailsActionTypes.request),
    map((action: ArticleTextContentLoadDetailsAction) => action.payload.id),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([id, locale]) => this.service.details(locale, id).pipe(
      map((res: NormalizedSingle) => new ArticleTextContentLoadDetailsSuccessAction({ id, res })),
      catchError((errors: HttpErrorResponse) => of(new ArticleTextContentLoadDetailsFailAction({ id, errors })))
    ))
  );

  @Effect({ dispatch: false })
  crudFail$ = this.actions$.pipe(
    ofType(
      ArticleTextContentAddActionTypes.requestFail,
      ArticleTextContentEditActionTypes.requestFail
    ),
    map((action: ArticleTextContentAddFailAction | ArticleTextContentEditFailAction) => action.payload.errors),
    tap((data: HttpErrorResponse) => {
      if (data?.status === ResponseStatus.BAD_REQUEST || data?.status === ResponseStatus.UNPROCESSABLE_ENTITY) {
        this.snackBar.openFromComponent(LibSnackbarComponent, {
          data: {
            message: AlertUtil.getControlTranslation(data?.error?.detail),
            type: 'error'
          },
          duration: AppGlobal.defaultSnackBarDuration
        });
      }
    })
  );

  constructor(
    private actions$: Actions,
    private store: Store<ByKey>,
    private service: ArticleTextContentService,
    private snackBar: MatSnackBar
  ) {
  }
}
