import { IdType, SetStateAdapter } from '@industi/ngx-common';
import { Injectable } from '@angular/core';
import { Action, Selector } from '@ngrx/store';
import { getItemLastAddedId } from '../common.reducer';
import { ItemClearLastAddedIdAction, ItemSelectLastAddedIdAction } from '../actions/select-last-added-id.actions';

@Injectable({ providedIn: 'root' })
export class ItemSetLastAddedAdapterService extends SetStateAdapter<IdType> {

  clearAction(): Action {
    return new ItemClearLastAddedIdAction();
  }

  getValueSelector(): Selector<any, IdType> {
    return getItemLastAddedId;
  }

  setValueAction(value: IdType): Action {
    return new ItemSelectLastAddedIdAction({ value });
  }

}
