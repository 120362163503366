import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SendByIdPayload,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { OrderPayload } from '../models/order-payload';

export const OrderAddActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[order] Add'),
  requestFail: uniqueActionType('[order] Add fail'),
  requestSuccess: uniqueActionType('[order] Add success'),
  requestClearErrors: uniqueActionType('[order] Add clear errors'),
  requestClear: uniqueActionType('[order] Add clear'),
};

export class OrderAddAction implements PayloadAction {
  readonly type = OrderAddActionTypes.request;

  constructor(public payload: SendByIdPayload<OrderPayload>) {
  }
}


export class OrderAddFailAction implements PayloadAction {
  readonly type = OrderAddActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class OrderAddSuccessAction implements PayloadAction {
  readonly type = OrderAddActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class OrderAddClearErrorsAction implements PayloadAction {
  readonly type = OrderAddActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class OrderAddClearAction implements PayloadAction {
  readonly type = OrderAddActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
