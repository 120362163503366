import {
  ByIdPayload,
  FailByIdPayload,
  OperationReducerTypes,
  PayloadAction,
  uniqueActionType
} from '@industi/ngx-common';

export const OrderDeleteActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[order] Delete'),
  requestFail: uniqueActionType('[order] Delete fail'),
  requestSuccess: uniqueActionType('[order] Delete success'),
  requestClearErrors: uniqueActionType('[order] Delete clear errors'),
  requestClear: uniqueActionType('[order] Delete clear')
};

export class OrderDeleteAction implements PayloadAction {
  readonly type = OrderDeleteActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class OrderDeleteFailAction implements PayloadAction {
  readonly type = OrderDeleteActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class OrderDeleteSuccessAction implements PayloadAction {
  readonly type = OrderDeleteActionTypes.requestSuccess;

  constructor(public payload: ByIdPayload) {
  }
}

export class OrderDeleteClearErrorsAction implements PayloadAction {
  readonly type = OrderDeleteActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class OrderDeleteClearAction implements PayloadAction {
  readonly type = OrderDeleteActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
