import {
  ByIdPayload,
  FailByIdPayload,
  OperationReducerTypes,
  PayloadAction,
  uniqueActionType
} from '@industi/ngx-common';

export const ItemDeleteActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[item] Delete'),
  requestFail: uniqueActionType('[item] Delete fail'),
  requestSuccess: uniqueActionType('[item] Delete success'),
  requestClearErrors: uniqueActionType('[item] Delete clear errors'),
  requestClear: uniqueActionType('[item] Delete clear')
};

export class ItemDeleteAction implements PayloadAction {
  readonly type = ItemDeleteActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class ItemDeleteFailAction implements PayloadAction {
  readonly type = ItemDeleteActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class ItemDeleteSuccessAction implements PayloadAction {
  readonly type = ItemDeleteActionTypes.requestSuccess;

  constructor(public payload: ByIdPayload) {
  }
}

export class ItemDeleteClearErrorsAction implements PayloadAction {
  readonly type = ItemDeleteActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class ItemDeleteClearAction implements PayloadAction {
  readonly type = ItemDeleteActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
