import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ItemImageStoreModule } from './image/image-store.module';
import { ItemCommonStoreModule } from './common/common-store.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ItemCommonStoreModule,
    ItemImageStoreModule
  ]
})
export class ItemStoreModule { }
