import {
  ByIdPayload,
  FailByIdPayload,
  OperationReducerTypes,
  PayloadAction,
  uniqueActionType
} from '@industi/ngx-common';

export const ServiceProjectOperationDeleteActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[service/project-operation] Delete'),
  requestFail: uniqueActionType('[service/project-operation] Delete fail'),
  requestSuccess: uniqueActionType('[service/project-operation] Delete success'),
  requestClearErrors: uniqueActionType('[service/project-operation] Delete clear errors'),
  requestClear: uniqueActionType('[service/project-operation] Delete clear')
};

export class ServiceProjectOperationDeleteAction implements PayloadAction {
  readonly type = ServiceProjectOperationDeleteActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class ServiceProjectOperationDeleteFailAction implements PayloadAction {
  readonly type = ServiceProjectOperationDeleteActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class ServiceProjectOperationDeleteSuccessAction implements PayloadAction {
  readonly type = ServiceProjectOperationDeleteActionTypes.requestSuccess;

  constructor(public payload: ByIdPayload) {
  }
}

export class ServiceProjectOperationDeleteClearErrorsAction implements PayloadAction {
  readonly type = ServiceProjectOperationDeleteActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class ServiceProjectOperationDeleteClearAction implements PayloadAction {
  readonly type = ServiceProjectOperationDeleteActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
