import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { first } from 'rxjs/operators';
import { getLibAuthAccessToken, LibAuthAccessTokenGuardRejectAction } from 'app-store-auth';
import { ByKey } from '@industi/ngx-common';

@Injectable()
export class AccessTokenGuard implements CanActivate {

  constructor(
    private store: Store<ByKey>
  ) {
  }

  canActivate(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.store.pipe(
        select(getLibAuthAccessToken)
      ).pipe(
        first()
      ).subscribe((accessToken: string) => {
        if (accessToken) {
          resolve(true);
        } else {
          resolve(false);
          this.store.dispatch(new LibAuthAccessTokenGuardRejectAction());
        }
      });
    });
  }

}
