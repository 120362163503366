import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SendByIdPayload,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { ArticleTextContentPayload } from '../models/text-content-payload';

export const ArticleTextContentEditActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[article/text-content] Edit'),
  requestFail: uniqueActionType('[article/text-content] Edit fail'),
  requestSuccess: uniqueActionType('[article/text-content] Edit success'),
  requestClearErrors: uniqueActionType('[article/text-content] Edit clear errors'),
  requestClear: uniqueActionType('[article/text-content] Edit clear')
};

export class ArticleTextContentEditAction implements PayloadAction {
  readonly type = ArticleTextContentEditActionTypes.request;

  constructor(public payload: SendByIdPayload<ArticleTextContentPayload>) {
  }
}

export class ArticleTextContentEditFailAction implements PayloadAction {
  readonly type = ArticleTextContentEditActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class ArticleTextContentEditSuccessAction implements PayloadAction {
  readonly type = ArticleTextContentEditActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class ArticleTextContentEditClearErrorsAction implements PayloadAction {
  readonly type = ArticleTextContentEditActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class ArticleTextContentEditClearAction implements PayloadAction {
  readonly type = ArticleTextContentEditActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
