import { IdType, SetStateAdapter } from '@industi/ngx-common';
import { Injectable } from '@angular/core';
import { Action, Selector } from '@ngrx/store';
import {
  ArticleBrandClearLastAddedIdAction,
  ArticleBrandSelectLastAddedIdAction
} from '../actions/select-last-added-id.actions';
import { getArticleBrandLastAddedId } from '../brand.reducer';

@Injectable({ providedIn: 'root' })
export class ArticleBrandSetLastAddedAdapterService extends SetStateAdapter<IdType> {

  clearAction(): Action {
    return new ArticleBrandClearLastAddedIdAction();
  }

  getValueSelector(): Selector<any, IdType> {
    return getArticleBrandLastAddedId;
  }

  setValueAction(value: IdType): Action {
    return new ArticleBrandSelectLastAddedIdAction({ value });
  }

}
