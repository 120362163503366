import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';

export const OrderUnpaidActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[order-unpaid] Unpaid'),
  requestFail: uniqueActionType('[order-unpaid] Unpaid fail'),
  requestSuccess: uniqueActionType('[order-unpaid] Unpaid success'),
  requestClearErrors: uniqueActionType('[order-unpaid] Unpaid clear errors'),
  requestClear: uniqueActionType('[order-unpaid] Unpaid clear')
};

export class OrderUnpaidAction implements PayloadAction {
  readonly type = OrderUnpaidActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class OrderUnpaidFailAction implements PayloadAction {
  readonly type = OrderUnpaidActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class OrderUnpaidSuccessAction implements PayloadAction {
  readonly type = OrderUnpaidActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class OrderUnpaidClearErrorsAction implements PayloadAction {
  readonly type = OrderUnpaidActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class OrderUnpaidClearAction implements PayloadAction {
  readonly type = OrderUnpaidActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
