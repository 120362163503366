import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';

export const LayerLoadDetailsActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[layer] Load details'),
  requestFail: uniqueActionType('[layer] Load details fail'),
  requestSuccess: uniqueActionType('[layer] Load details success'),
  requestClearErrors: uniqueActionType('[layer] Load details clear errors'),
  requestClear: uniqueActionType('[layer] Load details clear')
};

export class LayerLoadDetailsAction implements PayloadAction {
  readonly type = LayerLoadDetailsActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class LayerLoadDetailsFailAction implements PayloadAction {
  readonly type = LayerLoadDetailsActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class LayerLoadDetailsSuccessAction implements PayloadAction {
  readonly type = LayerLoadDetailsActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class LayerLoadDetailsClearAction implements PayloadAction {
  readonly type = LayerLoadDetailsActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}

export class LayerLoadDetailsClearErrorsAction implements PayloadAction {
  readonly type = LayerLoadDetailsActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}
