import {
  ByIdPayload,
  ChangeParamsByIdPayload,
  FailByIdPayload,
  ListReducerTypes,
  NormalizedList,
  PayloadAction,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { TimelineListParams } from '../models/timeline-list-params';

export const TimelineLoadListActionTypes: ListReducerTypes = {
  request: uniqueActionType('[timeline] Load list'),
  requestFail: uniqueActionType('[timeline] Load list fail'),
  requestSuccess: uniqueActionType('[timeline] Load list success'),
  requestClear: uniqueActionType('[timeline] Load list clear'),
  changeParams: uniqueActionType('[timeline] Load list change params'),
  clearParams: uniqueActionType('[timeline] Load list clear params'),
  clearIds: uniqueActionType('[timeline] Load list clear ids')
};

export class TimelineLoadListAction implements PayloadAction {
  readonly type = TimelineLoadListActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class TimelineLoadListFailAction implements PayloadAction {
  readonly type = TimelineLoadListActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class TimelineLoadListSuccessAction implements PayloadAction {
  readonly type = TimelineLoadListActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedList>) {
  }
}

export class TimelineLoadListClearAction implements PayloadAction {
  readonly type = TimelineLoadListActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}

export class TimelineLoadListChangeParamsAction implements PayloadAction {
  readonly type = TimelineLoadListActionTypes.changeParams;

  constructor(public payload: ChangeParamsByIdPayload<TimelineListParams>) {
  }
}

export class TimelineLoadListClearParamsAction implements PayloadAction {
  readonly type = TimelineLoadListActionTypes.clearParams;

  constructor(public payload: ByIdPayload) {
  }
}

export class TimelineLoadListClearIdsAction implements PayloadAction {
  readonly type = TimelineLoadListActionTypes.clearIds;

  constructor(public payload: ByIdPayload) {
  }
}
