import { NgModule } from '@angular/core';
import { ArticleCommonStoreModule } from './common/common-store.module';
import { ArticleBrandStoreModule } from './brand/brand-store.module';
import { ArticleTextContentStoreModule } from './text-content/text-content-store.module';
import { ArticleVideoContentStoreModule } from './video-content/video-content-store.module';
import { ArticleGalleryContentStoreModule } from './gallery-content/gallery-content-store.module';
import { ArticleContentStoreModule } from './content/content-store.module';
import { ArticleImageContentStoreModule } from './image-content/image-content-store.module';

@NgModule({
  imports: [
    ArticleCommonStoreModule,
    ArticleBrandStoreModule,
    ArticleContentStoreModule,
    ArticleGalleryContentStoreModule,
    ArticleImageContentStoreModule,
    ArticleTextContentStoreModule,
    ArticleVideoContentStoreModule
  ]
})
export class ArticleStoreModule {
}
