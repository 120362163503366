import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { ByIdPayload, ByKey, IdType, NormalizedList, NormalizedSingle } from '@industi/ngx-common';
import { catchError, first, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { getInstanceLocale } from '../../instance/instance.reducer';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import { createCarbonPartCommissionListParams } from './selectors/get-load-list.selectors';
import { CarbonPartCommissionService } from './part-commission.service';
import {
  CarbonPartCommissionLoadListAction,
  CarbonPartCommissionLoadListActionTypes,
  CarbonPartCommissionLoadListClearIdsAction,
  CarbonPartCommissionLoadListFailAction,
  CarbonPartCommissionLoadListSuccessAction
} from './actions/list.actions';
import { SymfonyPaginationParams } from '../../symfony/list/models/symfony-pagination-params';
import {
  CarbonPartCommissionLoadDetailsAction,
  CarbonPartCommissionLoadDetailsActionTypes,
  CarbonPartCommissionLoadDetailsFailAction,
  CarbonPartCommissionLoadDetailsSuccessAction
} from './actions/load-details.actions';
import {
  CarbonPartCommissionAddAction,
  CarbonPartCommissionAddActionTypes,
  CarbonPartCommissionAddFailAction,
  CarbonPartCommissionAddSuccessAction
} from './actions/add.actions';
import { ResponseStatus } from '../../../shared/enums/response-status.enum';
import { LibSnackbarComponent } from '@industi/ngx-modules';
import { AppGlobal } from '../../../shared/app-global';
import {
  CarbonPartCommissionEditAction,
  CarbonPartCommissionEditActionTypes,
  CarbonPartCommissionEditFailAction, CarbonPartCommissionEditSuccessAction
} from './actions/edit.actions';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlertUtil } from '../../../shared/utils/alert.util';

@Injectable()
export class CarbonPartCommissionEffects {

  @Effect()
  loadList$ = this.actions$.pipe(
    ofType(CarbonPartCommissionLoadListActionTypes.request),
    map((action: CarbonPartCommissionLoadListAction) => action.payload),
    switchMap((payload: ByIdPayload) => this.store.pipe(
      select(createCarbonPartCommissionListParams(payload.id)),
      map((params: SymfonyPaginationParams) => [payload.id, params] as [IdType, SymfonyPaginationParams]),
      first()
    )),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([[ id, params ], locale]) => this.service.loadList(locale, params).pipe(
      mergeMap((res: NormalizedList) => [
        new CarbonPartCommissionLoadListClearIdsAction({ id }),
        new CarbonPartCommissionLoadListSuccessAction({ id, res })]),
      catchError((errors: HttpErrorResponse) => of(new CarbonPartCommissionLoadListFailAction({ id, errors })))
    ))
  );

  @Effect()
  add$ = this.actions$.pipe(
    ofType(CarbonPartCommissionAddActionTypes.request),
    map((action: CarbonPartCommissionAddAction) => action.payload),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([{ id, data }, locale]) => this.service.add(locale, data).pipe(
      map((res: NormalizedSingle) => new CarbonPartCommissionAddSuccessAction({ res, id })),
      catchError((errors: HttpErrorResponse) => of(new CarbonPartCommissionAddFailAction({ errors, id })))
    ))
  );

  @Effect()
  edit$ = this.actions$.pipe(
    ofType(CarbonPartCommissionEditActionTypes.request),
    map((action: CarbonPartCommissionEditAction) => action.payload),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([{ id, data }, locale]) => this.service.edit(locale, data, id).pipe(
      map((res: NormalizedSingle) => new CarbonPartCommissionEditSuccessAction({ res, id })),
      catchError((errors: HttpErrorResponse) => of(new CarbonPartCommissionEditFailAction({ errors, id })))
    ))
  );

  @Effect()
  loadDetails$ = this.actions$.pipe(
    ofType(CarbonPartCommissionLoadDetailsActionTypes.request),
    map((action: CarbonPartCommissionLoadDetailsAction) => action.payload.id),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([id, locale]) => this.service.details(locale, id).pipe(
      map((res: NormalizedSingle) => new CarbonPartCommissionLoadDetailsSuccessAction({ id, res })),
      catchError((errors: HttpErrorResponse) => of(new CarbonPartCommissionLoadDetailsFailAction({ id, errors })))
    ))
  );

  @Effect({ dispatch: false })
  crudFail$ = this.actions$.pipe(
    ofType(
      CarbonPartCommissionAddActionTypes.requestFail,
      CarbonPartCommissionEditActionTypes.requestFail,
    ),
    map((action: CarbonPartCommissionAddFailAction | CarbonPartCommissionEditFailAction) => action.payload.errors),
    tap((data: HttpErrorResponse) => {
      if (data?.status === ResponseStatus.BAD_REQUEST || data?.status === ResponseStatus.UNPROCESSABLE_ENTITY) {
        this.snackBar.openFromComponent(LibSnackbarComponent, {
          data: {
            message: AlertUtil.getControlTranslation(data.error.detail),
            type: 'error'
          },
          duration: AppGlobal.defaultSnackBarDuration
        });
      }
    })
  );

  constructor(
    private actions$: Actions,
    private store: Store<ByKey>,
    private service: CarbonPartCommissionService,
    private snackBar: MatSnackBar
  ) {
  }
}
