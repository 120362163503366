import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SendByIdPayload,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { OrderItemPayload } from '../models/item-payload';

export const OrderItemEditActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[order/item] Edit'),
  requestFail: uniqueActionType('[order/item] Edit fail'),
  requestSuccess: uniqueActionType('[order/item] Edit success'),
  requestClearErrors: uniqueActionType('[order/item] Edit clear errors'),
  requestClear: uniqueActionType('[order/item] Edit clear'),
};

export class OrderItemEditAction implements PayloadAction {
  readonly type = OrderItemEditActionTypes.request;

  constructor(public payload: SendByIdPayload<OrderItemPayload>) {
  }
}

export class OrderItemEditFailAction implements PayloadAction {
  readonly type = OrderItemEditActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class OrderItemEditSuccessAction implements PayloadAction {
  readonly type = OrderItemEditActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class OrderItemEditClearAction implements PayloadAction {
  readonly type = OrderItemEditActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}

export class OrderItemEditClearErrorsAction implements PayloadAction {
  readonly type = OrderItemEditActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}
