import { EntityTypes } from '../../entity/entity-types';
import { select, Store } from '@ngrx/store';
import { OfferImage } from './models/offer-image';
import { denormalize } from 'normalizr';
import { catchError, first, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { OfferImageAddDoneListActionTypes, OfferImageAddDoneListSuccessAction } from './actions/add-done-list.actions';
import {
  OfferImageDeleteAction,
  OfferImageDeleteActionTypes,
  OfferImageDeleteFailAction,
  OfferImageDeleteSuccessAction
} from './actions/delete.actions';
import { of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import {
  OfferImageGalleryAddAction,
  OfferImageGalleryAddActionTypes,
  OfferImageGalleryAddSuccessAction
} from './actions/add-gallery.actions';
import { Entities } from '../../entity/entities';
import { getInstanceLocale } from '../../instance/instance.reducer';
import {
  OfferImageAddPendingListActionTypes,
  OfferImageAddPendingListRemoveIdAction,
  OfferImageAddPendingListSuccessAction
} from './actions/add-pending-list.actions';
import { ByKey, NormalizedSingle } from '@industi/ngx-common';
import { createOfferImageAddPendingList } from './selectors/get-add-photo-pending-list.selectors';
import { OfferImageService } from './image.service';
import {
  OfferImageAddAction,
  OfferImageAddActionTypes,
  OfferImageAddFailAction,
  OfferImageAddSuccessAction
} from './actions/add.actions';

@Injectable()
export class OfferImageEffects {

  @Effect({ dispatch: false })
  preparePhotoToSend$ = this.actions$.pipe(
    ofType(OfferImageAddPendingListActionTypes.requestSuccess),
    map((action: OfferImageAddPendingListSuccessAction) => action.payload),
    tap(({ id, res }) => {
      const denormalized: OfferImage[] = denormalize(res.result, [Entities[EntityTypes.image]], res.entities);

      if (denormalized && denormalized.length) {
        this.store.dispatch(new OfferImageGalleryAddAction({ id, data: denormalized[0] }));
        this.store.dispatch(new OfferImageAddPendingListRemoveIdAction({ id, removeId: denormalized[0].id }));
      } else {
        this.store.dispatch(new OfferImageGalleryAddSuccessAction({ id }));
      }
    })
  );

  @Effect()
  addPhotoGallery$ = this.actions$.pipe(
    ofType(OfferImageGalleryAddActionTypes.request),
    map((action: OfferImageGalleryAddAction) => action.payload),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([{ id, data }, locale]) => this.service.add(locale, data).pipe(
      map((res: NormalizedSingle) => new OfferImageAddDoneListSuccessAction({
        id,
        res: { result: [res.result], entities: res.entities }
      })),
      catchError(() => of(new OfferImageAddDoneListSuccessAction({ id, res: { result: [data.id] } })))
    ))
  );

  @Effect({ dispatch: false })
  donePhotoListSuccess$ = this.actions$.pipe(
    ofType(OfferImageAddDoneListActionTypes.requestSuccess),
    map((action: OfferImageAddDoneListSuccessAction) => action.payload),
    switchMap(({ id }) => this.store.pipe(
      select(createOfferImageAddPendingList(id)),
      map((data: OfferImage[]) => ({ id, data })),
      first()
    )),
    tap(({ id, data }) => {
      if (data && data.length) {
        this.store.dispatch(new OfferImageGalleryAddAction({ id, data: data[0] }));
        this.store.dispatch(new OfferImageAddPendingListRemoveIdAction({ id, removeId: data[0].id }));
      } else {
        this.store.dispatch(new OfferImageGalleryAddSuccessAction({ id }));
      }
    })
  );

  @Effect()
  addPhoto$ = this.actions$.pipe(
    ofType(OfferImageAddActionTypes.request),
    map((action: OfferImageAddAction) => action.payload),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([{ id, data }, locale]) => this.service.addItemImage(locale, data).pipe(
      map(() => new OfferImageAddSuccessAction({ id })),
      catchError((errors: HttpErrorResponse) => of(new OfferImageAddFailAction({ id, errors })))
    ))
  );

  @Effect()
  delete$ = this.actions$.pipe(
    ofType(OfferImageDeleteActionTypes.request),
    map((action: OfferImageDeleteAction) => action.payload.id),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([id, locale]) => this.service.delete(locale, id).pipe(
      map(() => new OfferImageDeleteSuccessAction({ id })),
      catchError((errors: HttpErrorResponse) => of(new OfferImageDeleteFailAction({ id, errors })))
    ))
  );

  constructor(
    private actions$: Actions,
    private store: Store<ByKey>,
    private service: OfferImageService
  ) {
  }
}
