import { IdType, SetStateAdapter } from '@industi/ngx-common';
import { Injectable } from '@angular/core';
import { Action, Selector } from '@ngrx/store';
import { ClientClearLastAddedIdAction, ClientSelectLastAddedIdAction } from '../actions/select-last-added-id.actions';
import { getClientLastAddedId } from '../client.reducer';

@Injectable({ providedIn: 'root' })
export class ClientSetLastAddedAdapterService extends SetStateAdapter<IdType> {

  clearAction(): Action {
    return new ClientClearLastAddedIdAction();
  }

  getValueSelector(): Selector<any, IdType> {
    return getClientLastAddedId;
  }

  setValueAction(value: IdType): Action {
    return new ClientSelectLastAddedIdAction({ value });
  }

}
