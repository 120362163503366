import { ByKey, listByIdReducer, ListState, operationByIdReducer, SingleState } from '@industi/ngx-common';
import { ActionReducer, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import { InjectionToken, Provider } from '@angular/core';
import { CarbonFormCommissionLoadListActionTypes } from './actions/list.actions';
import { CarbonFormCommissionLoadDetailsActionTypes } from './actions/load-details.actions';
import { CarbonFormCommissionEditActionTypes } from './actions/edit.actions';
import { CarbonFormCommissionAddActionTypes } from './actions/add.actions';
import { CarbonFormCommissionDeleteActionTypes } from './actions/delete.actions';

export const rootReducerKey = 'formCommission';

export interface CarbonFormCommissionOperationByIdState {
  add: SingleState;
  edit: SingleState;
  details: SingleState;
  delete: SingleState;
}

export interface CarbonFormCommissionState {
  operationsById: ByKey<CarbonFormCommissionOperationByIdState>;
  list: ByKey<ListState>;
}

const initialState: CarbonFormCommissionState = {
  operationsById: {},
  list: {}
};

const reducer: ActionReducer<CarbonFormCommissionState> = combineReducers<CarbonFormCommissionState>({
  operationsById: operationByIdReducer<CarbonFormCommissionOperationByIdState>({
    mapActionToId: (a) => a && a.payload && a.payload.id,
    operations: {
      add: CarbonFormCommissionAddActionTypes,
      edit: CarbonFormCommissionEditActionTypes,
      details: CarbonFormCommissionLoadDetailsActionTypes,
      delete: CarbonFormCommissionDeleteActionTypes
    }
  }),
  list: listByIdReducer({ types: CarbonFormCommissionLoadListActionTypes })
}, initialState);

export function getReducers(): ActionReducer<CarbonFormCommissionState> {
  return reducer;
}

export const CARBON_FORM_COMMISSION_REDUCER_TOKEN = new InjectionToken<ActionReducer<CarbonFormCommissionState>>(rootReducerKey);

export const carbonFormCommissionReducerProvider: Provider = { provide: CARBON_FORM_COMMISSION_REDUCER_TOKEN, useFactory: getReducers };

const getState = createFeatureSelector<CarbonFormCommissionState>(rootReducerKey);

export const getCarbonFormCommissionOperationsById = createSelector(
  getState,
  (state: CarbonFormCommissionState) => state && state.operationsById
);

export const getCarbonFormCommissionListState = createSelector(
  getState,
  (state: CarbonFormCommissionState) => state && state.list
);
