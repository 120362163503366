import { Injectable } from '@angular/core';
import { IdType, ListStateToComponentAdapter } from '@industi/ngx-common';
import { Action, Selector } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import * as _ from 'lodash';
import { PartListParams } from '../models/part-list-params';
import {
  PartLoadListAction,
  PartLoadListChangeParamsAction,
  PartLoadListClearAction, PartLoadListClearIdsAction,
  PartLoadListClearParamsAction
} from '../actions/list.actions';
import { AppGlobal } from '../../../shared/app-global';
import { Part } from '../models/part';
import {
  createPartListData,
  createPartListErrors, createPartListLoading, createPartListParams,
  createPartListSuccess,
  createPartListTotalCount
} from '../selectors/get-load-list.selectors';

@Injectable({
  providedIn: 'root'
})
export class PartListAdapterService
  extends ListStateToComponentAdapter<Part, PartListParams> {

  apiItemToTableItem(apiModel: Part): Part {
    return apiModel;
  }

  changeParamsAction(id: IdType, params: PartListParams): Action {
    return new PartLoadListChangeParamsAction({ id, params });
  }

  clearAction(id: IdType): Action {
    return new PartLoadListClearAction({ id });
  }

  clearIdsAction(id: IdType): Action {
    return new PartLoadListClearIdsAction({ id });
  }

  clearParamsAction(id: IdType): Action {
    return new PartLoadListClearParamsAction({ id });
  }

  getDataSelector(id: IdType): Selector<any, Part[]> {
    return createPartListData(id);
  }

  getErrorsSelector(id: IdType): Selector<any, HttpErrorResponse> {
    return createPartListErrors(id);
  }

  getLoadingSelector(id: IdType): Selector<any, boolean> {
    return createPartListLoading(id);
  }

  getParamsSelector(id: IdType): Selector<any, PartListParams> {
    return createPartListParams(id);
  }

  getSuccessSelector(id: IdType): Selector<any, boolean> {
    return createPartListSuccess(id);
  }

  getTotalCountSelector(id: IdType): Selector<any, number> {
    return createPartListTotalCount(id);
  }

  loadListAction(id: IdType): Action {
    return new PartLoadListAction({ id });
  }

  tableParamsToApiParams(tableParams: PartListParams): PartListParams {
    return _.omitBy({
      ...tableParams,
      page: tableParams?.page || AppGlobal.listParams.page,
    } as PartListParams, _.isNil);
  }

}
