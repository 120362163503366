import { ByKey, listByIdReducer, ListState, operationByIdReducer, SingleState } from '@industi/ngx-common';
import { ActionReducer, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import { InjectionToken, Provider } from '@angular/core';
import { DictionaryUnitStateEditActionTypes } from './actions/edit.actions';
import { DictionaryUnitStateAddActionTypes } from './actions/add.actions';
import { DictionaryUnitStateLoadListActionTypes } from './actions/list.actions';
import { DictionaryUnitStateLoadDetailsActionTypes } from './actions/load-details.actions';
import { DictionaryUnitStateDeleteActionTypes } from './actions/delete.actions';

export const rootReducerKey = 'unitState';

export interface DictionaryUnitStateOperationByIdState {
  add: SingleState;
  edit: SingleState;
  details: SingleState;
  delete: SingleState;
}

export interface DictionaryUnitStateState {
  operationsById: ByKey<DictionaryUnitStateOperationByIdState>;
  list: ByKey<ListState>;
}

const initialState: DictionaryUnitStateState = {
  operationsById: {},
  list: {}
};

const reducer: ActionReducer<DictionaryUnitStateState> = combineReducers<DictionaryUnitStateState>({
  operationsById: operationByIdReducer<DictionaryUnitStateOperationByIdState>({
    mapActionToId: (a) => a && a.payload && a.payload.id,
    operations: {
      add: DictionaryUnitStateAddActionTypes,
      edit: DictionaryUnitStateEditActionTypes,
      details: DictionaryUnitStateLoadDetailsActionTypes,
      delete: DictionaryUnitStateDeleteActionTypes
    }
  }),
  list: listByIdReducer({ types: DictionaryUnitStateLoadListActionTypes })
}, initialState);

export function getReducers(): ActionReducer<DictionaryUnitStateState> {
  return reducer;
}

export const DICTIONARY_UNIT_STATE_REDUCER_TOKEN = new InjectionToken<ActionReducer<DictionaryUnitStateState>>(rootReducerKey);

export const dictionaryUnitStateReducerProvider: Provider = { provide: DICTIONARY_UNIT_STATE_REDUCER_TOKEN, useFactory: getReducers };

const getState = createFeatureSelector<DictionaryUnitStateState>(rootReducerKey);

export const getDictionaryUnitStateOperationsById = createSelector(
  getState,
  (state: DictionaryUnitStateState) => state && state.operationsById
);

export const getDictionaryUnitStateListState = createSelector(
  getState,
  (state: DictionaryUnitStateState) => state && state.list
);
