import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';

export const DictionaryProcessLoadDetailsActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[dictionary/process] Load details'),
  requestFail: uniqueActionType('[dictionary/process] Load details fail'),
  requestSuccess: uniqueActionType('[dictionary/process] Load details success'),
  requestClearErrors: uniqueActionType('[dictionary/process] Load details clear errors'),
  requestClear: uniqueActionType('[dictionary/process] Load details clear')
};

export class DictionaryProcessLoadDetailsAction implements PayloadAction {
  readonly type = DictionaryProcessLoadDetailsActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class DictionaryProcessLoadDetailsFailAction implements PayloadAction {
  readonly type = DictionaryProcessLoadDetailsActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class DictionaryProcessLoadDetailsSuccessAction implements PayloadAction {
  readonly type = DictionaryProcessLoadDetailsActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class DictionaryProcessLoadDetailsClearAction implements PayloadAction {
  readonly type = DictionaryProcessLoadDetailsActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}

export class DictionaryProcessLoadDetailsClearErrorsAction implements PayloadAction {
  readonly type = DictionaryProcessLoadDetailsActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}
