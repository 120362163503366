import {
  ByIdPayload,
  ChangeParamsByIdPayload,
  FailByIdPayload,
  ListReducerTypes,
  NormalizedList,
  PayloadAction,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { SymfonyPaginationParams } from '../../../symfony/list/models/symfony-pagination-params';

export const DictionaryCarbonMaterialLoadListActionTypes: ListReducerTypes = {
  request: uniqueActionType('[dictionary/carbon-material] Load list'),
  requestFail: uniqueActionType('[dictionary/carbon-material] Load list fail'),
  requestSuccess: uniqueActionType('[dictionary/carbon-material] Load list success'),
  requestClear: uniqueActionType('[dictionary/carbon-material] Load list clear'),
  changeParams: uniqueActionType('[dictionary/carbon-material] Load list change params'),
  clearParams: uniqueActionType('[dictionary/carbon-material] Load list clear params'),
  clearIds: uniqueActionType('[dictionary/carbon-material] Load list clear ids')
};

export class DictionaryCarbonMaterialLoadListAction implements PayloadAction {
  readonly type = DictionaryCarbonMaterialLoadListActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class DictionaryCarbonMaterialLoadListFailAction implements PayloadAction {
  readonly type = DictionaryCarbonMaterialLoadListActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class DictionaryCarbonMaterialLoadListSuccessAction implements PayloadAction {
  readonly type = DictionaryCarbonMaterialLoadListActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedList>) {
  }
}

export class DictionaryCarbonMaterialLoadListClearAction implements PayloadAction {
  readonly type = DictionaryCarbonMaterialLoadListActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}

export class DictionaryCarbonMaterialLoadListChangeParamsAction implements PayloadAction {
  readonly type = DictionaryCarbonMaterialLoadListActionTypes.changeParams;

  constructor(public payload: ChangeParamsByIdPayload<SymfonyPaginationParams>) {
  }
}

export class DictionaryCarbonMaterialLoadListClearParamsAction implements PayloadAction {
  readonly type = DictionaryCarbonMaterialLoadListActionTypes.clearParams;

  constructor(public payload: ByIdPayload) {
  }
}


export class DictionaryCarbonMaterialLoadListClearIdsAction implements PayloadAction {
  readonly type = DictionaryCarbonMaterialLoadListActionTypes.clearIds;

  constructor(public payload: ByIdPayload) {
  }
}
