import { ByKey, listByIdReducer, ListState, operationByIdReducer, SingleState } from '@industi/ngx-common';
import { ActionReducer, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import { InjectionToken, Provider } from '@angular/core';
import { ArticleContentDeleteActionTypes } from './actions/delete.actions';
import { ArticleContentLoadListActionTypes } from './actions/list.actions';
import { ArticleContentLoadDetailsActionTypes } from './actions/details.actions';

export const rootReducerKey = 'articleContent';

export interface ArticleContentOperationByIdState {
  details: SingleState;
  delete: SingleState;
}

export interface ArticleContentState {
  operationsById: ByKey<ArticleContentOperationByIdState>;
  list: ByKey<ListState>;
}

const initialState: ArticleContentState = {
  operationsById: {},
  list: {}
};

const reducer: ActionReducer<ArticleContentState> = combineReducers<ArticleContentState>({
  operationsById: operationByIdReducer<ArticleContentOperationByIdState>({
    mapActionToId: (a) => a?.payload?.id,
    operations: {
      details: ArticleContentLoadDetailsActionTypes,
      delete: ArticleContentDeleteActionTypes
    }
  }),
  list: listByIdReducer({ types: ArticleContentLoadListActionTypes })
}, initialState);

export function getReducers(): ActionReducer<ArticleContentState> {
  return reducer;
}

export const ARTICLE_CONTENT_REDUCER_TOKEN = new InjectionToken<ActionReducer<ArticleContentState>>(rootReducerKey);

export const articleContentReducerProvider: Provider = { provide: ARTICLE_CONTENT_REDUCER_TOKEN, useFactory: getReducers };

const getState = createFeatureSelector<ArticleContentState>(rootReducerKey);

export const getArticleContentOperationsById = createSelector(
  getState,
  (state: ArticleContentState) => state && state.operationsById
);

export const getArticleContentListState = createSelector(
  getState,
  (state: ArticleContentState) => state && state.list
);
