import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SendByIdPayload,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { LayerPayload } from '../models/layer-payload';

export const LayerEditActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[layer] Edit'),
  requestFail: uniqueActionType('[layer] Edit fail'),
  requestSuccess: uniqueActionType('[layer] Edit success'),
  requestClearErrors: uniqueActionType('[layer] Edit clear errors'),
  requestClear: uniqueActionType('[layer] Edit clear')
};

export class LayerEditAction implements PayloadAction {
  readonly type = LayerEditActionTypes.request;

  constructor(public payload: SendByIdPayload<LayerPayload>) {
  }
}

export class LayerEditFailAction implements PayloadAction {
  readonly type = LayerEditActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class LayerEditSuccessAction implements PayloadAction {
  readonly type = LayerEditActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class LayerEditClearErrorsAction implements PayloadAction {
  readonly type = LayerEditActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class LayerEditClearAction implements PayloadAction {
  readonly type = LayerEditActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
