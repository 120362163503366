import { createSelector } from '@ngrx/store';
import { denormalize } from 'normalizr';
import {
  ByKey,
  createListStateIdsSelector,
  createListStateParamsSelector,
  createStateErrorsSelector,
  createStateLoadingSelector,
  createStateSuccessSelector,
  IdType,
  ListState
} from '@industi/ngx-common';
import { EntityTypes } from '../../../entity/entity-types';
import { createEntitiesSelector } from '../../../entity/entities.reducer';
import { createSymfonyTotalCountSelector } from '../../../symfony/list/selectors/list.selectors';
import { Entities } from '../../../entity/entities';
import { EntityState } from '../../../entity/entity.reducer';
import { getDictionaryCarbonMaterialListState } from '../carbon-material.reducer';
import { DictionaryCarbonMaterial } from '../models/carbon-material';

const createState = (id: IdType) => createSelector(
  getDictionaryCarbonMaterialListState,
  (state: ByKey<ListState>) => state && state[id]
);

const createStateByContext = (id: IdType) => createListStateIdsSelector(createState(id));

export const createDictionaryCarbonMaterialListData = (id: IdType) => createSelector(
  createStateByContext(id),
  createEntitiesSelector([
    EntityTypes.carbonMaterial
  ]),
  (
    ids: IdType[],
    entities: EntityState
  ) => denormalize(ids, [Entities[EntityTypes.carbonMaterial]], entities) as DictionaryCarbonMaterial[]
);

export const createDictionaryCarbonMaterialListLoading = (id: IdType) => createStateLoadingSelector(
  createState(id));

export const createDictionaryCarbonMaterialListSuccess = (id: IdType) => createStateSuccessSelector(
  createState(id));

export const createDictionaryCarbonMaterialListErrors = (id: IdType) => createStateErrorsSelector(
  createState(id));

export const createDictionaryCarbonMaterialListTotalCount = (id: IdType) => createSymfonyTotalCountSelector(
  createState(id));

export const createDictionaryCarbonMaterialListParams = (id: IdType) => createListStateParamsSelector(
  createState(id));
