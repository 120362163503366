<mat-table [dataSource]="tableData"
           libTableList>

  <!--  [ngClass]="tableClass"-->
  <ng-container [matColumnDef]="columnNames?.height">
    <mat-header-cell *matHeaderCellDef>Wysokość</mat-header-cell>
    <mat-cell *matCellDef="let element">{{ element?.height }}</mat-cell>
  </ng-container>

  <ng-container [matColumnDef]="columnNames?.length">
    <mat-header-cell *matHeaderCellDef>Długość</mat-header-cell>
    <mat-cell *matCellDef="let element">{{ element?.length }}</mat-cell>
  </ng-container>

  <ng-container [matColumnDef]="columnNames?.width">
    <mat-header-cell *matHeaderCellDef>Szerokość</mat-header-cell>
    <mat-cell *matCellDef="let element">{{ element?.width }}</mat-cell>
  </ng-container>

  <ng-container [matColumnDef]="columnNames?.weight">
    <mat-header-cell *matHeaderCellDef>Waga</mat-header-cell>
    <mat-cell *matCellDef="let element">{{ element?.weight }}</mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>
