import { Pipe, PipeTransform } from '@angular/core';
import { Price } from '../../store/common/models/price';

@Pipe({
  name: 'priceInfoByCurrency'
})
export class PriceInfoByCurrencyPipe implements PipeTransform {

  transform(prices: Price[], currency: string): Price {
    return prices?.find((price: Price) => price?.currency === currency) || {};
  }

}
