import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SendByIdPayload,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { PartPayload } from '../models/part-payload';

export const PartAddActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[part] Add'),
  requestFail: uniqueActionType('[part] Add fail'),
  requestSuccess: uniqueActionType('[part] Add success'),
  requestClearErrors: uniqueActionType('[part] Add clear errors'),
  requestClear: uniqueActionType('[part] Add clear'),
};

export class PartAddAction implements PayloadAction {
  readonly type = PartAddActionTypes.request;

  constructor(public payload: SendByIdPayload<PartPayload>) {
  }
}

export class PartAddFailAction implements PayloadAction {
  readonly type = PartAddActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class PartAddSuccessAction implements PayloadAction {
  readonly type = PartAddActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class PartAddClearErrorsAction implements PayloadAction {
  readonly type = PartAddActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class PartAddClearAction implements PayloadAction {
  readonly type = PartAddActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
