import { ByKey, listByIdReducer, ListState, operationByIdReducer, SingleState } from '@industi/ngx-common';
import { ActionReducer, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import { InjectionToken, Provider } from '@angular/core';
import { IcsConversationLoadListActionTypes } from './actions/list.actions';
import { IcsConversationLoadDetailsActionTypes } from './actions/load-details.actions';
import { IcsConversationDeleteActionTypes } from './actions/delete.actions';
import { IcsConversationMessageAddActionTypes } from './actions/add-message.actions';
import { IcsConversationAttachmentAddActionTypes } from './actions/add-attachment.actions';
import { IcsConversationTaskAddActionTypes } from './actions/add-task.actions';

export const rootReducerKey = 'icsConversation';

export interface IcsConversationOperationByIdState {
  details: SingleState;
  delete: SingleState;
}

export interface IcsConversationMessageOperationByIdState {
  add: SingleState;
}

export interface IcsConversationAttachmentOperationByIdState {
  add: SingleState;
}

export interface IcsConversationTaskOperationByIdState {
  add: SingleState;
}

export interface IcsConversationState {
  operationsById: ByKey<IcsConversationOperationByIdState>;
  messageOperationsById: ByKey<IcsConversationMessageOperationByIdState>;
  attachmentOperationsById: ByKey<IcsConversationAttachmentOperationByIdState>;
  taskOperationsById: ByKey<IcsConversationTaskOperationByIdState>;
  list: ByKey<ListState>;
}

const initialState: IcsConversationState = {
  operationsById: {},
  messageOperationsById: {},
  attachmentOperationsById: {},
  taskOperationsById: {},
  list: {}
};

const reducer: ActionReducer<IcsConversationState> = combineReducers<IcsConversationState>({
  operationsById: operationByIdReducer<IcsConversationOperationByIdState>({
    mapActionToId: (a) => a?.payload?.id,
    operations: {
      details: IcsConversationLoadDetailsActionTypes,
      delete: IcsConversationDeleteActionTypes
    }
  }),
  messageOperationsById: operationByIdReducer<IcsConversationMessageOperationByIdState>({
    mapActionToId: (a) => a?.payload?.id,
    operations: {
      add: IcsConversationMessageAddActionTypes
    }
  }),
  attachmentOperationsById: operationByIdReducer<IcsConversationAttachmentOperationByIdState>({
    mapActionToId: (a) => a?.payload?.id,
    operations: {
      add: IcsConversationAttachmentAddActionTypes
    }
  }),
  taskOperationsById: operationByIdReducer<IcsConversationTaskOperationByIdState>({
    mapActionToId: (a) => a?.payload?.id,
    operations: {
      add: IcsConversationTaskAddActionTypes
    }
  }),
  list: listByIdReducer({ types: IcsConversationLoadListActionTypes })
}, initialState);

export function getReducers(): ActionReducer<IcsConversationState> {
  return reducer;
}

export const ICS_CONVERSATION_REDUCER_TOKEN = new InjectionToken<ActionReducer<IcsConversationState>>(rootReducerKey);

export const icsConversationReducerProvider: Provider = { provide: ICS_CONVERSATION_REDUCER_TOKEN, useFactory: getReducers };

const getState = createFeatureSelector<IcsConversationState>(rootReducerKey);

export const getIcsConversationOperationsById = createSelector(
  getState,
  (state: IcsConversationState) => state && state.operationsById
);

export const getIcsConversationMessageOperationsById = createSelector(
  getState,
  (state: IcsConversationState) => state && state.messageOperationsById
);

export const getIcsConversationAttachmentOperationsById = createSelector(
  getState,
  (state: IcsConversationState) => state && state.attachmentOperationsById
);

export const getIcsConversationTaskOperationsById = createSelector(
  getState,
  (state: IcsConversationState) => state && state.taskOperationsById
);

export const getIcsConversationListState = createSelector(
  getState,
  (state: IcsConversationState) => state && state.list
);
