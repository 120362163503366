import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, first, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { ByIdPayload, ByKey, IdType, NormalizedList, NormalizedSingle } from '@industi/ngx-common';
import { select, Store } from '@ngrx/store';
import { getInstanceLocale } from '../instance/instance.reducer';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import { ResponseStatus } from '../../shared/enums/response-status.enum';
import { LibSnackbarComponent } from '@industi/ngx-modules';
import { AlertUtil } from '../../shared/utils/alert.util';
import { AppGlobal } from '../../shared/app-global';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  DiscountGroupAddAction,
  DiscountGroupAddActionTypes,
  DiscountGroupAddFailAction,
  DiscountGroupAddSuccessAction
} from './actions/add.actions';
import {
  DiscountGroupLoadListAction,
  DiscountGroupLoadListActionTypes,
  DiscountGroupLoadListClearIdsAction,
  DiscountGroupLoadListFailAction,
  DiscountGroupLoadListSuccessAction
} from './actions/list.actions';
import {
  DiscountGroupEditAction,
  DiscountGroupEditActionTypes,
  DiscountGroupEditFailAction,
  DiscountGroupEditSuccessAction
} from './actions/edit.actions';
import {
  DiscountGroupLoadDetailsAction,
  DiscountGroupLoadDetailsActionTypes,
  DiscountGroupLoadDetailsFailAction,
  DiscountGroupLoadDetailsSuccessAction
} from './actions/details.actions';
import {
  DiscountGroupDeleteAction,
  DiscountGroupDeleteActionTypes,
  DiscountGroupDeleteFailAction,
  DiscountGroupDeleteSuccessAction
} from './actions/delete.actions';
import { DiscountGroupService } from './discount-group.service';
import { createDiscountGroupListParams } from './selectors/get-load-list.selectors';
import { DiscountGroupListParams } from './models/discount-group-list-params';

@Injectable()
export class DiscountGroupEffects {

  @Effect()
  loadList$ = this.actions$.pipe(
    ofType(DiscountGroupLoadListActionTypes.request),
    map((action: DiscountGroupLoadListAction) => action.payload),
    switchMap((payload: ByIdPayload) => this.store.pipe(
      select(createDiscountGroupListParams(payload.id)),
      map((params: DiscountGroupListParams) => [payload.id, params] as [IdType, DiscountGroupListParams]),
      first()
    )),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([[ id, params ], locale]) => this.service.loadList(locale, params).pipe(
      mergeMap((res: NormalizedList) => [
        new DiscountGroupLoadListClearIdsAction({ id }),
        new DiscountGroupLoadListSuccessAction({ id, res })]),
      catchError((errors: HttpErrorResponse) => of(new DiscountGroupLoadListFailAction({ id, errors })))
    ))
  );

  @Effect()
  add$ = this.actions$.pipe(
    ofType(DiscountGroupAddActionTypes.request),
    map((action: DiscountGroupAddAction) => action.payload),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([{ id, data }, locale]) => this.service.add(locale, data).pipe(
      map((res: NormalizedSingle) => new DiscountGroupAddSuccessAction({ res, id })),
      catchError((errors: HttpErrorResponse) => of(new DiscountGroupAddFailAction({ errors, id })))
    ))
  );

  @Effect()
  edit$ = this.actions$.pipe(
    ofType(DiscountGroupEditActionTypes.request),
    map((action: DiscountGroupEditAction) => action.payload),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([{ id, data }, locale]) => this.service.edit(locale, data, id).pipe(
      map((res: NormalizedSingle) => new DiscountGroupEditSuccessAction({ res, id })),
      catchError((errors: HttpErrorResponse) => of(new DiscountGroupEditFailAction({ errors, id })))
    ))
  );

  @Effect()
  loadDetails$ = this.actions$.pipe(
    ofType(DiscountGroupLoadDetailsActionTypes.request),
    map((action: DiscountGroupLoadDetailsAction) => action.payload.id),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([id, locale]) => this.service.details(locale, id).pipe(
      map((res: NormalizedSingle) => new DiscountGroupLoadDetailsSuccessAction({ id, res })),
      catchError((errors: HttpErrorResponse) => of(new DiscountGroupLoadDetailsFailAction({ id, errors })))
    ))
  );

  @Effect()
  delete$ = this.actions$.pipe(
    ofType(DiscountGroupDeleteActionTypes.request),
    map((action: DiscountGroupDeleteAction) => action.payload.id),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([id, locale]) => this.service.delete(locale, id).pipe(
      map(() => new DiscountGroupDeleteSuccessAction({ id })),
      catchError((errors: HttpErrorResponse) => of(new DiscountGroupDeleteFailAction({ id, errors })))
    ))
  );

  @Effect({ dispatch: false })
  crudFail$ = this.actions$.pipe(
    ofType(
      DiscountGroupAddActionTypes.requestFail,
      DiscountGroupEditActionTypes.requestFail
    ),
    map((action: DiscountGroupAddFailAction | DiscountGroupEditFailAction) => action.payload.errors),
    tap((data: HttpErrorResponse) => {
      if (data?.status === ResponseStatus.BAD_REQUEST || data?.status === ResponseStatus.UNPROCESSABLE_ENTITY) {
        this.snackBar.openFromComponent(LibSnackbarComponent, {
          data: {
            message: AlertUtil.getControlTranslation(data.error.detail),
            type: 'error'
          },
          duration: AppGlobal.defaultSnackBarDuration
        });
      }
    })
  );

  constructor(
    private actions$: Actions,
    private store: Store<ByKey>,
    private service: DiscountGroupService,
    private snackBar: MatSnackBar
  ) {
  }
}
