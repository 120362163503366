import { NgModule } from '@angular/core';
import { ServiceProjectCommonStoreModule } from './common/common-store.module';
import { ServiceProjectPartStoreModule } from './part/part-store.module';
import { ServiceProjectItemStoreModule } from './item/item-store.module';
import { ServiceProjectOperationStoreModule } from './operation/operation-store.module';

@NgModule({
  declarations: [],
  imports: [
    ServiceProjectCommonStoreModule,
    ServiceProjectPartStoreModule,
    ServiceProjectItemStoreModule,
    ServiceProjectOperationStoreModule
  ]
})
export class ServiceProjectStoreModule { }
