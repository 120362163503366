import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';

export const DictionaryUnitStateLoadDetailsActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[dictionary/unit-state] Load details'),
  requestFail: uniqueActionType('[dictionary/unit-state] Load details fail'),
  requestSuccess: uniqueActionType('[dictionary/unit-state] Load details success'),
  requestClearErrors: uniqueActionType('[dictionary/unit-state] Load details clear errors'),
  requestClear: uniqueActionType('[dictionary/unit-state] Load details clear')
};

export class DictionaryUnitStateLoadDetailsAction implements PayloadAction {
  readonly type = DictionaryUnitStateLoadDetailsActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class DictionaryUnitStateLoadDetailsFailAction implements PayloadAction {
  readonly type = DictionaryUnitStateLoadDetailsActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class DictionaryUnitStateLoadDetailsSuccessAction implements PayloadAction {
  readonly type = DictionaryUnitStateLoadDetailsActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class DictionaryUnitStateLoadDetailsClearAction implements PayloadAction {
  readonly type = DictionaryUnitStateLoadDetailsActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}

export class DictionaryUnitStateLoadDetailsClearErrorsAction implements PayloadAction {
  readonly type = DictionaryUnitStateLoadDetailsActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}
