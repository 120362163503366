import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import {
  CARBON_FORM_COMMISSION_REDUCER_TOKEN,
  carbonFormCommissionReducerProvider,
  rootReducerKey
} from './form-commission.reducer';
import { EffectsModule } from '@ngrx/effects';
import { CarbonFormCommissionEffects } from './form-commission.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(rootReducerKey, CARBON_FORM_COMMISSION_REDUCER_TOKEN),
    EffectsModule.forFeature([CarbonFormCommissionEffects])
  ],
  providers: [
    carbonFormCommissionReducerProvider
  ]
})
export class CarbonFormCommissionStoreModule { }
