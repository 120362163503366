import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SendByIdPayload,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { DiscountGroupPayload } from '../models/discount-group-payload';

export const DiscountGroupEditActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[discount-group] Edit'),
  requestFail: uniqueActionType('[discount-group] Edit fail'),
  requestSuccess: uniqueActionType('[discount-group] Edit success'),
  requestClearErrors: uniqueActionType('[discount-group] Edit clear errors'),
  requestClear: uniqueActionType('[discount-group] Edit clear')
};

export class DiscountGroupEditAction implements PayloadAction {
  readonly type = DiscountGroupEditActionTypes.request;

  constructor(public payload: SendByIdPayload<DiscountGroupPayload>) {
  }
}

export class DiscountGroupEditFailAction implements PayloadAction {
  readonly type = DiscountGroupEditActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class DiscountGroupEditSuccessAction implements PayloadAction {
  readonly type = DiscountGroupEditActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class DiscountGroupEditClearErrorsAction implements PayloadAction {
  readonly type = DiscountGroupEditActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class DiscountGroupEditClearAction implements PayloadAction {
  readonly type = DiscountGroupEditActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
