import { ActionReducer, ActionReducerMap, createFeatureSelector, createSelector, createSelectorFactory, defaultMemoize } from '@ngrx/store';
import { EntityTypes } from './entity-types';
import { entityReducer, EntityState } from './entity.reducer';
import { InjectionToken, Provider } from '@angular/core';
import { denormalize } from 'normalizr';
import { Entities } from './entities';
import { ByKey, IdType, PayloadAction } from '@industi/ngx-common';

export const ENTITIES_REDUCER_TOKEN = new InjectionToken<ActionReducerMap<EntitiesState, PayloadAction>>('entities');

export const entitiesReducerProvider: Provider = { provide: ENTITIES_REDUCER_TOKEN, useFactory: getReducers };

export type EntitiesState = ByKey<EntityState>;

const entitiesReducers = Object
  .keys(EntityTypes)
  .reduce((
    result: ByKey<ActionReducer<EntityState, PayloadAction>>,
    currentEntityType: string
  ) => <ByKey<ActionReducer<EntityState, PayloadAction>>> {
    ...result,
    [EntityTypes[currentEntityType]]: entityReducer(EntityTypes[currentEntityType])
  }, {});

const entitiesInitialState = Object
  .keys(EntityTypes)
  .reduce((
    result: EntitiesState,
    currentEntityType: string
  ) => <EntitiesState> {
    ...result,
    [EntityTypes[currentEntityType]]: {}
  }, {});

export function getReducers(): ActionReducerMap<EntitiesState, PayloadAction> {
  return entitiesReducers;
}

const getState = createFeatureSelector<EntitiesState>('entities');

const getEntityState = (entityType: string) => createSelector(
  getState,
  (state: EntityState) => state && state[entityType] || {}
);

const createSelectorWithManyArgs = createSelectorFactory(defaultMemoize);

export const createEntitiesSelector = (entityTypes: string[]) => {
  return createSelectorWithManyArgs(
    entityTypes.map((entityType: string) => getEntityState(entityType)),
    (...args) => <EntitiesState> {
      ...entitiesInitialState,
      ...entityTypes.reduce((result: EntitiesState, entityType: string, idx: number) => <EntitiesState> {
        ...result,
        [entityType]: args[idx]
      }, {} as EntitiesState)
    }
  );
};


/**
 * @experimental
 */
export const createEntitySelector = (id: IdType, entityType: string, nestedEntityTypes: string[] = []) => createSelector(
  createEntitiesSelector([
    entityType,
    ...nestedEntityTypes
  ]),
  (entities: EntitiesState) => denormalize(
    id,
    Entities[entityType],
    entities
  )
);
