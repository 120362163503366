import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SendByIdPayload,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { CarbonCommissionTaskPayload } from '../models/task-payload';

export const CarbonCommissionTaskAddActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[carbon/commission-task] Add'),
  requestFail: uniqueActionType('[carbon/commission-task] Add fail'),
  requestSuccess: uniqueActionType('[carbon/commission-task] Add success'),
  requestClearErrors: uniqueActionType('[carbon/commission-task] Add clear errors'),
  requestClear: uniqueActionType('[carbon/commission-task] Add clear'),
};

export class CarbonCommissionTaskAddAction implements PayloadAction {
  readonly type = CarbonCommissionTaskAddActionTypes.request;

  constructor(public payload: SendByIdPayload<CarbonCommissionTaskPayload>) {
  }
}

export class CarbonCommissionTaskAddFailAction implements PayloadAction {
  readonly type = CarbonCommissionTaskAddActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class CarbonCommissionTaskAddSuccessAction implements PayloadAction {
  readonly type = CarbonCommissionTaskAddActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class CarbonCommissionTaskAddClearErrorsAction implements PayloadAction {
  readonly type = CarbonCommissionTaskAddActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class CarbonCommissionTaskAddClearAction implements PayloadAction {
  readonly type = CarbonCommissionTaskAddActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
