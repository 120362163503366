import {
  ByIdPayload,
  FailByIdPayload,
  OperationReducerTypes,
  PayloadAction,
  uniqueActionType
} from '@industi/ngx-common';

export const PartDeleteActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[part] Delete'),
  requestFail: uniqueActionType('[part] Delete fail'),
  requestSuccess: uniqueActionType('[part] Delete success'),
  requestClearErrors: uniqueActionType('[part] Delete clear errors'),
  requestClear: uniqueActionType('[part] Delete clear')
};

export class PartDeleteAction implements PayloadAction {
  readonly type = PartDeleteActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class PartDeleteFailAction implements PayloadAction {
  readonly type = PartDeleteActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class PartDeleteSuccessAction implements PayloadAction {
  readonly type = PartDeleteActionTypes.requestSuccess;

  constructor(public payload: ByIdPayload) {
  }
}

export class PartDeleteClearErrorsAction implements PayloadAction {
  readonly type = PartDeleteActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class PartDeleteClearAction implements PayloadAction {
  readonly type = PartDeleteActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
