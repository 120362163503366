import {
  ByIdPayload,
  ChangeParamsByIdPayload,
  FailByIdPayload,
  ListReducerTypes,
  NormalizedList,
  PayloadAction,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { SymfonyPaginationParams } from '../../../symfony/list/models/symfony-pagination-params';

export const DictionaryCarbonMaterialTypeLoadListActionTypes: ListReducerTypes = {
  request: uniqueActionType('[dictionary/carbon-material-type] Load list'),
  requestFail: uniqueActionType('[dictionary/carbon-material-type] Load list fail'),
  requestSuccess: uniqueActionType('[dictionary/carbon-material-type] Load list success'),
  requestClear: uniqueActionType('[dictionary/carbon-material-type] Load list clear'),
  changeParams: uniqueActionType('[dictionary/carbon-material-type] Load list change params'),
  clearParams: uniqueActionType('[dictionary/carbon-material-type] Load list clear params'),
  clearIds: uniqueActionType('[dictionary/carbon-material-type] Load list clear ids')
};

export class DictionaryCarbonMaterialTypeLoadListAction implements PayloadAction {
  readonly type = DictionaryCarbonMaterialTypeLoadListActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class DictionaryCarbonMaterialTypeLoadListFailAction implements PayloadAction {
  readonly type = DictionaryCarbonMaterialTypeLoadListActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class DictionaryCarbonMaterialTypeLoadListSuccessAction implements PayloadAction {
  readonly type = DictionaryCarbonMaterialTypeLoadListActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedList>) {
  }
}

export class DictionaryCarbonMaterialTypeLoadListClearAction implements PayloadAction {
  readonly type = DictionaryCarbonMaterialTypeLoadListActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}

export class DictionaryCarbonMaterialTypeLoadListChangeParamsAction implements PayloadAction {
  readonly type = DictionaryCarbonMaterialTypeLoadListActionTypes.changeParams;

  constructor(public payload: ChangeParamsByIdPayload<SymfonyPaginationParams>) {
  }
}

export class DictionaryCarbonMaterialTypeLoadListClearParamsAction implements PayloadAction {
  readonly type = DictionaryCarbonMaterialTypeLoadListActionTypes.clearParams;

  constructor(public payload: ByIdPayload) {
  }
}


export class DictionaryCarbonMaterialTypeLoadListClearIdsAction implements PayloadAction {
  readonly type = DictionaryCarbonMaterialTypeLoadListActionTypes.clearIds;

  constructor(public payload: ByIdPayload) {
  }
}
