import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Part } from '../../../store/part/models/part';
import { ByKey, IdType } from '@industi/ngx-common';
import { select, Store } from '@ngrx/store';
import { PartListAdapterService } from '../../../store/part/adapters/list-adapter.service';
import { AppGlobal } from '../../app-global';
import { ItemListAdapterService } from '../../../store/item/common/adapters/list-adapter.service';
import { DiagramListAdapterService } from '../../../store/diagram/common/adapters/list-adapter.service';
import { Item } from '../../../store/item/common/models/item';
import { Diagram } from '../../../store/diagram/common/models/diagram';

@Component({
  selector: 'app-global-search-result',
  templateUrl: './global-search-result.component.html',
  styleUrls: ['./global-search-result.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GlobalSearchResultComponent
  implements OnInit {

  partList$: Observable<Part[]>;
  partListLoading$: Observable<boolean>;

  itemList$: Observable<Item[]>;
  itemListLoading$: Observable<boolean>;

  diagramList$: Observable<Diagram[]>;
  diagramListLoading$: Observable<boolean>;

  constructor(
    private store: Store<ByKey>,
    private partListAdapter: PartListAdapterService,
    private itemListAdapter: ItemListAdapterService,
    private diagramListAdapter: DiagramListAdapterService
  ) { }

  ngOnInit(): void {
    this.partList$ = this.store.pipe(select(this.partListAdapter.getDataSelector(AppGlobal.addId)));
    this.partListLoading$ = this.store.pipe(select(this.partListAdapter.getLoadingSelector(AppGlobal.addId)));

    this.itemList$ = this.store.pipe(select(this.itemListAdapter.getDataSelector(AppGlobal.addId)));
    this.itemListLoading$ = this.store.pipe(select(this.itemListAdapter.getLoadingSelector(AppGlobal.addId)));

    this.diagramList$ = this.store.pipe(select(this.diagramListAdapter.getDataSelector(AppGlobal.addId)));
    this.diagramListLoading$ = this.store.pipe(select(this.diagramListAdapter.getLoadingSelector(AppGlobal.addId)));
  }

  trackById(item: any): IdType {
    return item?.id;
  }

}
