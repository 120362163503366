import { AppMenuItem } from '../../shared/modules/app-menu/app-menu-item';
import * as _ from 'lodash';
import { Role } from '../auth/auth-roles';

export class TemplateUtil {

  static getMenu(roles: Role[], menuItems: AppMenuItem[]): AppMenuItem[] {
    return TemplateUtil
      .filterByRole(roles, menuItems)
      .filter((item: AppMenuItem) => !item?.disabled)
      .map((item: AppMenuItem) => {
        const accessibleItem = _.assign({}, item) as AppMenuItem;
        if (accessibleItem.children && accessibleItem.children.length > 0) {
          accessibleItem.children = TemplateUtil.getMenu(roles, accessibleItem.children);
        }
        return accessibleItem;
      });
  }

  private static filterByRole(roles: Role[], menuItems: AppMenuItem[]): AppMenuItem[] {
    return (menuItems || []).filter((item: AppMenuItem) => {

      // Jeśli item nie ma określonej listy ról, to zostawiamy go w tablice
      if (item && (!item.roles || item.roles.length === 0)) {
        return true;
      }

      const menuItemRoles: Role[] = item.roles || [];
      const intersectedRolesWithItemRoles: Role[] = _.intersection<Role>(roles, menuItemRoles);

      // Jeśli nie znaleziono odpowiedniej roli, to usuwamy item
      if (intersectedRolesWithItemRoles.length === 0) {
        return false;
      }

      return true;
    });
  }


}
