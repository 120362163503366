import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IcsConversationStoreModule } from './conversation/conversation-store.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    IcsConversationStoreModule
  ]
})
export class IcsStoreModule { }
