import {
  ByIdPayload,
  FailByIdPayload,
  OperationReducerTypes,
  PayloadAction,
  uniqueActionType
} from '@industi/ngx-common';

export const ServiceProjectPartDeleteActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[service/project-part] Delete'),
  requestFail: uniqueActionType('[service/project-part] Delete fail'),
  requestSuccess: uniqueActionType('[service/project-part] Delete success'),
  requestClearErrors: uniqueActionType('[service/project-part] Delete clear errors'),
  requestClear: uniqueActionType('[service/project-part] Delete clear')
};

export class ServiceProjectPartDeleteAction implements PayloadAction {
  readonly type = ServiceProjectPartDeleteActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class ServiceProjectPartDeleteFailAction implements PayloadAction {
  readonly type = ServiceProjectPartDeleteActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class ServiceProjectPartDeleteSuccessAction implements PayloadAction {
  readonly type = ServiceProjectPartDeleteActionTypes.requestSuccess;

  constructor(public payload: ByIdPayload) {
  }
}

export class ServiceProjectPartDeleteClearErrorsAction implements PayloadAction {
  readonly type = ServiceProjectPartDeleteActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class ServiceProjectPartDeleteClearAction implements PayloadAction {
  readonly type = ServiceProjectPartDeleteActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
