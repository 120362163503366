import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TEMPLATE_REDUCER_TOKEN, templateReducerProvider } from './template.reducer';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { TemplateEffects } from './template.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('template', TEMPLATE_REDUCER_TOKEN),
    EffectsModule.forFeature([TemplateEffects])
  ],
  providers: [
    templateReducerProvider
  ]
})
export class TemplateStoreModule { }
