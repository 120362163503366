import { AuthUtil } from './auth.util';
import { LibAuthSetTokenAction, LibAuthSetTokenActionTypes } from 'app-store-auth';
import { ParsedToken } from './models/parsed-token';
import { ActionReducer, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import { InjectionToken, Provider } from '@angular/core';
import * as _ from 'lodash';
import { setValueReducer } from '@industi/ngx-common';

export const rootReducerKey = 'appAuth';

export interface AppAuthState {
  parsedAccessToken: ParsedToken;
}

const initialState: AppAuthState = {
  parsedAccessToken: AuthUtil.parseTokenString(AuthUtil.accessToken),
};

const reducer: ActionReducer<AppAuthState> = combineReducers<AppAuthState>({
  parsedAccessToken: setValueReducer<ParsedToken>({
    types: LibAuthSetTokenActionTypes,
    mapActionToValue: (action: LibAuthSetTokenAction) => AuthUtil.parseTokenString(_.get(action, 'payload.value.access_token', null))
  })
}, initialState);

export function getReducers(): ActionReducer<AppAuthState> {
  return reducer;
}

export const APP_AUTH_REDUCER_TOKEN = new InjectionToken<ActionReducer<AppAuthState>>(rootReducerKey);

export const appAuthReducerProvider: Provider = { provide: APP_AUTH_REDUCER_TOKEN, useFactory: getReducers };

const getState = createFeatureSelector<AppAuthState>(rootReducerKey);

export const getParsedToken = createSelector(
  getState,
  (state: AppAuthState) => state && state.parsedAccessToken
);

export const getLoggedUserId = createSelector(
  getParsedToken,
  (token: ParsedToken) => token && token.username
);

export const getLoggedUserRoles = createSelector(
  getParsedToken,
  (token: ParsedToken) => token && token.roles
);

export const getLoggedUserName = createSelector(
  getParsedToken,
  (token: ParsedToken) => token && token.fullName
);
