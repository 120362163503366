import {
  ByIdPayload,
  FailByIdPayload,
  OperationReducerTypes,
  PayloadAction,
  uniqueActionType
} from '@industi/ngx-common';

export const ServiceProjectItemDeleteActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[service/project-item] Delete'),
  requestFail: uniqueActionType('[service/project-item] Delete fail'),
  requestSuccess: uniqueActionType('[service/project-item] Delete success'),
  requestClearErrors: uniqueActionType('[service/project-item] Delete clear errors'),
  requestClear: uniqueActionType('[service/project-item] Delete clear')
};

export class ServiceProjectItemDeleteAction implements PayloadAction {
  readonly type = ServiceProjectItemDeleteActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class ServiceProjectItemDeleteFailAction implements PayloadAction {
  readonly type = ServiceProjectItemDeleteActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class ServiceProjectItemDeleteSuccessAction implements PayloadAction {
  readonly type = ServiceProjectItemDeleteActionTypes.requestSuccess;

  constructor(public payload: ByIdPayload) {
  }
}

export class ServiceProjectItemDeleteClearErrorsAction implements PayloadAction {
  readonly type = ServiceProjectItemDeleteActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class ServiceProjectItemDeleteClearAction implements PayloadAction {
  readonly type = ServiceProjectItemDeleteActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
