import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SendByIdPayload,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { Dictionary } from '../../../../shared/models/dictionary';

export const DictionaryAcquisitionAddActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[dictionary/acquisition] Add'),
  requestFail: uniqueActionType('[dictionary/acquisition] Add fail'),
  requestSuccess: uniqueActionType('[dictionary/acquisition] Add success'),
  requestClearErrors: uniqueActionType('[dictionary/acquisition] Add clear errors'),
  requestClear: uniqueActionType('[dictionary/acquisition] Add clear'),
};

export class DictionaryAcquisitionAddAction implements PayloadAction {
  readonly type = DictionaryAcquisitionAddActionTypes.request;

  constructor(public payload: SendByIdPayload<Dictionary>) {
  }
}

export class DictionaryAcquisitionAddFailAction implements PayloadAction {
  readonly type = DictionaryAcquisitionAddActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class DictionaryAcquisitionAddSuccessAction implements PayloadAction {
  readonly type = DictionaryAcquisitionAddActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class DictionaryAcquisitionAddClearErrorsAction implements PayloadAction {
  readonly type = DictionaryAcquisitionAddActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class DictionaryAcquisitionAddClearAction implements PayloadAction {
  readonly type = DictionaryAcquisitionAddActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
