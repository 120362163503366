import { Inject, Injectable } from '@angular/core';
import { API_URL } from '../app-store-providers';
import { HttpClient } from '@angular/common/http';
import { ClientListParams } from './models/client-list-params';
import { Client } from './models/client';
import { EntityTypes } from '../entity/entity-types';
import { ClientPayload } from './models/client-payload';
import { StoreBaseService } from '../common/base.service';

@Injectable({
  providedIn: 'root'
})
export class ClientService
  extends StoreBaseService<Client, ClientPayload, ClientListParams> {

  get entityType(): string {
    return EntityTypes.client;
  }

  get resourceName(): string {
    return 'client';
  }

  constructor(
    @Inject(API_URL) apiUrl: string,
    http: HttpClient
  ) {
    super(apiUrl, http);
  }
}
