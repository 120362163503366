import * as _ from 'lodash';

export class AlertUtil {

  private static readonly controlsKeys: { [key in string]: string } = {
    brand: 'Marka',
    brandId: 'Marka',
    code: 'Numer części',
    decisionId: 'Decyzja',
    email: 'E-mail',
    modelId: 'Model',
    'measureUnit.measureType': 'Miara',
    name: 'Nazwa',
    number: 'Numer',
    password: 'Podaj hasło',
    phoneNumber: 'Telefon',
    processId: 'Proces',
    quantity: 'Ilość',
    repeatPassword: 'Potwierdź hasło',
    roles: 'Przypisane uprawnienia',
    surname: 'Nazwisko',
    username: 'E-mail',
    unitStateId: 'Stan',
    images: 'Galeria',
    offerItems: 'Egzemplarze',
    'version.bodywork': 'Cabrio/coupe',
    'shipment.sentAt': 'Data nadania',
    endedAt: 'Zakończenie projektu',
    version: 'Wersja'
  };

  static getControlTranslation(message: string) {
    const messageKeys = message.split(':');
    const keyFirst = _.first(messageKeys);
    messageKeys[0] = AlertUtil.controlsKeys[keyFirst] || keyFirst;
    return messageKeys.join(':');
  }

  static getMultipleControlTranslations(message: string) {
    const messageArray = message.split('\n');
    const messages = messageArray.map(messageItem => messageItem.split(':'));
    return messages.map(messageItem => {
      const keyFirst = _.first(messageItem);
      messageItem[0] = AlertUtil.controlsKeys[keyFirst] || keyFirst;
      return messageItem.join(':');
    });
  }
}
