import { ByKey, listByIdReducer, ListState, operationByIdReducer, SingleState } from '@industi/ngx-common';
import { ActionReducer, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import { InjectionToken, Provider } from '@angular/core';
import { DictionaryOperationLoadDetailsActionTypes } from './actions/load-details.actions';
import { DictionaryOperationLoadListActionTypes } from './actions/list.actions';
import { DictionaryOperationEditActionTypes } from './actions/edit.actions';
import { DictionaryOperationAddActionTypes } from './actions/add.actions';
import { DictionaryOperationDeleteActionTypes } from './actions/delete.actions';

export const rootReducerKey = 'operation';

export interface DictionaryOperationOperationByIdState {
  add: SingleState;
  edit: SingleState;
  details: SingleState;
  delete: SingleState;
}

export interface DictionaryOperationState {
  operationsById: ByKey<DictionaryOperationOperationByIdState>;
  list: ByKey<ListState>;
}

const initialState: DictionaryOperationState = {
  operationsById: {},
  list: {}
};

const reducer: ActionReducer<DictionaryOperationState> = combineReducers<DictionaryOperationState>({
  operationsById: operationByIdReducer<DictionaryOperationOperationByIdState>({
    mapActionToId: (a) => a && a.payload && a.payload.id,
    operations: {
      add: DictionaryOperationAddActionTypes,
      edit: DictionaryOperationEditActionTypes,
      details: DictionaryOperationLoadDetailsActionTypes,
      delete: DictionaryOperationDeleteActionTypes
    }
  }),
  list: listByIdReducer({ types: DictionaryOperationLoadListActionTypes })
}, initialState);

export function getReducers(): ActionReducer<DictionaryOperationState> {
  return reducer;
}

export const DICTIONARY_OPERATION_REDUCER_TOKEN = new InjectionToken<ActionReducer<DictionaryOperationState>>(rootReducerKey);

export const dictionaryOperationReducerProvider: Provider = { provide: DICTIONARY_OPERATION_REDUCER_TOKEN, useFactory: getReducers };

const getState = createFeatureSelector<DictionaryOperationState>(rootReducerKey);

export const getDictionaryOperationOperationsById = createSelector(
  getState,
  (state: DictionaryOperationState) => state && state.operationsById
);

export const getDictionaryOperationListState = createSelector(
  getState,
  (state: DictionaryOperationState) => state && state.list
);
