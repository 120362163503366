import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SendByIdPayload,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { DictionaryCarbonMaterial } from '../models/carbon-material';

export const DictionaryCarbonMaterialAddActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[dictionary/carbon-material] Add'),
  requestFail: uniqueActionType('[dictionary/carbon-material] Add fail'),
  requestSuccess: uniqueActionType('[dictionary/carbon-material] Add success'),
  requestClearErrors: uniqueActionType('[dictionary/carbon-material] Add clear errors'),
  requestClear: uniqueActionType('[dictionary/carbon-material] Add clear'),
};

export class DictionaryCarbonMaterialAddAction implements PayloadAction {
  readonly type = DictionaryCarbonMaterialAddActionTypes.request;

  constructor(public payload: SendByIdPayload<DictionaryCarbonMaterial>) {
  }
}

export class DictionaryCarbonMaterialAddFailAction implements PayloadAction {
  readonly type = DictionaryCarbonMaterialAddActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class DictionaryCarbonMaterialAddSuccessAction implements PayloadAction {
  readonly type = DictionaryCarbonMaterialAddActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class DictionaryCarbonMaterialAddClearErrorsAction implements PayloadAction {
  readonly type = DictionaryCarbonMaterialAddActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class DictionaryCarbonMaterialAddClearAction implements PayloadAction {
  readonly type = DictionaryCarbonMaterialAddActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
