import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';

export const ClientLoadDetailsActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[client] Load details'),
  requestFail: uniqueActionType('[client] Load details fail'),
  requestSuccess: uniqueActionType('[client] Load details success'),
  requestClearErrors: uniqueActionType('[client] Load details clear errors'),
  requestClear: uniqueActionType('[client] Load details clear'),
};

export class ClientLoadDetailsAction implements PayloadAction {
  readonly type = ClientLoadDetailsActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class ClientLoadDetailsFailAction implements PayloadAction {
  readonly type = ClientLoadDetailsActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class ClientLoadDetailsSuccessAction implements PayloadAction {
  readonly type = ClientLoadDetailsActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class ClientLoadDetailsClearErrorsAction implements PayloadAction {
  readonly type = ClientLoadDetailsActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class ClientLoadDetailsClearAction implements PayloadAction {
  readonly type = ClientLoadDetailsActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
