import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SeakPaginationDirective } from './directives/seak-pagination.directive';

@NgModule({
  declarations: [
    SeakPaginationDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    SeakPaginationDirective
  ]
})
export class AppSeakPaginationModule { }
