import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { ByIdPayload, ByKey, IdType, NormalizedList, NormalizedSingle } from '@industi/ngx-common';
import { DiagramCommonService } from './common.service';
import { catchError, first, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { getInstanceLocale } from '../../instance/instance.reducer';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import { DiagramListParams } from './models/diagram-list-params';
import {
  DiagramLoadListAction,
  DiagramLoadListActionTypes,
  DiagramLoadListClearIdsAction,
  DiagramLoadListFailAction,
  DiagramLoadListSuccessAction
} from './actions/list.actions';
import { createDiagramListParams } from './selectors/get-load-list.selectors';
import {
  DiagramLoadDetailsAction,
  DiagramLoadDetailsActionTypes,
  DiagramLoadDetailsFailAction,
  DiagramLoadDetailsSuccessAction
} from './actions/load-details.actions';
import {
  DiagramEditAction,
  DiagramEditActionTypes,
  DiagramEditFailAction,
  DiagramEditSuccessAction
} from './actions/edit.actions';
import {
  DiagramAddAction,
  DiagramAddActionTypes,
  DiagramAddFailAction,
  DiagramAddSuccessAction
} from './actions/add.actions';
import { DiagramSetLastAddedAdapterService } from './adapters/set-last-added-adapter.service';
import {
  DiagramDeleteAction,
  DiagramDeleteActionTypes,
  DiagramDeleteFailAction,
  DiagramDeleteSuccessAction
} from './actions/delete.actions';
import { ResponseStatus } from '../../../shared/enums/response-status.enum';
import { LibSnackbarComponent } from '@industi/ngx-modules';
import { AppGlobal } from '../../../shared/app-global';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlertUtil } from '../../../shared/utils/alert.util';

@Injectable()
export class DiagramCommonEffects {

  @Effect()
  loadList$ = this.actions$.pipe(
    ofType(DiagramLoadListActionTypes.request),
    map((action: DiagramLoadListAction) => action.payload),
    switchMap((payload: ByIdPayload) => this.store.pipe(
      select(createDiagramListParams(payload.id)),
      map((params: DiagramListParams) => [payload.id, params] as [IdType, DiagramListParams]),
      first()
    )),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([[ id, params ], locale]) => this.service.loadList(locale, params).pipe(
      mergeMap((res: NormalizedList) => [
        new DiagramLoadListClearIdsAction({ id }),
        new DiagramLoadListSuccessAction({ id, res })]),
      catchError((errors: HttpErrorResponse) => of(new DiagramLoadListFailAction({ id, errors })))
    ))
  );

  @Effect()
  add$ = this.actions$.pipe(
    ofType(DiagramAddActionTypes.request),
    map((action: DiagramAddAction) => action.payload),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([{ id, data }, locale]) => this.service.add(locale, data).pipe(
      mergeMap((res: NormalizedSingle) => [
        this.lastAddedAdapter.setValueAction(res.result),
        new DiagramAddSuccessAction({ res, id })
      ]),
      catchError((errors: HttpErrorResponse) => of(new DiagramAddFailAction({ errors, id })))
    ))
  );

  @Effect()
  edit$ = this.actions$.pipe(
    ofType(DiagramEditActionTypes.request),
    map((action: DiagramEditAction) => action.payload),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([{ id, data }, locale]) => this.service.edit(locale, data, id).pipe(
      map((res: NormalizedSingle) => new DiagramEditSuccessAction({ res, id })),
      catchError((errors: HttpErrorResponse) => of(new DiagramEditFailAction({ errors, id })))
    ))
  );

  @Effect()
  loadDetails$ = this.actions$.pipe(
    ofType(DiagramLoadDetailsActionTypes.request),
    map((action: DiagramLoadDetailsAction) => action.payload.id),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([id, locale]) => this.service.details(locale, id).pipe(
      map((res: NormalizedSingle) => new DiagramLoadDetailsSuccessAction({ id, res })),
      catchError((errors: HttpErrorResponse) => of(new DiagramLoadDetailsFailAction({ id, errors })))
    ))
  );

  @Effect()
  delete$ = this.actions$.pipe(
    ofType(DiagramDeleteActionTypes.request),
    map((action: DiagramDeleteAction) => action.payload.id),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([id, locale]) => this.service.delete(locale, id).pipe(
      map(() => new DiagramDeleteSuccessAction({ id })),
      catchError((errors: HttpErrorResponse) => of(new DiagramDeleteFailAction({ id, errors })))
    ))
  );

  @Effect({ dispatch: false })
  crudFail$ = this.actions$.pipe(
    ofType(
      DiagramAddActionTypes.requestFail,
      DiagramEditActionTypes.requestFail
    ),
    map((action: DiagramAddFailAction | DiagramEditFailAction) => action.payload.errors),
    tap((data: HttpErrorResponse) => {
      if (data?.status === ResponseStatus.BAD_REQUEST || data?.status === ResponseStatus.UNPROCESSABLE_ENTITY) {
        this.snackBar.openFromComponent(LibSnackbarComponent, {
          data: {
            message: AlertUtil.getControlTranslation(data.error.detail),
            type: 'error'
          },
          duration: AppGlobal.defaultSnackBarDuration
        });
      }
    })
  );

  constructor(
    private actions$: Actions,
    private store: Store<ByKey>,
    private service: DiagramCommonService,
    private lastAddedAdapter: DiagramSetLastAddedAdapterService,
    private snackBar: MatSnackBar
  ) {
  }
}
