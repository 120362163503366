import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';

export const ItemLoadDetailsActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[item] Load details'),
  requestFail: uniqueActionType('[item] Load details fail'),
  requestSuccess: uniqueActionType('[item] Load details success'),
  requestClearErrors: uniqueActionType('[item] Load details clear errors'),
  requestClear: uniqueActionType('[item] Load details clear')
};

export class ItemLoadDetailsAction implements PayloadAction {
  readonly type = ItemLoadDetailsActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class ItemLoadDetailsFailAction implements PayloadAction {
  readonly type = ItemLoadDetailsActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class ItemLoadDetailsSuccessAction implements PayloadAction {
  readonly type = ItemLoadDetailsActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class ItemLoadDetailsClearAction implements PayloadAction {
  readonly type = ItemLoadDetailsActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}

export class ItemLoadDetailsClearErrorsAction implements PayloadAction {
  readonly type = ItemLoadDetailsActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}
