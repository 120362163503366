import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SendByIdPayload,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { ArticleGalleryContentPayload } from '../models/gallery-content-payload';

export const ArticleGalleryContentAddActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[article/gallery-content] Add'),
  requestFail: uniqueActionType('[article/gallery-content] Add fail'),
  requestSuccess: uniqueActionType('[article/gallery-content] Add success'),
  requestClearErrors: uniqueActionType('[article/gallery-content] Add clear errors'),
  requestClear: uniqueActionType('[article/gallery-content] Add clear'),
};

export class ArticleGalleryContentAddAction implements PayloadAction {
  readonly type = ArticleGalleryContentAddActionTypes.request;

  constructor(public payload: SendByIdPayload<ArticleGalleryContentPayload>) {
  }
}

export class ArticleGalleryContentAddFailAction implements PayloadAction {
  readonly type = ArticleGalleryContentAddActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class ArticleGalleryContentAddSuccessAction implements PayloadAction {
  readonly type = ArticleGalleryContentAddActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class ArticleGalleryContentAddClearErrorsAction implements PayloadAction {
  readonly type = ArticleGalleryContentAddActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class ArticleGalleryContentAddClearAction implements PayloadAction {
  readonly type = ArticleGalleryContentAddActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
