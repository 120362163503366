import { Inject, Injectable } from '@angular/core';
import { API_URL } from '../../../app-store-providers';
import { HttpClient } from '@angular/common/http';
import { EntityTypes } from '../../../entity/entity-types';
import { ServiceProjectOperationPayload } from './models/operation-payload';
import { ServiceProjectOperation } from './models/operation';
import { ServiceProjectOperationListParams } from './models/operation-list-params';
import { StoreBaseService } from '../../../common/base.service';

@Injectable({
  providedIn: 'root'
})
export class ServiceProjectOperationService
  extends StoreBaseService<ServiceProjectOperation, ServiceProjectOperationPayload, ServiceProjectOperationListParams> {

  get entityType(): string {
    return EntityTypes.projectOperation;
  }

  get resourceName(): string {
    return 'service/project-operation';
  }

  constructor(
    @Inject(API_URL) apiUrl: string,
    http: HttpClient
  ) {
    super(apiUrl, http);
  }
}
