import { ByKey, listByIdReducer, ListState, operationByIdReducer, SingleState } from '@industi/ngx-common';
import { ActionReducer, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import { DictionaryAcquisitionAddActionTypes } from './actions/add.actions';
import { DictionaryAcquisitionEditActionTypes } from './actions/edit.actions';
import { DictionaryAcquisitionLoadDetailsActionTypes } from './actions/load-details.actions';
import { DictionaryAcquisitionDeleteActionTypes } from './actions/delete.actions';
import { DictionaryAcquisitionLoadListActionTypes } from './actions/list.actions';
import { InjectionToken, Provider } from '@angular/core';

export const rootReducerKey = 'acquisition';

export interface DictionaryAcquisitionOperationByIdState {
  add: SingleState;
  edit: SingleState;
  details: SingleState;
  delete: SingleState;
}

export interface DictionaryAcquisitionState {
  operationsById: ByKey<DictionaryAcquisitionOperationByIdState>;
  list: ByKey<ListState>;
}

const initialState: DictionaryAcquisitionState = {
  operationsById: {},
  list: {}
};

const reducer: ActionReducer<DictionaryAcquisitionState> = combineReducers<DictionaryAcquisitionState>({
  operationsById: operationByIdReducer<DictionaryAcquisitionOperationByIdState>({
    mapActionToId: (a) => a && a.payload && a.payload.id,
    operations: {
      add: DictionaryAcquisitionAddActionTypes,
      edit: DictionaryAcquisitionEditActionTypes,
      details: DictionaryAcquisitionLoadDetailsActionTypes,
      delete: DictionaryAcquisitionDeleteActionTypes
    }
  }),
  list: listByIdReducer({ types: DictionaryAcquisitionLoadListActionTypes })
}, initialState);

export function getReducers(): ActionReducer<DictionaryAcquisitionState> {
  return reducer;
}

export const DICTIONARY_ACQUISITION_REDUCER_TOKEN = new InjectionToken<ActionReducer<DictionaryAcquisitionState>>(rootReducerKey);

export const dictionaryAcquisitionReducerProvider: Provider = {
  provide: DICTIONARY_ACQUISITION_REDUCER_TOKEN,
  useFactory: getReducers
};

const getState = createFeatureSelector<DictionaryAcquisitionState>(rootReducerKey);

export const getDictionaryAcquisitionOperationsById = createSelector(
  getState,
  (state: DictionaryAcquisitionState) => state && state.operationsById
);

export const getDictionaryAcquisitionListState = createSelector(
  getState,
  (state: DictionaryAcquisitionState) => state && state.list
);
