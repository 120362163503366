import { NgModule } from '@angular/core';
import { OrderCommonStoreModule } from './common/common-store.module';
import { OrderItemStoreModule } from './item/item-store.module';

@NgModule({
  declarations: [],
  imports: [
    OrderCommonStoreModule,
    OrderItemStoreModule
  ]
})
export class OrderStoreModule { }
