import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SendByIdPayload,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { Dictionary } from '../../../../shared/models/dictionary';

export const DictionaryCarbonMaterialTypeEditActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[dictionary/carbon-material-type] Edit'),
  requestFail: uniqueActionType('[dictionary/carbon-material-type] Edit fail'),
  requestSuccess: uniqueActionType('[dictionary/carbon-material-type] Edit success'),
  requestClearErrors: uniqueActionType('[dictionary/carbon-material-type] Edit clear errors'),
  requestClear: uniqueActionType('[dictionary/carbon-material-type] Edit clear')
};

export class DictionaryCarbonMaterialTypeEditAction implements PayloadAction {
  readonly type = DictionaryCarbonMaterialTypeEditActionTypes.request;

  constructor(public payload: SendByIdPayload<Dictionary>) {
  }
}

export class DictionaryCarbonMaterialTypeEditFailAction implements PayloadAction {
  readonly type = DictionaryCarbonMaterialTypeEditActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class DictionaryCarbonMaterialTypeEditSuccessAction implements PayloadAction {
  readonly type = DictionaryCarbonMaterialTypeEditActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class DictionaryCarbonMaterialTypeEditClearErrorsAction implements PayloadAction {
  readonly type = DictionaryCarbonMaterialTypeEditActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class DictionaryCarbonMaterialTypeEditClearAction implements PayloadAction {
  readonly type = DictionaryCarbonMaterialTypeEditActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
