import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SendByIdPayload,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { CarbonPartCommission } from '../models/part-commission';

export const CarbonPartCommissionEditActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[carbon/part-commission] Edit'),
  requestFail: uniqueActionType('[carbon/part-commission] Edit fail'),
  requestSuccess: uniqueActionType('[carbon/part-commission] Edit success'),
  requestClearErrors: uniqueActionType('[carbon/part-commission] Edit clear errors'),
  requestClear: uniqueActionType('[carbon/part-commission] Edit clear')
};

export class CarbonPartCommissionEditAction implements PayloadAction {
  readonly type = CarbonPartCommissionEditActionTypes.request;

  constructor(public payload: SendByIdPayload<CarbonPartCommission>) {
  }
}

export class CarbonPartCommissionEditFailAction implements PayloadAction {
  readonly type = CarbonPartCommissionEditActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class CarbonPartCommissionEditSuccessAction implements PayloadAction {
  readonly type = CarbonPartCommissionEditActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class CarbonPartCommissionEditClearErrorsAction implements PayloadAction {
  readonly type = CarbonPartCommissionEditActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class CarbonPartCommissionEditClearAction implements PayloadAction {
  readonly type = CarbonPartCommissionEditActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
