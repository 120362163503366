import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { normalize } from 'normalizr';
import { Entities } from '../entity/entities';
import { SymfonyPaginationParams } from '../symfony/list/models/symfony-pagination-params';
import { SymfonyListUtil } from '../symfony/list/utils/symfony-list-util';
import { SymfonyModuleHttpClientConfig } from '../symfony/resources/symfony-module-http-client.config';
import { IdType, NormalizedList, NormalizedSingle, QueryParamsUtil } from '@industi/ngx-common';

export abstract class StoreBaseService<ApiOutputModel, ApiInputModel = ApiOutputModel, ApiParamsModel
  extends SymfonyPaginationParams = SymfonyPaginationParams> {

  abstract get entityType(): string;

  abstract get resourceName(): string;

  getDictionaryListPath(locale: string, resourceName: string): string {
    return `${this.apiUrl}/${locale}/api/${resourceName}`;
  }

  getDictionaryDetailsPath(locale: string, resourceName: string, id: IdType): string {
    return `${this.apiUrl}/${locale}/api/${resourceName}/${id}`;
  }

  getDictionaryAddPath(locale: string, resourceName: string): string {
    return `${this.apiUrl}/${locale}/api/${resourceName}`;
  }

  getDictionaryEditPath(locale: string, resourceName: string, id: IdType): string {
    return `${this.apiUrl}/${locale}/api/${resourceName}/${id}`;
  }

  getDictionaryDeletePath(locale: string, resourceName: string, id: IdType): string {
    return `${this.apiUrl}/${locale}/api/${resourceName}/${id}`;
  }

  protected constructor(
    protected apiUrl: string,
    protected http: HttpClient
  ) {
  }

  loadList(locale: string, params: ApiParamsModel): Observable<NormalizedList> {
    const url = `${this.getDictionaryListPath(locale, this.resourceName)}?${QueryParamsUtil.toParamsString(params)}`;
    return this.http.get<ApiOutputModel[]>(url, SymfonyModuleHttpClientConfig).pipe(
      map((res: HttpResponse<ApiOutputModel[]>) => ({
        ...normalize(res?.body, [Entities[this.entityType]]),
        meta: {
          maxResults: SymfonyListUtil.getCount(res)
        }
      } as NormalizedList))
    );
  }

  details(locale: string, id: IdType): Observable<NormalizedSingle> {
    const url = this.getDictionaryDetailsPath(locale, this.resourceName, id);
    return this.http.get<ApiOutputModel>(url).pipe(
      map((res: ApiOutputModel) => normalize(res, Entities[this.entityType]))
    );
  }

  add(locale: string, data: ApiInputModel): Observable<NormalizedSingle> {
    const url = this.getDictionaryAddPath(locale, this.resourceName);
    return this.http.post<ApiOutputModel>(url, data).pipe(
      map((res: ApiOutputModel) => normalize(res, Entities[this.entityType]))
    );
  }

  edit(locale: string, data: ApiInputModel, id: IdType): Observable<NormalizedSingle> {
    const url = this.getDictionaryEditPath(locale, this.resourceName, id);
    return this.http.put<ApiOutputModel>(url, data).pipe(
      map((res: ApiOutputModel) => normalize(res, Entities[this.entityType]))
    );
  }

  delete(locale: string, id: IdType): Observable<void> {
    const url = this.getDictionaryDeletePath(locale, this.resourceName, id);
    return this.http.delete<void>(url);
  }
}
