import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SendByIdPayload,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { ModelPayload } from '../models/model-payload';

export const ModelAddActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[model] Add'),
  requestFail: uniqueActionType('[model] Add fail'),
  requestSuccess: uniqueActionType('[model] Add success'),
  requestClearErrors: uniqueActionType('[model] Add clear errors'),
  requestClear: uniqueActionType('[model] Add clear'),
};

export class ModelAddAction implements PayloadAction {
  readonly type = ModelAddActionTypes.request;

  constructor(public payload: SendByIdPayload<ModelPayload>) {
  }
}

export class ModelAddFailAction implements PayloadAction {
  readonly type = ModelAddActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class ModelAddSuccessAction implements PayloadAction {
  readonly type = ModelAddActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class ModelAddClearErrorsAction implements PayloadAction {
  readonly type = ModelAddActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class ModelAddClearAction implements PayloadAction {
  readonly type = ModelAddActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
