import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { OfferCommonEffects } from './common.effects';
import { OFFER_COMMON_REDUCER_TOKEN, offerCommonReducerProvider, rootReducerKey } from './common.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(rootReducerKey, OFFER_COMMON_REDUCER_TOKEN),
    EffectsModule.forFeature([OfferCommonEffects])
  ],
  providers: [
    offerCommonReducerProvider
  ]
})
export class OfferCommonStoreModule {
}
