import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SendByIdPayload,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { DiagramPart } from '../models/part';

export const DiagramPartEditActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[diagram/part] Edit'),
  requestFail: uniqueActionType('[diagram/part] Edit fail'),
  requestSuccess: uniqueActionType('[diagram/part] Edit success'),
  requestClearErrors: uniqueActionType('[diagram/part] Edit clear errors'),
  requestClear: uniqueActionType('[diagram/part] Edit clear')
};

export class DiagramPartEditAction implements PayloadAction {
  readonly type = DiagramPartEditActionTypes.request;

  constructor(public payload: SendByIdPayload<DiagramPart>) {
  }
}

export class DiagramPartEditFailAction implements PayloadAction {
  readonly type = DiagramPartEditActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class DiagramPartEditSuccessAction implements PayloadAction {
  readonly type = DiagramPartEditActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class DiagramPartEditClearErrorsAction implements PayloadAction {
  readonly type = DiagramPartEditActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class DiagramPartEditClearAction implements PayloadAction {
  readonly type = DiagramPartEditActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
