import { Inject, Injectable } from '@angular/core';
import { API_URL } from '../app-store-providers';
import { HttpClient } from '@angular/common/http';
import { EntityTypes } from '../entity/entity-types';
import { DiscountGroupPayload } from './models/discount-group-payload';
import { DiscountGroup } from './models/discount-group';
import { DiscountGroupListParams } from './models/discount-group-list-params';
import { StoreBaseService } from '../common/base.service';

@Injectable({
  providedIn: 'root'
})
export class DiscountGroupService
  extends StoreBaseService<DiscountGroup, DiscountGroupPayload, DiscountGroupListParams> {

  get entityType(): string {
    return EntityTypes.discountGroup;
  }

  get resourceName(): string {
    return 'discount-group';
  }

  constructor(
    @Inject(API_URL) apiUrl: string,
    http: HttpClient
  ) {
    super(apiUrl, http);
  }
}
