import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import {
  rootReducerKey,
  SERVICE_PROJECT_PART_REDUCER_TOKEN,
  serviceProjectPartReducerProvider
} from './part.reducer';
import { EffectsModule } from '@ngrx/effects';
import { ServiceProjectPartEffects } from './part.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(rootReducerKey, SERVICE_PROJECT_PART_REDUCER_TOKEN),
    EffectsModule.forFeature([ServiceProjectPartEffects])
  ],
  providers: [
    serviceProjectPartReducerProvider
  ]
})
export class ServiceProjectPartStoreModule {
}
