import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';

export const DiagramLoadDetailsActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[diagram] Load details'),
  requestFail: uniqueActionType('[diagram] Load details fail'),
  requestSuccess: uniqueActionType('[diagram] Load details success'),
  requestClearErrors: uniqueActionType('[diagram] Load details clear errors'),
  requestClear: uniqueActionType('[diagram] Load details clear')
};

export class DiagramLoadDetailsAction implements PayloadAction {
  readonly type = DiagramLoadDetailsActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class DiagramLoadDetailsFailAction implements PayloadAction {
  readonly type = DiagramLoadDetailsActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class DiagramLoadDetailsSuccessAction implements PayloadAction {
  readonly type = DiagramLoadDetailsActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class DiagramLoadDetailsClearAction implements PayloadAction {
  readonly type = DiagramLoadDetailsActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}

export class DiagramLoadDetailsClearErrorsAction implements PayloadAction {
  readonly type = DiagramLoadDetailsActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}
