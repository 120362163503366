import { ByKey, operationByIdReducer, SingleState } from '@industi/ngx-common';
import { ActionReducer, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import { InjectionToken, Provider } from '@angular/core';
import { ArticleTextContentAddActionTypes } from './actions/add.actions';
import { ArticleTextContentLoadDetailsActionTypes } from './actions/details.actions';
import { ArticleTextContentEditActionTypes } from './actions/edit.actions';

export const rootReducerKey = 'articleTextContent';

export interface ArticleTextContentOperationByIdState {
  add: SingleState;
  edit: SingleState;
  details: SingleState;
}

export interface ArticleTextContentState {
  operationsById: ByKey<ArticleTextContentOperationByIdState>;
}

const initialState: ArticleTextContentState = {
  operationsById: {}
};

const reducer: ActionReducer<ArticleTextContentState> = combineReducers<ArticleTextContentState>({
  operationsById: operationByIdReducer<ArticleTextContentOperationByIdState>({
    mapActionToId: (a) => a?.payload?.id,
    operations: {
      add: ArticleTextContentAddActionTypes,
      edit: ArticleTextContentEditActionTypes,
      details: ArticleTextContentLoadDetailsActionTypes,
    }
  })
}, initialState);

export function getReducers(): ActionReducer<ArticleTextContentState> {
  return reducer;
}

export const ARTICLE_TEXT_CONTENT_REDUCER_TOKEN = new InjectionToken<ActionReducer<ArticleTextContentState>>(rootReducerKey);

export const articleTextContentReducerProvider: Provider = { provide: ARTICLE_TEXT_CONTENT_REDUCER_TOKEN, useFactory: getReducers };

const getState = createFeatureSelector<ArticleTextContentState>(rootReducerKey);

export const getArticleTextContentOperationsById = createSelector(
  getState,
  (state: ArticleTextContentState) => state && state.operationsById
);
