import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { ByIdPayload, ByKey, IdType, NormalizedList, NormalizedSingle } from '@industi/ngx-common';
import { catchError, first, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { getInstanceLocale } from '../../instance/instance.reducer';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import {
  ArticleBrandLoadListAction,
  ArticleBrandLoadListActionTypes,
  ArticleBrandLoadListClearIdsAction,
  ArticleBrandLoadListFailAction,
  ArticleBrandLoadListSuccessAction
} from './actions/list.actions';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  ArticleBrandEditAction,
  ArticleBrandEditActionTypes,
  ArticleBrandEditFailAction,
  ArticleBrandEditSuccessAction
} from './actions/edit.actions';
import {
  ArticleBrandLoadDetailsAction,
  ArticleBrandLoadDetailsActionTypes,
  ArticleBrandLoadDetailsFailAction,
  ArticleBrandLoadDetailsSuccessAction
} from './actions/details.actions';
import {
  ArticleBrandAddAction,
  ArticleBrandAddActionTypes,
  ArticleBrandAddFailAction,
  ArticleBrandAddSuccessAction
} from './actions/add.actions';
import {
  ArticleBrandDeleteAction,
  ArticleBrandDeleteActionTypes,
  ArticleBrandDeleteFailAction,
  ArticleBrandDeleteSuccessAction
} from './actions/delete.actions';
import { LibSnackbarComponent } from '@industi/ngx-modules';
import { ResponseStatus } from '../../../shared/enums/response-status.enum';
import { AlertUtil } from '../../../shared/utils/alert.util';
import { AppGlobal } from '../../../shared/app-global';
import { ArticleBrandService } from './brand.service';
import { ArticleBrandListParams } from './models/article-brand-list-params';
import { createArticleBrandListParams } from './selectors/get-load-list.selectors';
import { ArticleBrandSetLastAddedAdapterService } from './adapters/set-last-added-adapter.service';
import {
  ArticleBrandImageAddAction,
  ArticleBrandImageAddActionTypes,
  ArticleBrandImageAddFailAction,
  ArticleBrandImageAddSuccessAction
} from './actions/add-image.actions';
import { ArticleBrandImageSetLastAddedAdapterService } from './adapters/set-last-added-image-adapter.service';
import {
  ArticleBrandImageDeleteAction,
  ArticleBrandImageDeleteActionTypes,
  ArticleBrandImageDeleteFailAction,
  ArticleBrandImageDeleteSuccessAction
} from './actions/delete-image.actions';

@Injectable()
export class ArticleBrandEffects {

  @Effect()
  loadList$ = this.actions$.pipe(
    ofType(ArticleBrandLoadListActionTypes.request),
    map((action: ArticleBrandLoadListAction) => action.payload),
    switchMap((payload: ByIdPayload) => this.store.pipe(
      select(createArticleBrandListParams(payload.id)),
      map((params: ArticleBrandListParams) => [payload.id, params] as [IdType, ArticleBrandListParams]),
      first()
    )),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([[id, params], locale]) => this.service.loadList(locale, params).pipe(
      mergeMap((res: NormalizedList) => [
        new ArticleBrandLoadListClearIdsAction({ id }),
        new ArticleBrandLoadListSuccessAction({ id, res })]),
      catchError((errors: HttpErrorResponse) => of(new ArticleBrandLoadListFailAction({ id, errors })))
    ))
  );

  @Effect()
  add$ = this.actions$.pipe(
    ofType(ArticleBrandAddActionTypes.request),
    map((action: ArticleBrandAddAction) => action.payload),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([{ id, data }, locale]) => this.service.add(locale, data).pipe(
      mergeMap((res: NormalizedSingle) => [
        this.lastAddedAdapter.setValueAction(res.result),
        new ArticleBrandAddSuccessAction({ res, id })
      ]),
      catchError((errors: HttpErrorResponse) => of(new ArticleBrandAddFailAction({ errors, id })))
    ))
  );

  @Effect()
  edit$ = this.actions$.pipe(
    ofType(ArticleBrandEditActionTypes.request),
    map((action: ArticleBrandEditAction) => action.payload),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([{ id, data }, locale]) => this.service.edit(locale, data, id).pipe(
      map((res: NormalizedSingle) => new ArticleBrandEditSuccessAction({ res, id })),
      catchError((errors: HttpErrorResponse) => of(new ArticleBrandEditFailAction({ errors, id })))
    ))
  );

  @Effect()
  addImage$ = this.actions$.pipe(
    ofType(ArticleBrandImageAddActionTypes.request),
    map((action: ArticleBrandImageAddAction) => action.payload),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([{ id, data }, locale]) => this.service.addImage(locale, data).pipe(
      mergeMap((res: NormalizedSingle) => [
        this.lastAddedImageAdapter.setValueAction(res.result),
        new ArticleBrandImageAddSuccessAction({ res, id })
      ]),
      catchError((errors: HttpErrorResponse) => of(new ArticleBrandImageAddFailAction({ errors, id })))
    ))
  );

  @Effect()
  loadDetails$ = this.actions$.pipe(
    ofType(ArticleBrandLoadDetailsActionTypes.request),
    map((action: ArticleBrandLoadDetailsAction) => action.payload.id),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([id, locale]) => this.service.details(locale, id).pipe(
      map((res: NormalizedSingle) => new ArticleBrandLoadDetailsSuccessAction({ id, res })),
      catchError((errors: HttpErrorResponse) => of(new ArticleBrandLoadDetailsFailAction({ id, errors })))
    ))
  );

  @Effect()
  delete$ = this.actions$.pipe(
    ofType(ArticleBrandDeleteActionTypes.request),
    map((action: ArticleBrandDeleteAction) => action.payload.id),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([id, locale]) => this.service.delete(locale, id).pipe(
      map(() => new ArticleBrandDeleteSuccessAction({ id })),
      catchError((errors: HttpErrorResponse) => of(new ArticleBrandDeleteFailAction({ id, errors })))
    ))
  );

  @Effect()
  deleteImage$ = this.actions$.pipe(
    ofType(ArticleBrandImageDeleteActionTypes.request),
    map((action: ArticleBrandImageDeleteAction) => action.payload.id),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([id, locale]) => this.service.deleteImage(locale, id).pipe(
      map(() => new ArticleBrandImageDeleteSuccessAction({ id })),
      catchError((errors: HttpErrorResponse) => of(new ArticleBrandImageDeleteFailAction({ id, errors })))
    ))
  );

  @Effect({ dispatch: false })
  crudFail$ = this.actions$.pipe(
    ofType(
      ArticleBrandAddActionTypes.requestFail,
      ArticleBrandEditActionTypes.requestFail
    ),
    map((action: ArticleBrandAddFailAction | ArticleBrandEditFailAction) => action.payload.errors),
    tap((data: HttpErrorResponse) => {
      if (data?.status === ResponseStatus.BAD_REQUEST || data?.status === ResponseStatus.UNPROCESSABLE_ENTITY) {
        this.snackBar.openFromComponent(LibSnackbarComponent, {
          data: {
            message: AlertUtil.getControlTranslation(data?.error?.detail),
            type: 'error'
          },
          duration: AppGlobal.defaultSnackBarDuration
        });
      }
    })
  );

  constructor(
    private actions$: Actions,
    private store: Store<ByKey>,
    private service: ArticleBrandService,
    private snackBar: MatSnackBar,
    private lastAddedAdapter: ArticleBrandSetLastAddedAdapterService,
    private lastAddedImageAdapter: ArticleBrandImageSetLastAddedAdapterService
  ) {
  }
}
