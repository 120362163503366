import { ByKey, listByIdReducer, ListState, operationByIdReducer, SingleState } from '@industi/ngx-common';
import { ActionReducer, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import { InjectionToken, Provider } from '@angular/core';
import { OrderLoadListActionTypes } from './actions/list.actions';
import { OrderLoadDetailsActionTypes } from './actions/load-details.actions';
import { OrderEditActionTypes } from './actions/edit.actions';
import { OrderDeleteActionTypes } from './actions/delete.actions';
import { OrderAddActionTypes } from './actions/add.actions';
import { OrderPaidActionTypes } from './actions/paid.actions';
import { OrderSentActionTypes } from './actions/sent.actions';
import { OrderUnpaidActionTypes } from './actions/unpaid.actions';
import { OrderCancelActionTypes } from './actions/cancel.actions';

export const rootReducerKey = 'order';

export interface OrderOperationByIdState {
  add: SingleState;
  edit: SingleState;
  details: SingleState;
  delete: SingleState;
  paid: SingleState;
  unpaid: SingleState;
  sent: SingleState;
  cancel: SingleState;
}

export interface OrderState {
  operationsById: ByKey<OrderOperationByIdState>;
  list: ByKey<ListState>;
}

const initialState: OrderState = {
  operationsById: {},
  list: {}
};

const reducer: ActionReducer<OrderState> = combineReducers<OrderState>({
  operationsById: operationByIdReducer<OrderOperationByIdState>({
    mapActionToId: (a) => a && a.payload && a.payload.id,
    operations: {
      add: OrderAddActionTypes,
      edit: OrderEditActionTypes,
      details: OrderLoadDetailsActionTypes,
      delete: OrderDeleteActionTypes,
      paid: OrderPaidActionTypes,
      unpaid: OrderUnpaidActionTypes,
      sent: OrderSentActionTypes,
      cancel: OrderCancelActionTypes
    }
  }),
  list: listByIdReducer({ types: OrderLoadListActionTypes })
}, initialState);

export function getReducers(): ActionReducer<OrderState> {
  return reducer;
}

export const ORDER_COMMON_REDUCER_TOKEN = new InjectionToken<ActionReducer<OrderState>>(rootReducerKey);

export const orderCommonReducerProvider: Provider = { provide: ORDER_COMMON_REDUCER_TOKEN, useFactory: getReducers };

const getState = createFeatureSelector<OrderState>(rootReducerKey);

export const getOrderOperationsById = createSelector(
  getState,
  (state: OrderState) => state && state.operationsById
);

export const getOrderListState = createSelector(
  getState,
  (state: OrderState) => state && state.list
);
