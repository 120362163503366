export const AppGlobal = {
  page: [],
  filterInputDebounceTime: 500,
  queryDateTimeFormat: 'YYYY-MM-DDTHH:mm:ssZZ',
  queryDateFormat: 'YYYY-MM-DD',
  queryTimeFormat: 'HH:mm',
  queryTimeSecondsFormat: 'HH:mm:ss',

  symfonyDateTimeFormat: 'YYYY-MM-DD HH:mm:ss',
  symfonyDateFormat: 'YYYY-MM-DD',
  symfonyTimeFormat: 'HH:mm',

  angularDateFormat: 'dd-MM-yyyy',
  angularInputDateFormat: 'DD.MM.YYYY',
  angularTimeFormat: 'HH:mm',
  angularDateTimeFormat: 'dd-MM-yyyy HH:mm',
  angularLongDateFormat: 'dd MMMM yyyy',
  angularLongDateTimeFormat: 'dd MMMM yyyy HH:mm',

  appPageToolbarHeight: 93,

  pythonInputDateFormat: 'yyyy-MM-dd',

  addId: 0,
  editId: 'randomId',

  listParams: {
    offset: 0,
    page: 1,
    perPage: 30,
    pageSizeOptions: [10, 25, 50, 100]
  },
  maxPerPage: 100,
  noPagination: false,

  peimUserImage: 1 / 1,

  cardListItemMinWidth: 280,
  cardListItemHeight: 170,

  /* Material config */
  defaultSnackBarDuration: 4000,
  gridCols: 5,

  /* Bootstrap variables */
  bootstrap: {
    gridGutterWidth: 30 // px
  },

  table: {
    columns: {
      check: {
        name: 'check'
      }
    }
  }
};
