import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, first, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { ByIdPayload, ByKey, IdType, NormalizedList, NormalizedSingle } from '@industi/ngx-common';
import { select, Store } from '@ngrx/store';
import { getInstanceLocale } from '../../instance/instance.reducer';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import { ResponseStatus } from '../../../shared/enums/response-status.enum';
import { LibSnackbarComponent } from '@industi/ngx-modules';
import { AppGlobal } from '../../../shared/app-global';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  OrderLoadListAction,
  OrderLoadListActionTypes,
  OrderLoadListClearIdsAction,
  OrderLoadListFailAction,
  OrderLoadListSuccessAction
} from './actions/list.actions';
import {
  OrderEditAction,
  OrderEditActionTypes,
  OrderEditFailAction,
  OrderEditSuccessAction
} from './actions/edit.actions';
import {
  OrderLoadDetailsAction,
  OrderLoadDetailsActionTypes,
  OrderLoadDetailsFailAction,
  OrderLoadDetailsSuccessAction
} from './actions/load-details.actions';
import {
  OrderDeleteAction,
  OrderDeleteActionTypes,
  OrderDeleteFailAction,
  OrderDeleteSuccessAction
} from './actions/delete.actions';
import { OrderListParams } from './models/order-list-params';
import { createOrderListParams } from './selectors/get-load-list.selectors';
import { OrderCommonService } from './common.service';
import { AlertUtil } from '../../../shared/utils/alert.util';
import { OrderAddAction, OrderAddActionTypes, OrderAddFailAction, OrderAddSuccessAction } from './actions/add.actions';
import {
  OrderPaidAction,
  OrderPaidActionTypes,
  OrderPaidFailAction,
  OrderPaidSuccessAction
} from './actions/paid.actions';
import {
  OrderSentAction,
  OrderSentActionTypes,
  OrderSentFailAction,
  OrderSentSuccessAction
} from './actions/sent.actions';
import {
  OrderUnpaidAction,
  OrderUnpaidActionTypes,
  OrderUnpaidFailAction,
  OrderUnpaidSuccessAction
} from './actions/unpaid.actions';
import {
  OrderCancelAction,
  OrderCancelActionTypes,
  OrderCancelFailAction,
  OrderCancelSuccessAction
} from './actions/cancel.actions';

@Injectable()
export class OrderCommonEffects {

  @Effect()
  loadList$ = this.actions$.pipe(
    ofType(OrderLoadListActionTypes.request),
    map((action: OrderLoadListAction) => action.payload),
    switchMap((payload: ByIdPayload) => this.store.pipe(
      select(createOrderListParams(payload.id)),
      map((params: OrderListParams) => [payload.id, params] as [IdType, OrderListParams]),
      first()
    )),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([[id, params], locale]) => this.service.loadList(locale, params).pipe(
      mergeMap((res: NormalizedList) => [
        new OrderLoadListClearIdsAction({ id }),
        new OrderLoadListSuccessAction({ id, res })]),
      catchError((errors: HttpErrorResponse) => of(new OrderLoadListFailAction({ id, errors })))
    ))
  );

  @Effect()
  add$ = this.actions$.pipe(
    ofType(OrderAddActionTypes.request),
    map((action: OrderAddAction) => action.payload),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([{ id, data }, locale]) => this.service.add(locale, data).pipe(
      map((res: NormalizedSingle) => new OrderAddSuccessAction({ res, id })),
      catchError((errors: HttpErrorResponse) => of(new OrderAddFailAction({ errors, id })))
    ))
  );

  @Effect()
  edit$ = this.actions$.pipe(
    ofType(OrderEditActionTypes.request),
    map((action: OrderEditAction) => action.payload),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([{ id, data }, locale]) => this.service.edit(locale, data, id).pipe(
      map((res: NormalizedSingle) => new OrderEditSuccessAction({ res, id })),
      catchError((errors: HttpErrorResponse) => of(new OrderEditFailAction({ errors, id })))
    ))
  );

  @Effect()
  paid$ = this.actions$.pipe(
    ofType(OrderPaidActionTypes.request),
    map((action: OrderPaidAction) => action.payload.id),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([id, locale]) => this.service.paid(locale, id).pipe(
      map((res: NormalizedSingle) => new OrderPaidSuccessAction({ res, id })),
      catchError((errors: HttpErrorResponse) => of(new OrderPaidFailAction({ errors, id })))
    ))
  );

  @Effect()
  unpaid$ = this.actions$.pipe(
    ofType(OrderUnpaidActionTypes.request),
    map((action: OrderUnpaidAction) => action.payload.id),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([id, locale]) => this.service.unpaid(locale, id).pipe(
      map((res: NormalizedSingle) => new OrderUnpaidSuccessAction({ res, id })),
      catchError((errors: HttpErrorResponse) => of(new OrderUnpaidFailAction({ errors, id })))
    ))
  );

  @Effect()
  sent$ = this.actions$.pipe(
    ofType(OrderSentActionTypes.request),
    map((action: OrderSentAction) => action.payload.id),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([id, locale]) => this.service.sent(locale, id).pipe(
      map((res: NormalizedSingle) => new OrderSentSuccessAction({ res, id })),
      catchError((errors: HttpErrorResponse) => of(new OrderSentFailAction({ errors, id })))
    ))
  );

  @Effect()
  cancel$ = this.actions$.pipe(
    ofType(OrderCancelActionTypes.request),
    map((action: OrderCancelAction) => action.payload.id),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([id, locale]) => this.service.cancel(locale, id).pipe(
      map((res: NormalizedSingle) => new OrderCancelSuccessAction({ res, id })),
      catchError((errors: HttpErrorResponse) => of(new OrderCancelFailAction({ errors, id })))
    ))
  );

  @Effect()
  loadDetails$ = this.actions$.pipe(
    ofType(OrderLoadDetailsActionTypes.request),
    map((action: OrderLoadDetailsAction) => action.payload.id),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([id, locale]) => this.service.details(locale, id).pipe(
      map((res: NormalizedSingle) => new OrderLoadDetailsSuccessAction({ res, id })),
      catchError((errors: HttpErrorResponse) => of(new OrderLoadDetailsFailAction({ errors, id })))
    ))
  );

  @Effect()
  delete$ = this.actions$.pipe(
    ofType(OrderDeleteActionTypes.request),
    map((action: OrderDeleteAction) => action.payload.id),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([id, locale]) => this.service.delete(locale, id).pipe(
      map(() => new OrderDeleteSuccessAction({ id })),
      catchError((errors: HttpErrorResponse) => of(new OrderDeleteFailAction({ errors, id })))
    ))
  );

  @Effect({ dispatch: false })
  crudFail$ = this.actions$.pipe(
    ofType(
      OrderAddActionTypes.requestFail,
      OrderEditActionTypes.requestFail
    ),
    map((action: OrderAddFailAction | OrderEditFailAction) => action.payload.errors),
    tap((data: HttpErrorResponse) => {
      if (data?.status === ResponseStatus.BAD_REQUEST || data?.status === ResponseStatus.UNPROCESSABLE_ENTITY) {
        this.snackbar.openFromComponent(LibSnackbarComponent, {
          data: {
            message: AlertUtil.getControlTranslation(data.error.detail),
            type: 'error'
          },
          duration: AppGlobal.defaultSnackBarDuration
        });
      }
    })
  );

  constructor(
    private actions$: Actions,
    private store: Store<ByKey>,
    private service: OrderCommonService,
    private snackbar: MatSnackBar
  ) {
  }
}
