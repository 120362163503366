import {
  ByIdPayload,
  FailByIdPayload,
  OperationReducerTypes,
  PayloadAction,
  uniqueActionType
} from '@industi/ngx-common';

export const ModelDeleteActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[model] Delete'),
  requestFail: uniqueActionType('[model] Delete fail'),
  requestSuccess: uniqueActionType('[model] Delete success'),
  requestClearErrors: uniqueActionType('[model] Delete clear errors'),
  requestClear: uniqueActionType('[model] Delete clear')
};

export class ModelDeleteAction implements PayloadAction {
  readonly type = ModelDeleteActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class ModelDeleteFailAction implements PayloadAction {
  readonly type = ModelDeleteActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class ModelDeleteSuccessAction implements PayloadAction {
  readonly type = ModelDeleteActionTypes.requestSuccess;

  constructor(public payload: ByIdPayload) {
  }
}

export class ModelDeleteClearErrorsAction implements PayloadAction {
  readonly type = ModelDeleteActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class ModelDeleteClearAction implements PayloadAction {
  readonly type = ModelDeleteActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
