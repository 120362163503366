import {
  ByIdPayload,
  FailByIdPayload,
  OperationReducerTypes,
  PayloadAction,
  uniqueActionType
} from '@industi/ngx-common';

export const DiagramDeleteActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[diagram] Delete'),
  requestFail: uniqueActionType('[diagram] Delete fail'),
  requestSuccess: uniqueActionType('[diagram] Delete success'),
  requestClearErrors: uniqueActionType('[diagram] Delete clear errors'),
  requestClear: uniqueActionType('[diagram] Delete clear')
};

export class DiagramDeleteAction implements PayloadAction {
  readonly type = DiagramDeleteActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class DiagramDeleteFailAction implements PayloadAction {
  readonly type = DiagramDeleteActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class DiagramDeleteSuccessAction implements PayloadAction {
  readonly type = DiagramDeleteActionTypes.requestSuccess;

  constructor(public payload: ByIdPayload) {
  }
}

export class DiagramDeleteClearErrorsAction implements PayloadAction {
  readonly type = DiagramDeleteActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class DiagramDeleteClearAction implements PayloadAction {
  readonly type = DiagramDeleteActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
