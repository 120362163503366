import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';

export const ArticleGalleryContentLoadDetailsActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[article/gallery-content] Load details'),
  requestFail: uniqueActionType('[article/gallery-content] Load details fail'),
  requestSuccess: uniqueActionType('[article/gallery-content] Load details success'),
  requestClearErrors: uniqueActionType('[article/gallery-content] Load details clear errors'),
  requestClear: uniqueActionType('[article/gallery-content] Load details clear')
};

export class ArticleGalleryContentLoadDetailsAction implements PayloadAction {
  readonly type = ArticleGalleryContentLoadDetailsActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class ArticleGalleryContentLoadDetailsFailAction implements PayloadAction {
  readonly type = ArticleGalleryContentLoadDetailsActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class ArticleGalleryContentLoadDetailsSuccessAction implements PayloadAction {
  readonly type = ArticleGalleryContentLoadDetailsActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class ArticleGalleryContentLoadDetailsClearAction implements PayloadAction {
  readonly type = ArticleGalleryContentLoadDetailsActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}

export class ArticleGalleryContentLoadDetailsClearErrorsAction implements PayloadAction {
  readonly type = ArticleGalleryContentLoadDetailsActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}
