import {
  ByIdPayload,
  FailByIdPayload,
  OperationReducerTypes,
  PayloadAction,
  SendByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { PartImportPayload } from '../models/part-import-payload';

export const PartImportActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[part] Import'),
  requestFail: uniqueActionType('[part] Import fail'),
  requestSuccess: uniqueActionType('[part] Import success'),
  requestClearErrors: uniqueActionType('[part] Import clear errors'),
  requestClear: uniqueActionType('[part] Import clear'),
};

export class PartImportAction implements PayloadAction {
  readonly type = PartImportActionTypes.request;

  constructor(public payload: SendByIdPayload<PartImportPayload>) {
  }
}

export class PartImportFailAction implements PayloadAction {
  readonly type = PartImportActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class PartImportSuccessAction implements PayloadAction {
  readonly type = PartImportActionTypes.requestSuccess;

  constructor(public payload: ByIdPayload) {
  }
}

export class PartImportClearErrorsAction implements PayloadAction {
  readonly type = PartImportActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class PartImportClearAction implements PayloadAction {
  readonly type = PartImportActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
