import {
  ByIdPayload,
  FailByIdPayload,
  OperationReducerTypes,
  PayloadAction,
  uniqueActionType
} from '@industi/ngx-common';

export const CarbonCommissionTaskDeleteActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[carbon/commission-task] Delete'),
  requestFail: uniqueActionType('[carbon/commission-task] Delete fail'),
  requestSuccess: uniqueActionType('[carbon/commission-task] Delete success'),
  requestClearErrors: uniqueActionType('[carbon/commission-task] Delete clear errors'),
  requestClear: uniqueActionType('[carbon/commission-task] Delete clear')
};

export class CarbonCommissionTaskDeleteAction implements PayloadAction {
  readonly type = CarbonCommissionTaskDeleteActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class CarbonCommissionTaskDeleteFailAction implements PayloadAction {
  readonly type = CarbonCommissionTaskDeleteActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class CarbonCommissionTaskDeleteSuccessAction implements PayloadAction {
  readonly type = CarbonCommissionTaskDeleteActionTypes.requestSuccess;

  constructor(public payload: ByIdPayload) {
  }
}

export class CarbonCommissionTaskDeleteClearErrorsAction implements PayloadAction {
  readonly type = CarbonCommissionTaskDeleteActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class CarbonCommissionTaskDeleteClearAction implements PayloadAction {
  readonly type = CarbonCommissionTaskDeleteActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
