import {
  ByKey,
  createListStateIdsSelector,
  createListStateParamsSelector,
  createStateErrorsSelector,
  createStateLoadingSelector,
  createStateSuccessSelector,
  IdType,
  ListState
} from '@industi/ngx-common';
import { createSelector } from '@ngrx/store';
import { getOrderListState } from '../common.reducer';
import { createEntitiesSelector } from '../../../entity/entities.reducer';
import { EntityTypes } from '../../../entity/entity-types';
import { EntityState } from '../../../entity/entity.reducer';
import { denormalize } from 'normalizr';
import { Entities } from '../../../entity/entities';
import { Order } from '../models/order';
import { createSymfonyTotalCountSelector } from '../../../symfony/list/selectors/list.selectors';

const createState = (id: IdType) => createSelector(
  getOrderListState,
  (state: ByKey<ListState>) => state && state[id]
);

const createStateByContext = (id: IdType) => createListStateIdsSelector(createState(id));

export const createOrderListData = (id: IdType) => createSelector(
  createStateByContext(id),
  createEntitiesSelector([
    EntityTypes.order
  ]),
  (
    ids: IdType[],
    entities: EntityState
  ) => denormalize(ids, [Entities[EntityTypes.order]], entities) as Order[]
);

export const createOrderListLoading = (id: IdType) => createStateLoadingSelector(createState(id));
export const createOrderListSuccess = (id: IdType) => createStateSuccessSelector(createState(id));
export const createOrderListErrors = (id: IdType) => createStateErrorsSelector(createState(id));
export const createOrderListTotalCount = (id: IdType) => createSymfonyTotalCountSelector(createState(id));
export const createOrderListParams = (id: IdType) => createListStateParamsSelector(createState(id));
