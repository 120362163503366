import {
  ByKey,
  IdType,
  listByIdReducer,
  ListState,
  operationByIdReducer,
  setValueReducer,
  SingleState
} from '@industi/ngx-common';
import { ActionReducer, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import { InjectionToken, Provider } from '@angular/core';
import { ServiceProjectLoadListActionTypes } from './actions/list.actions';
import { ServiceProjectLoadDetailsActionTypes } from './actions/details.actions';
import { ServiceProjectAddActionTypes } from './actions/add.actions';
import { ServiceProjectEditActionTypes } from './actions/edit.actions';
import { ServiceProjectDeleteActionTypes } from './actions/delete.actions';
import { ServiceProjectSelectLastAddedIdActionTypes } from './actions/select-last-added-id.actions';
import { DiagramState } from '../../../diagram/common/common.reducer';

export const rootReducerKey = 'project';

export interface ServiceProjectOperationByIdState {
  add: SingleState;
  edit: SingleState;
  delete: SingleState;
  details: SingleState;
}

export interface ServiceProjectState {
  operationsById: ByKey<ServiceProjectOperationByIdState>;
  list: ByKey<ListState>;
  lastAddedId: IdType;
}

const initialState: ServiceProjectState = {
  operationsById: {},
  list: {},
  lastAddedId: null
};

const reducer: ActionReducer<ServiceProjectState> = combineReducers<ServiceProjectState>({
  operationsById: operationByIdReducer<ServiceProjectOperationByIdState>({
    mapActionToId: (a) => a && a.payload && a.payload.id,
    operations: {
      add: ServiceProjectAddActionTypes,
      edit: ServiceProjectEditActionTypes,
      delete: ServiceProjectDeleteActionTypes,
      details: ServiceProjectLoadDetailsActionTypes
    }
  }),
  list: listByIdReducer({ types: ServiceProjectLoadListActionTypes }),
  lastAddedId: setValueReducer({ types: ServiceProjectSelectLastAddedIdActionTypes })
}, initialState);

export function getReducers(): ActionReducer<ServiceProjectState> {
  return reducer;
}

export const SERVICE_PROJECT_COMMON_REDUCER_TOKEN = new InjectionToken<ActionReducer<ServiceProjectState>>(rootReducerKey);

export const serviceProjectCommonReducerProvider: Provider = { provide: SERVICE_PROJECT_COMMON_REDUCER_TOKEN, useFactory: getReducers };

const getState = createFeatureSelector<ServiceProjectState>(rootReducerKey);

export const getServiceProjectOperationsById = createSelector(
  getState,
  (state: ServiceProjectState) => state && state.operationsById
);

export const getServiceProjectListState = createSelector(
  getState,
  (state: ServiceProjectState) => state && state.list
);

export const getServiceProjectLastAddedId = createSelector(
  getState,
  (state: DiagramState) => state && state.lastAddedId
);
