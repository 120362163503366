import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';

export const CarbonCommissionTaskLoadDetailsActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[carbon/commission-task] Load details'),
  requestFail: uniqueActionType('[carbon/commission-task] Load details fail'),
  requestSuccess: uniqueActionType('[carbon/commission-task] Load details success'),
  requestClearErrors: uniqueActionType('[carbon/commission-task] Load details clear errors'),
  requestClear: uniqueActionType('[carbon/commission-task] Load details clear')
};

export class CarbonCommissionTaskLoadDetailsAction implements PayloadAction {
  readonly type = CarbonCommissionTaskLoadDetailsActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class CarbonCommissionTaskLoadDetailsFailAction implements PayloadAction {
  readonly type = CarbonCommissionTaskLoadDetailsActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class CarbonCommissionTaskLoadDetailsSuccessAction implements PayloadAction {
  readonly type = CarbonCommissionTaskLoadDetailsActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class CarbonCommissionTaskLoadDetailsClearAction implements PayloadAction {
  readonly type = CarbonCommissionTaskLoadDetailsActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}

export class CarbonCommissionTaskLoadDetailsClearErrorsAction implements PayloadAction {
  readonly type = CarbonCommissionTaskLoadDetailsActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}
