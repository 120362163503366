import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { ByIdPayload, ByKey, IdType, NormalizedList, NormalizedSingle } from '@industi/ngx-common';
import { catchError, first, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import {
  ModelLoadListAction,
  ModelLoadListActionTypes,
  ModelLoadListClearIdsAction,
  ModelLoadListFailAction,
  ModelLoadListSuccessAction
} from './actions/list.actions';
import { ModelAddAction, ModelAddActionTypes, ModelAddFailAction, ModelAddSuccessAction } from './actions/add.actions';
import { ModelService } from './model.service';
import { getInstanceLocale } from '../instance/instance.reducer';
import { of } from 'rxjs';
import { ModelListParams } from './models/model-list-params';
import { HttpErrorResponse } from '@angular/common/http';
import { createModelListParams } from './selectors/get-load-list.selectors';
import {
  ModelEditAction,
  ModelEditActionTypes,
  ModelEditFailAction,
  ModelEditSuccessAction
} from './actions/edit.actions';
import {
  ModelLoadDetailsAction,
  ModelLoadDetailsActionTypes,
  ModelLoadDetailsFailAction,
  ModelLoadDetailsSuccessAction
} from './actions/load-details.actions';
import {
  ModelDeleteAction,
  ModelDeleteActionTypes,
  ModelDeleteFailAction,
  ModelDeleteSuccessAction
} from './actions/delete.actions';
import { ResponseStatus } from '../../shared/enums/response-status.enum';
import { LibSnackbarComponent } from '@industi/ngx-modules';
import { AppGlobal } from '../../shared/app-global';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlertUtil } from '../../shared/utils/alert.util';

@Injectable()
export class ModelEffects {

  @Effect()
  loadList$ = this.actions$.pipe(
    ofType(ModelLoadListActionTypes.request),
    map((action: ModelLoadListAction) => action.payload),
    switchMap((payload: ByIdPayload) => this.store.pipe(
      select(createModelListParams(payload.id)),
      map((params: ModelListParams) => [payload.id, params] as [IdType, ModelListParams]),
      first()
    )),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([[ id, params ], locale]) => this.service.loadList(locale, params).pipe(
      mergeMap((res: NormalizedList) => [
        new ModelLoadListClearIdsAction({ id }),
        new ModelLoadListSuccessAction({ id, res })]),
      catchError((errors: HttpErrorResponse) => of(new ModelLoadListFailAction({ id, errors })))
    ))
  );

  @Effect()
  add$ = this.actions$.pipe(
    ofType(ModelAddActionTypes.request),
    map((action: ModelAddAction) => action.payload),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([{ id, data }, locale]) => this.service.add(locale, data).pipe(
      map((res: NormalizedSingle) => new ModelAddSuccessAction({ res, id })),
      catchError((errors: HttpErrorResponse) => of(new ModelAddFailAction({ errors, id })))
    ))
  );

  @Effect()
  edit$ = this.actions$.pipe(
    ofType(ModelEditActionTypes.request),
    map((action: ModelEditAction) => action.payload),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([{ id, data }, locale]) => this.service.edit(locale, data, id).pipe(
      map((res: NormalizedSingle) => new ModelEditSuccessAction({ res, id })),
      catchError((errors: HttpErrorResponse) => of(new ModelEditFailAction({ errors, id })))
    ))
  );

  @Effect()
  loadDetails$ = this.actions$.pipe(
    ofType(ModelLoadDetailsActionTypes.request),
    map((action: ModelLoadDetailsAction) => action.payload.id),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([id, locale]) => this.service.details(locale, id).pipe(
      map((res: NormalizedSingle) => new ModelLoadDetailsSuccessAction({ id, res })),
      catchError((errors: HttpErrorResponse) => of(new ModelLoadDetailsFailAction({ id, errors })))
    ))
  );

  @Effect()
  delete$ = this.actions$.pipe(
    ofType(ModelDeleteActionTypes.request),
    map((action: ModelDeleteAction) => action.payload.id),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([id, locale]) => this.service.delete(locale, id).pipe(
      map(() => new ModelDeleteSuccessAction({ id })),
      catchError((errors: HttpErrorResponse) => of(new ModelDeleteFailAction({ id, errors })))
    ))
  );

  @Effect({ dispatch: false })
  crudFail$ = this.actions$.pipe(
    ofType(
      ModelAddActionTypes.requestFail,
      ModelEditActionTypes.requestFail
    ),
    map((action: ModelEditFailAction | ModelAddFailAction) => action.payload.errors),
    tap((data: HttpErrorResponse) => {
      if (data?.status === ResponseStatus.BAD_REQUEST || data?.status === ResponseStatus.UNPROCESSABLE_ENTITY) {
        this.snackBar.openFromComponent(LibSnackbarComponent, {
          data: {
            message: AlertUtil.getControlTranslation(data.error.detail),
            type: 'error'
          },
          duration: AppGlobal.defaultSnackBarDuration
        });
      }
    })
  );

  constructor(
    private actions$: Actions,
    private store: Store<ByKey>,
    private service: ModelService,
    private snackBar: MatSnackBar
  ) {
  }
}
