import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SendByIdPayload,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { Dictionary } from '../../../../shared/models/dictionary';

export const DictionaryTagAddActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[dictionary/tag] Add'),
  requestFail: uniqueActionType('[dictionary/tag] Add fail'),
  requestSuccess: uniqueActionType('[dictionary/tag] Add success'),
  requestClearErrors: uniqueActionType('[dictionary/tag] Add clear errors'),
  requestClear: uniqueActionType('[dictionary/tag] Add clear'),
};

export class DictionaryTagAddAction implements PayloadAction {
  readonly type = DictionaryTagAddActionTypes.request;

  constructor(public payload: SendByIdPayload<Dictionary>) {
  }
}

export class DictionaryTagAddFailAction implements PayloadAction {
  readonly type = DictionaryTagAddActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class DictionaryTagAddSuccessAction implements PayloadAction {
  readonly type = DictionaryTagAddActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class DictionaryTagAddClearErrorsAction implements PayloadAction {
  readonly type = DictionaryTagAddActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class DictionaryTagAddClearAction implements PayloadAction {
  readonly type = DictionaryTagAddActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
