import { createSelector } from '@ngrx/store';
import { denormalize } from 'normalizr';
import {
  ByKey,
  createListStateIdsSelector,
  createListStateParamsSelector,
  createStateErrorsSelector,
  createStateLoadingSelector,
  createStateSuccessSelector,
  IdType,
  ListState
} from '@industi/ngx-common';
import { EntityState } from '../../../entity/entity.reducer';
import { EntityTypes } from '../../../entity/entity-types';
import { createEntitiesSelector } from '../../../entity/entities.reducer';
import { createSymfonyTotalCountSelector } from '../../../symfony/list/selectors/list.selectors';
import { Entities } from '../../../entity/entities';
import { getArticleContentListState } from '../content.reducer';
import { ArticleContent } from '../models/content';

const createState = (id: IdType) => createSelector(
  getArticleContentListState,
  (state: ByKey<ListState>) => state && state[id]
);

const createStateByContext = (id: IdType) => createListStateIdsSelector(createState(id));

export const createArticleContentListData = (id: IdType) => createSelector(
  createStateByContext(id),
  createEntitiesSelector([
    EntityTypes.articleContent
  ]),
  (
    ids: IdType[],
    entities: EntityState
  ) => denormalize(ids, [Entities[EntityTypes.articleContent]], entities) as ArticleContent[]
);

export const createArticleContentListLoading = (id: IdType) => createStateLoadingSelector(
  createState(id));

export const createArticleContentListSuccess = (id: IdType) => createStateSuccessSelector(
  createState(id));

export const createArticleContentListErrors = (id: IdType) => createStateErrorsSelector(
  createState(id));

export const createArticleContentListTotalCount = (id: IdType) => createSymfonyTotalCountSelector(
  createState(id));

export const createArticleContentListParams = (id: IdType) => createListStateParamsSelector(
  createState(id));
