import {
  ByKey,
  IdType,
  listByIdReducer,
  ListState,
  operationByIdReducer,
  setValueReducer,
  SingleState
} from '@industi/ngx-common';
import { ActionReducer, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import { ClientAddActionTypes } from './actions/add.actions';
import { ClientEditActionTypes } from './actions/edit.actions';
import { ClientDeleteActionTypes } from './actions/delete.actions';
import { ClientLoadDetailsActionTypes } from './actions/load-details.actions';
import { ClientLoadListActionTypes } from './actions/list.actions';
import { InjectionToken, Provider } from '@angular/core';
import { ClientSelectLastAddedIdActionTypes } from './actions/select-last-added-id.actions';

export const rootReducerKey = 'client';

export interface ClientOperationByIdState {
  add: SingleState;
  edit: SingleState;
  details: SingleState;
  delete: SingleState;
}

export interface ClientState {
  operationsById: ByKey<ClientOperationByIdState>;
  list: ByKey<ListState>;
  lastAddedId: IdType;
}

const initialState: ClientState = {
  operationsById: {},
  list: {},
  lastAddedId: null
};

const reducer: ActionReducer<ClientState> = combineReducers<ClientState>({
  operationsById: operationByIdReducer<ClientOperationByIdState>({
    mapActionToId: (a) => a && a.payload && a.payload.id,
    operations: {
      add: ClientAddActionTypes,
      edit: ClientEditActionTypes,
      delete: ClientDeleteActionTypes,
      details: ClientLoadDetailsActionTypes
    }
  }),
  list: listByIdReducer({ types: ClientLoadListActionTypes }),
  lastAddedId: setValueReducer({ types: ClientSelectLastAddedIdActionTypes })
}, initialState);

export function getReducer(): ActionReducer<ClientState> {
  return reducer;
}

export const CLIENT_REDUCER_TOKEN = new InjectionToken<ActionReducer<ClientState>>(rootReducerKey);

export const clientReducerProvider: Provider = { provide: CLIENT_REDUCER_TOKEN, useFactory: getReducer };

const getState = createFeatureSelector<ClientState>(rootReducerKey);

export const getClientOperationsById = createSelector(
  getState,
  (state: ClientState) => state && state.operationsById
);

export const getClientListState = createSelector(
  getState,
  (state: ClientState) => state && state.list
);

export const getClientLastAddedId = createSelector(
  getState,
  (state: ClientState) => state && state.lastAddedId
);
