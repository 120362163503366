import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { DICTIONARY_BRAND_REDUCER_TOKEN, dictionaryBrandReducerProvider, rootReducerKey } from './brand.reducer';
import { EffectsModule } from '@ngrx/effects';
import { DictionaryBrandEffects } from './brand.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(rootReducerKey, DICTIONARY_BRAND_REDUCER_TOKEN),
    EffectsModule.forFeature([DictionaryBrandEffects])
  ],
  providers: [
    dictionaryBrandReducerProvider
  ]
})
export class DictionaryBrandStoreModule { }
