import { createSelector } from '@ngrx/store';
import { denormalize } from 'normalizr';
import {
  ByKey,
  createListStateIdsSelector,
  createListStateParamsSelector,
  createStateErrorsSelector,
  createStateLoadingSelector,
  createStateSuccessSelector,
  IdType,
  ListState
} from '@industi/ngx-common';
import { EntityState } from '../../../entity/entity.reducer';
import { EntityTypes } from '../../../entity/entity-types';
import { createEntitiesSelector } from '../../../entity/entities.reducer';
import { createSymfonyTotalCountSelector } from '../../../symfony/list/selectors/list.selectors';
import { Entities } from '../../../entity/entities';
import { getItemListState } from '../common.reducer';
import { Item } from '../models/item';

const createState = (id: IdType) => createSelector(
  getItemListState,
  (state: ByKey<ListState>) => state && state[id]
);

const createStateByContext = (id: IdType) => createListStateIdsSelector(createState(id));

export const createItemListData = (id: IdType) => createSelector(
  createStateByContext(id),
  createEntitiesSelector([
    EntityTypes.item,
    EntityTypes.image
  ]),
  (
    ids: IdType[],
    entities: EntityState
  ) => denormalize(ids, [Entities[EntityTypes.item]], entities) as Item[]
);

export const createItemListLoading = (id: IdType) => createStateLoadingSelector(
  createState(id));

export const createItemListSuccess = (id: IdType) => createStateSuccessSelector(
  createState(id));

export const createItemListErrors = (id: IdType) => createStateErrorsSelector(
  createState(id));

export const createItemListTotalCount = (id: IdType) => createSymfonyTotalCountSelector(
  createState(id));

export const createItemListParams = (id: IdType) => createListStateParamsSelector(
  createState(id));

export const createItemListDataByIds = (ids: IdType[]) => createSelector(
  createEntitiesSelector([
    EntityTypes.item,
    EntityTypes.image
  ]),
  (
    entities: EntityState
  ) => denormalize(ids, [Entities[EntityTypes.item]], entities) as Item[]
);
