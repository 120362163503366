import { Inject, Injectable } from '@angular/core';
import { API_URL } from '../app-store-providers';
import { HttpClient } from '@angular/common/http';
import { EntityTypes } from '../entity/entity-types';
import { Layer } from './models/layer';
import { LayerListParams } from './models/layer-list-params';
import { LayerPayload } from './models/layer-payload';
import { StoreBaseService } from '../common/base.service';

@Injectable({
  providedIn: 'root'
})
export class LayerService
  extends StoreBaseService<Layer, LayerPayload, LayerListParams> {

  get entityType(): string {
    return EntityTypes.layer;
  }

  get resourceName(): string {
    return 'stock/layer';
  }

  constructor(
    @Inject(API_URL) apiUrl: string,
    http: HttpClient
  ) {
    super(apiUrl, http);
  }
}
