import {
  ByIdPayload,
  FailByIdPayload,
  OperationReducerTypes,
  PayloadAction,
  uniqueActionType
} from '@industi/ngx-common';

export const WarehouseDeleteActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[warehouse] Delete'),
  requestFail: uniqueActionType('[warehouse] Delete fail'),
  requestSuccess: uniqueActionType('[warehouse] Delete success'),
  requestClearErrors: uniqueActionType('[warehouse] Delete clear errors'),
  requestClear: uniqueActionType('[warehouse] Delete clear')
};

export class WarehouseDeleteAction implements PayloadAction {
  readonly type = WarehouseDeleteActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class WarehouseDeleteFailAction implements PayloadAction {
  readonly type = WarehouseDeleteActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class WarehouseDeleteSuccessAction implements PayloadAction {
  readonly type = WarehouseDeleteActionTypes.requestSuccess;

  constructor(public payload: ByIdPayload) {
  }
}

export class WarehouseDeleteClearErrorsAction implements PayloadAction {
  readonly type = WarehouseDeleteActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class WarehouseDeleteClearAction implements PayloadAction {
  readonly type = WarehouseDeleteActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
