import {
  ByIdPayload,
  FailByIdPayload,
  OperationReducerTypes,
  PayloadAction,
  uniqueActionType
} from '@industi/ngx-common';

export const ArticleContentDeleteActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[article-content] Delete'),
  requestFail: uniqueActionType('[article-content] Delete fail'),
  requestSuccess: uniqueActionType('[article-content] Delete success'),
  requestClearErrors: uniqueActionType('[article-content] Delete clear errors'),
  requestClear: uniqueActionType('[article-content] Delete clear')
};

export class ArticleContentDeleteAction implements PayloadAction {
  readonly type = ArticleContentDeleteActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class ArticleContentDeleteFailAction implements PayloadAction {
  readonly type = ArticleContentDeleteActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class ArticleContentDeleteSuccessAction implements PayloadAction {
  readonly type = ArticleContentDeleteActionTypes.requestSuccess;

  constructor(public payload: ByIdPayload) {
  }
}

export class ArticleContentDeleteClearErrorsAction implements PayloadAction {
  readonly type = ArticleContentDeleteActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class ArticleContentDeleteClearAction implements PayloadAction {
  readonly type = ArticleContentDeleteActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
