import {
  ByIdPayload,
  FailByIdPayload,
  OperationReducerTypes,
  PayloadAction,
  uniqueActionType
} from '@industi/ngx-common';

export const ServiceProjectDeleteActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[service/project] Delete'),
  requestFail: uniqueActionType('[service/project] Delete fail'),
  requestSuccess: uniqueActionType('[service/project] Delete success'),
  requestClearErrors: uniqueActionType('[service/project] Delete clear errors'),
  requestClear: uniqueActionType('[service/project] Delete clear')
};

export class ServiceProjectDeleteAction implements PayloadAction {
  readonly type = ServiceProjectDeleteActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class ServiceProjectDeleteFailAction implements PayloadAction {
  readonly type = ServiceProjectDeleteActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class ServiceProjectDeleteSuccessAction implements PayloadAction {
  readonly type = ServiceProjectDeleteActionTypes.requestSuccess;

  constructor(public payload: ByIdPayload) {
  }
}

export class ServiceProjectDeleteClearErrorsAction implements PayloadAction {
  readonly type = ServiceProjectDeleteActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class ServiceProjectDeleteClearAction implements PayloadAction {
  readonly type = ServiceProjectDeleteActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
