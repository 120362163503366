import {
  ByIdPayload,
  FailByIdPayload,
  OperationReducerTypes,
  PayloadAction,
  uniqueActionType
} from '@industi/ngx-common';

export const DictionaryUnitStateDeleteActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[dictionary/unit-state] Delete'),
  requestFail: uniqueActionType('[dictionary/unit-state] Delete fail'),
  requestSuccess: uniqueActionType('[dictionary/unit-state] Delete success'),
  requestClearErrors: uniqueActionType('[dictionary/unit-state] Delete clear errors'),
  requestClear: uniqueActionType('[dictionary/unit-state] Delete clear')
};

export class DictionaryUnitStateDeleteAction implements PayloadAction {
  readonly type = DictionaryUnitStateDeleteActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class DictionaryUnitStateDeleteFailAction implements PayloadAction {
  readonly type = DictionaryUnitStateDeleteActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class DictionaryUnitStateDeleteSuccessAction implements PayloadAction {
  readonly type = DictionaryUnitStateDeleteActionTypes.requestSuccess;

  constructor(public payload: ByIdPayload) {
  }
}

export class DictionaryUnitStateDeleteClearErrorsAction implements PayloadAction {
  readonly type = DictionaryUnitStateDeleteActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class DictionaryUnitStateDeleteClearAction implements PayloadAction {
  readonly type = DictionaryUnitStateDeleteActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
