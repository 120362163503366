import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { ENTITIES_REDUCER_TOKEN, entitiesReducerProvider } from './entities.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('entities', ENTITIES_REDUCER_TOKEN)
  ],
  providers: [
    entitiesReducerProvider
  ]
})
export class EntityStoreModule {
}
