import {
  ByIdPayload,
  FailByIdPayload,
  OperationReducerTypes,
  PayloadAction,
  SendByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { ArticleImageContentPayload } from '../models/image-content-payload';

export const ArticleImageContentAddActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[article/image-content] Add gallery photo'),
  requestFail: uniqueActionType('[article/image-content] Add gallery photo fail'),
  requestSuccess: uniqueActionType('[article/image-content] Add gallery photo success'),
  requestClearErrors: uniqueActionType('[article/image-content] Add gallery photo clear errors'),
  requestClear: uniqueActionType('[article/image-content] Add gallery photo clear'),
};

export class ArticleImageContentAddAction implements PayloadAction {
  readonly type = ArticleImageContentAddActionTypes.request;

  constructor(public payload: SendByIdPayload<ArticleImageContentPayload>) {
  }
}

export class ArticleImageContentAddFailAction implements PayloadAction {
  readonly type = ArticleImageContentAddActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class ArticleImageContentAddSuccessAction implements PayloadAction {
  readonly type = ArticleImageContentAddActionTypes.requestSuccess;

  constructor(public payload: ByIdPayload) {
  }
}

export class ArticleImageContentAddClearErrorsAction implements PayloadAction {
  readonly type = ArticleImageContentAddActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class ArticleImageContentAddClearAction implements PayloadAction {
  readonly type = ArticleImageContentAddActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
