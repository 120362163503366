import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';

export const OrderLoadDetailsActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[order] Load details'),
  requestFail: uniqueActionType('[order] Load details fail'),
  requestSuccess: uniqueActionType('[order] Load details success'),
  requestClearErrors: uniqueActionType('[order] Load details clear errors'),
  requestClear: uniqueActionType('[order] Load details clear')
};

export class OrderLoadDetailsAction implements PayloadAction {
  readonly type = OrderLoadDetailsActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class OrderLoadDetailsFailAction implements PayloadAction {
  readonly type = OrderLoadDetailsActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class OrderLoadDetailsSuccessAction implements PayloadAction {
  readonly type = OrderLoadDetailsActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class OrderLoadDetailsClearErrorsAction implements PayloadAction {
  readonly type = OrderLoadDetailsActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class OrderLoadDetailsClearAction implements PayloadAction {
  readonly type = OrderLoadDetailsActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
