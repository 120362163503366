import {
  ByIdPayload,
  FailByIdPayload,
  OperationReducerTypes,
  PayloadAction,
  uniqueActionType
} from '@industi/ngx-common';

export const ArticleBrandImageDeleteActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[article-brand] Delete image'),
  requestFail: uniqueActionType('[article-brand] Delete image fail'),
  requestSuccess: uniqueActionType('[article-brand] Delete image success'),
  requestClearErrors: uniqueActionType('[article-brand] Delete image clear errors'),
  requestClear: uniqueActionType('[article-brand] Delete image clear')
};

export class ArticleBrandImageDeleteAction implements PayloadAction {
  readonly type = ArticleBrandImageDeleteActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class ArticleBrandImageDeleteFailAction implements PayloadAction {
  readonly type = ArticleBrandImageDeleteActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class ArticleBrandImageDeleteSuccessAction implements PayloadAction {
  readonly type = ArticleBrandImageDeleteActionTypes.requestSuccess;

  constructor(public payload: ByIdPayload) {
  }
}

export class ArticleBrandImageDeleteClearErrorsAction implements PayloadAction {
  readonly type = ArticleBrandImageDeleteActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class ArticleBrandImageDeleteClearAction implements PayloadAction {
  readonly type = ArticleBrandImageDeleteActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
