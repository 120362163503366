import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';

export const OrderItemLoadDetailsActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[order/item] Load details'),
  requestFail: uniqueActionType('[order/item] Load details fail'),
  requestSuccess: uniqueActionType('[order/item] Load details success'),
  requestClearErrors: uniqueActionType('[order/item] Load details clear errors'),
  requestClear: uniqueActionType('[order/item] Load details clear'),
};

export class OrderItemLoadDetailsAction implements PayloadAction {
  readonly type = OrderItemLoadDetailsActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class OrderItemLoadDetailsFailAction implements PayloadAction {
  readonly type = OrderItemLoadDetailsActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class OrderItemLoadDetailsSuccessAction implements PayloadAction {
  readonly type = OrderItemLoadDetailsActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class OrderItemLoadDetailsClearAction implements PayloadAction {
  readonly type = OrderItemLoadDetailsActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}

export class OrderItemLoadDetailsClearErrorsAction implements PayloadAction {
  readonly type = OrderItemLoadDetailsActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}
