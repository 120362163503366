import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';

export const ArticleBrandLoadDetailsActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[article-brand] Load details'),
  requestFail: uniqueActionType('[article-brand] Load details fail'),
  requestSuccess: uniqueActionType('[article-brand] Load details success'),
  requestClearErrors: uniqueActionType('[article-brand] Load details clear errors'),
  requestClear: uniqueActionType('[article-brand] Load details clear')
};

export class ArticleBrandLoadDetailsAction implements PayloadAction {
  readonly type = ArticleBrandLoadDetailsActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class ArticleBrandLoadDetailsFailAction implements PayloadAction {
  readonly type = ArticleBrandLoadDetailsActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class ArticleBrandLoadDetailsSuccessAction implements PayloadAction {
  readonly type = ArticleBrandLoadDetailsActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class ArticleBrandLoadDetailsClearAction implements PayloadAction {
  readonly type = ArticleBrandLoadDetailsActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}

export class ArticleBrandLoadDetailsClearErrorsAction implements PayloadAction {
  readonly type = ArticleBrandLoadDetailsActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}
