import { ByKey, listByIdReducer, ListState, operationByIdReducer, SingleState } from '@industi/ngx-common';
import { ActionReducer, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import { InjectionToken, Provider } from '@angular/core';
import { ServiceProjectItemLoadListActionTypes } from './actions/list.actions';
import { ServiceProjectItemAddActionTypes } from './actions/add.actions';
import { ServiceProjectItemDeleteActionTypes } from './actions/delete.actions';
import { ServiceProjectItemLoadDetailsActionTypes } from './actions/details.actions';
import { ServiceProjectItemEditActionTypes } from './actions/edit.actions';

export const rootReducerKey = 'projectItem';

export interface ServiceProjectItemOperationByIdState {
  add: SingleState;
  edit: SingleState;
  delete: SingleState;
  details: SingleState;
}

export interface ServiceProjectItemState {
  operationsById: ByKey<ServiceProjectItemOperationByIdState>;
  list: ByKey<ListState>;
}

const initialState: ServiceProjectItemState = {
  operationsById: {},
  list: {}
};

const reducer: ActionReducer<ServiceProjectItemState> = combineReducers<ServiceProjectItemState>({
  operationsById: operationByIdReducer<ServiceProjectItemOperationByIdState>({
    mapActionToId: (a) => a && a.payload && a.payload.id,
    operations: {
      add: ServiceProjectItemAddActionTypes,
      edit: ServiceProjectItemEditActionTypes,
      delete: ServiceProjectItemDeleteActionTypes,
      details: ServiceProjectItemLoadDetailsActionTypes
    }
  }),
  list: listByIdReducer({ types: ServiceProjectItemLoadListActionTypes })
}, initialState);

export function getReducers(): ActionReducer<ServiceProjectItemState> {
  return reducer;
}

export const SERVICE_PROJECT_ITEM_REDUCER_TOKEN = new InjectionToken<ActionReducer<ServiceProjectItemState>>(rootReducerKey);

export const serviceProjectItemReducerProvider: Provider = { provide: SERVICE_PROJECT_ITEM_REDUCER_TOKEN, useFactory: getReducers };

const getState = createFeatureSelector<ServiceProjectItemState>(rootReducerKey);

export const getServiceProjectItemOperationsById = createSelector(
  getState,
  (state: ServiceProjectItemState) => state && state.operationsById
);

export const getServiceProjectItemListState = createSelector(
  getState,
  (state: ServiceProjectItemState) => state && state.list
);
