import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, first, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { ByIdPayload, ByKey, IdType, NormalizedList, NormalizedSingle } from '@industi/ngx-common';
import { select, Store } from '@ngrx/store';
import { SymfonyPaginationParams } from '../../symfony/list/models/symfony-pagination-params';
import { getInstanceLocale } from '../../instance/instance.reducer';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import {
  DictionaryMaterialExploitationAddAction,
  DictionaryMaterialExploitationAddActionTypes,
  DictionaryMaterialExploitationAddFailAction,
  DictionaryMaterialExploitationAddSuccessAction
} from './actions/add.actions';
import {
  DictionaryMaterialExploitationEditAction,
  DictionaryMaterialExploitationEditActionTypes,
  DictionaryMaterialExploitationEditFailAction,
  DictionaryMaterialExploitationEditSuccessAction
} from './actions/edit.actions';
import {
  DictionaryMaterialExploitationLoadListAction,
  DictionaryMaterialExploitationLoadListActionTypes,
  DictionaryMaterialExploitationLoadListClearIdsAction,
  DictionaryMaterialExploitationLoadListFailAction,
  DictionaryMaterialExploitationLoadListSuccessAction
} from './actions/list.actions';
import {
  DictionaryMaterialExploitationLoadDetailsAction,
  DictionaryMaterialExploitationLoadDetailsActionTypes,
  DictionaryMaterialExploitationLoadDetailsFailAction,
  DictionaryMaterialExploitationLoadDetailsSuccessAction
} from './actions/load-details.actions';
import { DictionaryMaterialExploitationService } from './material-exploitation.service';
import { createDictionaryMaterialExploitationListParams } from './selectors/get-load-list.selectors';
import {
  DictionaryMaterialExploitationDeleteAction,
  DictionaryMaterialExploitationDeleteActionTypes,
  DictionaryMaterialExploitationDeleteFailAction,
  DictionaryMaterialExploitationDeleteSuccessAction
} from './actions/delete.actions';
import { ResponseStatus } from '../../../shared/enums/response-status.enum';
import { LibSnackbarComponent } from '@industi/ngx-modules';
import { AppGlobal } from '../../../shared/app-global';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlertUtil } from '../../../shared/utils/alert.util';

@Injectable()
export class DictionaryMaterialExploitationEffects {

  @Effect()
  loadList$ = this.actions$.pipe(
    ofType(DictionaryMaterialExploitationLoadListActionTypes.request),
    map((action: DictionaryMaterialExploitationLoadListAction) => action.payload),
    switchMap((payload: ByIdPayload) => this.store.pipe(
      select(createDictionaryMaterialExploitationListParams(payload.id)),
      map((params: SymfonyPaginationParams) => [payload.id, params] as [IdType, SymfonyPaginationParams]),
      first()
    )),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([[ id, params ], locale]) => this.service.loadList(locale, params).pipe(
      mergeMap((res: NormalizedList) => [
        new DictionaryMaterialExploitationLoadListClearIdsAction({ id }),
        new DictionaryMaterialExploitationLoadListSuccessAction({ id, res })]),
      catchError((errors: HttpErrorResponse) => of(new DictionaryMaterialExploitationLoadListFailAction({ id, errors })))
    ))
  );

  @Effect()
  add$ = this.actions$.pipe(
    ofType(DictionaryMaterialExploitationAddActionTypes.request),
    map((action: DictionaryMaterialExploitationAddAction) => action.payload),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([{ id, data }, locale]) => this.service.add(locale, data).pipe(
      map((res: NormalizedSingle) => new DictionaryMaterialExploitationAddSuccessAction({ res, id })),
      catchError((errors: HttpErrorResponse) => of(new DictionaryMaterialExploitationAddFailAction({ errors, id })))
    ))
  );

  @Effect()
  edit$ = this.actions$.pipe(
    ofType(DictionaryMaterialExploitationEditActionTypes.request),
    map((action: DictionaryMaterialExploitationEditAction) => action.payload),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([{ id, data }, locale]) => this.service.edit(locale, data, id).pipe(
      map((res: NormalizedSingle) => new DictionaryMaterialExploitationEditSuccessAction({ res, id })),
      catchError((errors: HttpErrorResponse) => of(new DictionaryMaterialExploitationEditFailAction({ errors, id })))
    ))
  );

  @Effect()
  loadDetails$ = this.actions$.pipe(
    ofType(DictionaryMaterialExploitationLoadDetailsActionTypes.request),
    map((action: DictionaryMaterialExploitationLoadDetailsAction) => action.payload.id),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([id, locale]) => this.service.details(locale, id).pipe(
      map((res: NormalizedSingle) => new DictionaryMaterialExploitationLoadDetailsSuccessAction({ id, res })),
      catchError((errors: HttpErrorResponse) => of(new DictionaryMaterialExploitationLoadDetailsFailAction({ id, errors })))
    ))
  );

  @Effect()
  delete$ = this.actions$.pipe(
    ofType(DictionaryMaterialExploitationDeleteActionTypes.request),
    map((action: DictionaryMaterialExploitationDeleteAction) => action.payload.id),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([id, locale]) => this.service.delete(locale, id).pipe(
      map(() => new DictionaryMaterialExploitationDeleteSuccessAction({ id })),
      catchError((errors: HttpErrorResponse) => of(new DictionaryMaterialExploitationDeleteFailAction({ id, errors })))
    ))
  );

  @Effect({ dispatch: false })
  crudFail$ = this.actions$.pipe(
    ofType(
      DictionaryMaterialExploitationAddActionTypes.requestFail,
      DictionaryMaterialExploitationEditActionTypes.requestFail
    ),
    map((action: DictionaryMaterialExploitationAddFailAction | DictionaryMaterialExploitationEditFailAction) => action.payload.errors),
    tap((data: HttpErrorResponse) => {
      if (data?.status === ResponseStatus.BAD_REQUEST || data?.status === ResponseStatus.UNPROCESSABLE_ENTITY) {
        this.snackBar.openFromComponent(LibSnackbarComponent, {
          data: {
            message: AlertUtil.getControlTranslation(data.error.detail),
            type: 'error'
          },
          duration: AppGlobal.defaultSnackBarDuration
        });
      }
    })
  );

  constructor(
    private actions$: Actions,
    private store: Store<ByKey>,
    private service: DictionaryMaterialExploitationService,
    private snackBar: MatSnackBar
  ) {
  }
}
