import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SendByIdPayload,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { DictionaryCarbonMaterial } from '../models/carbon-material';

export const DictionaryCarbonMaterialEditActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[dictionary/carbon-material] Edit'),
  requestFail: uniqueActionType('[dictionary/carbon-material] Edit fail'),
  requestSuccess: uniqueActionType('[dictionary/carbon-material] Edit success'),
  requestClearErrors: uniqueActionType('[dictionary/carbon-material] Edit clear errors'),
  requestClear: uniqueActionType('[dictionary/carbon-material] Edit clear')
};

export class DictionaryCarbonMaterialEditAction implements PayloadAction {
  readonly type = DictionaryCarbonMaterialEditActionTypes.request;

  constructor(public payload: SendByIdPayload<DictionaryCarbonMaterial>) {
  }
}

export class DictionaryCarbonMaterialEditFailAction implements PayloadAction {
  readonly type = DictionaryCarbonMaterialEditActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class DictionaryCarbonMaterialEditSuccessAction implements PayloadAction {
  readonly type = DictionaryCarbonMaterialEditActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class DictionaryCarbonMaterialEditClearErrorsAction implements PayloadAction {
  readonly type = DictionaryCarbonMaterialEditActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class DictionaryCarbonMaterialEditClearAction implements PayloadAction {
  readonly type = DictionaryCarbonMaterialEditActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
