import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, first, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { ByIdPayload, ByKey, IdType, NormalizedList, NormalizedSingle } from '@industi/ngx-common';
import { select, Store } from '@ngrx/store';
import { getInstanceLocale } from '../../../instance/instance.reducer';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  ServiceProjectLoadListAction, ServiceProjectLoadListActionTypes,
  ServiceProjectLoadListClearIdsAction,
  ServiceProjectLoadListFailAction, ServiceProjectLoadListSuccessAction
} from './actions/list.actions';
import { createServiceProjectListParams } from './selectors/get-list.selectors';
import { ServiceProjectListParams } from './models/project-list-params';
import { ServiceProjectCommonService } from './common.service';
import {
  ServiceProjectLoadDetailsAction,
  ServiceProjectLoadDetailsActionTypes,
  ServiceProjectLoadDetailsFailAction, ServiceProjectLoadDetailsSuccessAction
} from './actions/details.actions';
import {
  ServiceProjectAddAction,
  ServiceProjectAddActionTypes,
  ServiceProjectAddFailAction,
  ServiceProjectAddSuccessAction
} from './actions/add.actions';
import {
  ServiceProjectEditAction,
  ServiceProjectEditActionTypes, ServiceProjectEditFailAction,
  ServiceProjectEditSuccessAction
} from './actions/edit.actions';
import { ResponseStatus } from '../../../../shared/enums/response-status.enum';
import { LibSnackbarComponent } from '@industi/ngx-modules';
import { AppGlobal } from '../../../../shared/app-global';
import {
  ServiceProjectDeleteAction,
  ServiceProjectDeleteActionTypes,
  ServiceProjectDeleteFailAction,
  ServiceProjectDeleteSuccessAction
} from './actions/delete.actions';
import { ServiceProjectSetLastAddedAdapterService } from './adapters/set-last-added-adapter.service';
import { AlertUtil } from '../../../../shared/utils/alert.util';

@Injectable()
export class ServiceProjectCommonEffects {

  @Effect()
  loadList$ = this.actions$.pipe(
    ofType(ServiceProjectLoadListActionTypes.request),
    map((action: ServiceProjectLoadListAction) => action.payload),
    switchMap((payload: ByIdPayload) => this.store.pipe(
      select(createServiceProjectListParams(payload.id)),
      map((params: ServiceProjectListParams) => [payload.id, params] as [IdType, ServiceProjectListParams]),
      first()
    )),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([[ id, params ], locale]) => this.service.loadList(locale, params).pipe(
      mergeMap((res: NormalizedList) => [
        new ServiceProjectLoadListClearIdsAction({ id }),
        new ServiceProjectLoadListSuccessAction({ id, res })]),
      catchError((errors: HttpErrorResponse) => of(new ServiceProjectLoadListFailAction({ id, errors })))
    ))
  );

  @Effect()
  add$ = this.actions$.pipe(
    ofType(ServiceProjectAddActionTypes.request),
    map((action: ServiceProjectAddAction) => action.payload),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([{ id, data }, locale]) => this.service.add(locale, data).pipe(
      mergeMap((res: NormalizedSingle) => [
        this.lastAddedAdapter.setValueAction(res.result),
        new ServiceProjectAddSuccessAction({ res, id })
      ]),
      catchError((errors: HttpErrorResponse) => of(new ServiceProjectAddFailAction({ errors, id })))
    ))
  );

  @Effect()
  edit$ = this.actions$.pipe(
    ofType(ServiceProjectEditActionTypes.request),
    map((action: ServiceProjectEditAction) => action.payload),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([{ id, data }, locale]) => this.service.edit(locale, data, id).pipe(
      map((res: NormalizedSingle) => new ServiceProjectEditSuccessAction({ res, id })),
      catchError((errors: HttpErrorResponse) => of(new ServiceProjectEditFailAction({ errors, id })))
    ))
  );

  @Effect()
  loadDetails$ = this.actions$.pipe(
    ofType(ServiceProjectLoadDetailsActionTypes.request),
    map((action: ServiceProjectLoadDetailsAction) => action.payload.id),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([id, locale]) => this.service.details(locale, id).pipe(
      map((res: NormalizedSingle) => new ServiceProjectLoadDetailsSuccessAction({ id, res })),
      catchError((errors: HttpErrorResponse) => of(new ServiceProjectLoadDetailsFailAction({ id, errors })))
    ))
  );

  @Effect()
  delete$ = this.actions$.pipe(
    ofType(ServiceProjectDeleteActionTypes.request),
    map((action: ServiceProjectDeleteAction) => action.payload.id),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([id, locale]) => this.service.delete(locale, id).pipe(
      map(() => new ServiceProjectDeleteSuccessAction({ id })),
      catchError((errors: HttpErrorResponse) => of(new ServiceProjectDeleteFailAction({ id, errors })))
    ))
  );

  @Effect({ dispatch: false })
  crudFail$ = this.actions$.pipe(
    ofType(
      ServiceProjectAddActionTypes.requestFail,
      ServiceProjectEditActionTypes.requestFail
    ),
    map((action: ServiceProjectAddFailAction | ServiceProjectEditFailAction) => action.payload.errors),
    tap((data: HttpErrorResponse) => {
      if (data?.status === ResponseStatus.BAD_REQUEST || data?.status === ResponseStatus.UNPROCESSABLE_ENTITY) {
        this.snackBar.openFromComponent(LibSnackbarComponent, {
          data: {
            message: AlertUtil.getControlTranslation(data.error.detail),
            type: 'error'
          },
          duration: AppGlobal.defaultSnackBarDuration
        });
      }
    })
  );

  constructor(
    private actions$: Actions,
    private store: Store<ByKey>,
    private service: ServiceProjectCommonService,
    private lastAddedAdapter: ServiceProjectSetLastAddedAdapterService,
    private snackBar: MatSnackBar
  ) {
  }
}
