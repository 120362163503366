import { LibTableColumnsMap } from '@industi/ngx-modules';
import { PriceTableColumnsType } from '../../../shared/components/price-data-table/price-data-table-columns.type';

export const PriceTableColumnsMap: { [key in PriceTableColumnsType]: string } = {
  ...LibTableColumnsMap,
  currency: 'currency',
  retailPrice: 'retailPrice',
  sellingPrice: 'sellingPrice',
  purchasePrice: 'purchasePrice'
};
