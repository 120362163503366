import {
  ByIdPayload,
  ChangeParamsByIdPayload,
  FailByIdPayload,
  ListReducerTypes,
  NormalizedList,
  PayloadAction,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { ArticleBrandListParams } from '../models/article-brand-list-params';

export const ArticleBrandLoadListActionTypes: ListReducerTypes = {
  request: uniqueActionType('[article-brand] Load list'),
  requestFail: uniqueActionType('[article-brand] Load list fail'),
  requestSuccess: uniqueActionType('[article-brand] Load list success'),
  requestClear: uniqueActionType('[article-brand] Load list clear'),
  changeParams: uniqueActionType('[article-brand] Load list change params'),
  clearParams: uniqueActionType('[article-brand] Load list clear params'),
  clearIds: uniqueActionType('[article-brand] Load list clear ids')
};

export class ArticleBrandLoadListAction implements PayloadAction {
  readonly type = ArticleBrandLoadListActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class ArticleBrandLoadListFailAction implements PayloadAction {
  readonly type = ArticleBrandLoadListActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class ArticleBrandLoadListSuccessAction implements PayloadAction {
  readonly type = ArticleBrandLoadListActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedList>) {
  }
}

export class ArticleBrandLoadListClearAction implements PayloadAction {
  readonly type = ArticleBrandLoadListActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}

export class ArticleBrandLoadListChangeParamsAction implements PayloadAction {
  readonly type = ArticleBrandLoadListActionTypes.changeParams;

  constructor(public payload: ChangeParamsByIdPayload<ArticleBrandListParams>) {
  }
}

export class ArticleBrandLoadListClearParamsAction implements PayloadAction {
  readonly type = ArticleBrandLoadListActionTypes.clearParams;

  constructor(public payload: ByIdPayload) {
  }
}


export class ArticleBrandLoadListClearIdsAction implements PayloadAction {
  readonly type = ArticleBrandLoadListActionTypes.clearIds;

  constructor(public payload: ByIdPayload) {
  }
}
