import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SendByIdPayload,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { DictionaryProcessPayload } from '../models/process-payload';

export const DictionaryProcessAddActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[dictionary/process] Add'),
  requestFail: uniqueActionType('[dictionary/process] Add fail'),
  requestSuccess: uniqueActionType('[dictionary/process] Add success'),
  requestClearErrors: uniqueActionType('[dictionary/process] Add clear errors'),
  requestClear: uniqueActionType('[dictionary/process] Add clear'),
};

export class DictionaryProcessAddAction implements PayloadAction {
  readonly type = DictionaryProcessAddActionTypes.request;

  constructor(public payload: SendByIdPayload<DictionaryProcessPayload>) {
  }
}

export class DictionaryProcessAddFailAction implements PayloadAction {
  readonly type = DictionaryProcessAddActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class DictionaryProcessAddSuccessAction implements PayloadAction {
  readonly type = DictionaryProcessAddActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class DictionaryProcessAddClearErrorsAction implements PayloadAction {
  readonly type = DictionaryProcessAddActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class DictionaryProcessAddClearAction implements PayloadAction {
  readonly type = DictionaryProcessAddActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
