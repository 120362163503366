import {
  ByKey,
  createListStateIdsSelector,
  createListStateParamsSelector,
  createStateErrorsSelector,
  createStateLoadingSelector,
  createStateSuccessSelector,
  IdType,
  ListState
} from '@industi/ngx-common';
import { createSelector } from '@ngrx/store';
import { getOfferListState } from '../common.reducer';
import { createEntitiesSelector } from '../../../entity/entities.reducer';
import { EntityTypes } from '../../../entity/entity-types';
import { EntityState } from '../../../entity/entity.reducer';
import { denormalize } from 'normalizr';
import { Entities } from '../../../entity/entities';
import { Offer } from '../models/offer';
import { createSymfonyTotalCountSelector } from '../../../symfony/list/selectors/list.selectors';
import { OfferStatusEnum } from '../enums/status.enum';

const createState = (id: IdType) => createSelector(
  getOfferListState,
  (state: ByKey<ListState>) => state && state[id]
);

const createStateByContext = (id: IdType) => createListStateIdsSelector(createState(id));

export const createOfferListData = (id: IdType) => createSelector(
  createStateByContext(id),
  createEntitiesSelector([
    EntityTypes.offer,
    EntityTypes.image
  ]),
  (
    ids: IdType[],
    entities: EntityState
  ) => denormalize(ids, [Entities[EntityTypes.offer]], entities) as Offer[]
);

export const createOfferListLoading = (id: IdType) => createStateLoadingSelector(createState(id));
export const createOfferListSuccess = (id: IdType) => createStateSuccessSelector(createState(id));
export const createOfferListErrors = (id: IdType) => createStateErrorsSelector(createState(id));
export const createOfferListTotalCount = (id: IdType) => createSymfonyTotalCountSelector(createState(id));
export const createOfferListParams = (id: IdType) => createListStateParamsSelector(createState(id));

export const createOfferListActiveCount = (id: IdType) => createSelector(
  createOfferListData(id),
  (
    list: Offer[]
  ) => list.filter((offer: Offer) => offer?.status === OfferStatusEnum.ACTIVE)?.length
);
