import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SendByIdPayload,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { Dictionary } from '../../../../shared/models/dictionary';

export const DictionaryCourierEditActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[dictionary/courier] Edit'),
  requestFail: uniqueActionType('[dictionary/courier] Edit fail'),
  requestSuccess: uniqueActionType('[dictionary/courier] Edit success'),
  requestClearErrors: uniqueActionType('[dictionary/courier] Edit clear errors'),
  requestClear: uniqueActionType('[dictionary/courier] Edit clear')
};

export class DictionaryCourierEditAction implements PayloadAction {
  readonly type = DictionaryCourierEditActionTypes.request;

  constructor(public payload: SendByIdPayload<Dictionary>) {
  }
}

export class DictionaryCourierEditFailAction implements PayloadAction {
  readonly type = DictionaryCourierEditActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class DictionaryCourierEditSuccessAction implements PayloadAction {
  readonly type = DictionaryCourierEditActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class DictionaryCourierEditClearErrorsAction implements PayloadAction {
  readonly type = DictionaryCourierEditActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class DictionaryCourierEditClearAction implements PayloadAction {
  readonly type = DictionaryCourierEditActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
