import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { ByIdPayload, ByKey, IdType, NormalizedList, NormalizedSingle } from '@industi/ngx-common';
import {
  UserLoadListAction,
  UserLoadListActionTypes,
  UserLoadListClearIdsAction,
  UserLoadListFailAction,
  UserLoadListSuccessAction
} from './actions/list.actions';
import { catchError, first, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { UserListParams } from './models/user-list-params';
import { createUserListParams } from './selectors/get-load-list.selectors';
import { UserService } from './user.service';
import { UserAddAction, UserAddActionTypes, UserAddFailAction, UserAddSuccessAction } from './actions/add.actions';
import { getInstanceLocale } from '../instance/instance.reducer';
import {
  UserLoadDetailsAction,
  UserLoadDetailsActionTypes,
  UserLoadDetailsFailAction,
  UserLoadDetailsSuccessAction
} from './actions/load-details.actions';
import { UserEditAction, UserEditActionTypes, UserEditFailAction, UserEditSuccessAction } from './actions/edit.actions';
import {
  UserDeleteAction,
  UserDeleteActionTypes,
  UserDeleteFailAction,
  UserDeleteSuccessAction
} from './actions/delete.actions';
import { ResponseStatus } from '../../shared/enums/response-status.enum';
import { LibSnackbarComponent } from '@industi/ngx-modules';
import { AppGlobal } from '../../shared/app-global';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlertUtil } from '../../shared/utils/alert.util';

@Injectable()
export class UserEffects {

  @Effect()
  loadList$ = this.actions$.pipe(
    ofType(UserLoadListActionTypes.request),
    map((action: UserLoadListAction) => action.payload),
    switchMap((payload: ByIdPayload) => this.store.pipe(
      select(createUserListParams(payload.id)),
      map((params: UserListParams) => [payload.id, params] as [IdType, UserListParams]),
      first()
    )),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([[ id, params ], locale]) => this.service.loadList(locale, params).pipe(
      mergeMap((res: NormalizedList) => [
        new UserLoadListClearIdsAction({ id }),
        new UserLoadListSuccessAction({ id, res })]),
      catchError((errors: HttpErrorResponse) => of(new UserLoadListFailAction({ id, errors })))
    ))
  );

  @Effect()
  add$ = this.actions$.pipe(
    ofType(UserAddActionTypes.request),
    map((action: UserAddAction) => action.payload),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([{ id, data }, locale]) => this.service.add(locale, data).pipe(
      map((res: NormalizedSingle) => new UserAddSuccessAction({ res, id })),
      catchError((errors: HttpErrorResponse) => of(new UserAddFailAction({ errors, id })))
    ))
  );

  @Effect()
  edit$ = this.actions$.pipe(
    ofType(UserEditActionTypes.request),
    map((action: UserEditAction) => action.payload),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([{ id, data }, locale]) => this.service.edit(locale, data, id).pipe(
      map((res: NormalizedSingle) => new UserEditSuccessAction({ res, id })),
      catchError((errors: HttpErrorResponse) => of(new UserEditFailAction({ errors, id })))
    ))
  );

  @Effect()
  loadDetails$ = this.actions$.pipe(
    ofType(UserLoadDetailsActionTypes.request),
    map((action: UserLoadDetailsAction) => action.payload.id),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([id, locale]) => this.service.details(locale, id).pipe(
      map((res: NormalizedSingle) => new UserLoadDetailsSuccessAction({ id, res })),
      catchError((errors: HttpErrorResponse) => of(new UserLoadDetailsFailAction({ id, errors })))
    ))
  );

  @Effect()
  delete$ = this.actions$.pipe(
    ofType(UserDeleteActionTypes.request),
    map((action: UserDeleteAction) => action.payload.id),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([id, locale]) => this.service.delete(locale, id).pipe(
      map(() => new UserDeleteSuccessAction({ id })),
      catchError((errors: HttpErrorResponse) => of(new UserDeleteFailAction({ id, errors })))
    ))
  );

  @Effect({ dispatch: false })
  crudFail$ = this.actions$.pipe(
    ofType(
      UserAddActionTypes.requestFail,
      UserEditActionTypes.requestFail
    ),
    map((action: UserAddFailAction | UserEditFailAction) => action.payload.errors),
    tap((data: HttpErrorResponse) => {
      if (data?.status === ResponseStatus.BAD_REQUEST || data?.status === ResponseStatus.UNPROCESSABLE_ENTITY) {
        this.snackBar.openFromComponent(LibSnackbarComponent, {
          data: {
            message: AlertUtil.getControlTranslation(data.error.detail),
            type: 'error'
          },
          duration: AppGlobal.defaultSnackBarDuration
        });
      }
    })
  );

  constructor(
    private actions$: Actions,
    private store: Store<ByKey>,
    private service: UserService,
    private snackBar: MatSnackBar
  ) {
  }
}
