import { IdType, SetValuePayload, SetValueReducerTypes, uniqueActionType } from '@industi/ngx-common';

export const ClientSelectLastAddedIdActionTypes: SetValueReducerTypes = {
  setValue: uniqueActionType('[client] Select last added id'),
  clearValue: uniqueActionType('[client] Clear last added id'),
};

export class ClientSelectLastAddedIdAction {
  readonly type = ClientSelectLastAddedIdActionTypes.setValue;

  constructor(public payload: SetValuePayload<IdType>) {
  }
}

export class ClientClearLastAddedIdAction {
  readonly type = ClientSelectLastAddedIdActionTypes.setValue;
  readonly payload = null;
}
