import {
  ByIdPayload,
  ChangeParamsByIdPayload,
  FailByIdPayload,
  ListReducerTypes,
  NormalizedList,
  PayloadAction,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { DictionaryUnitStateListParams } from '../models/unit-state-list-params';

export const DictionaryUnitStateLoadListActionTypes: ListReducerTypes = {
  request: uniqueActionType('[dictionary/unit-state] Load list'),
  requestFail: uniqueActionType('[dictionary/unit-state] Load list fail'),
  requestSuccess: uniqueActionType('[dictionary/unit-state] Load list success'),
  requestClear: uniqueActionType('[dictionary/unit-state] Load list clear'),
  changeParams: uniqueActionType('[dictionary/unit-state] Load list change params'),
  clearParams: uniqueActionType('[dictionary/unit-state] Load list clear params'),
  clearIds: uniqueActionType('[dictionary/unit-state] Load list clear ids')
};

export class DictionaryUnitStateLoadListAction implements PayloadAction {
  readonly type = DictionaryUnitStateLoadListActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class DictionaryUnitStateLoadListFailAction implements PayloadAction {
  readonly type = DictionaryUnitStateLoadListActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class DictionaryUnitStateLoadListSuccessAction implements PayloadAction {
  readonly type = DictionaryUnitStateLoadListActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedList>) {
  }
}

export class DictionaryUnitStateLoadListClearAction implements PayloadAction {
  readonly type = DictionaryUnitStateLoadListActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}

export class DictionaryUnitStateLoadListChangeParamsAction implements PayloadAction {
  readonly type = DictionaryUnitStateLoadListActionTypes.changeParams;

  constructor(public payload: ChangeParamsByIdPayload<DictionaryUnitStateListParams>) {
  }
}

export class DictionaryUnitStateLoadListClearParamsAction implements PayloadAction {
  readonly type = DictionaryUnitStateLoadListActionTypes.clearParams;

  constructor(public payload: ByIdPayload) {
  }
}


export class DictionaryUnitStateLoadListClearIdsAction implements PayloadAction {
  readonly type = DictionaryUnitStateLoadListActionTypes.clearIds;

  constructor(public payload: ByIdPayload) {
  }
}
