import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import {
  DICTIONARY_CARBON_MATERIAL_REDUCER_TOKEN,
  dictionaryCarbonMaterialReducerProvider,
  rootReducerKey
} from './carbon-material.reducer';
import { DictionaryCarbonMaterialEffects } from './carbon-material.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(rootReducerKey, DICTIONARY_CARBON_MATERIAL_REDUCER_TOKEN),
    EffectsModule.forFeature([DictionaryCarbonMaterialEffects])
  ],
  providers: [
    dictionaryCarbonMaterialReducerProvider
  ]
})
export class DictionaryCarbonMaterialStoreModule { }
