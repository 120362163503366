import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { CommonModule } from '@angular/common';
import {
  ARTICLE_VIDEO_CONTENT_REDUCER_TOKEN,
  articleVideoContentReducerProvider,
  rootReducerKey
} from './video-content.reducer';
import { ArticleVideoContentEffects } from './video-content.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(rootReducerKey, ARTICLE_VIDEO_CONTENT_REDUCER_TOKEN),
    EffectsModule.forFeature([ArticleVideoContentEffects])
  ],
  providers: [
    articleVideoContentReducerProvider
  ]
})
export class ArticleVideoContentStoreModule {
}
