import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';

export const ArticleVideoContentLoadDetailsActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[article/video-content] Load details'),
  requestFail: uniqueActionType('[article/video-content] Load details fail'),
  requestSuccess: uniqueActionType('[article/video-content] Load details success'),
  requestClearErrors: uniqueActionType('[article/video-content] Load details clear errors'),
  requestClear: uniqueActionType('[article/video-content] Load details clear')
};

export class ArticleVideoContentLoadDetailsAction implements PayloadAction {
  readonly type = ArticleVideoContentLoadDetailsActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class ArticleVideoContentLoadDetailsFailAction implements PayloadAction {
  readonly type = ArticleVideoContentLoadDetailsActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class ArticleVideoContentLoadDetailsSuccessAction implements PayloadAction {
  readonly type = ArticleVideoContentLoadDetailsActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class ArticleVideoContentLoadDetailsClearAction implements PayloadAction {
  readonly type = ArticleVideoContentLoadDetailsActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}

export class ArticleVideoContentLoadDetailsClearErrorsAction implements PayloadAction {
  readonly type = ArticleVideoContentLoadDetailsActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}
