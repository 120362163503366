import { NgModule } from '@angular/core';
import { OfferCommonStoreModule } from './common/common-store.module';
import { OfferItemStoreModule } from './item/item-store.module';
import { OfferImageStoreModule } from './image/image-store.module';

@NgModule({
  declarations: [],
  imports: [
    OfferCommonStoreModule,
    OfferItemStoreModule,
    OfferImageStoreModule
  ],
})
export class OfferStoreModule {
}
