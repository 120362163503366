import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SendByIdPayload,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { DictionaryOperationPayload } from '../models/operation-payload';

export const DictionaryOperationAddActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[dictionary/operation] Add'),
  requestFail: uniqueActionType('[dictionary/operation] Add fail'),
  requestSuccess: uniqueActionType('[dictionary/operation] Add success'),
  requestClearErrors: uniqueActionType('[dictionary/operation] Add clear errors'),
  requestClear: uniqueActionType('[dictionary/operation] Add clear'),
};

export class DictionaryOperationAddAction implements PayloadAction {
  readonly type = DictionaryOperationAddActionTypes.request;

  constructor(public payload: SendByIdPayload<DictionaryOperationPayload>) {
  }
}

export class DictionaryOperationAddFailAction implements PayloadAction {
  readonly type = DictionaryOperationAddActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class DictionaryOperationAddSuccessAction implements PayloadAction {
  readonly type = DictionaryOperationAddActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class DictionaryOperationAddClearErrorsAction implements PayloadAction {
  readonly type = DictionaryOperationAddActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class DictionaryOperationAddClearAction implements PayloadAction {
  readonly type = DictionaryOperationAddActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
