import { ByKey, listByIdReducer, ListState } from '@industi/ngx-common';
import { ActionReducer, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import { TimelineLoadListActionTypes } from './actions/list.actions';
import { InjectionToken, Provider } from '@angular/core';

export const rootReducerKey = 'timeline';

export interface TimelineState {
  list: ByKey<ListState>;
}

const initialState: TimelineState = {
  list: {}
};

const reducer: ActionReducer<TimelineState> = combineReducers<TimelineState>({
  list: listByIdReducer({ types: TimelineLoadListActionTypes })
}, initialState);

export function getReducers(): ActionReducer<TimelineState> {
  return reducer;
}

export const TIMELINE_REDUCER_TOKEN = new InjectionToken<ActionReducer<TimelineState>>(rootReducerKey);

export const timelineReducerProvider: Provider = { provide: TIMELINE_REDUCER_TOKEN, useFactory: getReducers };

const getState = createFeatureSelector<TimelineState>(rootReducerKey);

export const getTimelineListState = createSelector(
  getState,
  (state: TimelineState) => state && state.list
);
