import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';

export const CarbonPartCommissionLoadDetailsActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[carbon/part-commission] Load details'),
  requestFail: uniqueActionType('[carbon/part-commission] Load details fail'),
  requestSuccess: uniqueActionType('[carbon/part-commission] Load details success'),
  requestClearErrors: uniqueActionType('[carbon/part-commission] Load details clear errors'),
  requestClear: uniqueActionType('[carbon/part-commission] Load details clear')
};

export class CarbonPartCommissionLoadDetailsAction implements PayloadAction {
  readonly type = CarbonPartCommissionLoadDetailsActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class CarbonPartCommissionLoadDetailsFailAction implements PayloadAction {
  readonly type = CarbonPartCommissionLoadDetailsActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class CarbonPartCommissionLoadDetailsSuccessAction implements PayloadAction {
  readonly type = CarbonPartCommissionLoadDetailsActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class CarbonPartCommissionLoadDetailsClearAction implements PayloadAction {
  readonly type = CarbonPartCommissionLoadDetailsActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}

export class CarbonPartCommissionLoadDetailsClearErrorsAction implements PayloadAction {
  readonly type = CarbonPartCommissionLoadDetailsActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}
