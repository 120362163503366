import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { ByIdPayload, ByKey, IdType, NormalizedList, NormalizedSingle } from '@industi/ngx-common';
import { catchError, first, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { getInstanceLocale } from '../../instance/instance.reducer';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import {
  ArticleLoadListAction,
  ArticleLoadListActionTypes,
  ArticleLoadListClearIdsAction,
  ArticleLoadListFailAction,
  ArticleLoadListSuccessAction
} from './actions/list.actions';
import { ArticleCommonService } from './common.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ArticleListParams } from './models/article-list-params';
import { createArticleListParams } from './selectors/get-load-list.selectors';
import {
  ArticleEditAction,
  ArticleEditActionTypes,
  ArticleEditFailAction,
  ArticleEditSuccessAction
} from './actions/edit.actions';
import {
  ArticleLoadDetailsAction,
  ArticleLoadDetailsActionTypes,
  ArticleLoadDetailsFailAction,
  ArticleLoadDetailsSuccessAction
} from './actions/details.actions';
import {
  ArticleAddAction,
  ArticleAddActionTypes,
  ArticleAddFailAction,
  ArticleAddSuccessAction
} from './actions/add.actions';
import {
  ArticleDeleteAction,
  ArticleDeleteActionTypes,
  ArticleDeleteFailAction,
  ArticleDeleteSuccessAction
} from './actions/delete.actions';
import { LibSnackbarComponent } from '@industi/ngx-modules';
import { ResponseStatus } from '../../../shared/enums/response-status.enum';
import { AlertUtil } from '../../../shared/utils/alert.util';
import { AppGlobal } from '../../../shared/app-global';

@Injectable()
export class ArticleCommonEffects {

  @Effect()
  loadList$ = this.actions$.pipe(
    ofType(ArticleLoadListActionTypes.request),
    map((action: ArticleLoadListAction) => action.payload),
    switchMap((payload: ByIdPayload) => this.store.pipe(
      select(createArticleListParams(payload.id)),
      map((params: ArticleListParams) => [payload.id, params] as [IdType, ArticleListParams]),
      first()
    )),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([[id, params], locale]) => this.service.loadList(locale, params).pipe(
      mergeMap((res: NormalizedList) => [
        new ArticleLoadListClearIdsAction({ id }),
        new ArticleLoadListSuccessAction({ id, res })]),
      catchError((errors: HttpErrorResponse) => of(new ArticleLoadListFailAction({ id, errors })))
    ))
  );

  @Effect()
  add$ = this.actions$.pipe(
    ofType(ArticleAddActionTypes.request),
    map((action: ArticleAddAction) => action.payload),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([{ id, data }, locale]) => this.service.add(locale, data).pipe(
      map((res: NormalizedSingle) => new ArticleAddSuccessAction({ res, id })),
      catchError((errors: HttpErrorResponse) => of(new ArticleAddFailAction({ errors, id })))
    ))
  );

  @Effect()
  edit$ = this.actions$.pipe(
    ofType(ArticleEditActionTypes.request),
    map((action: ArticleEditAction) => action.payload),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([{ id, data }, locale]) => this.service.edit(locale, data, id).pipe(
      map((res: NormalizedSingle) => new ArticleEditSuccessAction({ res, id })),
      catchError((errors: HttpErrorResponse) => of(new ArticleEditFailAction({ errors, id })))
    ))
  );

  @Effect()
  loadDetails$ = this.actions$.pipe(
    ofType(ArticleLoadDetailsActionTypes.request),
    map((action: ArticleLoadDetailsAction) => action.payload.id),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([id, locale]) => this.service.details(locale, id).pipe(
      map((res: NormalizedSingle) => new ArticleLoadDetailsSuccessAction({ id, res })),
      catchError((errors: HttpErrorResponse) => of(new ArticleLoadDetailsFailAction({ id, errors })))
    ))
  );

  @Effect()
  delete$ = this.actions$.pipe(
    ofType(ArticleDeleteActionTypes.request),
    map((action: ArticleDeleteAction) => action.payload.id),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([id, locale]) => this.service.delete(locale, id).pipe(
      map(() => new ArticleDeleteSuccessAction({ id })),
      catchError((errors: HttpErrorResponse) => of(new ArticleDeleteFailAction({ id, errors })))
    ))
  );

  @Effect({ dispatch: false })
  crudFail$ = this.actions$.pipe(
    ofType(
      ArticleAddActionTypes.requestFail,
      ArticleEditActionTypes.requestFail
    ),
    map((action: ArticleAddFailAction | ArticleEditFailAction) => action.payload.errors),
    tap((data: HttpErrorResponse) => {
      if (data?.status === ResponseStatus.BAD_REQUEST || data?.status === ResponseStatus.UNPROCESSABLE_ENTITY) {
        this.snackBar.openFromComponent(LibSnackbarComponent, {
          data: {
            message: AlertUtil.getControlTranslation(data?.error?.detail),
            type: 'error'
          },
          duration: AppGlobal.defaultSnackBarDuration
        });
      }
    })
  );

  constructor(
    private actions$: Actions,
    private store: Store<ByKey>,
    private service: ArticleCommonService,
    private snackBar: MatSnackBar
  ) {
  }
}
