import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';

@Injectable({ providedIn: 'root' })
export class VersionUpdateService {

  constructor(private updates: SwUpdate) {
  }

  updateOnNewVersionAvailable(): void {
    this.updates.available.subscribe(() => {
      alert('Uaktualniliśmy aplikację, kliknij OK, aby uruchomić nową wersję');
      this.updates.activateUpdate().then(() => document.location.reload());
    });
  }
}
