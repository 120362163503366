import {
  ByIdPayload,
  FailByIdPayload,
  OperationReducerTypes,
  PayloadAction,
  uniqueActionType
} from '@industi/ngx-common';

export const CarbonFormCommissionDeleteActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[carbon/form-commission] Delete'),
  requestFail: uniqueActionType('[carbon/form-commission] Delete fail'),
  requestSuccess: uniqueActionType('[carbon/form-commission] Delete success'),
  requestClearErrors: uniqueActionType('[carbon/form-commission] Delete clear errors'),
  requestClear: uniqueActionType('[carbon/form-commission] Delete clear')
};

export class CarbonFormCommissionDeleteAction implements PayloadAction {
  readonly type = CarbonFormCommissionDeleteActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class CarbonFormCommissionDeleteFailAction implements PayloadAction {
  readonly type = CarbonFormCommissionDeleteActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class CarbonFormCommissionDeleteSuccessAction implements PayloadAction {
  readonly type = CarbonFormCommissionDeleteActionTypes.requestSuccess;

  constructor(public payload: ByIdPayload) {
  }
}

export class CarbonFormCommissionDeleteClearErrorsAction implements PayloadAction {
  readonly type = CarbonFormCommissionDeleteActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class CarbonFormCommissionDeleteClearAction implements PayloadAction {
  readonly type = CarbonFormCommissionDeleteActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
