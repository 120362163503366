import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { ITEM_COMMON_REDUCER_TOKEN, itemCommonReducerProvider, rootReducerKey } from './common.reducer';
import { EffectsModule } from '@ngrx/effects';
import { ItemCommonEffects } from './common.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(rootReducerKey, ITEM_COMMON_REDUCER_TOKEN),
    EffectsModule.forFeature([ItemCommonEffects])
  ],
  providers: [
    itemCommonReducerProvider
  ]
})
export class ItemCommonStoreModule { }
