import {
  ByKey,
  createListStateIdsSelector,
  createListStateParamsSelector,
  createStateErrorsSelector,
  createStateLoadingSelector,
  createStateSuccessSelector,
  IdType,
  ListState
} from '@industi/ngx-common';
import { createSelector } from '@ngrx/store';
import { getDictionaryAcquisitionListState } from '../acquisition.reducer';
import { createEntitiesSelector } from '../../../entity/entities.reducer';
import { EntityTypes } from '../../../entity/entity-types';
import { EntityState } from '../../../entity/entity.reducer';
import { denormalize } from 'normalizr';
import { Entities } from '../../../entity/entities';
import { Dictionary } from '../../../../shared/models/dictionary';
import { createSymfonyTotalCountSelector } from '../../../symfony/list/selectors/list.selectors';

const createState = (id: IdType) => createSelector(
  getDictionaryAcquisitionListState,
  (state: ByKey<ListState>) => state && state[id]
);

const createStateByContext = (id: IdType) => createListStateIdsSelector(createState(id));

export const createDictionaryAcquisitionListData = (id: IdType) => createSelector(
  createStateByContext(id),
  createEntitiesSelector([
    EntityTypes.acquisition
  ]),
  (
    ids: IdType[],
    entites: EntityState
  ) => denormalize(ids, [Entities[EntityTypes.acquisition]], entites) as Dictionary[]
);

export const createDictionaryAcquisitionListLoading = (id: IdType) => createStateLoadingSelector(createState(id));

export const createDictionaryAcquisitionListSuccess = (id: IdType) => createStateSuccessSelector(createState(id));

export const createDictionaryAcquisitionListErrors = (id: IdType) => createStateErrorsSelector(createState(id));

export const createDictionaryAcquisitionListTotalCount = (id: IdType) => createSymfonyTotalCountSelector(createState(id));

export const createDictionaryAcquisitionListParams = (id: IdType) => createListStateParamsSelector(createState(id));
