import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SendByIdPayload,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { Dictionary } from '../../../../shared/models/dictionary';

export const DictionaryUnitStateAddActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[dictionary/unit-state] Add'),
  requestFail: uniqueActionType('[dictionary/unit-state] Add fail'),
  requestSuccess: uniqueActionType('[dictionary/unit-state] Add success'),
  requestClearErrors: uniqueActionType('[dictionary/unit-state] Add clear errors'),
  requestClear: uniqueActionType('[dictionary/unit-state] Add clear'),
};

export class DictionaryUnitStateAddAction implements PayloadAction {
  readonly type = DictionaryUnitStateAddActionTypes.request;

  constructor(public payload: SendByIdPayload<Dictionary>) {
  }
}

export class DictionaryUnitStateAddFailAction implements PayloadAction {
  readonly type = DictionaryUnitStateAddActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class DictionaryUnitStateAddSuccessAction implements PayloadAction {
  readonly type = DictionaryUnitStateAddActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class DictionaryUnitStateAddClearErrorsAction implements PayloadAction {
  readonly type = DictionaryUnitStateAddActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class DictionaryUnitStateAddClearAction implements PayloadAction {
  readonly type = DictionaryUnitStateAddActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
