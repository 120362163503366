import { createSelector } from '@ngrx/store';
import { denormalize } from 'normalizr';
import {
  ByKey,
  createListStateIdsSelector,
  createListStateParamsSelector,
  createStateErrorsSelector,
  createStateLoadingSelector,
  createStateSuccessSelector,
  IdType,
  ListState
} from '@industi/ngx-common';
import { EntityState } from '../../entity/entity.reducer';
import { EntityTypes } from '../../entity/entity-types';
import { createEntitiesSelector } from '../../entity/entities.reducer';
import { createSymfonyTotalCountSelector } from '../../symfony/list/selectors/list.selectors';
import { Entities } from '../../entity/entities';
import { DiscountGroup } from '../models/discount-group';
import { getDiscountGroupListState } from '../discount-group.reducer';

const createState = (id: IdType) => createSelector(
  getDiscountGroupListState,
  (state: ByKey<ListState>) => state && state[id]
);

const createStateById = (id: IdType) => createListStateIdsSelector(createState(id));

export const createDiscountGroupListData = (id: IdType) => createSelector(
  createStateById(id),
  createEntitiesSelector([
    EntityTypes.discountGroup
  ]),
  (
    ids: IdType[],
    entities: EntityState
  ) => denormalize(ids, [Entities[EntityTypes.discountGroup]], entities) as DiscountGroup[]
);

export const createDiscountGroupListLoading = (id: IdType) => createStateLoadingSelector(
  createState(id));

export const createDiscountGroupListSuccess = (id: IdType) => createStateSuccessSelector(
  createState(id));

export const createDiscountGroupListErrors = (id: IdType) => createStateErrorsSelector(
  createState(id));

export const createDiscountGroupListTotalCount = (id: IdType) => createSymfonyTotalCountSelector(
  createState(id));

export const createDiscountGroupListParams = (id: IdType) => createListStateParamsSelector(
  createState(id));
