import {
  ByKey,
  IdType,
  listByIdReducer,
  ListState,
  operationByIdReducer,
  setValueReducer,
  SingleState
} from '@industi/ngx-common';
import { ActionReducer, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import { InjectionToken, Provider } from '@angular/core';
import { DiagramLoadListActionTypes } from './actions/list.actions';
import { DiagramLoadDetailsActionTypes } from './actions/load-details.actions';
import { DiagramEditActionTypes } from './actions/edit.actions';
import { DiagramAddActionTypes } from './actions/add.actions';
import { DiagramSelectLastAddedIdActionTypes } from './actions/select-last-added-id.actions';
import { DiagramDeleteActionTypes } from './actions/delete.actions';

export const rootReducerKey = 'diagram';

export interface DiagramOperationByIdState {
  add: SingleState;
  edit: SingleState;
  details: SingleState;
  delete: SingleState;
}

export interface DiagramState {
  operationsById: ByKey<DiagramOperationByIdState>;
  list: ByKey<ListState>;
  lastAddedId: IdType;
}

const initialState: DiagramState = {
  operationsById: {},
  list: {},
  lastAddedId: null
};

const reducer: ActionReducer<DiagramState> = combineReducers<DiagramState>({
  operationsById: operationByIdReducer<DiagramOperationByIdState>({
    mapActionToId: (a) => a && a.payload && a.payload.id,
    operations: {
      add: DiagramAddActionTypes,
      edit: DiagramEditActionTypes,
      details: DiagramLoadDetailsActionTypes,
      delete: DiagramDeleteActionTypes
    }
  }),
  list: listByIdReducer({ types: DiagramLoadListActionTypes }),
  lastAddedId: setValueReducer({ types: DiagramSelectLastAddedIdActionTypes })
}, initialState);

export function getReducers(): ActionReducer<DiagramState> {
  return reducer;
}

export const DIAGRAM_COMMON_REDUCER_TOKEN = new InjectionToken<ActionReducer<DiagramState>>(rootReducerKey);

export const diagramCommonReducerProvider: Provider = { provide: DIAGRAM_COMMON_REDUCER_TOKEN, useFactory: getReducers };

const getState = createFeatureSelector<DiagramState>(rootReducerKey);

export const getDiagramOperationsById = createSelector(
  getState,
  (state: DiagramState) => state && state.operationsById
);

export const getDiagramListState = createSelector(
  getState,
  (state: DiagramState) => state && state.list
);

export const getDiagramLastAddedId = createSelector(
  getState,
  (state: DiagramState) => state && state.lastAddedId
);
