import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SendByIdPayload,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { OrderPayload } from '../models/order-payload';

export const OrderEditActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[order] Edit'),
  requestFail: uniqueActionType('[order] Edit fail'),
  requestSuccess: uniqueActionType('[order] Edit success'),
  requestClearErrors: uniqueActionType('[order] Edit clear errors'),
  requestClear: uniqueActionType('[order] Edit clear')
};

export class OrderEditAction implements PayloadAction {
  readonly type = OrderEditActionTypes.request;

  constructor(public payload: SendByIdPayload<OrderPayload>) {
  }
}

export class OrderEditFailAction implements PayloadAction {
  readonly type = OrderEditActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class OrderEditSuccessAction implements PayloadAction {
  readonly type = OrderEditActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class OrderEditClearErrorsAction implements PayloadAction {
  readonly type = OrderEditActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class OrderEditClearAction implements PayloadAction {
  readonly type = OrderEditActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
