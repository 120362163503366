import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DiagramCommonStoreModule } from './common/common-store.module';
import { DiagramPartStoreModule } from './part/part-store.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    DiagramCommonStoreModule,
    DiagramPartStoreModule
  ]
})
export class DiagramStoreModule { }
