import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, first, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { ByIdPayload, ByKey, IdType, NormalizedList, NormalizedSingle } from '@industi/ngx-common';
import { select, Store } from '@ngrx/store';
import { getInstanceLocale } from '../../instance/instance.reducer';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import {
  DictionaryOperationEditAction,
  DictionaryOperationEditActionTypes,
  DictionaryOperationEditFailAction,
  DictionaryOperationEditSuccessAction
} from './actions/edit.actions';
import {
  DictionaryOperationLoadDetailsAction,
  DictionaryOperationLoadDetailsActionTypes,
  DictionaryOperationLoadDetailsFailAction,
  DictionaryOperationLoadDetailsSuccessAction
} from './actions/load-details.actions';
import {
  DictionaryOperationAddAction,
  DictionaryOperationAddActionTypes,
  DictionaryOperationAddFailAction,
  DictionaryOperationAddSuccessAction
} from './actions/add.actions';
import {
  DictionaryOperationLoadListAction,
  DictionaryOperationLoadListActionTypes,
  DictionaryOperationLoadListClearIdsAction,
  DictionaryOperationLoadListFailAction,
  DictionaryOperationLoadListSuccessAction
} from './actions/list.actions';
import { DictionaryOperationService } from './operation.service';
import { SymfonyPaginationParams } from '../../symfony/list/models/symfony-pagination-params';
import { createDictionaryOperationListParams } from './selectors/get-load-list.selectors';
import {
  DictionaryOperationDeleteAction,
  DictionaryOperationDeleteActionTypes,
  DictionaryOperationDeleteFailAction,
  DictionaryOperationDeleteSuccessAction
} from './actions/delete.actions';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ResponseStatus } from '../../../shared/enums/response-status.enum';
import { LibSnackbarComponent } from '@industi/ngx-modules';
import { AppGlobal } from '../../../shared/app-global';
import { AlertUtil } from '../../../shared/utils/alert.util';

@Injectable()
export class DictionaryOperationEffects {

  @Effect()
  loadList$ = this.actions$.pipe(
    ofType(DictionaryOperationLoadListActionTypes.request),
    map((action: DictionaryOperationLoadListAction) => action.payload),
    switchMap((payload: ByIdPayload) => this.store.pipe(
      select(createDictionaryOperationListParams(payload.id)),
      map((params: SymfonyPaginationParams) => [payload.id, params] as [IdType, SymfonyPaginationParams]),
      first()
    )),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([[ id, params ], locale]) => this.service.loadList(locale, params).pipe(
      mergeMap((res: NormalizedList) => [
        new DictionaryOperationLoadListClearIdsAction({ id }),
        new DictionaryOperationLoadListSuccessAction({ id, res })]),
      catchError((errors: HttpErrorResponse) => of(new DictionaryOperationLoadListFailAction({ id, errors })))
    ))
  );

  @Effect()
  add$ = this.actions$.pipe(
    ofType(DictionaryOperationAddActionTypes.request),
    map((action: DictionaryOperationAddAction) => action.payload),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([{ id, data }, locale]) => this.service.add(locale, data).pipe(
      map((res: NormalizedSingle) => new DictionaryOperationAddSuccessAction({ res, id })),
      catchError((errors: HttpErrorResponse) => of(new DictionaryOperationAddFailAction({ errors, id })))
    ))
  );

  @Effect()
  edit$ = this.actions$.pipe(
    ofType(DictionaryOperationEditActionTypes.request),
    map((action: DictionaryOperationEditAction) => action.payload),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([{ id, data }, locale]) => this.service.edit(locale, data, id).pipe(
      map((res: NormalizedSingle) => new DictionaryOperationEditSuccessAction({ res, id })),
      catchError((errors: HttpErrorResponse) => of(new DictionaryOperationEditFailAction({ errors, id })))
    ))
  );

  @Effect()
  loadDetails$ = this.actions$.pipe(
    ofType(DictionaryOperationLoadDetailsActionTypes.request),
    map((action: DictionaryOperationLoadDetailsAction) => action.payload.id),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([id, locale]) => this.service.details(locale, id).pipe(
      map((res: NormalizedSingle) => new DictionaryOperationLoadDetailsSuccessAction({ id, res })),
      catchError((errors: HttpErrorResponse) => of(new DictionaryOperationLoadDetailsFailAction({ id, errors })))
    ))
  );

  @Effect()
  delete$ = this.actions$.pipe(
    ofType(DictionaryOperationDeleteActionTypes.request),
    map((action: DictionaryOperationDeleteAction) => action.payload.id),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([id, locale]) => this.service.delete(locale, id).pipe(
      map(() => new DictionaryOperationDeleteSuccessAction({ id })),
      catchError((errors: HttpErrorResponse) => of(new DictionaryOperationDeleteFailAction({ id, errors })))
    ))
  );

  @Effect({ dispatch: false })
  crudFail$ = this.actions$.pipe(
    ofType(
      DictionaryOperationAddActionTypes.requestFail,
      DictionaryOperationEditActionTypes.requestFail
    ),
    map((action: DictionaryOperationAddFailAction | DictionaryOperationEditFailAction) => action.payload.errors),
    tap((data: HttpErrorResponse) => {
      if (data?.status === ResponseStatus.BAD_REQUEST || data?.status === ResponseStatus.UNPROCESSABLE_ENTITY) {
        this.snackBar.openFromComponent(LibSnackbarComponent, {
          data: {
            message: AlertUtil.getControlTranslation(data.error.detail),
            type: 'error'
          },
          duration: AppGlobal.defaultSnackBarDuration
        });
      }
    })
  );

  constructor(
    private actions$: Actions,
    private store: Store<ByKey>,
    private service: DictionaryOperationService,
    private snackBar: MatSnackBar
  ) {
  }
}
