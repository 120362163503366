import jwtDecode from 'jwt-decode';
import { ParsedToken } from './models/parsed-token';

export class AuthUtil {

  protected static get storage() {
    return localStorage;
  }

  static parseTokenString(token: string): ParsedToken {
    try {
      return jwtDecode(token);
    } catch (e) {
      return null;
    }
  }

  static get accessToken(): string {
    return AuthUtil.storage.getItem('access_token') || null;
  }
}
