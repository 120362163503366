import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { ByIdPayload, ByKey, IdType, NormalizedList, NormalizedSingle } from '@industi/ngx-common';
import { DictionaryProcessService } from './process.service';
import { catchError, first, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { SymfonyPaginationParams } from '../../symfony/list/models/symfony-pagination-params';
import { getInstanceLocale } from '../../instance/instance.reducer';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import {
  DictionaryProcessLoadListAction,
  DictionaryProcessLoadListActionTypes,
  DictionaryProcessLoadListClearIdsAction,
  DictionaryProcessLoadListFailAction,
  DictionaryProcessLoadListSuccessAction
} from './actions/list.actions';
import { createDictionaryProcessListParams } from './selectors/get-load-list.selectors';
import {
  DictionaryProcessAddAction,
  DictionaryProcessAddActionTypes,
  DictionaryProcessAddFailAction,
  DictionaryProcessAddSuccessAction
} from './actions/add.actions';
import {
  DictionaryProcessEditAction,
  DictionaryProcessEditActionTypes,
  DictionaryProcessEditFailAction,
  DictionaryProcessEditSuccessAction
} from './actions/edit.actions';
import {
  DictionaryProcessLoadDetailsAction,
  DictionaryProcessLoadDetailsActionTypes,
  DictionaryProcessLoadDetailsFailAction,
  DictionaryProcessLoadDetailsSuccessAction
} from './actions/load-details.actions';
import {
  DictionaryProcessDeleteAction,
  DictionaryProcessDeleteActionTypes,
  DictionaryProcessDeleteFailAction,
  DictionaryProcessDeleteSuccessAction
} from './actions/delete.actions';
import { DictionaryProcessListParams } from './models/process-list-params';
import { ResponseStatus } from '../../../shared/enums/response-status.enum';
import { LibSnackbarComponent } from '@industi/ngx-modules';
import { AppGlobal } from '../../../shared/app-global';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DictionaryBrandAddActionTypes, DictionaryBrandAddFailAction } from '../brand/actions/add.actions';
import { AlertUtil } from '../../../shared/utils/alert.util';

@Injectable()
export class DictionaryProcessEffects {

  @Effect()
  loadList$ = this.actions$.pipe(
    ofType(DictionaryProcessLoadListActionTypes.request),
    map((action: DictionaryProcessLoadListAction) => action.payload),
    switchMap((payload: ByIdPayload) => this.store.pipe(
      select(createDictionaryProcessListParams(payload.id)),
      map((params: SymfonyPaginationParams) => [payload.id, params] as [IdType, DictionaryProcessListParams]),
      first()
    )),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([[ id, params ], locale]) => this.service.loadList(locale, params).pipe(
      mergeMap((res: NormalizedList) => [
        new DictionaryProcessLoadListClearIdsAction({ id }),
        new DictionaryProcessLoadListSuccessAction({ id, res })]),
      catchError((errors: HttpErrorResponse) => of(new DictionaryProcessLoadListFailAction({ id, errors })))
    ))
  );

  @Effect()
  add$ = this.actions$.pipe(
    ofType(DictionaryProcessAddActionTypes.request),
    map((action: DictionaryProcessAddAction) => action.payload),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([{ id, data }, locale]) => this.service.add(locale, data).pipe(
      map((res: NormalizedSingle) => new DictionaryProcessAddSuccessAction({ res, id })),
      catchError((errors: HttpErrorResponse) => of(new DictionaryProcessAddFailAction({ errors, id })))
    ))
  );

  @Effect()
  edit$ = this.actions$.pipe(
    ofType(DictionaryProcessEditActionTypes.request),
    map((action: DictionaryProcessEditAction) => action.payload),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([{ id, data }, locale]) => this.service.edit(locale, data, id).pipe(
      map((res: NormalizedSingle) => new DictionaryProcessEditSuccessAction({ res, id })),
      catchError((errors: HttpErrorResponse) => of(new DictionaryProcessEditFailAction({ errors, id })))
    ))
  );

  @Effect()
  loadDetails$ = this.actions$.pipe(
    ofType(DictionaryProcessLoadDetailsActionTypes.request),
    map((action: DictionaryProcessLoadDetailsAction) => action.payload.id),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([id, locale]) => this.service.details(locale, id).pipe(
      map((res: NormalizedSingle) => new DictionaryProcessLoadDetailsSuccessAction({ id, res })),
      catchError((errors: HttpErrorResponse) => of(new DictionaryProcessLoadDetailsFailAction({ id, errors })))
    ))
  );

  @Effect()
  delete$ = this.actions$.pipe(
    ofType(DictionaryProcessDeleteActionTypes.request),
    map((action: DictionaryProcessDeleteAction) => action.payload.id),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([id, locale]) => this.service.delete(locale, id).pipe(
      map(() => new DictionaryProcessDeleteSuccessAction({ id })),
      catchError((errors: HttpErrorResponse) => of(new DictionaryProcessDeleteFailAction({ id, errors })))
    ))
  );

  @Effect({ dispatch: false })
  crudFail$ = this.actions$.pipe(
    ofType(
      DictionaryBrandAddActionTypes.requestFail,
      DictionaryProcessEditActionTypes.requestFail
    ),
    map((action: DictionaryBrandAddFailAction | DictionaryProcessEditFailAction) => action.payload.errors),
    tap((data: HttpErrorResponse) => {
      if (data?.status === ResponseStatus.BAD_REQUEST || data?.status === ResponseStatus.UNPROCESSABLE_ENTITY) {
        this.snackBar.openFromComponent(LibSnackbarComponent, {
          data: {
            message: AlertUtil.getControlTranslation(data.error.detail),
            type: 'error'
          },
          duration: AppGlobal.defaultSnackBarDuration
        });
      }
    })
  );

  constructor(
    private actions$: Actions,
    private store: Store<ByKey>,
    private service: DictionaryProcessService,
    private snackBar: MatSnackBar
  ) {
  }
}
