import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';

export const ModelLoadDetailsActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[model] Load details'),
  requestFail: uniqueActionType('[model] Load details fail'),
  requestSuccess: uniqueActionType('[model] Load details success'),
  requestClearErrors: uniqueActionType('[model] Load details clear errors'),
  requestClear: uniqueActionType('[model] Load details clear')
};

export class ModelLoadDetailsAction implements PayloadAction {
  readonly type = ModelLoadDetailsActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class ModelLoadDetailsFailAction implements PayloadAction {
  readonly type = ModelLoadDetailsActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class ModelLoadDetailsSuccessAction implements PayloadAction {
  readonly type = ModelLoadDetailsActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class ModelLoadDetailsClearAction implements PayloadAction {
  readonly type = ModelLoadDetailsActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}

export class ModelLoadDetailsClearErrorsAction implements PayloadAction {
  readonly type = ModelLoadDetailsActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}
