import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import {
  LAYER_REDUCER_TOKEN,
  layerReducerProvider,
  rootReducerKey
} from './layer.reducer';
import { EffectsModule } from '@ngrx/effects';
import { LayerEffects } from './layer.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(rootReducerKey, LAYER_REDUCER_TOKEN),
    EffectsModule.forFeature([LayerEffects])
  ],
  providers: [
    layerReducerProvider
  ]
})
export class LayerStoreModule { }
