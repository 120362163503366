import {
  ByIdPayload,
  FailByIdPayload,
  ListReducerTypes,
  NormalizedList,
  PayloadAction,
  RemoveIdByIdPayload,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';

export const OfferImageAddPendingListActionTypes: ListReducerTypes = {
  request: uniqueActionType('[offer/image] Add pending list'),
  requestFail: uniqueActionType('[offer/image] Add pending list fail'),
  requestSuccess: uniqueActionType('[offer/image] Add pending list success'),
  requestClearErrors: uniqueActionType('[offer/image] Add pending list clear errors'),
  requestClear: uniqueActionType('[offer/image] Add pending list clear'),
  removeId: uniqueActionType('[offer/image] Add pending list remove id')
};

export class OfferImageAddPendingListFailAction implements PayloadAction {
  readonly type = OfferImageAddPendingListActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class OfferImageAddPendingListSuccessAction implements PayloadAction {
  readonly type = OfferImageAddPendingListActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedList>) {
  }
}

export class OfferImageAddPendingListClearErrorsAction implements PayloadAction {
  readonly type = OfferImageAddPendingListActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class OfferImageAddPendingListClearAction implements PayloadAction {
  readonly type = OfferImageAddPendingListActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}

export class OfferImageAddPendingListRemoveIdAction implements PayloadAction {
  readonly type = OfferImageAddPendingListActionTypes.removeId;

  constructor(public payload: RemoveIdByIdPayload) {
  }
}
