import { Pipe, PipeTransform } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ByKey, IdType } from '@industi/ngx-common';
import { Dictionary } from '../models/dictionary';
import { select, Store } from '@ngrx/store';
import { distinctUntilChanged, map, withLatestFrom } from 'rxjs/operators';
import { getServiceProjectStatus } from '../../store/service/project/common/selectors/get-status.selectors';

@Pipe({
  name: 'serviceProjectStatusInfo'
})
export class ServiceProjectStatusInfoPipe implements PipeTransform {

  private id$: BehaviorSubject<IdType> = new BehaviorSubject<IdType>(null);
  private readonly result$: Observable<Dictionary>;

  constructor(
    private store: Store<ByKey>
  ) {
    this.result$ = this.id$.pipe(
      distinctUntilChanged(),
      withLatestFrom(this.store.pipe(select(getServiceProjectStatus))),
      map(([id, types]) => types.find(el => el.id === id))
    );
  }

  transform(value: IdType): Observable<Dictionary> {
    this.id$.next(value);
    return this.result$;
  }

}
