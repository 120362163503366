import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SendByIdPayload,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { Part } from '../models/part';

export const PartEditActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[part] Edit'),
  requestFail: uniqueActionType('[part] Edit fail'),
  requestSuccess: uniqueActionType('[part] Edit success'),
  requestClearErrors: uniqueActionType('[part] Edit clear errors'),
  requestClear: uniqueActionType('[part] Edit clear')
};

export class PartEditAction implements PayloadAction {
  readonly type = PartEditActionTypes.request;

  constructor(public payload: SendByIdPayload<Part>) {
  }
}

export class PartEditFailAction implements PayloadAction {
  readonly type = PartEditActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class PartEditSuccessAction implements PayloadAction {
  readonly type = PartEditActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class PartEditClearErrorsAction implements PayloadAction {
  readonly type = PartEditActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class PartEditClearAction implements PayloadAction {
  readonly type = PartEditActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
