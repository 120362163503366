import {
  ByIdPayload,
  ChangeParamsByIdPayload,
  FailByIdPayload,
  ListReducerTypes,
  NormalizedList,
  PayloadAction,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { ArticleListParams } from '../models/article-list-params';

export const ArticleLoadListActionTypes: ListReducerTypes = {
  request: uniqueActionType('[article] Load list'),
  requestFail: uniqueActionType('[article] Load list fail'),
  requestSuccess: uniqueActionType('[article] Load list success'),
  requestClear: uniqueActionType('[article] Load list clear'),
  changeParams: uniqueActionType('[article] Load list change params'),
  clearParams: uniqueActionType('[article] Load list clear params'),
  clearIds: uniqueActionType('[article] Load list clear ids')
};

export class ArticleLoadListAction implements PayloadAction {
  readonly type = ArticleLoadListActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class ArticleLoadListFailAction implements PayloadAction {
  readonly type = ArticleLoadListActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class ArticleLoadListSuccessAction implements PayloadAction {
  readonly type = ArticleLoadListActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedList>) {
  }
}

export class ArticleLoadListClearAction implements PayloadAction {
  readonly type = ArticleLoadListActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}

export class ArticleLoadListChangeParamsAction implements PayloadAction {
  readonly type = ArticleLoadListActionTypes.changeParams;

  constructor(public payload: ChangeParamsByIdPayload<ArticleListParams>) {
  }
}

export class ArticleLoadListClearParamsAction implements PayloadAction {
  readonly type = ArticleLoadListActionTypes.clearParams;

  constructor(public payload: ByIdPayload) {
  }
}


export class ArticleLoadListClearIdsAction implements PayloadAction {
  readonly type = ArticleLoadListActionTypes.clearIds;

  constructor(public payload: ByIdPayload) {
  }
}
