import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SendByIdPayload,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { ItemPayload } from '../models/item-payload';
import { FilesPayload } from '../../../common/models/files-payload';

export const ItemAddActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[item] Add'),
  requestFail: uniqueActionType('[item] Add fail'),
  requestSuccess: uniqueActionType('[item] Add success'),
  requestClearErrors: uniqueActionType('[item] Add clear errors'),
  requestClear: uniqueActionType('[item] Add clear'),
};

export class ItemAddAction implements PayloadAction {
  readonly type = ItemAddActionTypes.request;

  constructor(public payload: SendByIdPayload<FilesPayload<ItemPayload>>) {
  }
}

export class ItemAddFailAction implements PayloadAction {
  readonly type = ItemAddActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class ItemAddSuccessAction implements PayloadAction {
  readonly type = ItemAddActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class ItemAddClearErrorsAction implements PayloadAction {
  readonly type = ItemAddActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class ItemAddClearAction implements PayloadAction {
  readonly type = ItemAddActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
