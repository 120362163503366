import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ByKey, IdType } from '@industi/ngx-common';
import { select, Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationLoadCurrentAdapterService } from '../../store/application/adapters/load-current-adapter.service';
import { Observable } from 'rxjs';
import { getLoggedUserId } from '../../store/auth/auth.reducer';
import { AppGlobal } from '../../shared/app-global';
import { Application } from '../../store/application/models/application';

@Component({
  selector: 'app-application-current',
  templateUrl: './current.component.html',
  styleUrls: ['./current.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ApplicationCurrentComponent implements OnInit {

  userId$: Observable<IdType>;

  loading$: Observable<boolean>;

  details$: Observable<Application>;

  constructor(
    protected store: Store<ByKey>,
    protected currentAdapter: ApplicationLoadCurrentAdapterService,
    protected route: ActivatedRoute,
    protected router: Router
  ) {
  }

  ngOnInit(): void {
    this.userId$ = this.store.pipe(select(getLoggedUserId));
    this.loading$ = this.store.pipe(select(this.currentAdapter.getLoadingSelector(AppGlobal.addId)));
    this.details$ = this.store.pipe(select(this.currentAdapter.getDataSelector(AppGlobal.addId)));

    this.store.dispatch(this.currentAdapter.requestAction(AppGlobal.addId));
  }

  back(): void {
    this.router.navigate(['../'], {
      relativeTo: this.route
    });
  }
}
