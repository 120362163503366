import {
  ByIdPayload,
  FailByIdPayload,
  ListReducerTypes,
  NormalizedList,
  PayloadAction,
  RemoveIdByIdPayload,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';

export const ArticleImageContentAddPendingListActionTypes: ListReducerTypes = {
  request: uniqueActionType('[article/image-content] Add pending list'),
  requestFail: uniqueActionType('[article/image-content] Add pending list fail'),
  requestSuccess: uniqueActionType('[article/image-content] Add pending list success'),
  requestClearErrors: uniqueActionType('[article/image-content] Add pending list clear errors'),
  requestClear: uniqueActionType('[article/image-content] Add pending list clear'),
  removeId: uniqueActionType('[article/image-content] Add pending list remove id')
};

export class ArticleImageContentAddPendingListFailAction implements PayloadAction {
  readonly type = ArticleImageContentAddPendingListActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class ArticleImageContentAddPendingListSuccessAction implements PayloadAction {
  readonly type = ArticleImageContentAddPendingListActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedList>) {
  }
}

export class ArticleImageContentAddPendingListClearErrorsAction implements PayloadAction {
  readonly type = ArticleImageContentAddPendingListActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class ArticleImageContentAddPendingListClearAction implements PayloadAction {
  readonly type = ArticleImageContentAddPendingListActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}

export class ArticleImageContentAddPendingListRemoveIdAction implements PayloadAction {
  readonly type = ArticleImageContentAddPendingListActionTypes.removeId;

  constructor(public payload: RemoveIdByIdPayload) {
  }
}
