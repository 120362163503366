import {
  ByIdPayload,
  FailByIdPayload,
  OperationReducerTypes,
  PayloadAction,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';

export const WarehouseLoadPdfActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[warehouse] Load pdf'),
  requestFail: uniqueActionType('[warehouse] Load pdf fail'),
  requestSuccess: uniqueActionType('[warehouse] Load pdf success'),
  requestClear: uniqueActionType('[warehouse] Load pdf clear'),
  requestClearErrors: uniqueActionType('[warehouse] Load pdf clear errors'),
};

export class WarehouseLoadPdfAction implements PayloadAction {
  readonly type = WarehouseLoadPdfActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class WarehouseLoadPdfFailAction implements PayloadAction {
  readonly type = WarehouseLoadPdfActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class WarehouseLoadPdfSuccessAction implements PayloadAction {
  readonly type = WarehouseLoadPdfActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<any>) {
  }
}

export class WarehouseLoadPdfClearAction implements PayloadAction {
  readonly type = WarehouseLoadPdfActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}

export class WarehouseLoadPdfClearErrorsAction implements PayloadAction {
  readonly type = WarehouseLoadPdfActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
