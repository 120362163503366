import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { ByIdPayload, ByKey, IdType, NormalizedList, NormalizedSingle } from '@industi/ngx-common';
import { DictionaryAcquisitionService } from './acquisition.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  DictionaryAcquisitionLoadListAction,
  DictionaryAcquisitionLoadListActionTypes,
  DictionaryAcquisitionLoadListClearIdsAction,
  DictionaryAcquisitionLoadListFailAction,
  DictionaryAcquisitionLoadListSuccessAction
} from './actions/list.actions';
import { catchError, first, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { createDictionaryAcquisitionListParams } from './selectors/get-load-list.selectors';
import { SymfonyPaginationParams } from '../../symfony/list/models/symfony-pagination-params';
import { getInstanceLocale } from '../../instance/instance.reducer';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import {
  DictionaryAcquisitionAddAction,
  DictionaryAcquisitionAddActionTypes,
  DictionaryAcquisitionAddFailAction,
  DictionaryAcquisitionAddSuccessAction
} from './actions/add.actions';
import {
  DictionaryAcquisitionEditAction,
  DictionaryAcquisitionEditActionTypes,
  DictionaryAcquisitionEditFailAction,
  DictionaryAcquisitionEditSuccessAction
} from './actions/edit.actions';
import {
  DictionaryAcquisitionLoadDetailsAction,
  DictionaryAcquisitionLoadDetailsActionTypes,
  DictionaryAcquisitionLoadDetailsFailAction,
  DictionaryAcquisitionLoadDetailsSuccessAction
} from './actions/load-details.actions';
import {
  DictionaryAcquisitionDeleteAction,
  DictionaryAcquisitionDeleteActionTypes,
  DictionaryAcquisitionDeleteFailAction,
  DictionaryAcquisitionDeleteSuccessAction
} from './actions/delete.actions';
import { ResponseStatus } from '../../../shared/enums/response-status.enum';
import { LibSnackbarComponent } from '@industi/ngx-modules';
import { AppGlobal } from '../../../shared/app-global';
import { AlertUtil } from '../../../shared/utils/alert.util';

@Injectable()
export class DictionaryAcquisitionEffects {

  @Effect()
  loadList$ = this.actions$.pipe(
    ofType(DictionaryAcquisitionLoadListActionTypes.request),
    map((action: DictionaryAcquisitionLoadListAction) => action.payload),
    switchMap((payload: ByIdPayload) => this.store.pipe(
      select(createDictionaryAcquisitionListParams(payload.id)),
      map((params: SymfonyPaginationParams) => [payload.id, params] as [IdType, SymfonyPaginationParams]),
      first()
    )),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([[id, params], locale]) => this.service.loadList(locale, params).pipe(
      mergeMap((res: NormalizedList) => [
        new DictionaryAcquisitionLoadListClearIdsAction({ id }),
        new DictionaryAcquisitionLoadListSuccessAction({ id, res })]),
      catchError((errors: HttpErrorResponse) => of(new DictionaryAcquisitionLoadListFailAction({ id, errors })))
    ))
  );

  @Effect()
  add$ = this.actions$.pipe(
    ofType(DictionaryAcquisitionAddActionTypes.request),
    map((action: DictionaryAcquisitionAddAction) => action.payload),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([{ id, data }, locale]) => this.service.add(locale, data).pipe(
      map((res: NormalizedSingle) => new DictionaryAcquisitionAddSuccessAction({ res, id })),
      catchError((errors: HttpErrorResponse) => of(new DictionaryAcquisitionLoadListFailAction({ id, errors })))
    ))
  );

  @Effect()
  edit$ = this.actions$.pipe(
    ofType(DictionaryAcquisitionEditActionTypes.request),
    map((action: DictionaryAcquisitionEditAction) => action.payload),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([{ id, data }, locale]) => this.service.edit(locale, data, id).pipe(
      map((res: NormalizedSingle) => new DictionaryAcquisitionEditSuccessAction({ res, id })),
      catchError((errors: HttpErrorResponse) => of(new DictionaryAcquisitionEditFailAction({ id, errors })))
    ))
  );

  @Effect()
  loadDetails$ = this.actions$.pipe(
    ofType(DictionaryAcquisitionLoadDetailsActionTypes.request),
    map((action: DictionaryAcquisitionLoadDetailsAction) => action.payload.id),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([id, locale]) => this.service.details(locale, id).pipe(
      map((res: NormalizedSingle) => new DictionaryAcquisitionLoadDetailsSuccessAction({ res, id })),
      catchError((errors: HttpErrorResponse) => of(new DictionaryAcquisitionLoadDetailsFailAction({ id, errors })))
    ))
  );

  @Effect()
  $delete = this.actions$.pipe(
    ofType(DictionaryAcquisitionDeleteActionTypes.request),
    map((action: DictionaryAcquisitionDeleteAction) => action.payload.id),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([id, locale]) => this.service.delete(locale, id).pipe(
      map(() => new DictionaryAcquisitionDeleteSuccessAction({ id })),
      catchError((errors: HttpErrorResponse) => of(new DictionaryAcquisitionDeleteFailAction({ id, errors })))
    ))
  );

  @Effect({ dispatch: false })
  crudFail$ = this.actions$.pipe(
    ofType(
      DictionaryAcquisitionAddActionTypes.requestFail,
      DictionaryAcquisitionEditActionTypes.requestFail
    ),
    map((action: DictionaryAcquisitionAddFailAction | DictionaryAcquisitionEditFailAction) => action.payload.errors),
    tap((data: HttpErrorResponse) => {
      if (data?.status === ResponseStatus.BAD_REQUEST || data?.status === ResponseStatus.UNPROCESSABLE_ENTITY) {
        this.snackBar.openFromComponent(LibSnackbarComponent, {
          data: {
            message: AlertUtil.getControlTranslation(data.error.detail),
            type: 'error'
          },
          duration: AppGlobal.defaultSnackBarDuration
        });
      }
    })
  );

  constructor(
    private actions$: Actions,
    private store: Store<ByKey>,
    private service: DictionaryAcquisitionService,
    private snackBar: MatSnackBar
  ) {
  }
}
