import { Pipe, PipeTransform } from '@angular/core';
import { TypeToComponent } from '@industi/ngx-common';

@Pipe({
  name: 'typeToComponentByElementType'
})
export class TypeToComponentByElementTypePipe implements PipeTransform {

  transform(list: TypeToComponent<any>[], type: string | number): any {
    return (list || []).find((item: TypeToComponent<any>) => item?.type === type);
  }

}
