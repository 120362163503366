import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { InstanceUtil } from './instance.util';
import { InstanceSetLocaleAction, InstanceSetLocaleActionTypes } from './actions/set-locale.actions';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class InstanceEffects {

  @Effect({ dispatch: false })
  setToken$ = this.actions$.pipe(
    ofType(InstanceSetLocaleActionTypes.setValue),
    map((action: InstanceSetLocaleAction) => action.payload.value),
    tap((locale: string) => {
      this.util.locale = locale;
    })
  );

  constructor(
    private actions$: Actions,
    private util: InstanceUtil
  ) {
  }
}
