import { createSelector } from '@ngrx/store';
import { denormalize } from 'normalizr';
import {
  ByKey,
  createListStateIdsSelector,
  createListStateParamsSelector,
  createStateErrorsSelector,
  createStateLoadingSelector,
  createStateSuccessSelector,
  IdType,
  ListState
} from '@industi/ngx-common';
import { EntityState } from '../../../entity/entity.reducer';
import { EntityTypes } from '../../../entity/entity-types';
import { createEntitiesSelector } from '../../../entity/entities.reducer';
import { createSymfonyTotalCountSelector } from '../../../symfony/list/selectors/list.selectors';
import { Entities } from '../../../entity/entities';
import { getArticleBrandListState } from '../brand.reducer';
import { ArticleBrand } from '../models/article-brand';

const createState = (id: IdType) => createSelector(
  getArticleBrandListState,
  (state: ByKey<ListState>) => state && state[id]
);

const createStateByContext = (id: IdType) => createListStateIdsSelector(createState(id));

export const createArticleBrandListData = (id: IdType) => createSelector(
  createStateByContext(id),
  createEntitiesSelector([
    EntityTypes.articleBrand
  ]),
  (
    ids: IdType[],
    entities: EntityState
  ) => denormalize(ids, [Entities[EntityTypes.articleBrand]], entities) as ArticleBrand[]
);

export const createArticleBrandListLoading = (id: IdType) => createStateLoadingSelector(
  createState(id));

export const createArticleBrandListSuccess = (id: IdType) => createStateSuccessSelector(
  createState(id));

export const createArticleBrandListErrors = (id: IdType) => createStateErrorsSelector(
  createState(id));

export const createArticleBrandListTotalCount = (id: IdType) => createSymfonyTotalCountSelector(
  createState(id));

export const createArticleBrandListParams = (id: IdType) => createListStateParamsSelector(
  createState(id));
