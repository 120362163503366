import { ByKey, listByIdReducer, ListState, operationByIdReducer, SingleState } from '@industi/ngx-common';
import { ActionReducer, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import { InjectionToken, Provider } from '@angular/core';
import { DictionaryProcessLoadListActionTypes } from './actions/list.actions';
import { DictionaryProcessAddActionTypes } from './actions/add.actions';
import { DictionaryProcessEditActionTypes } from './actions/edit.actions';
import { DictionaryProcessLoadDetailsActionTypes } from './actions/load-details.actions';
import { DictionaryProcessDeleteActionTypes } from './actions/delete.actions';

export const rootReducerKey = 'process';

export interface DictionaryProcessOperationsByIdState {
  add: SingleState;
  edit: SingleState;
  details: SingleState;
  delete: SingleState;
}

export interface DictionaryProcessState {
  operationsById: ByKey<DictionaryProcessOperationsByIdState>;
  list: ByKey<ListState>;
}

const initialState: DictionaryProcessState = {
  operationsById: {},
  list: {}
};

const reducer: ActionReducer<DictionaryProcessState> = combineReducers<DictionaryProcessState>({
  operationsById: operationByIdReducer<DictionaryProcessOperationsByIdState>({
    mapActionToId: (a) => a && a.payload && a.payload.id,
    operations: {
      add: DictionaryProcessAddActionTypes,
      edit: DictionaryProcessEditActionTypes,
      details: DictionaryProcessLoadDetailsActionTypes,
      delete: DictionaryProcessDeleteActionTypes
    }
  }),
  list: listByIdReducer({ types: DictionaryProcessLoadListActionTypes })
}, initialState);

export function getReducers(): ActionReducer<DictionaryProcessState> {
  return reducer;
}

export const DICTIONARY_PROCESS_REDUCER_TOKEN = new InjectionToken<ActionReducer<DictionaryProcessState>>(rootReducerKey);

export const dictionaryProcessReducerProvider: Provider = { provide: DICTIONARY_PROCESS_REDUCER_TOKEN, useFactory: getReducers };

const getState = createFeatureSelector<DictionaryProcessState>(rootReducerKey);

export const getDictionaryProcessOperationsById = createSelector(
  getState,
  (state: DictionaryProcessState) => state && state.operationsById
);

export const getDictionaryProcessListState = createSelector(
  getState,
  (state: DictionaryProcessState) => state && state.list
);
