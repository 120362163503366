import { ByKey, listByIdReducer, ListState, operationByIdReducer, SingleState } from '@industi/ngx-common';
import { ActionReducer, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import { InjectionToken, Provider } from '@angular/core';
import { DictionaryCarbonMaterialTypeEditActionTypes } from './actions/edit.actions';
import { DictionaryCarbonMaterialTypeLoadDetailsActionTypes } from './actions/load-details.actions';
import { DictionaryCarbonMaterialTypeAddActionTypes } from './actions/add.actions';
import { DictionaryCarbonMaterialTypeLoadListActionTypes } from './actions/list.actions';
import { DictionaryCarbonMaterialTypeDeleteActionTypes } from './actions/delete.actions';

export const rootReducerKey = 'carbonMaterialTypeType';

export interface DictionaryCarbonMaterialTypeOperationByIdState {
  add: SingleState;
  edit: SingleState;
  details: SingleState;
  delete: SingleState;
}

export interface DictionaryCarbonMaterialTypeState {
  operationsById: ByKey<DictionaryCarbonMaterialTypeOperationByIdState>;
  list: ByKey<ListState>;
}

const initialState: DictionaryCarbonMaterialTypeState = {
  operationsById: {},
  list: {}
};

const reducer: ActionReducer<DictionaryCarbonMaterialTypeState> = combineReducers<DictionaryCarbonMaterialTypeState>({
  operationsById: operationByIdReducer<DictionaryCarbonMaterialTypeOperationByIdState>({
    mapActionToId: (a) => a && a.payload && a.payload.id,
    operations: {
      add: DictionaryCarbonMaterialTypeAddActionTypes,
      edit: DictionaryCarbonMaterialTypeEditActionTypes,
      details: DictionaryCarbonMaterialTypeLoadDetailsActionTypes,
      delete: DictionaryCarbonMaterialTypeDeleteActionTypes,
    }
  }),
  list: listByIdReducer({ types: DictionaryCarbonMaterialTypeLoadListActionTypes })
}, initialState);

export function getReducers(): ActionReducer<DictionaryCarbonMaterialTypeState> {
  return reducer;
}

export const DICTIONARY_CARBON_MATERIAL_TYPE_REDUCER_TOKEN =
  new InjectionToken<ActionReducer<DictionaryCarbonMaterialTypeState>>(rootReducerKey);

export const dictionaryCarbonMaterialTypeReducerProvider: Provider = {
  provide: DICTIONARY_CARBON_MATERIAL_TYPE_REDUCER_TOKEN, useFactory: getReducers
};

const getState = createFeatureSelector<DictionaryCarbonMaterialTypeState>(rootReducerKey);

export const getDictionaryCarbonMaterialTypeOperationsById = createSelector(
  getState,
  (state: DictionaryCarbonMaterialTypeState) => state && state.operationsById
);

export const getDictionaryCarbonMaterialTypeListState = createSelector(
  getState,
  (state: DictionaryCarbonMaterialTypeState) => state && state.list
);
