import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { PART_REDUCER_TOKEN, partReducerProvider, rootReducerKey } from './part.reducer';
import { EffectsModule } from '@ngrx/effects';
import { PartEffects } from './part.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(rootReducerKey, PART_REDUCER_TOKEN),
    EffectsModule.forFeature([PartEffects])
  ],
  providers: [
    partReducerProvider
  ]
})
export class PartStoreModule { }
