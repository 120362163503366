import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';

export const DictionaryCarbonMaterialLoadDetailsActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[dictionary/carbon-material] Load details'),
  requestFail: uniqueActionType('[dictionary/carbon-material] Load details fail'),
  requestSuccess: uniqueActionType('[dictionary/carbon-material] Load details success'),
  requestClearErrors: uniqueActionType('[dictionary/carbon-material] Load details clear errors'),
  requestClear: uniqueActionType('[dictionary/carbon-material] Load details clear')
};

export class DictionaryCarbonMaterialLoadDetailsAction implements PayloadAction {
  readonly type = DictionaryCarbonMaterialLoadDetailsActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class DictionaryCarbonMaterialLoadDetailsFailAction implements PayloadAction {
  readonly type = DictionaryCarbonMaterialLoadDetailsActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class DictionaryCarbonMaterialLoadDetailsSuccessAction implements PayloadAction {
  readonly type = DictionaryCarbonMaterialLoadDetailsActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class DictionaryCarbonMaterialLoadDetailsClearAction implements PayloadAction {
  readonly type = DictionaryCarbonMaterialLoadDetailsActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}

export class DictionaryCarbonMaterialLoadDetailsClearErrorsAction implements PayloadAction {
  readonly type = DictionaryCarbonMaterialLoadDetailsActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}
