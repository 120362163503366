import {
  ByIdPayload,
  ChangeParamsByIdPayload,
  FailByIdPayload,
  ListReducerTypes,
  NormalizedList,
  PayloadAction,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { SymfonyPaginationParams } from '../../../symfony/list/models/symfony-pagination-params';

export const CarbonPartCommissionLoadListActionTypes: ListReducerTypes = {
  request: uniqueActionType('[carbon/part-commission] Load list'),
  requestFail: uniqueActionType('[carbon/part-commission] Load list fail'),
  requestSuccess: uniqueActionType('[carbon/part-commission] Load list success'),
  requestClear: uniqueActionType('[carbon/part-commission] Load list clear'),
  changeParams: uniqueActionType('[carbon/part-commission] Load list change params'),
  clearParams: uniqueActionType('[carbon/part-commission] Load list clear params'),
  clearIds: uniqueActionType('[carbon/part-commission] Load list clear ids')
};

export class CarbonPartCommissionLoadListAction implements PayloadAction {
  readonly type = CarbonPartCommissionLoadListActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class CarbonPartCommissionLoadListFailAction implements PayloadAction {
  readonly type = CarbonPartCommissionLoadListActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class CarbonPartCommissionLoadListSuccessAction implements PayloadAction {
  readonly type = CarbonPartCommissionLoadListActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedList>) {
  }
}

export class CarbonPartCommissionLoadListClearAction implements PayloadAction {
  readonly type = CarbonPartCommissionLoadListActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}

export class CarbonPartCommissionLoadListChangeParamsAction implements PayloadAction {
  readonly type = CarbonPartCommissionLoadListActionTypes.changeParams;

  constructor(public payload: ChangeParamsByIdPayload<SymfonyPaginationParams>) {
  }
}

export class CarbonPartCommissionLoadListClearParamsAction implements PayloadAction {
  readonly type = CarbonPartCommissionLoadListActionTypes.clearParams;

  constructor(public payload: ByIdPayload) {
  }
}


export class CarbonPartCommissionLoadListClearIdsAction implements PayloadAction {
  readonly type = CarbonPartCommissionLoadListActionTypes.clearIds;

  constructor(public payload: ByIdPayload) {
  }
}
