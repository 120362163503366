import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, first, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { ByIdPayload, ByKey, IdType, NormalizedList, NormalizedSingle } from '@industi/ngx-common';
import { select, Store } from '@ngrx/store';
import { getInstanceLocale } from '../../instance/instance.reducer';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import {
  DiagramPartLoadListAction,
  DiagramPartLoadListActionTypes,
  DiagramPartLoadListClearIdsAction,
  DiagramPartLoadListFailAction,
  DiagramPartLoadListSuccessAction
} from './actions/list.actions';
import {
  DiagramPartAddAction,
  DiagramPartAddActionTypes,
  DiagramPartAddFailAction,
  DiagramPartAddSuccessAction
} from './actions/add.actions';
import {
  DiagramPartEditAction,
  DiagramPartEditActionTypes,
  DiagramPartEditFailAction,
  DiagramPartEditSuccessAction
} from './actions/edit.actions';
import {
  DiagramPartLoadDetailsAction,
  DiagramPartLoadDetailsActionTypes,
  DiagramPartLoadDetailsFailAction,
  DiagramPartLoadDetailsSuccessAction
} from './actions/load-details.actions';
import { DiagramPartListParams } from './models/part-list-params';
import { DiagramPartService } from './part.service';
import { createDiagramPartListParams } from './selectors/get-load-list.selectors';
import {
  DiagramPartDeleteAction,
  DiagramPartDeleteActionTypes,
  DiagramPartDeleteFailAction,
  DiagramPartDeleteSuccessAction
} from './actions/delete.actions';
import { ResponseStatus } from '../../../shared/enums/response-status.enum';
import { LibSnackbarComponent } from '@industi/ngx-modules';
import { AppGlobal } from '../../../shared/app-global';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlertUtil } from '../../../shared/utils/alert.util';
import {
  DiagramPartDetailsRemovePartAction,
  DiagramPartDetailsRemovePartActionTypes,
  DiagramPartDetailsRemovePartFailAction,
  DiagramPartDetailsRemovePartSuccessAction
} from './actions/remove-part.actions';

@Injectable()
export class DiagramPartEffects {

  @Effect()
  loadList$ = this.actions$.pipe(
    ofType(DiagramPartLoadListActionTypes.request),
    map((action: DiagramPartLoadListAction) => action.payload),
    switchMap((payload: ByIdPayload) => this.store.pipe(
      select(createDiagramPartListParams(payload.id)),
      map((params: DiagramPartListParams) => [payload.id, params] as [IdType, DiagramPartListParams]),
      first()
    )),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([[ id, params ], locale]) => this.service.loadList(locale, params).pipe(
      mergeMap((res: NormalizedList) => [
        new DiagramPartLoadListClearIdsAction({ id }),
        new DiagramPartLoadListSuccessAction({ id, res })]),
      catchError((errors: HttpErrorResponse) => of(new DiagramPartLoadListFailAction({ id, errors })))
    ))
  );

  @Effect()
  add$ = this.actions$.pipe(
    ofType(DiagramPartAddActionTypes.request),
    map((action: DiagramPartAddAction) => action.payload),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([{ id, data }, locale]) => this.service.add(locale, data).pipe(
      map((res: NormalizedSingle) => new DiagramPartAddSuccessAction({ res, id })),
      catchError((errors: HttpErrorResponse) => of(new DiagramPartAddFailAction({ errors, id })))
    ))
  );

  @Effect()
  edit$ = this.actions$.pipe(
    ofType(DiagramPartEditActionTypes.request),
    map((action: DiagramPartEditAction) => action.payload),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([{ id, data }, locale]) => this.service.edit(locale, data, id).pipe(
      map((res: NormalizedSingle) => new DiagramPartEditSuccessAction({ res, id })),
      catchError((errors: HttpErrorResponse) => of(new DiagramPartEditFailAction({ errors, id })))
    ))
  );

  @Effect()
  removePart$ = this.actions$.pipe(
    ofType(DiagramPartDetailsRemovePartActionTypes.request),
    map((action: DiagramPartDetailsRemovePartAction) => action.payload),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([{ id, data }, locale]) => this.service.partRemove(locale, data, id).pipe(
      map((res: NormalizedSingle) => new DiagramPartDetailsRemovePartSuccessAction({ res, id })),
      catchError((errors: HttpErrorResponse) => of(new DiagramPartDetailsRemovePartFailAction({ errors, id })))
    ))
  );

  @Effect()
  loadDetails$ = this.actions$.pipe(
    ofType(DiagramPartLoadDetailsActionTypes.request),
    map((action: DiagramPartLoadDetailsAction) => action.payload.id),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([id, locale]) => this.service.details(locale, id).pipe(
      map((res: NormalizedSingle) => new DiagramPartLoadDetailsSuccessAction({ id, res })),
      catchError((errors: HttpErrorResponse) => of(new DiagramPartLoadDetailsFailAction({ id, errors })))
    ))
  );

  @Effect()
  delete$ = this.actions$.pipe(
    ofType(DiagramPartDeleteActionTypes.request),
    map((action: DiagramPartDeleteAction) => action.payload.id),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([id, locale]) => this.service.delete(locale, id).pipe(
      map(() => new DiagramPartDeleteSuccessAction({ id })),
      catchError((errors: HttpErrorResponse) => of(new DiagramPartDeleteFailAction({ id, errors })))
    ))
  );

  @Effect({ dispatch: false })
  crudFail$ = this.actions$.pipe(
    ofType(
      DiagramPartAddActionTypes.requestFail,
      DiagramPartEditActionTypes.requestFail,
      DiagramPartDetailsRemovePartActionTypes.requestFail,
    ),
    map((
      action: DiagramPartAddFailAction | DiagramPartEditFailAction | DiagramPartDetailsRemovePartFailAction
    ) => action.payload.errors),
    tap((data: HttpErrorResponse) => {
      if (data?.status === ResponseStatus.BAD_REQUEST || data?.status === ResponseStatus.UNPROCESSABLE_ENTITY) {
        this.snackBar.openFromComponent(LibSnackbarComponent, {
          data: {
            message: AlertUtil.getControlTranslation(data.error.detail),
            type: 'error'
          },
          duration: AppGlobal.defaultSnackBarDuration
        });
      }
    })
  );

  constructor(
    private actions$: Actions,
    private store: Store<ByKey>,
    private service: DiagramPartService,
    private snackBar: MatSnackBar
  ) {
  }
}
