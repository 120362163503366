import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SendByIdPayload,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { DictionaryMaterialExploitation } from '../models/material-exploitation';

export const DictionaryMaterialExploitationEditActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[dictionary/material-exploitation] Edit'),
  requestFail: uniqueActionType('[dictionary/material-exploitation] Edit fail'),
  requestSuccess: uniqueActionType('[dictionary/material-exploitation] Edit success'),
  requestClearErrors: uniqueActionType('[dictionary/material-exploitation] Edit clear errors'),
  requestClear: uniqueActionType('[dictionary/material-exploitation] Edit clear')
};

export class DictionaryMaterialExploitationEditAction implements PayloadAction {
  readonly type = DictionaryMaterialExploitationEditActionTypes.request;

  constructor(public payload: SendByIdPayload<DictionaryMaterialExploitation>) {
  }
}

export class DictionaryMaterialExploitationEditFailAction implements PayloadAction {
  readonly type = DictionaryMaterialExploitationEditActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class DictionaryMaterialExploitationEditSuccessAction implements PayloadAction {
  readonly type = DictionaryMaterialExploitationEditActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class DictionaryMaterialExploitationEditClearErrorsAction implements PayloadAction {
  readonly type = DictionaryMaterialExploitationEditActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class DictionaryMaterialExploitationEditClearAction implements PayloadAction {
  readonly type = DictionaryMaterialExploitationEditActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
