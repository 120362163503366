import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DictionaryBrandStoreModule } from './brand/brand-store.module';
import { DictionaryUnitStateStoreModule } from './unit-state/unit-state-store.module';
import { DictionaryCarbonMaterialStoreModule } from './carbon-material/carbon-material-store.module';
import { DictionaryCarbonMaterialTypeStoreModule } from './carbon-material-type/carbon-material-type-store.module';
import { DictionaryMaterialExploitationStoreModule } from './material-exploitation/material-exploitation-store.module';
import { DictionaryOperationStoreModule } from './operation/operation-store.module';
import { DictionaryTagStoreModule } from './tag/tag-store.module';
import { DictionaryProcessStoreModule } from './process/process-store.module';
import { DictionaryAcquisitionStoreModule } from './acquisition/acquisition-store.module';
import { DictionaryCourierStoreModule } from './courier/courier-store.module';

@NgModule({
  imports: [
    CommonModule,
    DictionaryBrandStoreModule,
    DictionaryUnitStateStoreModule,
    DictionaryCarbonMaterialStoreModule,
    DictionaryCarbonMaterialTypeStoreModule,
    DictionaryMaterialExploitationStoreModule,
    DictionaryOperationStoreModule,
    DictionaryTagStoreModule,
    DictionaryProcessStoreModule,
    DictionaryAcquisitionStoreModule,
    DictionaryCourierStoreModule
  ]
})
export class DictionaryStoreModule { }
