import { ByKey, listByIdReducer, ListState, operationByIdReducer, SingleState } from '@industi/ngx-common';
import { ActionReducer, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import { InjectionToken, Provider } from '@angular/core';
import { WarehouseAddActionTypes } from './actions/add.actions';
import { WarehouseLoadDetailsActionTypes } from './actions/load-details.actions';
import { WarehouseLoadListActionTypes } from './actions/list.actions';
import { WarehouseEditActionTypes } from './actions/edit.actions';
import { WarehouseDeleteActionTypes } from './actions/delete.actions';
import { WarehouseLoadPdfActionTypes } from './actions/load-pdf.actions';

export const rootReducerKey = 'warehouse';

export interface WarehouseOperationByIdState {
  add: SingleState;
  edit: SingleState;
  details: SingleState;
  delete: SingleState;
  pdf: SingleState;
}

export interface WarehouseState {
  operationsById: ByKey<WarehouseOperationByIdState>;
  list: ByKey<ListState>;
}

const initialState: WarehouseState = {
  operationsById: {},
  list: {}
};

const reducer: ActionReducer<WarehouseState> = combineReducers<WarehouseState>({
  operationsById: operationByIdReducer<WarehouseOperationByIdState>({
    mapActionToId: (a) => a && a.payload && a.payload.id,
    operations: {
      add: WarehouseAddActionTypes,
      edit: WarehouseEditActionTypes,
      details: WarehouseLoadDetailsActionTypes,
      delete: WarehouseDeleteActionTypes,
      pdf: WarehouseLoadPdfActionTypes
    }
  }),
  list: listByIdReducer({ types: WarehouseLoadListActionTypes })
}, initialState);

export function getReducers(): ActionReducer<WarehouseState> {
  return reducer;
}

export const WAREHOUSE_REDUCER_TOKEN = new InjectionToken<ActionReducer<WarehouseState>>(rootReducerKey);

export const warehouseReducerProvider: Provider = { provide: WAREHOUSE_REDUCER_TOKEN, useFactory: getReducers };

const getState = createFeatureSelector<WarehouseState>(rootReducerKey);

export const getWarehouseOperationsById = createSelector(
  getState,
  (state: WarehouseState) => state && state.operationsById
);

export const getWarehouseListState = createSelector(
  getState,
  (state: WarehouseState) => state && state.list
);
