import { createSelector } from '@ngrx/store';
import { denormalize } from 'normalizr';
import {
  ByKey,
  createListStateIdsSelector,
  createListStateParamsSelector,
  createStateErrorsSelector,
  createStateLoadingSelector,
  createStateSuccessSelector,
  IdType,
  ListState
} from '@industi/ngx-common';
import { createEntitiesSelector } from '../../../../entity/entities.reducer';
import { EntityTypes } from '../../../../entity/entity-types';
import { EntityState } from '../../../../entity/entity.reducer';
import { Entities } from '../../../../entity/entities';
import { createSymfonyTotalCountSelector } from '../../../../symfony/list/selectors/list.selectors';
import { getServiceProjectItemListState } from '../item.reducer';
import { ServiceProjectItem } from '../models/item';

const createState = (id: IdType) => createSelector(
  getServiceProjectItemListState,
  (state: ByKey<ListState>) => state && state[id]
);

const createStateById = (id: IdType) => createListStateIdsSelector(createState(id));

export const createServiceProjectItemListData = (id: IdType) => createSelector(
  createStateById(id),
  createEntitiesSelector([
    EntityTypes.projectItem,
    EntityTypes.item,
    EntityTypes.image
  ]),
  (
    ids: IdType[],
    entities: EntityState
  ) => denormalize(ids, [Entities[EntityTypes.projectItem]], entities) as ServiceProjectItem[]
);

export const createServiceProjectItemListLoading = (id: IdType) => createStateLoadingSelector(
  createState(id));

export const createServiceProjectItemListSuccess = (id: IdType) => createStateSuccessSelector(
  createState(id));

export const createServiceProjectItemListErrors = (id: IdType) => createStateErrorsSelector(
  createState(id));

export const createServiceProjectItemListTotalCount = (id: IdType) => createSymfonyTotalCountSelector(
  createState(id));

export const createServiceProjectItemListParams = (id: IdType) => createListStateParamsSelector(
  createState(id));

export const createServiceProjectItemProjectPartIds = (id: IdType) => createSelector(
  createServiceProjectItemListData(id),
  (
    list: ServiceProjectItem[]
  ) => list.reduce((acc, curr, i) => {
    if (curr?.projectPart) {
      acc.push(curr?.projectPart?.id);
    }
    return acc;
  }, [])
);
