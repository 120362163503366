import {
  ByIdPayload,
  FailByIdPayload,
  OperationReducerTypes,
  PayloadAction,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';

export const ItemLoadPdfActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[item] Load pdf'),
  requestFail: uniqueActionType('[item] Load pdf fail'),
  requestSuccess: uniqueActionType('[item] Load pdf success'),
  requestClear: uniqueActionType('[item] Load pdf clear')
};

export class ItemLoadPdfAction implements PayloadAction {
  readonly type = ItemLoadPdfActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class ItemLoadPdfFailAction implements PayloadAction {
  readonly type = ItemLoadPdfActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class ItemLoadPdfSuccessAction implements PayloadAction {
  readonly type = ItemLoadPdfActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<any>) {
  }
}

export class ItemLoadPdfClearAction implements PayloadAction {
  readonly type = ItemLoadPdfActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
