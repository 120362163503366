import { IdType, SetValuePayload, SetValueReducerTypes, uniqueActionType } from '@industi/ngx-common';

export const DiagramSelectLastAddedIdActionTypes: SetValueReducerTypes = {
  setValue: uniqueActionType('[diagram] Select last added id'),
  clearValue: uniqueActionType('[diagram] Clear last added id'),
};

export class DiagramSelectLastAddedIdAction {
  readonly type = DiagramSelectLastAddedIdActionTypes.setValue;

  constructor(public payload: SetValuePayload<IdType>) {
  }
}

export class DiagramClearLastAddedIdAction {
  readonly type = DiagramSelectLastAddedIdActionTypes.setValue;
  readonly payload = null;
}
