import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppStoreModule } from './store/app-store.module';
import { RouterModule } from '@angular/router';
import { appRoutes } from './app.routes';
import { AppLayoutModule } from './app-layout/app-layout.module';
import { appModuleProviders } from './app.providers';
import { SharedModule } from './shared/shared.module';
import { LibMaterialSharedModule } from '@industi/ngx-modules';
import { registerLocaleData } from '@angular/common';
import localePl from '@angular/common/locales/pl';

registerLocaleData(localePl);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppStoreModule,
    AppLayoutModule,
    RouterModule.forRoot(appRoutes),
    LibMaterialSharedModule.forRoot(),
    SharedModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    ...appModuleProviders
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
