import { Inject, Injectable } from '@angular/core';
import { API_URL } from '../app-store-providers';
import { HttpClient } from '@angular/common/http';
import { EntityTypes } from '../entity/entity-types';
import { UserListParams } from './models/user-list-params';
import { User } from './models/user';
import { UserPayload } from './models/user-payload';
import { StoreBaseService } from '../common/base.service';

@Injectable({
  providedIn: 'root'
})
export class UserService
  extends StoreBaseService<User, UserPayload, UserListParams> {

  get entityType(): string {
    return EntityTypes.user;
  }

  get resourceName(): string {
    return 'user';
  }

  constructor(
    @Inject(API_URL) apiUrl: string,
    http: HttpClient
  ) {
    super(apiUrl, http);
  }
}
