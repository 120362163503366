import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SendByIdPayload,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { Dictionary } from '../../../../shared/models/dictionary';

export const DictionaryTagEditActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[dictionary/tag] Edit'),
  requestFail: uniqueActionType('[dictionary/tag] Edit fail'),
  requestSuccess: uniqueActionType('[dictionary/tag] Edit success'),
  requestClearErrors: uniqueActionType('[dictionary/tag] Edit clear errors'),
  requestClear: uniqueActionType('[dictionary/tag] Edit clear')
};

export class DictionaryTagEditAction implements PayloadAction {
  readonly type = DictionaryTagEditActionTypes.request;

  constructor(public payload: SendByIdPayload<Dictionary>) {
  }
}

export class DictionaryTagEditFailAction implements PayloadAction {
  readonly type = DictionaryTagEditActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class DictionaryTagEditSuccessAction implements PayloadAction {
  readonly type = DictionaryTagEditActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class DictionaryTagEditClearErrorsAction implements PayloadAction {
  readonly type = DictionaryTagEditActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class DictionaryTagEditClearAction implements PayloadAction {
  readonly type = DictionaryTagEditActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
