import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';

export const WarehouseLoadDetailsActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[warehouse] Load details'),
  requestFail: uniqueActionType('[warehouse] Load details fail'),
  requestSuccess: uniqueActionType('[warehouse] Load details success'),
  requestClearErrors: uniqueActionType('[warehouse] Load details clear errors'),
  requestClear: uniqueActionType('[warehouse] Load details clear')
};

export class WarehouseLoadDetailsAction implements PayloadAction {
  readonly type = WarehouseLoadDetailsActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class WarehouseLoadDetailsFailAction implements PayloadAction {
  readonly type = WarehouseLoadDetailsActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class WarehouseLoadDetailsSuccessAction implements PayloadAction {
  readonly type = WarehouseLoadDetailsActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class WarehouseLoadDetailsClearAction implements PayloadAction {
  readonly type = WarehouseLoadDetailsActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}

export class WarehouseLoadDetailsClearErrorsAction implements PayloadAction {
  readonly type = WarehouseLoadDetailsActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}
