import {
  ByIdPayload,
  FailByIdPayload,
  OperationReducerTypes,
  PayloadAction,
  uniqueActionType
} from '@industi/ngx-common';

export const DiagramPartDeleteActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[diagram/part] Delete'),
  requestFail: uniqueActionType('[diagram/part] Delete fail'),
  requestSuccess: uniqueActionType('[diagram/part] Delete success'),
  requestClearErrors: uniqueActionType('[diagram/part] Delete clear errors'),
  requestClear: uniqueActionType('[diagram/part] Delete clear')
};

export class DiagramPartDeleteAction implements PayloadAction {
  readonly type = DiagramPartDeleteActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class DiagramPartDeleteFailAction implements PayloadAction {
  readonly type = DiagramPartDeleteActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class DiagramPartDeleteSuccessAction implements PayloadAction {
  readonly type = DiagramPartDeleteActionTypes.requestSuccess;

  constructor(public payload: ByIdPayload) {
  }
}

export class DiagramPartDeleteClearErrorsAction implements PayloadAction {
  readonly type = DiagramPartDeleteActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class DiagramPartDeleteClearAction implements PayloadAction {
  readonly type = DiagramPartDeleteActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
