import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { ByKey, NormalizedSingle } from '@industi/ngx-common';
import { catchError, first, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { getInstanceLocale } from '../../instance/instance.reducer';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import { EntityTypes } from '../../entity/entity-types';
import {
  ArticleImageContentAddDoneListActionTypes,
  ArticleImageContentAddDoneListSuccessAction
} from './actions/add-done-list.actions';
import {
  ArticleImageContentAddAction,
  ArticleImageContentAddActionTypes,
  ArticleImageContentAddSuccessAction
} from './actions/add.actions';
import {
  ArticleImageContentLoadDetailsAction,
  ArticleImageContentLoadDetailsActionTypes,
  ArticleImageContentLoadDetailsFailAction,
  ArticleImageContentLoadDetailsSuccessAction
} from './actions/load-details.actions';
import {
  ArticleImageContentAddPendingListActionTypes,
  ArticleImageContentAddPendingListRemoveIdAction,
  ArticleImageContentAddPendingListSuccessAction
} from './actions/add-pending-list.actions';
import { denormalize } from 'normalizr';
import {
  ArticleImageContentDeleteAction,
  ArticleImageContentDeleteActionTypes,
  ArticleImageContentDeleteFailAction,
  ArticleImageContentDeleteSuccessAction
} from './actions/delete.actions';
import { Entities } from '../../entity/entities';
import { ArticleImageContent } from './models/image-content';
import { createArticleImageContentAddPendingList } from './selectors/get-add-pending-list.selectors';
import { ArticleImageContentService } from './image-content.service';

@Injectable()
export class ArticleImageContentEffects {

  @Effect({ dispatch: false })
  preparePhotoToSend$ = this.actions$.pipe(
    ofType(ArticleImageContentAddPendingListActionTypes.requestSuccess),
    map((action: ArticleImageContentAddPendingListSuccessAction) => action.payload),
    tap(({ id, res }) => {
      const denormalized: ArticleImageContent[] = denormalize(res.result, [Entities[EntityTypes.articleImageContent]], res.entities);

      if (denormalized && denormalized.length) {
        this.store.dispatch(new ArticleImageContentAddAction({ id, data: denormalized[0] }));
        this.store.dispatch(new ArticleImageContentAddPendingListRemoveIdAction({ id, removeId: denormalized[0].id }));
      } else {
        this.store.dispatch(new ArticleImageContentAddSuccessAction({ id }));
      }
    })
  );

  @Effect()
  addPhoto$ = this.actions$.pipe(
    ofType(ArticleImageContentAddActionTypes.request),
    map((action: ArticleImageContentAddAction) => action.payload),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([{ id, data }, locale]) => this.service.add(locale, data).pipe(
      map((res: NormalizedSingle) => new ArticleImageContentAddDoneListSuccessAction({
        id,
        res: { result: [res.result], entities: res.entities }
      })),
      catchError(() => of(new ArticleImageContentAddDoneListSuccessAction({ id, res: { result: [data.id] } })))
    ))
  );


  @Effect({ dispatch: false })
  donePhotoListSuccess$ = this.actions$.pipe(
    ofType(ArticleImageContentAddDoneListActionTypes.requestSuccess),
    map((action: ArticleImageContentAddDoneListSuccessAction) => action.payload),
    switchMap(({ id }) => this.store.pipe(
      select(createArticleImageContentAddPendingList(id)),
      map((data: ArticleImageContent[]) => ({ id, data })),
      first()
    )),
    tap(({ id, data }) => {
      if (data && data.length) {
        this.store.dispatch(new ArticleImageContentAddAction({ id, data: data[0] }));
        this.store.dispatch(new ArticleImageContentAddPendingListRemoveIdAction({ id, removeId: data[0].id }));
      } else {
        this.store.dispatch(new ArticleImageContentAddSuccessAction({ id }));
      }
    })
  );

  @Effect()
  loadDetails$ = this.actions$.pipe(
    ofType(ArticleImageContentLoadDetailsActionTypes.request),
    map((action: ArticleImageContentLoadDetailsAction) => action.payload.id),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([id, locale]) => this.service.details(locale, id).pipe(
      map((res: NormalizedSingle) => new ArticleImageContentLoadDetailsSuccessAction({ id, res })),
      catchError((errors: HttpErrorResponse) => of(new ArticleImageContentLoadDetailsFailAction({ id, errors })))
    ))
  );

  @Effect()
  delete$ = this.actions$.pipe(
    ofType(ArticleImageContentDeleteActionTypes.request),
    map((action: ArticleImageContentDeleteAction) => action.payload.id),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([id, locale]) => this.service.delete(locale, id).pipe(
      map(() => new ArticleImageContentDeleteSuccessAction({ id })),
      catchError((errors: HttpErrorResponse) => of(new ArticleImageContentDeleteFailAction({ id, errors })))
    ))
  );

  constructor(
    private actions$: Actions,
    private store: Store<ByKey>,
    private service: ArticleImageContentService
  ) {
  }
}
