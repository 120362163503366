import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Price } from '../../../store/common/models/price';
import { LibTableDirective } from '@industi/ngx-modules';
import { PriceTableColumnsType } from './price-data-table-columns.type';
import { PriceTableColumnsMap } from '../../../store/common/maps/price-data-table-columns.map';

@Component({
  selector: 'app-price-data-table',
  templateUrl: './price-data-table.component.html',
  styleUrls: ['./price-data-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PriceDataTableComponent
  extends LibTableDirective<Price> {

  get columnNames(): { [key in PriceTableColumnsType]: string } {
    return PriceTableColumnsMap;
  }

}
