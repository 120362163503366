import {
  ByIdPayload,
  FailByIdPayload,
  OperationReducerTypes,
  PayloadAction,
  uniqueActionType
} from '@industi/ngx-common';

export const UserDeleteActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[user] Delete'),
  requestFail: uniqueActionType('[user] Delete fail'),
  requestSuccess: uniqueActionType('[user] Delete success'),
  requestClearErrors: uniqueActionType('[user] Delete clear errors'),
  requestClear: uniqueActionType('[user] Delete clear')
};

export class UserDeleteAction implements PayloadAction {
  readonly type = UserDeleteActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class UserDeleteFailAction implements PayloadAction {
  readonly type = UserDeleteActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class UserDeleteSuccessAction implements PayloadAction {
  readonly type = UserDeleteActionTypes.requestSuccess;

  constructor(public payload: ByIdPayload) {
  }
}

export class UserDeleteClearErrorsAction implements PayloadAction {
  readonly type = UserDeleteActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class UserDeleteClearAction implements PayloadAction {
  readonly type = UserDeleteActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
