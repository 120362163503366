import {
  ByIdPayload,
  FailByIdPayload,
  OperationReducerTypes,
  PayloadAction,
  uniqueActionType
} from '@industi/ngx-common';

export const DictionaryCarbonMaterialTypeDeleteActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[dictionary/carbon-material-type] Delete'),
  requestFail: uniqueActionType('[dictionary/carbon-material-type] Delete fail'),
  requestSuccess: uniqueActionType('[dictionary/carbon-material-type] Delete success'),
  requestClearErrors: uniqueActionType('[dictionary/carbon-material-type] Delete clear errors'),
  requestClear: uniqueActionType('[dictionary/carbon-material-type] Delete clear')
};

export class DictionaryCarbonMaterialTypeDeleteAction implements PayloadAction {
  readonly type = DictionaryCarbonMaterialTypeDeleteActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class DictionaryCarbonMaterialTypeDeleteFailAction implements PayloadAction {
  readonly type = DictionaryCarbonMaterialTypeDeleteActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class DictionaryCarbonMaterialTypeDeleteSuccessAction implements PayloadAction {
  readonly type = DictionaryCarbonMaterialTypeDeleteActionTypes.requestSuccess;

  constructor(public payload: ByIdPayload) {
  }
}

export class DictionaryCarbonMaterialTypeDeleteClearErrorsAction implements PayloadAction {
  readonly type = DictionaryCarbonMaterialTypeDeleteActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class DictionaryCarbonMaterialTypeDeleteClearAction implements PayloadAction {
  readonly type = DictionaryCarbonMaterialTypeDeleteActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
