import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SendByIdPayload,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { Dictionary } from '../../../../shared/models/dictionary';

export const DictionaryUnitStateEditActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[dictionary/unit-state] Edit'),
  requestFail: uniqueActionType('[dictionary/unit-state] Edit fail'),
  requestSuccess: uniqueActionType('[dictionary/unit-state] Edit success'),
  requestClearErrors: uniqueActionType('[dictionary/unit-state] Edit clear errors'),
  requestClear: uniqueActionType('[dictionary/unit-state] Edit clear')
};

export class DictionaryUnitStateEditAction implements PayloadAction {
  readonly type = DictionaryUnitStateEditActionTypes.request;

  constructor(public payload: SendByIdPayload<Dictionary>) {
  }
}

export class DictionaryUnitStateEditFailAction implements PayloadAction {
  readonly type = DictionaryUnitStateEditActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class DictionaryUnitStateEditSuccessAction implements PayloadAction {
  readonly type = DictionaryUnitStateEditActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class DictionaryUnitStateEditClearErrorsAction implements PayloadAction {
  readonly type = DictionaryUnitStateEditActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class DictionaryUnitStateEditClearAction implements PayloadAction {
  readonly type = DictionaryUnitStateEditActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
