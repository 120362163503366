<div class="app-menu">
  <div [ngClass]="containerClass" *ngIf="menu && menu.length > 0">
    <ng-container *ngFor="let item of menu; trackBy: trackByPath">
      <ng-container *ngTemplateOutlet="item.children && item.children.length > 0
    ? child
    : link; context: { $implicit: item }">
      </ng-container>
    </ng-container>
  </div>
</div>

<ng-template #child let-item>
  <mat-expansion-panel class="mat-elevation-z"
                       [expanded]="doesPathMatch(item?.path)">
    <mat-expansion-panel-header [routerLinkActive]="[activeItemClass]"
                                class="app-menu-item"
                                [ngClass]="itemClass"
                                collapsedHeight="auto" expandedHeight="auto">
      <ng-container *ngTemplateOutlet="iconContainer; context: { $implicit: item?.icon }"></ng-container>
      <div class="app-menu-item-label">{{ item.label }}</div>
      <a class="d-none" [routerLink]="item?.path"></a>
    </mat-expansion-panel-header>
    <app-menu [menu]="item?.children" [menuLevel]="menuLevel + 1"></app-menu>
  </mat-expansion-panel>
</ng-template>

<ng-template #link let-item>
  <a [routerLinkActive]="[activeItemClass]" [routerLink]="item.path"
     [class]="'d-flex align-items-center app-menu-item ' + item?.itemClass"
     [ngClass]="itemClass">
    <ng-container *ngTemplateOutlet="iconContainer; context: { $implicit: item?.icon }"></ng-container>
    <div class="app-menu-item-label">{{ item.label }}</div>
  </a>
</ng-template>

<ng-template #iconContainer let-default>
  <ng-container *ngIf="default;">
    <lib-icon [icon]="default" [iconClass]="iconClass"></lib-icon>
  </ng-container>
</ng-template>

<ng-template #iconSpacer>
  <div class="app-menu-icon-spacer"><span>-</span></div>
</ng-template>
