import { ByKey, listByIdReducer, ListState, operationByIdReducer, SingleState } from '@industi/ngx-common';
import { ActionReducer, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import { InjectionToken, Provider } from '@angular/core';
import { PartLoadListActionTypes } from './actions/list.actions';
import { PartLoadDetailsActionTypes } from './actions/load-details.actions';
import { PartAddActionTypes } from './actions/add.actions';
import { PartDeleteActionTypes } from './actions/delete.actions';
import { PartEditActionTypes } from './actions/edit.actions';
import { PartImportActionTypes } from './actions/import.actions';

export const rootReducerKey = 'part';

export interface PartOperationByIdState {
  add: SingleState;
  edit: SingleState;
  delete: SingleState;
  details: SingleState;
  import: SingleState;
}

export interface PartState {
  operationsById: ByKey<PartOperationByIdState>;
  list: ByKey<ListState>;
}

const initialState: PartState = {
  operationsById: {},
  list: {}
};

const reducer: ActionReducer<PartState> = combineReducers<PartState>({
  operationsById: operationByIdReducer<PartOperationByIdState>({
    mapActionToId: (a) => a && a.payload && a.payload.id,
    operations: {
      add: PartAddActionTypes,
      edit: PartEditActionTypes,
      delete: PartDeleteActionTypes,
      details: PartLoadDetailsActionTypes,
      import: PartImportActionTypes
    }
  }),
  list: listByIdReducer({ types: PartLoadListActionTypes })
}, initialState);

export function getReducers(): ActionReducer<PartState> {
  return reducer;
}

export const PART_REDUCER_TOKEN = new InjectionToken<ActionReducer<PartState>>(rootReducerKey);

export const partReducerProvider: Provider = { provide: PART_REDUCER_TOKEN, useFactory: getReducers };

const getState = createFeatureSelector<PartState>(rootReducerKey);

export const getPartOperationsById = createSelector(
  getState,
  (state: PartState) => state && state.operationsById
);

export const getPartListState = createSelector(
  getState,
  (state: PartState) => state && state.list
);
