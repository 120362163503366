import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppMenuComponent } from './app-menu.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { RouterModule } from '@angular/router';
import { appMenuProvider } from './app-menu.provider';
import { LibIconModule } from '@industi/ngx-modules';

@NgModule({
  declarations: [
    AppMenuComponent
  ],
  imports: [
    CommonModule,
    MatExpansionModule,
    RouterModule,
    LibIconModule
  ],
  exports: [
    AppMenuComponent
  ],
  providers: [
    appMenuProvider
  ]
})
export class AppMenuModule {
}
