import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EBAY_REDUCER_TOKEN, ebayReducerProvider, rootReducerKey } from './ebay.reducer';
import { EffectsModule } from '@ngrx/effects';
import { EbayEffects } from './ebay.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(rootReducerKey, EBAY_REDUCER_TOKEN),
    EffectsModule.forFeature([
      EbayEffects
    ])
  ],
  providers: [
    ebayReducerProvider
  ]
})
export class EbayStoreModule { }
