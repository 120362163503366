import { ByKey, listByIdReducer, ListState, operationByIdReducer, SingleState } from '@industi/ngx-common';
import { ActionReducer, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import { InjectionToken, Provider } from '@angular/core';
import { DictionaryCarbonMaterialLoadDetailsActionTypes } from './actions/load-details.actions';
import { DictionaryCarbonMaterialEditActionTypes } from './actions/edit.actions';
import { DictionaryCarbonMaterialAddActionTypes } from './actions/add.actions';
import { DictionaryCarbonMaterialLoadListActionTypes } from './actions/list.actions';
import { DictionaryCarbonMaterialDeleteActionTypes } from './actions/delete.actions';

export const rootReducerKey = 'carbonMaterial';

export interface DictionaryCarbonMaterialOperationByIdState {
  add: SingleState;
  edit: SingleState;
  details: SingleState;
  delete: SingleState;
}

export interface DictionaryCarbonMaterialState {
  operationsById: ByKey<DictionaryCarbonMaterialOperationByIdState>;
  list: ByKey<ListState>;
}

const initialState: DictionaryCarbonMaterialState = {
  operationsById: {},
  list: {}
};

const reducer: ActionReducer<DictionaryCarbonMaterialState> = combineReducers<DictionaryCarbonMaterialState>({
  operationsById: operationByIdReducer<DictionaryCarbonMaterialOperationByIdState>({
    mapActionToId: (a) => a && a.payload && a.payload.id,
    operations: {
      add: DictionaryCarbonMaterialAddActionTypes,
      edit: DictionaryCarbonMaterialEditActionTypes,
      details: DictionaryCarbonMaterialLoadDetailsActionTypes,
      delete: DictionaryCarbonMaterialDeleteActionTypes
    }
  }),
  list: listByIdReducer({ types: DictionaryCarbonMaterialLoadListActionTypes })
}, initialState);

export function getReducers(): ActionReducer<DictionaryCarbonMaterialState> {
  return reducer;
}

export const DICTIONARY_CARBON_MATERIAL_REDUCER_TOKEN = new InjectionToken<ActionReducer<DictionaryCarbonMaterialState>>(rootReducerKey);

export const dictionaryCarbonMaterialReducerProvider: Provider = {
  provide: DICTIONARY_CARBON_MATERIAL_REDUCER_TOKEN, useFactory: getReducers
};

const getState = createFeatureSelector<DictionaryCarbonMaterialState>(rootReducerKey);

export const getDictionaryCarbonMaterialOperationsById = createSelector(
  getState,
  (state: DictionaryCarbonMaterialState) => state && state.operationsById
);

export const getDictionaryCarbonMaterialListState = createSelector(
  getState,
  (state: DictionaryCarbonMaterialState) => state && state.list
);
