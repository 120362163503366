import {
  ByIdPayload,
  ChangeParamsByIdPayload,
  FailByIdPayload,
  ListReducerTypes,
  NormalizedList,
  PayloadAction,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { SymfonyPaginationParams } from '../../../symfony/list/models/symfony-pagination-params';

export const DictionaryOperationLoadListActionTypes: ListReducerTypes = {
  request: uniqueActionType('[dictionary/operation] Load list'),
  requestFail: uniqueActionType('[dictionary/operation] Load list fail'),
  requestSuccess: uniqueActionType('[dictionary/operation] Load list success'),
  requestClear: uniqueActionType('[dictionary/operation] Load list clear'),
  changeParams: uniqueActionType('[dictionary/operation] Load list change params'),
  clearParams: uniqueActionType('[dictionary/operation] Load list clear params'),
  clearIds: uniqueActionType('[dictionary/operation] Load list clear ids')
};

export class DictionaryOperationLoadListAction implements PayloadAction {
  readonly type = DictionaryOperationLoadListActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class DictionaryOperationLoadListFailAction implements PayloadAction {
  readonly type = DictionaryOperationLoadListActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class DictionaryOperationLoadListSuccessAction implements PayloadAction {
  readonly type = DictionaryOperationLoadListActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedList>) {
  }
}

export class DictionaryOperationLoadListClearAction implements PayloadAction {
  readonly type = DictionaryOperationLoadListActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}

export class DictionaryOperationLoadListChangeParamsAction implements PayloadAction {
  readonly type = DictionaryOperationLoadListActionTypes.changeParams;

  constructor(public payload: ChangeParamsByIdPayload<SymfonyPaginationParams>) {
  }
}

export class DictionaryOperationLoadListClearParamsAction implements PayloadAction {
  readonly type = DictionaryOperationLoadListActionTypes.clearParams;

  constructor(public payload: ByIdPayload) {
  }
}


export class DictionaryOperationLoadListClearIdsAction implements PayloadAction {
  readonly type = DictionaryOperationLoadListActionTypes.clearIds;

  constructor(public payload: ByIdPayload) {
  }
}
