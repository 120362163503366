import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';

export const OrderCancelActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[order-cancel] Cancel'),
  requestFail: uniqueActionType('[order-cancel] Cancel fail'),
  requestSuccess: uniqueActionType('[order-cancel] Cancel success'),
  requestClearErrors: uniqueActionType('[order-cancel] Cancel clear errors'),
  requestClear: uniqueActionType('[order-cancel] Cancel clear')
};

export class OrderCancelAction implements PayloadAction {
  readonly type = OrderCancelActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class OrderCancelFailAction implements PayloadAction {
  readonly type = OrderCancelActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class OrderCancelSuccessAction implements PayloadAction {
  readonly type = OrderCancelActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class OrderCancelClearErrorsAction implements PayloadAction {
  readonly type = OrderCancelActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class OrderCancelClearAction implements PayloadAction {
  readonly type = OrderCancelActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
