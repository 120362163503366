import { select, Store } from '@ngrx/store';
import { filter, first } from 'rxjs/operators';
import {
  getLibAuthRefreshTokenLoading,
  getLibAuthRefreshTokenSuccess,
  LibAuthRefreshTokenAction,
  LibAuthSetTokenAction,
  LibAuthUtil
} from 'app-store-auth';
import { AuthService } from './store/auth/auth.service';
import { ByKey } from '@industi/ngx-common';
import { InstanceUtil } from './store/instance/instance.util';
import { SetLocaleAdapterService } from './store/instance/adapters/set-locale-adapter.service';

export function appInitializer(
  service: AuthService,
  store: Store<ByKey>,
  instance: InstanceUtil,
  localeAdapter: SetLocaleAdapterService
): () => Promise<void> {
  return async (): Promise<void> => {

    async function refreshToken(): Promise<boolean> {
      store.dispatch(new LibAuthRefreshTokenAction());

      await store.pipe(
        select(getLibAuthRefreshTokenLoading),
        filter((loading: boolean) => !loading),
        first()
      ).toPromise<boolean>();

      return await store.pipe(select(getLibAuthRefreshTokenSuccess), first()).toPromise<boolean>();
    }

    const token = LibAuthUtil.token;
    const locale = instance.locale;

    if (token && token.refresh_token) {
      await refreshToken();
    } else {
      store.dispatch(new LibAuthSetTokenAction({ value: null }));
    }

    if (!locale) {
      store.dispatch(localeAdapter.setValueAction('pl'));
    } else {
      store.dispatch(localeAdapter.setValueAction(locale));
    }

    return;
  };
}
