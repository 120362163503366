import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SendByIdPayload,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { ArticleBrandPayload } from '../models/article-brand-payload';

export const ArticleBrandAddActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[article-brand] Add'),
  requestFail: uniqueActionType('[article-brand] Add fail'),
  requestSuccess: uniqueActionType('[article-brand] Add success'),
  requestClearErrors: uniqueActionType('[article-brand] Add clear errors'),
  requestClear: uniqueActionType('[article-brand] Add clear'),
};

export class ArticleBrandAddAction implements PayloadAction {
  readonly type = ArticleBrandAddActionTypes.request;

  constructor(public payload: SendByIdPayload<ArticleBrandPayload>) {
  }
}

export class ArticleBrandAddFailAction implements PayloadAction {
  readonly type = ArticleBrandAddActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class ArticleBrandAddSuccessAction implements PayloadAction {
  readonly type = ArticleBrandAddActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class ArticleBrandAddClearErrorsAction implements PayloadAction {
  readonly type = ArticleBrandAddActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class ArticleBrandAddClearAction implements PayloadAction {
  readonly type = ArticleBrandAddActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
