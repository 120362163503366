import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SendByIdPayload,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { ServiceProjectPayload } from '../models/project-payload';

export const ServiceProjectAddActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[service/project] Add'),
  requestFail: uniqueActionType('[service/project] Add fail'),
  requestSuccess: uniqueActionType('[service/project] Add success'),
  requestClearErrors: uniqueActionType('[service/project] Add clear errors'),
  requestClear: uniqueActionType('[service/project] Add clear'),
};

export class ServiceProjectAddAction implements PayloadAction {
  readonly type = ServiceProjectAddActionTypes.request;

  constructor(public payload: SendByIdPayload<ServiceProjectPayload>) {
  }
}

export class ServiceProjectAddFailAction implements PayloadAction {
  readonly type = ServiceProjectAddActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class ServiceProjectAddSuccessAction implements PayloadAction {
  readonly type = ServiceProjectAddActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class ServiceProjectAddClearErrorsAction implements PayloadAction {
  readonly type = ServiceProjectAddActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class ServiceProjectAddClearAction implements PayloadAction {
  readonly type = ServiceProjectAddActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
