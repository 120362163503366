import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { ORDER_ITEM_REDUCER_TOKEN, orderItemReducerProvider, rootReducerKey } from './item.reducer';
import { EffectsModule } from '@ngrx/effects';
import { OrderItemEffects } from './item.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(rootReducerKey, ORDER_ITEM_REDUCER_TOKEN),
    EffectsModule.forFeature([OrderItemEffects])
  ],
  providers: [
    orderItemReducerProvider
  ]
})
export class OrderItemStoreModule {
}
