import { createSelector } from '@ngrx/store';
import { denormalize } from 'normalizr';
import {
  ByKey,
  createListStateIdsSelector,
  createListStateParamsSelector,
  createStateErrorsSelector,
  createStateLoadingSelector,
  createStateSuccessSelector,
  IdType,
  ListState
} from '@industi/ngx-common';
import { getUserListState } from '../user.reducer';
import { EntityState } from '../../entity/entity.reducer';
import { User } from '../models/user';
import { EntityTypes } from '../../entity/entity-types';
import { createEntitiesSelector } from '../../entity/entities.reducer';
import { createSymfonyTotalCountSelector } from '../../symfony/list/selectors/list.selectors';
import { Entities } from '../../entity/entities';
import { getLoggedUserId } from '../../auth/auth.reducer';

const createState = (id: IdType) => createSelector(
  getUserListState,
  (state: ByKey<ListState>) => state && state[id]
);

const createStateByContext = (id: IdType) => createListStateIdsSelector(createState(id));

export const createUserListData = (id: IdType) => createSelector(
  createStateByContext(id),
  createEntitiesSelector([
    EntityTypes.user
  ]),
  (
    ids: IdType[],
    entities: EntityState
  ) => denormalize(ids, [Entities[EntityTypes.user]], entities) as User[]
);

export const createUserListLoading = (id: IdType) => createStateLoadingSelector(
  createState(id));

export const createUserListSuccess = (id: IdType) => createStateSuccessSelector(
  createState(id));

export const createUserListErrors = (id: IdType) => createStateErrorsSelector(
  createState(id));

export const createUserListTotalCount = (id: IdType) => createSymfonyTotalCountSelector(
  createState(id));

export const createUserListParams = (id: IdType) => createListStateParamsSelector(
  createState(id));

export const createUserDetailsById = (id: IdType) => createSelector(
  createUserListData(id),
  getLoggedUserId,
  (
    list: User[],
    userId: IdType
  ) => list?.find((user: User) => user?.username === userId) || {}
);
