import { Inject, Injectable } from '@angular/core';
import { API_URL } from '../../app-store-providers';
import { HttpClient } from '@angular/common/http';
import { EntityTypes } from '../../entity/entity-types';
import { CarbonCommissionTaskPayload } from './models/task-payload';
import { CarbonCommissionTask } from './models/task';
import { StoreBaseService } from '../../common/base.service';

@Injectable({
  providedIn: 'root'
})
export class CarbonCommissionTaskService
  extends StoreBaseService<CarbonCommissionTask, CarbonCommissionTaskPayload> {

  get entityType(): string {
    return EntityTypes.carbonCommissionTask;
  }

  get resourceName(): string {
    return 'carbon/commission-task';
  }

  constructor(
    @Inject(API_URL) apiUrl: string,
    http: HttpClient
  ) {
    super(apiUrl, http);
  }
}
