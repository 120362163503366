import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TemplateCloseSideNavAction } from './actions/set-side-nav-opened.actions';
import { ByKey } from '@industi/ngx-common';
import { LibAuthSetTokenAction, LibAuthSetTokenActionTypes, TokenResponse } from 'app-store-auth';

@Injectable()
export class TemplateEffects {

  @Effect({ dispatch: false }) setToken$ = this.actions$.pipe(
    ofType(LibAuthSetTokenActionTypes.setValue),
    map((action: LibAuthSetTokenAction) => action.payload.value),
    tap((token: TokenResponse) => {
      if (!token) {
        this.store.dispatch(new TemplateCloseSideNavAction());
      }
    })
  );

  constructor(
    private actions$: Actions,
    private store: Store<ByKey>
  ) {
  }
}
