import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, first, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { ByIdPayload, ByKey, IdType, NormalizedList, NormalizedSingle } from '@industi/ngx-common';
import { select, Store } from '@ngrx/store';
import { getInstanceLocale } from '../../instance/instance.reducer';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import { ResponseStatus } from '../../../shared/enums/response-status.enum';
import { LibSnackbarComponent } from '@industi/ngx-modules';
import { AlertUtil } from '../../../shared/utils/alert.util';
import { AppGlobal } from '../../../shared/app-global';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OfferItemService } from './item.service';
import {
  OfferItemLoadListAction,
  OfferItemLoadListActionTypes,
  OfferItemLoadListClearIdsAction,
  OfferItemLoadListFailAction,
  OfferItemLoadListSuccessAction
} from './actions/list.action';
import { SymfonyPaginationParams } from '../../symfony/list/models/symfony-pagination-params';
import {
  OfferItemAddAction,
  OfferItemAddActionTypes,
  OfferItemAddFailAction,
  OfferItemAddSuccessAction
} from './actions/add.action';
import { createOfferItemListParams } from './selectors/get-list.selectors';
import {
  OfferItemEditAction,
  OfferItemEditActionTypes,
  OfferItemEditFailAction,
  OfferItemEditSuccessAction
} from './actions/edit.action';
import {
  OfferItemLoadDetailsAction,
  OfferItemLoadDetailsActionTypes,
  OfferItemLoadDetailsFailAction,
  OfferItemLoadDetailsSuccessAction
} from './actions/details.action';
import {
  OfferItemDeleteAction,
  OfferItemDeleteActionTypes,
  OfferItemDeleteFailAction,
  OfferItemDeleteSuccessAction
} from './actions/delete.action';

@Injectable()
export class OfferItemEffects {

  @Effect()
  loadList$ = this.actions$.pipe(
    ofType(OfferItemLoadListActionTypes.request),
    map((action: OfferItemLoadListAction) => action.payload),
    switchMap((payload: ByIdPayload) => this.store.pipe(
      select(createOfferItemListParams(payload.id)),
      map((params: SymfonyPaginationParams) => [payload.id, params] as [IdType, SymfonyPaginationParams]),
      first()
    )),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([[id, params], locale]) => this.service.loadList(locale, params).pipe(
      mergeMap((res: NormalizedList) => [
        new OfferItemLoadListClearIdsAction({ id }),
        new OfferItemLoadListSuccessAction({ id, res })]),
      catchError((errors: HttpErrorResponse) => of(new OfferItemLoadListFailAction({ id, errors })))
    ))
  );

  @Effect()
  add$ = this.actions$.pipe(
    ofType(OfferItemAddActionTypes.request),
    map((action: OfferItemAddAction) => action.payload),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([{ id, data }, locale]) => this.service.add(locale, data).pipe(
      map((res: NormalizedSingle) => new OfferItemAddSuccessAction({ res, id })),
      catchError((errors: HttpErrorResponse) => of(new OfferItemAddFailAction({ errors, id })))
    ))
  );

  @Effect()
  edit$ = this.actions$.pipe(
    ofType(OfferItemEditActionTypes.request),
    map((action: OfferItemEditAction) => action.payload),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([{ id, data }, locale]) => this.service.edit(locale, data, id).pipe(
      map((res: NormalizedSingle) => new OfferItemEditSuccessAction({ res, id })),
      catchError((errors: HttpErrorResponse) => of(new OfferItemEditFailAction({ errors, id })))
    ))
  );

  @Effect()
  loadDetails$ = this.actions$.pipe(
    ofType(OfferItemLoadDetailsActionTypes.request),
    map((action: OfferItemLoadDetailsAction) => action.payload.id),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([id, locale]) => this.service.details(locale, id).pipe(
      map((res: NormalizedSingle) => new OfferItemLoadDetailsSuccessAction({ id, res })),
      catchError((errors: HttpErrorResponse) => of(new OfferItemLoadDetailsFailAction({ id, errors })))
    ))
  );

  @Effect()
  delete$ = this.actions$.pipe(
    ofType(OfferItemDeleteActionTypes.request),
    map((action: OfferItemDeleteAction) => action.payload.id),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([id, locale]) => this.service.delete(locale, id).pipe(
      map(() => new OfferItemDeleteSuccessAction({ id })),
      catchError((errors: HttpErrorResponse) => of(new OfferItemDeleteFailAction({ id, errors })))
    ))
  );

  @Effect({ dispatch: false })
  crudFail$ = this.actions$.pipe(
    ofType(
      OfferItemAddActionTypes.requestFail,
      OfferItemEditActionTypes.requestFail
    ),
    map((action: OfferItemAddFailAction | OfferItemEditFailAction) => action.payload.errors),
    tap((data: HttpErrorResponse) => {
      if (data?.status === ResponseStatus.BAD_REQUEST || data?.status === ResponseStatus.UNPROCESSABLE_ENTITY) {
        this.snackBar.openFromComponent(LibSnackbarComponent, {
          data: {
            message: AlertUtil.getControlTranslation(data.error.detail),
            type: 'error'
          },
          duration: AppGlobal.defaultSnackBarDuration
        });
      }
    })
  );

  constructor(
    private actions$: Actions,
    private store: Store<ByKey>,
    private service: OfferItemService,
    private snackBar: MatSnackBar
  ) {
  }
}
