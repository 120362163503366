import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SendByIdPayload,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { ItemPayload } from '../models/item-payload';

export const ItemEditActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[item] Edit'),
  requestFail: uniqueActionType('[item] Edit fail'),
  requestSuccess: uniqueActionType('[item] Edit success'),
  requestClearErrors: uniqueActionType('[item] Edit clear errors'),
  requestClear: uniqueActionType('[item] Edit clear')
};

export class ItemEditAction implements PayloadAction {
  readonly type = ItemEditActionTypes.request;

  constructor(public payload: SendByIdPayload<ItemPayload>) {
  }
}

export class ItemEditFailAction implements PayloadAction {
  readonly type = ItemEditActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class ItemEditSuccessAction implements PayloadAction {
  readonly type = ItemEditActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class ItemEditClearErrorsAction implements PayloadAction {
  readonly type = ItemEditActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class ItemEditClearAction implements PayloadAction {
  readonly type = ItemEditActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
