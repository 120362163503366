import {
  ByIdPayload,
  ChangeParamsByIdPayload,
  FailByIdPayload,
  ListReducerTypes,
  NormalizedList,
  PayloadAction,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { UserListParams } from '../models/user-list-params';

export const UserLoadListActionTypes: ListReducerTypes = {
  request: uniqueActionType('[user] Load list'),
  requestFail: uniqueActionType('[user] Load list fail'),
  requestSuccess: uniqueActionType('[user] Load list success'),
  requestClear: uniqueActionType('[user] Load list clear'),
  changeParams: uniqueActionType('[user] Load list change params'),
  clearParams: uniqueActionType('[user] Load list clear params'),
  clearIds: uniqueActionType('[user] Load list clear ids')
};

export class UserLoadListAction implements PayloadAction {
  readonly type = UserLoadListActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class UserLoadListFailAction implements PayloadAction {
  readonly type = UserLoadListActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class UserLoadListSuccessAction implements PayloadAction {
  readonly type = UserLoadListActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedList>) {
  }
}

export class UserLoadListClearAction implements PayloadAction {
  readonly type = UserLoadListActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}

export class UserLoadListChangeParamsAction implements PayloadAction {
  readonly type = UserLoadListActionTypes.changeParams;

  constructor(public payload: ChangeParamsByIdPayload<UserListParams>) {
  }
}

export class UserLoadListClearParamsAction implements PayloadAction {
  readonly type = UserLoadListActionTypes.clearParams;

  constructor(public payload: ByIdPayload) {
  }
}


export class UserLoadListClearIdsAction implements PayloadAction {
  readonly type = UserLoadListActionTypes.clearIds;

  constructor(public payload: ByIdPayload) {
  }
}
