import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, InjectionToken, LOCALE_ID, Provider } from '@angular/core';
import { environment } from '../environments/environment';
import { Store } from '@ngrx/store';
import { AuthInterceptor } from './store/auth/auth.interceptor';
import { AuthService } from './store/auth/auth.service';
import { API_URL, EBAY_API_URL } from './store/app-store-providers';
import { appInitializer } from './app.initializer';
import { APP_MENU } from './shared/modules/app-menu/app-menu.provider';
import { appMenu } from '../environments/menu/app.menu';
import { InstanceUtil } from './store/instance/instance.util';
import { SetLocaleAdapterService } from './store/instance/adapters/set-locale-adapter.service';

export const AuthAfterLoginRouteInjectionToken = new InjectionToken<string[]>('authAfterLoginRoute');
export const StartRouteInjectionToken = new InjectionToken<string[]>('startRoute');
export const FILE_SIZE = new InjectionToken<string[]>('fileSize');
export const WINDOW = new InjectionToken<Window>('window');

export const appModuleProviders: Provider[] = [
  { provide: LOCALE_ID, useValue: 'pl' },
  { provide: StartRouteInjectionToken, useValue: ['auth', 'login'] },
  { provide: AuthAfterLoginRouteInjectionToken, useValue: ['auth', 'success'] },
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  {
    provide: APP_INITIALIZER,
    useFactory: appInitializer,
    multi: true,
    deps: [
      AuthService,
      Store,
      InstanceUtil,
      SetLocaleAdapterService
    ]
  },
  { provide: FILE_SIZE, useValue: environment.fileSize },
  { provide: WINDOW, useValue: window },

  // Menu
  { provide: APP_MENU, useValue: appMenu },

  // API urls
  { provide: API_URL, useValue: environment.hosts.apiUrl },
  { provide: EBAY_API_URL, useValue: environment.hosts.ebayApiUrl }
];
