import { ByKey, listByIdReducer, ListState, operationByIdReducer, SingleState } from '@industi/ngx-common';
import { ActionReducer, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import { OfferItemAddActionTypes } from './actions/add.action';
import { OfferItemEditActionTypes } from './actions/edit.action';
import { OfferItemDeleteActionTypes } from './actions/delete.action';
import { OfferItemLoadDetailsActionTypes } from './actions/details.action';
import { OfferItemLoadListActionTypes } from './actions/list.action';
import { InjectionToken, Provider } from '@angular/core';

export const rootReducerKey = 'offerItem';

export interface OfferItemOperationsByIdState {
  add: SingleState;
  edit: SingleState;
  delete: SingleState;
  details: SingleState;
}

export interface OfferItemState {
  operationsById: ByKey<OfferItemOperationsByIdState>;
  list: ByKey<ListState>;
}

const initialState: OfferItemState = {
  operationsById: {},
  list: {}
};

const reducer: ActionReducer<OfferItemState> = combineReducers<OfferItemState>({
  operationsById: operationByIdReducer<OfferItemOperationsByIdState>({
    mapActionToId: (a) => a && a.payload && a.payload.id,
    operations: {
      add: OfferItemAddActionTypes,
      edit: OfferItemEditActionTypes,
      delete: OfferItemDeleteActionTypes,
      details: OfferItemLoadDetailsActionTypes
    }
  }),
  list: listByIdReducer({ types: OfferItemLoadListActionTypes })
}, initialState);

export function getReducers(): ActionReducer<OfferItemState> {
  return reducer;
}

export const OFFER_ITEM_REDUCER_TOKEN = new InjectionToken<ActionReducer<OfferItemState>>(rootReducerKey);

export const offerItemReducerProvider: Provider = {
  provide: OFFER_ITEM_REDUCER_TOKEN,
  useFactory: getReducers
};

const getState = createFeatureSelector<OfferItemState>(rootReducerKey);

export const getOfferItemOperationsById = createSelector(
  getState,
  (state: OfferItemState) => state && state.operationsById
);

export const getOfferItemListState = createSelector(
  getState,
  (state: OfferItemState) => state && state.list
);
