import {
  ByIdPayload,
  ChangeParamsByIdPayload,
  FailByIdPayload,
  ListReducerTypes,
  NormalizedList,
  PayloadAction,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { ClientListParams } from '../models/client-list-params';

export const ClientLoadListActionTypes: ListReducerTypes = {
  request: uniqueActionType('[client] Load list'),
  requestFail: uniqueActionType('[client] Load list fail'),
  requestSuccess: uniqueActionType('[client] Load list success'),
  requestClear: uniqueActionType('[client] Load list clear'),
  changeParams: uniqueActionType('[client] Load list change params'),
  clearParams: uniqueActionType('[client] Load list clear params'),
  clearIds: uniqueActionType('[client] Load list clear ids')
};

export class ClientLoadListAction implements PayloadAction {
  readonly type = ClientLoadListActionTypes.request;

  constructor(public payload: ByIdPayload) {

  }
}

export class ClientLoadListFailAction implements PayloadAction {
  readonly type = ClientLoadListActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class ClientLoadListSuccessAction implements PayloadAction {
  readonly type = ClientLoadListActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedList>) {
  }
}

export class ClientLoadListClearAction implements PayloadAction {
  readonly type = ClientLoadListActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}

export class ClientLoadListChangeParamsAction implements PayloadAction {
  readonly type = ClientLoadListActionTypes.changeParams;

  constructor(public payload: ChangeParamsByIdPayload<ClientListParams>) {

  }
}

export class ClientLoadListClearParamsAction implements PayloadAction {
  readonly type = ClientLoadListActionTypes.clearParams;

  constructor(public payload: ByIdPayload) {
  }
}

export class ClientLoadListClearIdsAction implements PayloadAction {
  readonly type = ClientLoadListActionTypes.clearIds;

  constructor(public payload: ByIdPayload) {
  }
}
