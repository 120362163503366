import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SendByIdPayload,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { ServiceProjectOperationPayload } from '../models/operation-payload';

export const ServiceProjectOperationAddActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[service/project-operation] Add'),
  requestFail: uniqueActionType('[service/project-operation] Add fail'),
  requestSuccess: uniqueActionType('[service/project-operation] Add success'),
  requestClearErrors: uniqueActionType('[service/project-operation] Add clear errors'),
  requestClear: uniqueActionType('[service/project-operation] Add clear'),
};

export class ServiceProjectOperationAddAction implements PayloadAction {
  readonly type = ServiceProjectOperationAddActionTypes.request;

  constructor(public payload: SendByIdPayload<ServiceProjectOperationPayload>) {
  }
}

export class ServiceProjectOperationAddFailAction implements PayloadAction {
  readonly type = ServiceProjectOperationAddActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class ServiceProjectOperationAddSuccessAction implements PayloadAction {
  readonly type = ServiceProjectOperationAddActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class ServiceProjectOperationAddClearErrorsAction implements PayloadAction {
  readonly type = ServiceProjectOperationAddActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class ServiceProjectOperationAddClearAction implements PayloadAction {
  readonly type = ServiceProjectOperationAddActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
