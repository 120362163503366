import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { ByIdPayload, ByKey, IdType, NormalizedList, NormalizedSingle } from '@industi/ngx-common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ServiceProjectItemService } from './item.service';
import { catchError, first, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { getInstanceLocale } from '../../../instance/instance.reducer';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import { createServiceProjectItemListParams } from './selectors/get-list.selectors';
import {
  ServiceProjectItemLoadListAction,
  ServiceProjectItemLoadListActionTypes,
  ServiceProjectItemLoadListClearIdsAction,
  ServiceProjectItemLoadListFailAction,
  ServiceProjectItemLoadListSuccessAction
} from './actions/list.actions';
import { ServiceProjectItemListParams } from './models/item-list-params';
import {
  ServiceProjectItemAddAction,
  ServiceProjectItemAddActionTypes,
  ServiceProjectItemAddFailAction,
  ServiceProjectItemAddSuccessAction
} from './actions/add.actions';
import {
  ServiceProjectItemEditAction,
  ServiceProjectItemEditActionTypes,
  ServiceProjectItemEditFailAction,
  ServiceProjectItemEditSuccessAction
} from './actions/edit.actions';
import {
  ServiceProjectItemLoadDetailsAction,
  ServiceProjectItemLoadDetailsActionTypes,
  ServiceProjectItemLoadDetailsFailAction,
  ServiceProjectItemLoadDetailsSuccessAction
} from './actions/details.actions';
import {
  ServiceProjectItemDeleteAction,
  ServiceProjectItemDeleteActionTypes,
  ServiceProjectItemDeleteFailAction,
  ServiceProjectItemDeleteSuccessAction
} from './actions/delete.actions';
import { ResponseStatus } from '../../../../shared/enums/response-status.enum';
import { LibSnackbarComponent } from '@industi/ngx-modules';
import { AlertUtil } from '../../../../shared/utils/alert.util';
import { AppGlobal } from '../../../../shared/app-global';

@Injectable()
export class ServiceProjectItemEffects {

  @Effect()
  loadList$ = this.actions$.pipe(
    ofType(ServiceProjectItemLoadListActionTypes.request),
    map((action: ServiceProjectItemLoadListAction) => action.payload),
    switchMap((payload: ByIdPayload) => this.store.pipe(
      select(createServiceProjectItemListParams(payload.id)),
      map((params: ServiceProjectItemListParams) => [payload.id, params] as [IdType, ServiceProjectItemListParams]),
      first()
    )),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([[ id, params ], locale]) => this.service.loadList(locale, params).pipe(
      mergeMap((res: NormalizedList) => [
        new ServiceProjectItemLoadListClearIdsAction({ id }),
        new ServiceProjectItemLoadListSuccessAction({ id, res })]),
      catchError((errors: HttpErrorResponse) => of(new ServiceProjectItemLoadListFailAction({ id, errors })))
    ))
  );

  @Effect()
  add$ = this.actions$.pipe(
    ofType(ServiceProjectItemAddActionTypes.request),
    map((action: ServiceProjectItemAddAction) => action.payload),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([{ id, data }, locale]) => this.service.add(locale, data).pipe(
      map((res: NormalizedSingle) => new ServiceProjectItemAddSuccessAction({ res, id })),
      catchError((errors: HttpErrorResponse) => of(new ServiceProjectItemAddFailAction({ errors, id })))
    ))
  );

  @Effect()
  edit$ = this.actions$.pipe(
    ofType(ServiceProjectItemEditActionTypes.request),
    map((action: ServiceProjectItemEditAction) => action.payload),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([{ id, data }, locale]) => this.service.edit(locale, data, id).pipe(
      map((res: NormalizedSingle) => new ServiceProjectItemEditSuccessAction({ res, id })),
      catchError((errors: HttpErrorResponse) => of(new ServiceProjectItemEditFailAction({ errors, id })))
    ))
  );

  @Effect()
  loadDetails$ = this.actions$.pipe(
    ofType(ServiceProjectItemLoadDetailsActionTypes.request),
    map((action: ServiceProjectItemLoadDetailsAction) => action.payload.id),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([id, locale]) => this.service.details(locale, id).pipe(
      map((res: NormalizedSingle) => new ServiceProjectItemLoadDetailsSuccessAction({ id, res })),
      catchError((errors: HttpErrorResponse) => of(new ServiceProjectItemLoadDetailsFailAction({ id, errors })))
    ))
  );

  @Effect()
  delete$ = this.actions$.pipe(
    ofType(ServiceProjectItemDeleteActionTypes.request),
    map((action: ServiceProjectItemDeleteAction) => action.payload.id),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([id, locale]) => this.service.delete(locale, id).pipe(
      map(() => new ServiceProjectItemDeleteSuccessAction({ id })),
      catchError((errors: HttpErrorResponse) => of(new ServiceProjectItemDeleteFailAction({ id, errors })))
    ))
  );

  @Effect({ dispatch: false })
  crudFail$ = this.actions$.pipe(
    ofType(
      ServiceProjectItemAddActionTypes.requestFail,
      ServiceProjectItemEditActionTypes.requestFail
    ),
    map((action: ServiceProjectItemAddFailAction | ServiceProjectItemEditFailAction) => action.payload.errors),
    tap((data: HttpErrorResponse) => {
      if (data?.status === ResponseStatus.BAD_REQUEST || data?.status === ResponseStatus.UNPROCESSABLE_ENTITY) {
        this.snackBar.openFromComponent(LibSnackbarComponent, {
          data: {
            message: AlertUtil.getControlTranslation(data.error.detail),
            type: 'error'
          },
          duration: AppGlobal.defaultSnackBarDuration
        });
      }
    })
  );

  constructor(
    private actions$: Actions,
    private store: Store<ByKey>,
    private service: ServiceProjectItemService,
    private snackBar: MatSnackBar
  ) {
  }
}
