import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SendByIdPayload,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { EbayPayload } from '../models/ebay-payload';

export const EbayAddConfigurationActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[ebay-configuration/add] Add configuration'),
  requestFail: uniqueActionType('[ebay-configuration/add] Add configuration fail'),
  requestSuccess: uniqueActionType('[ebay-configuration/add] Add configuration success'),
  requestClearErrors: uniqueActionType('[ebay-configuration/add] Add configuration clear errors'),
  requestClear: uniqueActionType('[ebay-configuration/add] Add configuration clear'),
};

export class EbayAddConfigurationAction implements PayloadAction {
  readonly type = EbayAddConfigurationActionTypes.request;

  constructor(public payload: SendByIdPayload<EbayPayload>) {
  }
}

export class EbayAddConfigurationFailAction implements PayloadAction {
  readonly type = EbayAddConfigurationActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class EbayAddConfigurationSuccessAction implements PayloadAction {
  readonly type = EbayAddConfigurationActionTypes.requestSuccess;

  constructor(public payload: ByIdPayload) {
  }
}

export class EbayAddConfigurationClearErrorsAction implements PayloadAction {
  readonly type = EbayAddConfigurationActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class EbayAddConfigurationClearAction implements PayloadAction {
  readonly type = EbayAddConfigurationActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
