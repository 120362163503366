import { Inject, Injectable } from '@angular/core';
import { API_URL } from '../../app-store-providers';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IdType, NormalizedList, NormalizedSingle, QueryParamsUtil } from '@industi/ngx-common';
import { SymfonyModuleHttpClientConfig } from '../../symfony/resources/symfony-module-http-client.config';
import { map } from 'rxjs/operators';
import { normalize } from 'normalizr';
import { Entities } from '../../entity/entities';
import { EntityTypes } from '../../entity/entity-types';
import { SymfonyListUtil } from '../../symfony/list/utils/symfony-list-util';
import { IcsConversation } from './models/conversation';
import { IcsConversationListParams } from './models/conversation-list-params';
import { EncodeUtil } from '../../../shared/utils/encode.util';
import { IcsConversationMessagePayload } from './models/conversation-message-payload';
import { IcsConversationAttachmentPayload } from './models/conversation-attachment-payload';
import { IcsConversationTaskPayload } from './models/conversation-task-payload';

@Injectable({
  providedIn: 'root'
})
export class IcsConversationService {

  constructor(
    @Inject(API_URL) private apiUrl: string,
    private http: HttpClient
  ) { }

  loadList(locale: string, params: IcsConversationListParams): Observable<NormalizedList> {
    const url = `${this.apiUrl}/${locale}/api/ics/conversation?${QueryParamsUtil.toParamsString({ ...params })}`;
    return this.http.get<IcsConversation[]>(url, SymfonyModuleHttpClientConfig).pipe(
      map((res: HttpResponse<IcsConversation[]>) => <NormalizedList>{
        ...normalize(res && res.body, [Entities[EntityTypes.icsConversation]]),
        meta: {
          maxResults: SymfonyListUtil.getCount(res)
        }
      })
    );
  }

  details(locale: string, id: IdType): Observable<NormalizedSingle> {
    const url = `${this.apiUrl}/${locale}/api/ics/conversation/${id}`;
    return this.http.get<IcsConversation>(url).pipe(
      map((res: IcsConversation) => normalize(res, Entities[EntityTypes.icsConversation]))
    );
  }

  delete(locale: string, id: IdType): Observable<void> {
    const url = `${this.apiUrl}/${locale}/api/ics/conversation/${id}`;
    return this.http.delete<void>(url);
  }

  addMessage(locale: string, data: IcsConversationMessagePayload): Observable<NormalizedSingle> {
    const url = `${this.apiUrl}/${locale}/api/ics/conversation-message`;
    return this.http.post<IcsConversation>(url, data).pipe(
      map((res: IcsConversation) => normalize(res, Entities[EntityTypes.icsConversation]))
    );
  }

  addTask(locale: string, data: IcsConversationTaskPayload): Observable<NormalizedSingle> {
    const url = `${this.apiUrl}/${locale}/api/ics/conversation-task`;
    return this.http.post<IcsConversation>(url, data).pipe(
      map((res: IcsConversation) => normalize(res, Entities[EntityTypes.icsConversation]))
    );
  }

  addAttachment(locale: string, data: IcsConversationAttachmentPayload): Observable<NormalizedSingle> {
    const url = `${this.apiUrl}/${locale}/api/ics/conversation-attachment`;
    return this.http.post<IcsConversation>(url, EncodeUtil.prepareMultipartFormData(data)).pipe(
      map((res: IcsConversation) => normalize(res, Entities[EntityTypes.icsConversation]))
    );
  }
}
