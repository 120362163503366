import {
  ByIdPayload,
  ChangeParamsByIdPayload,
  FailByIdPayload,
  ListReducerTypes,
  NormalizedList,
  PayloadAction,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { IcsConversationListParams } from '../models/conversation-list-params';

export const IcsConversationLoadListActionTypes: ListReducerTypes = {
  request: uniqueActionType('[ics/conversation] Load list'),
  requestFail: uniqueActionType('[ics/conversation] Load list fail'),
  requestSuccess: uniqueActionType('[ics/conversation] Load list success'),
  requestClear: uniqueActionType('[ics/conversation] Load list clear'),
  changeParams: uniqueActionType('[ics/conversation] Load list change params'),
  clearParams: uniqueActionType('[ics/conversation] Load list clear params'),
  clearIds: uniqueActionType('[ics/conversation] Load list clear ids')
};

export class IcsConversationLoadListAction implements PayloadAction {
  readonly type = IcsConversationLoadListActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class IcsConversationLoadListFailAction implements PayloadAction {
  readonly type = IcsConversationLoadListActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class IcsConversationLoadListSuccessAction implements PayloadAction {
  readonly type = IcsConversationLoadListActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedList>) {
  }
}

export class IcsConversationLoadListClearAction implements PayloadAction {
  readonly type = IcsConversationLoadListActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}

export class IcsConversationLoadListChangeParamsAction implements PayloadAction {
  readonly type = IcsConversationLoadListActionTypes.changeParams;

  constructor(public payload: ChangeParamsByIdPayload<IcsConversationListParams>) {
  }
}

export class IcsConversationLoadListClearParamsAction implements PayloadAction {
  readonly type = IcsConversationLoadListActionTypes.clearParams;

  constructor(public payload: ByIdPayload) {
  }
}


export class IcsConversationLoadListClearIdsAction implements PayloadAction {
  readonly type = IcsConversationLoadListActionTypes.clearIds;

  constructor(public payload: ByIdPayload) {
  }
}
