import { Inject, Injectable } from '@angular/core';
import { API_URL } from '../../app-store-providers';
import { HttpClient } from '@angular/common/http';
import { EntityTypes } from '../../entity/entity-types';
import { StoreBaseService } from '../../common/base.service';
import { ArticleContent } from './models/content';

@Injectable({
  providedIn: 'root'
})
export class ArticleContentService
  extends StoreBaseService<ArticleContent> {

  get entityType(): string {
    return EntityTypes.articleContent;
  }

  get resourceName(): string {
    return 'article-content';
  }

  constructor(
    @Inject(API_URL) apiUrl: string,
    http: HttpClient
  ) {
    super(apiUrl, http);
  }
}
