import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';

export const DictionaryCourierLoadDetailsActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[dictionary/courier] Load details'),
  requestFail: uniqueActionType('[dictionary/courier] Load details fail'),
  requestSuccess: uniqueActionType('[dictionary/courier] Load details success'),
  requestClearErrors: uniqueActionType('[dictionary/courier] Load details clear errors'),
  requestClear: uniqueActionType('[dictionary/courier] Load details clear')
};

export class DictionaryCourierLoadDetailsAction implements PayloadAction {
  readonly type = DictionaryCourierLoadDetailsActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class DictionaryCourierLoadDetailsFailAction implements PayloadAction {
  readonly type = DictionaryCourierLoadDetailsActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class DictionaryCourierLoadDetailsSuccessAction implements PayloadAction {
  readonly type = DictionaryCourierLoadDetailsActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class DictionaryCourierLoadDetailsClearAction implements PayloadAction {
  readonly type = DictionaryCourierLoadDetailsActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}

export class DictionaryCourierLoadDetailsClearErrorsAction implements PayloadAction {
  readonly type = DictionaryCourierLoadDetailsActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}
