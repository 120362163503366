import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { ByKey, NormalizedSingle } from '@industi/ngx-common';
import { ApplicationService } from './application.service';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { getInstanceLocale } from '../instance/instance.reducer';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import {
  ApplicationUpdateAction,
  ApplicationUpdateActionTypes,
  ApplicationUpdateFailAction,
  ApplicationUpdateSuccessAction
} from './actions/update.actions';
import { ResponseStatus } from '../../shared/enums/response-status.enum';
import { LibSnackbarComponent } from '@industi/ngx-modules';
import { AlertUtil } from '../../shared/utils/alert.util';
import { AppGlobal } from '../../shared/app-global';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  ApplicationLoadCurrentAction,
  ApplicationLoadCurrentActionTypes,
  ApplicationLoadCurrentFailAction,
  ApplicationLoadCurrentSuccessAction
} from './actions/load-current.actions';

@Injectable()
export class ApplicationEffects {

  @Effect()
  update$ = this.actions$.pipe(
    ofType(ApplicationUpdateActionTypes.request),
    map((action: ApplicationUpdateAction) => action.payload),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([{ id, data }, locale]) => this.service.update(locale, data).pipe(
      map(() => new ApplicationUpdateSuccessAction({ id })),
      catchError((errors: HttpErrorResponse) => of(new ApplicationUpdateFailAction({ errors, id })))
    ))
  );

  @Effect({ dispatch: false })
  updateSuccess$ = this.actions$.pipe(
    ofType(ApplicationUpdateActionTypes.requestSuccess),
    tap(() => {
      this.snackBar.openFromComponent(LibSnackbarComponent, {
        data: {
          message: 'Pomyślnie wgrano nową wersję aplikacji',
          type: 'success'
        }
      });
    })
  );

  @Effect()
  current$ = this.actions$.pipe(
    ofType(ApplicationLoadCurrentActionTypes.request),
    map((action: ApplicationLoadCurrentAction) => action.payload),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([{ id }, locale]) => this.service.current(locale).pipe(
      map((res: NormalizedSingle) => new ApplicationLoadCurrentSuccessAction({ id, res })),
      catchError((errors: HttpErrorResponse) => of(new ApplicationLoadCurrentFailAction({ id, errors })))
    ))
  );

  @Effect({ dispatch: false })
  crudFail$ = this.actions$.pipe(
    ofType(
      ApplicationUpdateActionTypes.requestFail
    ),
    map((action: ApplicationUpdateFailAction) => action.payload.errors),
    tap((data: HttpErrorResponse) => {
      if (data?.status === ResponseStatus.BAD_REQUEST || data?.status === ResponseStatus.UNPROCESSABLE_ENTITY) {
        this.snackBar.openFromComponent(LibSnackbarComponent, {
          data: {
            message: AlertUtil.getControlTranslation(data.error.detail),
            type: 'error'
          },
          duration: AppGlobal.defaultSnackBarDuration
        });
      }
    })
  );

  constructor(
    private actions$: Actions,
    private store: Store<ByKey>,
    private service: ApplicationService,
    private snackBar: MatSnackBar
  ) {
  }
}
