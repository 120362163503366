import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { ByIdPayload, ByKey, IdType, NormalizedList, NormalizedSingle } from '@industi/ngx-common';
import { catchError, first, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { getInstanceLocale } from '../../instance/instance.reducer';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import { ArticleContentService } from './content.service';
import {
  ArticleContentDeleteAction,
  ArticleContentDeleteActionTypes,
  ArticleContentDeleteFailAction,
  ArticleContentDeleteSuccessAction
} from './actions/delete.actions';
import { createArticleContentListParams } from './selectors/get-load-list.selectors';
import {
  ArticleContentLoadListAction,
  ArticleContentLoadListActionTypes,
  ArticleContentLoadListFailAction,
  ArticleContentLoadListSuccessAction
} from './actions/list.actions';
import { ArticleContentListParams } from './models/content-list-params';
import {
  ArticleContentLoadDetailsAction,
  ArticleContentLoadDetailsActionTypes,
  ArticleContentLoadDetailsFailAction,
  ArticleContentLoadDetailsSuccessAction
} from './actions/details.actions';

@Injectable()
export class ArticleContentEffects {

  @Effect()
  loadList$ = this.actions$.pipe(
    ofType(ArticleContentLoadListActionTypes.request),
    map((action: ArticleContentLoadListAction) => action.payload),
    switchMap((payload: ByIdPayload) => this.store.pipe(
      select(createArticleContentListParams(payload.id)),
      map((params: ArticleContentListParams) => [payload.id, params] as [IdType, ArticleContentListParams]),
      first()
    )),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([[id, params], locale]) => this.service.loadList(locale, params).pipe(
      mergeMap((res: NormalizedList) => [
        // new ArticleContentLoadListClearIdsAction({ id }),
        new ArticleContentLoadListSuccessAction({ id, res })]),
      catchError((errors: HttpErrorResponse) => of(new ArticleContentLoadListFailAction({ id, errors })))
    ))
  );

  @Effect()
  loadDetails$ = this.actions$.pipe(
    ofType(ArticleContentLoadDetailsActionTypes.request),
    map((action: ArticleContentLoadDetailsAction) => action.payload.id),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([id, locale]) => this.service.details(locale, id).pipe(
      map((res: NormalizedSingle) => new ArticleContentLoadDetailsSuccessAction({ id, res })),
      catchError((errors: HttpErrorResponse) => of(new ArticleContentLoadDetailsFailAction({ id, errors })))
    ))
  );

  @Effect()
  delete$ = this.actions$.pipe(
    ofType(ArticleContentDeleteActionTypes.request),
    map((action: ArticleContentDeleteAction) => action.payload.id),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([id, locale]) => this.service.delete(locale, id).pipe(
      map(() => new ArticleContentDeleteSuccessAction({ id })),
      catchError((errors: HttpErrorResponse) => of(new ArticleContentDeleteFailAction({ id, errors })))
    ))
  );

  constructor(
    private actions$: Actions,
    private store: Store<ByKey>,
    private service: ArticleContentService
  ) {
  }
}
