import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import {
  CARBON_PART_COMMISSION_REDUCER_TOKEN,
  carbonPartCommissionReducerProvider,
  rootReducerKey
} from './part-commission.reducer';
import { CarbonPartCommissionEffects } from './part-commission.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(rootReducerKey, CARBON_PART_COMMISSION_REDUCER_TOKEN),
    EffectsModule.forFeature([CarbonPartCommissionEffects])
  ],
  providers: [
    carbonPartCommissionReducerProvider
  ]
})
export class CarbonPartCommissionStoreModule { }
