import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';

export const DictionaryMaterialExploitationLoadDetailsActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[dictionary/material-exploitation] Load details'),
  requestFail: uniqueActionType('[dictionary/material-exploitation] Load details fail'),
  requestSuccess: uniqueActionType('[dictionary/material-exploitation] Load details success'),
  requestClearErrors: uniqueActionType('[dictionary/material-exploitation] Load details clear errors'),
  requestClear: uniqueActionType('[dictionary/material-exploitation] Load details clear')
};

export class DictionaryMaterialExploitationLoadDetailsAction implements PayloadAction {
  readonly type = DictionaryMaterialExploitationLoadDetailsActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class DictionaryMaterialExploitationLoadDetailsFailAction implements PayloadAction {
  readonly type = DictionaryMaterialExploitationLoadDetailsActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class DictionaryMaterialExploitationLoadDetailsSuccessAction implements PayloadAction {
  readonly type = DictionaryMaterialExploitationLoadDetailsActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class DictionaryMaterialExploitationLoadDetailsClearAction implements PayloadAction {
  readonly type = DictionaryMaterialExploitationLoadDetailsActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}

export class DictionaryMaterialExploitationLoadDetailsClearErrorsAction implements PayloadAction {
  readonly type = DictionaryMaterialExploitationLoadDetailsActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}
