import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SendByIdPayload,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { IcsConversationMessagePayload } from '../models/conversation-message-payload';

export const IcsConversationMessageAddActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[ics/conversation-message] Add'),
  requestFail: uniqueActionType('[ics/conversation-message] Add fail'),
  requestSuccess: uniqueActionType('[ics/conversation-message] Add success'),
  requestClearErrors: uniqueActionType('[ics/conversation-message] Add clear errors'),
  requestClear: uniqueActionType('[ics/conversation-message] Add clear'),
};

export class IcsConversationMessageAddAction implements PayloadAction {
  readonly type = IcsConversationMessageAddActionTypes.request;

  constructor(public payload: SendByIdPayload<IcsConversationMessagePayload>) {
  }
}

export class IcsConversationMessageAddFailAction implements PayloadAction {
  readonly type = IcsConversationMessageAddActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class IcsConversationMessageAddSuccessAction implements PayloadAction {
  readonly type = IcsConversationMessageAddActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class IcsConversationMessageAddClearErrorsAction implements PayloadAction {
  readonly type = IcsConversationMessageAddActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class IcsConversationMessageAddClearAction implements PayloadAction {
  readonly type = IcsConversationMessageAddActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
