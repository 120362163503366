import { PayloadAction, SetValuePayload, SetValueReducerTypes, uniqueActionType } from '@industi/ngx-common';
import { OutputTokenResponse } from '../../auth/models/output-token-response';

export const EbaySetTokenActionTypes: SetValueReducerTypes = {
  setValue: uniqueActionType('[ebay-token] Set eBay token')
};

export class EbaySetTokenAction implements PayloadAction {
  readonly type = EbaySetTokenActionTypes.setValue;

  constructor(public payload: SetValuePayload<OutputTokenResponse>) {
  }
}
