import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SendByIdPayload,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { UserPayload } from '../models/user-payload';

export const UserAddActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[user] Add'),
  requestFail: uniqueActionType('[user] Add fail'),
  requestSuccess: uniqueActionType('[user] Add success'),
  requestClearErrors: uniqueActionType('[user] Add clear errors'),
  requestClear: uniqueActionType('[user] Add clear'),
};

export class UserAddAction implements PayloadAction {
  readonly type = UserAddActionTypes.request;

  constructor(public payload: SendByIdPayload<UserPayload>) {
  }
}

export class UserAddFailAction implements PayloadAction {
  readonly type = UserAddActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class UserAddSuccessAction implements PayloadAction {
  readonly type = UserAddActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class UserAddClearErrorsAction implements PayloadAction {
  readonly type = UserAddActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class UserAddClearAction implements PayloadAction {
  readonly type = UserAddActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
