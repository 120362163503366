import {
  ByIdPayload,
  FailByIdPayload,
  ListReducerTypes,
  NormalizedList,
  PayloadAction,
  RemoveIdByIdPayload,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';

export const ArticleImageContentAddDoneListActionTypes: ListReducerTypes = {
  requestFail: uniqueActionType('[article/image-content] Add done list fail'),
  requestSuccess: uniqueActionType('[article/image-content] Add done list success'),
  requestClearErrors: uniqueActionType('[article/image-content] Add done list clear errors'),
  requestClear: uniqueActionType('[article/image-content] Add done list clear'),
  removeId: uniqueActionType('[article/image-content] Add done list remove id')
};

export class ArticleImageContentAddDoneListFailAction implements PayloadAction {
  readonly type = ArticleImageContentAddDoneListActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class ArticleImageContentAddDoneListSuccessAction implements PayloadAction {
  readonly type = ArticleImageContentAddDoneListActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedList>) {
  }
}

export class ArticleImageContentAddDoneListClearErrorsAction implements PayloadAction {
  readonly type = ArticleImageContentAddDoneListActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class ArticleImageContentAddDoneListClearAction implements PayloadAction {
  readonly type = ArticleImageContentAddDoneListActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}

export class ArticleImageContentAddDoneListRemoveIdAction implements PayloadAction {
  readonly type = ArticleImageContentAddDoneListActionTypes.removeId;

  constructor(public payload: RemoveIdByIdPayload) {
  }
}
