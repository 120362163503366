import {
  ByIdPayload,
  FailByIdPayload,
  OperationReducerTypes,
  PayloadAction,
  uniqueActionType
} from '@industi/ngx-common';

export const CarbonFormDeleteActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[carbon/form] Delete'),
  requestFail: uniqueActionType('[carbon/form] Delete fail'),
  requestSuccess: uniqueActionType('[carbon/form] Delete success'),
  requestClearErrors: uniqueActionType('[carbon/form] Delete clear errors'),
  requestClear: uniqueActionType('[carbon/form] Delete clear')
};

export class CarbonFormDeleteAction implements PayloadAction {
  readonly type = CarbonFormDeleteActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class CarbonFormDeleteFailAction implements PayloadAction {
  readonly type = CarbonFormDeleteActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class CarbonFormDeleteSuccessAction implements PayloadAction {
  readonly type = CarbonFormDeleteActionTypes.requestSuccess;

  constructor(public payload: ByIdPayload) {
  }
}

export class CarbonFormDeleteClearErrorsAction implements PayloadAction {
  readonly type = CarbonFormDeleteActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class CarbonFormDeleteClearAction implements PayloadAction {
  readonly type = CarbonFormDeleteActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
