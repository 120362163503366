import {
  ByIdPayload,
  FailByIdPayload,
  OperationReducerTypes,
  PayloadAction,
  uniqueActionType
} from '@industi/ngx-common';

export const DictionaryMaterialExploitationDeleteActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[dictionary/material-exploitation] Delete'),
  requestFail: uniqueActionType('[dictionary/material-exploitation] Delete fail'),
  requestSuccess: uniqueActionType('[dictionary/material-exploitation] Delete success'),
  requestClearErrors: uniqueActionType('[dictionary/material-exploitation] Delete clear errors'),
  requestClear: uniqueActionType('[dictionary/material-exploitation] Delete clear')
};

export class DictionaryMaterialExploitationDeleteAction implements PayloadAction {
  readonly type = DictionaryMaterialExploitationDeleteActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class DictionaryMaterialExploitationDeleteFailAction implements PayloadAction {
  readonly type = DictionaryMaterialExploitationDeleteActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class DictionaryMaterialExploitationDeleteSuccessAction implements PayloadAction {
  readonly type = DictionaryMaterialExploitationDeleteActionTypes.requestSuccess;

  constructor(public payload: ByIdPayload) {
  }
}

export class DictionaryMaterialExploitationDeleteClearErrorsAction implements PayloadAction {
  readonly type = DictionaryMaterialExploitationDeleteActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class DictionaryMaterialExploitationDeleteClearAction implements PayloadAction {
  readonly type = DictionaryMaterialExploitationDeleteActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
