import {
  ByIdPayload,
  FailByIdPayload,
  OperationReducerTypes,
  PayloadAction,
  uniqueActionType
} from '@industi/ngx-common';

export const ArticleImageContentDeleteActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[article/image-content] Delete'),
  requestFail: uniqueActionType('[article/image-content] Delete fail'),
  requestSuccess: uniqueActionType('[article/image-content] Delete success'),
  requestClearErrors: uniqueActionType('[article/image-content] Delete clear errors'),
  requestClear: uniqueActionType('[article/image-content] Delete clear')
};

export class ArticleImageContentDeleteAction implements PayloadAction {
  readonly type = ArticleImageContentDeleteActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class ArticleImageContentDeleteFailAction implements PayloadAction {
  readonly type = ArticleImageContentDeleteActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class ArticleImageContentDeleteSuccessAction implements PayloadAction {
  readonly type = ArticleImageContentDeleteActionTypes.requestSuccess;

  constructor(public payload: ByIdPayload) {
  }
}

export class ArticleImageContentDeleteClearErrorsAction implements PayloadAction {
  readonly type = ArticleImageContentDeleteActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class ArticleImageContentDeleteClearAction implements PayloadAction {
  readonly type = ArticleImageContentDeleteActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
