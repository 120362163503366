import {
  ByIdPayload,
  FailByIdPayload,
  OperationReducerTypes,
  PayloadAction,
  uniqueActionType
} from '@industi/ngx-common';

export const DictionaryProcessDeleteActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[dictionary/process] Delete'),
  requestFail: uniqueActionType('[dictionary/process] Delete fail'),
  requestSuccess: uniqueActionType('[dictionary/process] Delete success'),
  requestClearErrors: uniqueActionType('[dictionary/process] Delete clear errors'),
  requestClear: uniqueActionType('[dictionary/process] Delete clear')
};

export class DictionaryProcessDeleteAction implements PayloadAction {
  readonly type = DictionaryProcessDeleteActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class DictionaryProcessDeleteFailAction implements PayloadAction {
  readonly type = DictionaryProcessDeleteActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class DictionaryProcessDeleteSuccessAction implements PayloadAction {
  readonly type = DictionaryProcessDeleteActionTypes.requestSuccess;

  constructor(public payload: ByIdPayload) {
  }
}

export class DictionaryProcessDeleteClearErrorsAction implements PayloadAction {
  readonly type = DictionaryProcessDeleteActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class DictionaryProcessDeleteClearAction implements PayloadAction {
  readonly type = DictionaryProcessDeleteActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
