import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, first, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { getInstanceLocale } from '../../instance/instance.reducer';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import { ByKey, NormalizedSingle } from '@industi/ngx-common';
import {
  ItemImageDeleteAction,
  ItemImageDeleteActionTypes,
  ItemImageDeleteFailAction,
  ItemImageDeleteSuccessAction
} from './actions/delete.actions';
import { ItemImageService } from './image.service';
import { ItemImage } from './models/item-image';
import { denormalize } from 'normalizr';
import { Entities } from '../../entity/entities';
import { EntityTypes } from '../../entity/entity-types';
import {
  ItemImageAddPendingListActionTypes,
  ItemImageAddPendingListRemoveIdAction,
  ItemImageAddPendingListSuccessAction
} from './actions/add-pending-list.actions';
import {
  ItemImageGalleryAddAction,
  ItemImageGalleryAddActionTypes,
  ItemImageGalleryAddSuccessAction
} from './actions/add-gallery.actions';
import { ItemImageAddDoneListActionTypes, ItemImageAddDoneListSuccessAction } from './actions/add-done-list.actions';
import { createItemImageAddPendingList } from './selectors/get-add-photo-pending-list.selectors';
import {
  ItemImageLoadDetailsAction,
  ItemImageLoadDetailsActionTypes,
  ItemImageLoadDetailsFailAction,
  ItemImageLoadDetailsSuccessAction
} from './actions/load-details.actions';

@Injectable()
export class ItemImageEffects {

  @Effect({ dispatch: false })
  preparePhotoToSend$ = this.actions$.pipe(
    ofType(ItemImageAddPendingListActionTypes.requestSuccess),
    map((action: ItemImageAddPendingListSuccessAction) => action.payload),
    tap(({ id, res }) => {
      const denormalized: ItemImage[] = denormalize(res.result, [Entities[EntityTypes.image]], res.entities);

      if (denormalized && denormalized.length) {
        this.store.dispatch(new ItemImageGalleryAddAction({ id, data: denormalized[0] }));
        this.store.dispatch(new ItemImageAddPendingListRemoveIdAction({ id, removeId: denormalized[0].id }));
      } else {
        this.store.dispatch(new ItemImageGalleryAddSuccessAction({ id }));
      }
    })
  );

  @Effect()
  addPhotoGallery$ = this.actions$.pipe(
    ofType(ItemImageGalleryAddActionTypes.request),
    map((action: ItemImageGalleryAddAction) => action.payload),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([{ id, data }, locale]) => this.service.add(locale, data).pipe(
      map((res: NormalizedSingle) => new ItemImageAddDoneListSuccessAction({
        id,
        res: { result: [res.result], entities: res.entities }
      })),
      catchError(() => of(new ItemImageAddDoneListSuccessAction({ id, res: { result: [data.id] } })))
    ))
  );


  @Effect({ dispatch: false })
  donePhotoListSuccess$ = this.actions$.pipe(
    ofType(ItemImageAddDoneListActionTypes.requestSuccess),
    map((action: ItemImageAddDoneListSuccessAction) => action.payload),
    switchMap(({ id }) => this.store.pipe(
      select(createItemImageAddPendingList(id)),
      map((data: ItemImage[]) => ({ id, data })),
      first()
    )),
    tap(({ id, data }) => {
      if (data && data.length) {
        this.store.dispatch(new ItemImageGalleryAddAction({ id, data: data[0] }));
        this.store.dispatch(new ItemImageAddPendingListRemoveIdAction({ id, removeId: data[0].id }));
      } else {
        this.store.dispatch(new ItemImageGalleryAddSuccessAction({ id }));
      }
    })
  );

  @Effect()
  loadDetails$ = this.actions$.pipe(
    ofType(ItemImageLoadDetailsActionTypes.request),
    map((action: ItemImageLoadDetailsAction) => action.payload.id),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([id, locale]) => this.service.details(locale, id).pipe(
      map((res: NormalizedSingle) => new ItemImageLoadDetailsSuccessAction({ id, res })),
      catchError((errors: HttpErrorResponse) => of(new ItemImageLoadDetailsFailAction({ id, errors })))
    ))
  );

  @Effect()
  delete$ = this.actions$.pipe(
    ofType(ItemImageDeleteActionTypes.request),
    map((action: ItemImageDeleteAction) => action.payload.id),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([id, locale]) => this.service.delete(locale, id).pipe(
      map(() => new ItemImageDeleteSuccessAction({ id })),
      catchError((errors: HttpErrorResponse) => of(new ItemImageDeleteFailAction({ id, errors })))
    ))
  );

  constructor(
    private actions$: Actions,
    private store: Store<ByKey>,
    private service: ItemImageService
  ) {
  }
}
