import {
  ByIdPayload,
  ChangeParamsByIdPayload,
  FailByIdPayload,
  ListReducerTypes,
  NormalizedList,
  PayloadAction,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { SymfonyPaginationParams } from '../../symfony/list/models/symfony-pagination-params';

export const WarehouseLoadListActionTypes: ListReducerTypes = {
  request: uniqueActionType('[warehouse] Load list'),
  requestFail: uniqueActionType('[warehouse] Load list fail'),
  requestSuccess: uniqueActionType('[warehouse] Load list success'),
  requestClear: uniqueActionType('[warehouse] Load list clear'),
  changeParams: uniqueActionType('[warehouse] Load list change params'),
  clearParams: uniqueActionType('[warehouse] Load list clear params'),
  clearIds: uniqueActionType('[warehouse] Load list clear ids')
};

export class WarehouseLoadListAction implements PayloadAction {
  readonly type = WarehouseLoadListActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class WarehouseLoadListFailAction implements PayloadAction {
  readonly type = WarehouseLoadListActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class WarehouseLoadListSuccessAction implements PayloadAction {
  readonly type = WarehouseLoadListActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedList>) {
  }
}

export class WarehouseLoadListClearAction implements PayloadAction {
  readonly type = WarehouseLoadListActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}

export class WarehouseLoadListChangeParamsAction implements PayloadAction {
  readonly type = WarehouseLoadListActionTypes.changeParams;

  constructor(public payload: ChangeParamsByIdPayload<SymfonyPaginationParams>) {
  }
}

export class WarehouseLoadListClearParamsAction implements PayloadAction {
  readonly type = WarehouseLoadListActionTypes.clearParams;

  constructor(public payload: ByIdPayload) {
  }
}


export class WarehouseLoadListClearIdsAction implements PayloadAction {
  readonly type = WarehouseLoadListActionTypes.clearIds;

  constructor(public payload: ByIdPayload) {
  }
}
