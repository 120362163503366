import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';

export const UserLoadDetailsActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[user] Load details'),
  requestFail: uniqueActionType('[user] Load details fail'),
  requestSuccess: uniqueActionType('[user] Load details success'),
  requestClearErrors: uniqueActionType('[user] Load details clear errors'),
  requestClear: uniqueActionType('[user] Load details clear')
};

export class UserLoadDetailsAction implements PayloadAction {
  readonly type = UserLoadDetailsActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class UserLoadDetailsFailAction implements PayloadAction {
  readonly type = UserLoadDetailsActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class UserLoadDetailsSuccessAction implements PayloadAction {
  readonly type = UserLoadDetailsActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class UserLoadDetailsClearAction implements PayloadAction {
  readonly type = UserLoadDetailsActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}

export class UserLoadDetailsClearErrorsAction implements PayloadAction {
  readonly type = UserLoadDetailsActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}
