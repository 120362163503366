import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SendByIdPayload,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { ClientPayload } from '../models/client-payload';

export const ClientAddActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[client] Add'),
  requestFail: uniqueActionType('[client] Add fail'),
  requestSuccess: uniqueActionType('[client] Add success'),
  requestClearErrors: uniqueActionType('[client] Add clear errors'),
  requestClear: uniqueActionType('[client] Add clear'),
};

export class ClientAddAction implements PayloadAction {
  readonly type = ClientAddActionTypes.request;

  constructor(public payload: SendByIdPayload<ClientPayload>) {
  }
}

export class ClientAddFailAction implements PayloadAction {
  readonly type = ClientAddActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class ClientAddSuccessAction implements PayloadAction {
  readonly type = ClientAddActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class ClientAddClearErrorsAction implements PayloadAction {
  readonly type = ClientAddActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class ClientAddClearAction implements PayloadAction {
  readonly type = ClientAddActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
