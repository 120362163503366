import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';

export const ServiceProjectLoadDetailsActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[service/project] Load details'),
  requestFail: uniqueActionType('[service/project] Load details fail'),
  requestSuccess: uniqueActionType('[service/project] Load details success'),
  requestClearErrors: uniqueActionType('[service/project] Load details clear errors'),
  requestClear: uniqueActionType('[service/project] Load details clear')
};

export class ServiceProjectLoadDetailsAction implements PayloadAction {
  readonly type = ServiceProjectLoadDetailsActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class ServiceProjectLoadDetailsFailAction implements PayloadAction {
  readonly type = ServiceProjectLoadDetailsActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class ServiceProjectLoadDetailsSuccessAction implements PayloadAction {
  readonly type = ServiceProjectLoadDetailsActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class ServiceProjectLoadDetailsClearAction implements PayloadAction {
  readonly type = ServiceProjectLoadDetailsActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}

export class ServiceProjectLoadDetailsClearErrorsAction implements PayloadAction {
  readonly type = ServiceProjectLoadDetailsActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}
