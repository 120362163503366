import {
  ByIdPayload,
  FailByIdPayload,
  OperationReducerTypes,
  PayloadAction,
  SendByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { OfferImagePayload } from '../models/offer-image-payload';

export const OfferImageGalleryAddActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[offer/image] Add gallery photo'),
  requestFail: uniqueActionType('[offer/image] Add gallery photo fail'),
  requestSuccess: uniqueActionType('[offer/image] Add gallery photo success'),
  requestClearErrors: uniqueActionType('[offer/image] Add gallery photo clear errors'),
  requestClear: uniqueActionType('[offer/image] Add gallery photo clear'),
};

export class OfferImageGalleryAddAction implements PayloadAction {
  readonly type = OfferImageGalleryAddActionTypes.request;

  constructor(public payload: SendByIdPayload<OfferImagePayload>) {
  }
}

export class OfferImageGalleryAddFailAction implements PayloadAction {
  readonly type = OfferImageGalleryAddActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class OfferImageGalleryAddSuccessAction implements PayloadAction {
  readonly type = OfferImageGalleryAddActionTypes.requestSuccess;

  constructor(public payload: ByIdPayload) {
  }
}

export class OfferImageGalleryAddClearErrorsAction implements PayloadAction {
  readonly type = OfferImageGalleryAddActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class OfferImageGalleryAddClearAction implements PayloadAction {
  readonly type = OfferImageGalleryAddActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
