import {
  ByIdPayload,
  FailByIdPayload,
  OperationReducerTypes,
  PayloadAction,
  uniqueActionType
} from '@industi/ngx-common';

export const DictionaryBrandImageDeleteActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[dictionary/brand] Delete image'),
  requestFail: uniqueActionType('[dictionary/brand] Delete image fail'),
  requestSuccess: uniqueActionType('[dictionary/brand] Delete image success'),
  requestClearErrors: uniqueActionType('[dictionary/brand] Delete image clear errors'),
  requestClear: uniqueActionType('[dictionary/brand] Delete image clear')
};

export class DictionaryBrandImageDeleteAction implements PayloadAction {
  readonly type = DictionaryBrandImageDeleteActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class DictionaryBrandImageDeleteFailAction implements PayloadAction {
  readonly type = DictionaryBrandImageDeleteActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class DictionaryBrandImageDeleteSuccessAction implements PayloadAction {
  readonly type = DictionaryBrandImageDeleteActionTypes.requestSuccess;

  constructor(public payload: ByIdPayload) {
  }
}

export class DictionaryBrandImageDeleteClearErrorsAction implements PayloadAction {
  readonly type = DictionaryBrandImageDeleteActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class DictionaryBrandImageDeleteClearAction implements PayloadAction {
  readonly type = DictionaryBrandImageDeleteActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
