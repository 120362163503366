import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LibAuthEffectsModule, LibAuthService } from 'app-store-auth';
import { AuthService } from './auth.service';
import { EffectsModule } from '@ngrx/effects';
import { AuthEffects } from './auth.effects';

@NgModule({
  imports: [
    CommonModule,
    LibAuthEffectsModule,
    EffectsModule.forFeature([AuthEffects])
  ],
  providers: [
    { provide: LibAuthService, useClass: AuthService }
  ]
})
export class AuthEffectsModule {
}
