import {
  ByIdPayload,
  ChangeParamsByIdPayload,
  FailByIdPayload,
  ListReducerTypes,
  NormalizedList,
  PayloadAction,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { DictionaryProcessListParams } from '../models/process-list-params';

export const DictionaryProcessLoadListActionTypes: ListReducerTypes = {
  request: uniqueActionType('[dictionary/process] Load list'),
  requestFail: uniqueActionType('[dictionary/process] Load list fail'),
  requestSuccess: uniqueActionType('[dictionary/process] Load list success'),
  requestClear: uniqueActionType('[dictionary/process] Load list clear'),
  changeParams: uniqueActionType('[dictionary/process] Load list change params'),
  clearParams: uniqueActionType('[dictionary/process] Load list clear params'),
  clearIds: uniqueActionType('[dictionary/process] Load list clear ids')
};

export class DictionaryProcessLoadListAction implements PayloadAction {
  readonly type = DictionaryProcessLoadListActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class DictionaryProcessLoadListFailAction implements PayloadAction {
  readonly type = DictionaryProcessLoadListActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class DictionaryProcessLoadListSuccessAction implements PayloadAction {
  readonly type = DictionaryProcessLoadListActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedList>) {
  }
}

export class DictionaryProcessLoadListClearAction implements PayloadAction {
  readonly type = DictionaryProcessLoadListActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}

export class DictionaryProcessLoadListChangeParamsAction implements PayloadAction {
  readonly type = DictionaryProcessLoadListActionTypes.changeParams;

  constructor(public payload: ChangeParamsByIdPayload<DictionaryProcessListParams>) {
  }
}

export class DictionaryProcessLoadListClearParamsAction implements PayloadAction {
  readonly type = DictionaryProcessLoadListActionTypes.clearParams;

  constructor(public payload: ByIdPayload) {
  }
}


export class DictionaryProcessLoadListClearIdsAction implements PayloadAction {
  readonly type = DictionaryProcessLoadListActionTypes.clearIds;

  constructor(public payload: ByIdPayload) {
  }
}
