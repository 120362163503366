import { Injectable } from '@angular/core';
import { DictionaryBrandService } from './brand.service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { ByIdPayload, ByKey, IdType, NormalizedList, NormalizedSingle } from '@industi/ngx-common';
import { catchError, first, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { getInstanceLocale } from '../../instance/instance.reducer';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import {
  DictionaryBrandLoadListAction,
  DictionaryBrandLoadListActionTypes,
  DictionaryBrandLoadListClearIdsAction,
  DictionaryBrandLoadListFailAction,
  DictionaryBrandLoadListSuccessAction
} from './actions/list.actions';
import { DictionaryBrandListParams } from './models/brand-list-params';
import { createDictionaryBrandListParams } from './selectors/get-load-list.selectors';
import {
  DictionaryBrandAddAction,
  DictionaryBrandAddActionTypes,
  DictionaryBrandAddFailAction,
  DictionaryBrandAddSuccessAction
} from './actions/add.actions';
import {
  DictionaryBrandEditAction,
  DictionaryBrandEditActionTypes,
  DictionaryBrandEditFailAction,
  DictionaryBrandEditSuccessAction
} from './actions/edit.actions';
import {
  DictionaryBrandLoadDetailsAction,
  DictionaryBrandLoadDetailsActionTypes,
  DictionaryBrandLoadDetailsFailAction,
  DictionaryBrandLoadDetailsSuccessAction
} from './actions/load-details.actions';
import {
  DictionaryBrandDeleteAction,
  DictionaryBrandDeleteActionTypes,
  DictionaryBrandDeleteFailAction,
  DictionaryBrandDeleteSuccessAction
} from './actions/delete.actions';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ResponseStatus } from '../../../shared/enums/response-status.enum';
import { LibSnackbarComponent } from '@industi/ngx-modules';
import { AppGlobal } from '../../../shared/app-global';
import { AlertUtil } from '../../../shared/utils/alert.util';
import {
  DictionaryBrandLogoAddAction,
  DictionaryBrandLogoAddActionTypes,
  DictionaryBrandLogoAddFailAction,
  DictionaryBrandLogoAddSuccessAction
} from './actions/add-logo.actions';
import {
  DictionaryBrandImageDeleteAction,
  DictionaryBrandImageDeleteActionTypes,
  DictionaryBrandImageDeleteFailAction,
  DictionaryBrandImageDeleteSuccessAction
} from './actions/delete-image.actions';
import {
  DictionaryBrandCategoryImageAddAction,
  DictionaryBrandCategoryImageAddActionTypes,
  DictionaryBrandCategoryImageAddFailAction,
  DictionaryBrandCategoryImageAddSuccessAction
} from './actions/add-category-image.actions';

@Injectable()
export class DictionaryBrandEffects {

  @Effect()
  loadList$ = this.actions$.pipe(
    ofType(DictionaryBrandLoadListActionTypes.request),
    map((action: DictionaryBrandLoadListAction) => action.payload),
    switchMap((payload: ByIdPayload) => this.store.pipe(
      select(createDictionaryBrandListParams(payload.id)),
      map((params: DictionaryBrandListParams) => [payload.id, params] as [IdType, DictionaryBrandListParams]),
      first()
    )),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([[ id, params ], locale]) => this.service.loadList(locale, params).pipe(
      mergeMap((res: NormalizedList) => [
        new DictionaryBrandLoadListClearIdsAction({ id }),
        new DictionaryBrandLoadListSuccessAction({ id, res })]),
      catchError((errors: HttpErrorResponse) => of(new DictionaryBrandLoadListFailAction({ id, errors })))
    ))
  );

  @Effect()
  add$ = this.actions$.pipe(
    ofType(DictionaryBrandAddActionTypes.request),
    map((action: DictionaryBrandAddAction) => action.payload),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([{ id, data }, locale]) => this.service.add(locale, data).pipe(
      map((res: NormalizedSingle) => new DictionaryBrandAddSuccessAction({ res, id })),
      catchError((errors: HttpErrorResponse) => of(new DictionaryBrandAddFailAction({ errors, id })))
    ))
  );

  @Effect()
  addLogo$ = this.actions$.pipe(
    ofType(DictionaryBrandLogoAddActionTypes.request),
    map((action: DictionaryBrandLogoAddAction) => action.payload),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([{ id, data }, locale]) => this.service.addLogo(locale, data).pipe(
      map((res: NormalizedSingle) => new DictionaryBrandLogoAddSuccessAction({ res, id })),
      catchError((errors: HttpErrorResponse) => of(new DictionaryBrandLogoAddFailAction({ errors, id })))
    ))
  );

  @Effect()
  addCategoryImage$ = this.actions$.pipe(
    ofType(DictionaryBrandCategoryImageAddActionTypes.request),
    map((action: DictionaryBrandCategoryImageAddAction) => action.payload),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([{ id, data }, locale]) => this.service.addCategoryImage(locale, data).pipe(
      map((res: NormalizedSingle) => new DictionaryBrandCategoryImageAddSuccessAction({ res, id })),
      catchError((errors: HttpErrorResponse) => of(new DictionaryBrandCategoryImageAddFailAction({ errors, id })))
    ))
  );

  @Effect()
  edit$ = this.actions$.pipe(
    ofType(DictionaryBrandEditActionTypes.request),
    map((action: DictionaryBrandEditAction) => action.payload),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([{ id, data }, locale]) => this.service.edit(locale, data, id).pipe(
      map((res: NormalizedSingle) => new DictionaryBrandEditSuccessAction({ res, id })),
      catchError((errors: HttpErrorResponse) => of(new DictionaryBrandEditFailAction({ errors, id })))
    ))
  );

  @Effect()
  loadDetails$ = this.actions$.pipe(
    ofType(DictionaryBrandLoadDetailsActionTypes.request),
    map((action: DictionaryBrandLoadDetailsAction) => action.payload.id),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([id, locale]) => this.service.details(locale, id).pipe(
      map((res: NormalizedSingle) => new DictionaryBrandLoadDetailsSuccessAction({ id, res })),
      catchError((errors: HttpErrorResponse) => of(new DictionaryBrandLoadDetailsFailAction({ id, errors })))
    ))
  );

  @Effect()
  delete$ = this.actions$.pipe(
    ofType(DictionaryBrandDeleteActionTypes.request),
    map((action: DictionaryBrandDeleteAction) => action.payload.id),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([id, locale]) => this.service.delete(locale, id).pipe(
      map(() => new DictionaryBrandDeleteSuccessAction({ id })),
      catchError((errors: HttpErrorResponse) => of(new DictionaryBrandDeleteFailAction({ id, errors })))
    ))
  );

  @Effect()
  deleteImage$ = this.actions$.pipe(
    ofType(DictionaryBrandImageDeleteActionTypes.request),
    map((action: DictionaryBrandImageDeleteAction) => action.payload.id),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([id, locale]) => this.service.deleteImage(locale, id).pipe(
      map(() => new DictionaryBrandImageDeleteSuccessAction({ id })),
      catchError((errors: HttpErrorResponse) => of(new DictionaryBrandImageDeleteFailAction({ id, errors })))
    ))
  );

  @Effect({ dispatch: false })
  crudFail$ = this.actions$.pipe(
    ofType(
      DictionaryBrandAddActionTypes.requestFail,
      DictionaryBrandEditActionTypes.requestFail
    ),
    map((action: DictionaryBrandAddFailAction | DictionaryBrandEditFailAction) => action.payload.errors),
    tap((data: HttpErrorResponse) => {
      if (data?.status === ResponseStatus.BAD_REQUEST || data?.status === ResponseStatus.UNPROCESSABLE_ENTITY) {
        this.snackBar.openFromComponent(LibSnackbarComponent, {
          data: {
            message: AlertUtil.getControlTranslation(data.error.detail),
            type: 'error'
          },
          duration: AppGlobal.defaultSnackBarDuration
        });
      }
    })
  );

  constructor(
    private actions$: Actions,
    private store: Store<ByKey>,
    private service: DictionaryBrandService,
    private snackBar: MatSnackBar
  ) {
  }
}
