import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BaseRxDirective, ByKey, IdType } from '@industi/ngx-common';
import { combineLatest, fromEvent, Observable } from 'rxjs';
import { getLoggedUserName, getLoggedUserRoles } from '../../store/auth/auth.reducer';
import { LibAuthSetTokenAction } from 'app-store-auth';
import { debounceTime, distinctUntilChanged, filter, first, map, takeUntil, withLatestFrom } from 'rxjs/operators';
import { AppMenuItem } from '../../shared/modules/app-menu/app-menu-item';
import { Role } from '../../store/auth/auth-roles';
import * as _ from 'lodash';
import { TemplateUtil } from '../../store/template/template.util';
import { APP_MENU } from '../../shared/modules/app-menu/app-menu.provider';
import { SetLocaleAdapterService } from '../../store/instance/adapters/set-locale-adapter.service';
import { Dictionary } from '../../shared/models/dictionary';
import { getTemplateSideNavOpened } from '../../store/template/template.reducer';
import {
  TemplateCloseSideNavAction,
  TemplateOpenSideNavAction,
  TemplateToggleSideNavAction
} from '../../store/template/actions/set-side-nav-opened.actions';
import { LibViewWidth } from '@industi/ngx-modules';
import { NavigationEnd, Router } from '@angular/router';
import { StartRouteInjectionToken, WINDOW } from '../../app.providers';
import { FormControl } from '@angular/forms';
import { AppGlobal } from '../../shared/app-global';
import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { GlobalSearchResultComponent } from '../../shared/components/global-search-result/global-search-result.component';
import { PartListAdapterService } from '../../store/part/adapters/list-adapter.service';
import { OverlayRef } from '@angular/cdk/overlay/overlay-ref';
import { ItemListAdapterService } from '../../store/item/common/adapters/list-adapter.service';
import { DiagramListAdapterService } from '../../store/diagram/common/adapters/list-adapter.service';

@Component({
  selector: 'app-toolbar-content-layout',
  templateUrl: './toolbar-content-layout.component.html',
  styleUrls: ['./toolbar-content-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarContentLayoutComponent extends BaseRxDirective {

  isSideNavOpened$: Observable<boolean>;
  sideNavMode$: Observable<'over' | 'push' | 'side'>;

  sideNavMenu$: Observable<AppMenuItem[]>;

  sitePermission$: Observable<Role[]>;

  locale$: Observable<any| string>;

  userName$: Observable<string>;
  userRoles$: Observable<Role[]>;

  searchControl = new FormControl(null);

  private overlayRef: OverlayRef = null;

  constructor(
    private store: Store<ByKey>,
    private localeAdapter: SetLocaleAdapterService,
    private appViewWidth: LibViewWidth,
    private router: Router,
    @Inject(APP_MENU) private allMenuItems: AppMenuItem[],
    @Inject(StartRouteInjectionToken) private startRoute: string[],
    @Inject(WINDOW) private window: Window,
    private overlay: Overlay,
    private partListAdapter: PartListAdapterService,
    private itemListAdapter: ItemListAdapterService,
    private diagramListAdapter: DiagramListAdapterService
  ) {
    super();
  }

  protected setUpCommonSelectors() {
    this.sitePermission$ = this.store.pipe(select(getLoggedUserRoles));
    this.isSideNavOpened$ = this.store.pipe(select(getTemplateSideNavOpened));
  }

  protected setUpComponentSelectors() {
    this.sideNavMenu$ = this.sitePermission$.pipe(
      distinctUntilChanged(_.isEqual),
      filter((permission: Role[]) => !!permission),
      map((permission: Role[]) => TemplateUtil.getMenu(permission, this.allMenuItems))
    );
    this.locale$ = this.store.pipe(select(this.localeAdapter.getValueSelector()));
    this.userName$ = this.store.pipe(select(getLoggedUserName));
    this.userRoles$ = this.store.pipe(select(getLoggedUserRoles));

    this.sideNavMode$ = combineLatest([
      this.appViewWidth.small$,
      this.appViewWidth.medium$,
    ]).pipe(
      map(([sm, md]) => (sm || md) ? 'over' : 'side')
    );

    this.router.events.pipe(
      filter((event) => event && event instanceof NavigationEnd),
      withLatestFrom(this.appViewWidth.small$, this.appViewWidth.medium$),
      takeUntil(this.destroyed$)
    ).subscribe(([event, sm, md]) => {
      if (sm || md) {
        this.store.dispatch(new TemplateCloseSideNavAction());
      }

      if (this.overlayRef) {
        this.clearOverlayWithResult();
      }
    });

    this.searchControl.valueChanges.pipe(
      debounceTime(AppGlobal.filterInputDebounceTime),
      distinctUntilChanged(_.isEqual),
      filter((value: string) => !!value),
      takeUntil(this.destroyed$)
    ).subscribe((value: string) => {
      this.store.dispatch(this.partListAdapter.changeParamsAction(AppGlobal.addId, { code: value }));
      this.store.dispatch(this.itemListAdapter.changeParamsAction(AppGlobal.addId, { partCode: value }));
      this.store.dispatch(this.diagramListAdapter.changeParamsAction(AppGlobal.addId, { partCode: value }));
      this.store.dispatch(this.partListAdapter.loadListAction(AppGlobal.addId));
      this.store.dispatch(this.itemListAdapter.loadListAction(AppGlobal.addId));
      this.store.dispatch(this.diagramListAdapter.loadListAction(AppGlobal.addId));
    });

    // TODO: Close overlay if focus out from window. Material bug
    fromEvent(this.window, 'blur').pipe(
      filter(() => !!this.overlayRef),
      takeUntil(this.destroyed$)
    ).subscribe((event) => {
      this.clearOverlayWithResult();
    });
  }

  protected setUpDataFlow() {
    this.initSideNavPosition();
  }

  protected async clearState(): Promise<void> {
  }

  private clearOverlayWithResult(): void {
    this.overlayRef.detach();
    this.searchControl.reset();

    this.store.dispatch(this.partListAdapter.clearAction(AppGlobal.addId));
    this.store.dispatch(this.itemListAdapter.clearAction(AppGlobal.addId));
    this.store.dispatch(this.diagramListAdapter.clearAction(AppGlobal.addId));
  }

  onLogout(): void {
    this.store.dispatch(new LibAuthSetTokenAction({ value: null }));
    this.router.navigate(this.startRoute);
  }

  onChangeLocale(locale: string): void {
    this.store.dispatch(this.localeAdapter.setValueAction(locale));
  }

  onToggleSideNavClick(): void {
    this.isSideNavOpened$.pipe(
      first(),
      takeUntil(this.destroyed$)
    ).subscribe((opened: boolean) => {
      this.store.dispatch(new TemplateToggleSideNavAction(opened));
    });
  }

  onSidenavClose(): void {
    this.store.dispatch(new TemplateCloseSideNavAction());
  }

  private async initSideNavPosition(): Promise<void> {
    const sm = await this.appViewWidth.small$.pipe(first()).toPromise<boolean>();
    const md = await this.appViewWidth.medium$.pipe(first()).toPromise<boolean>();
    if (!(sm || md)) {
      this.store.dispatch(new TemplateOpenSideNavAction());
    }

    combineLatest([
      this.appViewWidth.small$.pipe(distinctUntilChanged()),
      this.appViewWidth.medium$.pipe(distinctUntilChanged()),
    ]).pipe(
      filter(([small, medium]) => !!(small || medium)),
      takeUntil(this.destroyed$)
    ).subscribe(() => {
      this.store.dispatch(new TemplateCloseSideNavAction());
    });
  }

  trackById(item: Dictionary): IdType {
    return item && item.id;
  }

  onCreateOverlay(): void {
    this.overlayRef = this.overlay.create({
      hasBackdrop: true,
      backdropClass: 'app-backdrop-transparent'
    });
    const previewPortal = new ComponentPortal(GlobalSearchResultComponent);

    this.overlayRef.attach(previewPortal);
    this.overlayRef.backdropClick().subscribe(() => {
      this.overlayRef.detach();
    });
  }

}
