import { Inject, Injectable } from '@angular/core';
import { API_URL, EBAY_API_URL } from '../app-store-providers';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IdType, NormalizedSingle, StoreUtil } from '@industi/ngx-common';
import { map } from 'rxjs/operators';
import { normalize } from 'normalizr';
import { Entities } from '../entity/entities';
import { EntityTypes } from '../entity/entity-types';
import { EbayConfig } from './models/ebay-config';
import { EbayPayload } from './models/ebay-payload';
import { OutputTokenResponse } from '../auth/models/output-token-response';
import { EbayCompatibility } from './models/ebay-compatibility';
import { EbayCompatibilityParams } from './models/ebay-compatibility-params';

@Injectable({
  providedIn: 'root'
})
export class EbayService {

  constructor(
    @Inject(API_URL) private apiUrl: string,
    @Inject(EBAY_API_URL) private ebayApiUrl: string,
    private http: HttpClient
  ) { }

  configuration(locale: string, id: IdType): Observable<NormalizedSingle> {
    const url = `${this.apiUrl}/${locale}/api/ebay-configuration/global`;
    return this.http.get<EbayConfig>(url).pipe(
      map((res: EbayConfig) => normalize({ ...res, id }, Entities[EntityTypes.ebay]))
    );
  }

  offerConfiguration(locale: string, id: IdType): Observable<NormalizedSingle> {
    const url = `${this.apiUrl}/${locale}/api/ebay-configuration/offer`;
    return this.http.get<EbayConfig>(url).pipe(
      map((res: EbayConfig) => normalize({ ...res, id }, Entities[EntityTypes.ebay]))
    );
  }

  configurationAdd(locale: string, payload: EbayPayload): Observable<any> {
    const url = `${this.apiUrl}/${locale}/api/ebay-configuration/global`;
    return this.http.post(url, payload);
  }

  configurationRefresh(locale: string): Observable<any> {
    const url = `${this.apiUrl}/${locale}/api/ebay-configuration`;
    return this.http.post(url, {});
  }

  token(locale: string): Observable<any> {
    const url = `${this.apiUrl}/${locale}/api/ebay-token`;
    return this.http.get(url).pipe(
      map((token: OutputTokenResponse) => token)
    );
  }

  loadCompatibilityProperties(id: IdType): Observable<NormalizedSingle> {
    const url = `${this.ebayApiUrl}/get_compatibility_properties?category_id=${id}`;
    return this.http.get(url).pipe(
      map((res: EbayCompatibility) => normalize({ ...res, id }, Entities[EntityTypes.ebayCompatibility]))
    );
  }

  loadCompatibilityPropertyValues(id: IdType, context: string): Observable<NormalizedSingle> {
    const url = `${this.ebayApiUrl}/get_compatibility_property_values?category_id=${id}&compatibility_property=${context}`;
    return this.http.get(url).pipe(
      map((res: EbayCompatibility) => normalize(
        { ...res, id: StoreUtil.keyByIdAndContext({ id, context }) },
        Entities[EntityTypes.ebayCompatibilityValue])
      )
    );
  }
}
