import { Inject, Injectable } from '@angular/core';
import { API_URL } from '../../app-store-providers';
import { HttpClient } from '@angular/common/http';
import { EntityTypes } from '../../entity/entity-types';
import { StoreBaseService } from '../../common/base.service';
import { ArticleListParams } from './models/article-list-params';
import { Article } from './models/article';
import { ArticlePayload } from './models/article-payload';

@Injectable({
  providedIn: 'root'
})
export class ArticleCommonService
  extends StoreBaseService<Article, ArticlePayload, ArticleListParams> {

  get entityType(): string {
    return EntityTypes.article;
  }

  get resourceName(): string {
    return 'article';
  }

  constructor(
    @Inject(API_URL) apiUrl: string,
    http: HttpClient
  ) {
    super(apiUrl, http);
  }
}
