import {
  ByIdPayload,
  ChangeParamsByIdPayload,
  FailByIdPayload,
  ListReducerTypes,
  NormalizedList,
  PayloadAction,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { DiscountGroupListParams } from '../models/discount-group-list-params';

export const DiscountGroupLoadListActionTypes: ListReducerTypes = {
  request: uniqueActionType('[discount-group] Load list'),
  requestFail: uniqueActionType('[discount-group] Load list fail'),
  requestSuccess: uniqueActionType('[discount-group] Load list success'),
  requestClear: uniqueActionType('[discount-group] Load list clear'),
  changeParams: uniqueActionType('[discount-group] Load list change params'),
  clearParams: uniqueActionType('[discount-group] Load list clear params'),
  clearIds: uniqueActionType('[discount-group] Load list clear ids')
};

export class DiscountGroupLoadListAction implements PayloadAction {
  readonly type = DiscountGroupLoadListActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class DiscountGroupLoadListFailAction implements PayloadAction {
  readonly type = DiscountGroupLoadListActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class DiscountGroupLoadListSuccessAction implements PayloadAction {
  readonly type = DiscountGroupLoadListActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedList>) {
  }
}

export class DiscountGroupLoadListClearAction implements PayloadAction {
  readonly type = DiscountGroupLoadListActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}

export class DiscountGroupLoadListChangeParamsAction implements PayloadAction {
  readonly type = DiscountGroupLoadListActionTypes.changeParams;

  constructor(public payload: ChangeParamsByIdPayload<DiscountGroupListParams>) {
  }
}

export class DiscountGroupLoadListClearParamsAction implements PayloadAction {
  readonly type = DiscountGroupLoadListActionTypes.clearParams;

  constructor(public payload: ByIdPayload) {
  }
}


export class DiscountGroupLoadListClearIdsAction implements PayloadAction {
  readonly type = DiscountGroupLoadListActionTypes.clearIds;

  constructor(public payload: ByIdPayload) {
  }
}
