import { Inject, Injectable } from '@angular/core';
import { API_URL } from '../app-store-providers';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { EntityTypes } from '../entity/entity-types';
import { Warehouse } from './models/warehouse';
import { WarehousePayload } from './models/warehouse-payload';
import { WarehouseListParams } from './models/warehouse-list-params';
import { Observable } from 'rxjs';
import { AppFile } from '../../shared/models/file';
import { map } from 'rxjs/operators';
import { StoreBaseService } from '../common/base.service';

@Injectable({
  providedIn: 'root'
})
export class WarehouseService
  extends StoreBaseService<Warehouse, WarehousePayload, WarehouseListParams> {

  get entityType(): string {
    return EntityTypes.warehouse;
  }

  get resourceName(): string {
    return 'warehouse';
  }

  constructor(
    @Inject(API_URL) apiUrl: string,
    http: HttpClient
  ) {
    super(apiUrl, http);
  }

  pdf(locale: string): Observable<AppFile> {
    const url = `${this.apiUrl}/${locale}/api/${this.resourceName}/generate-pdf`;
    return this.http.post<Blob>(url, {}, {
      responseType: 'blob' as 'json',
      observe: 'response'
    }).pipe(
      map((res: HttpResponse<Blob>) => ({ file: res.body }))
    );
  }
}
