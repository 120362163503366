import {
  ByIdPayload,
  ChangeParamsByIdPayload,
  FailByIdPayload,
  ListReducerTypes,
  NormalizedList,
  PayloadAction,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { ModelListParams } from '../models/model-list-params';

export const ModelLoadListActionTypes: ListReducerTypes = {
  request: uniqueActionType('[model] Load list'),
  requestFail: uniqueActionType('[model] Load list fail'),
  requestSuccess: uniqueActionType('[model] Load list success'),
  requestClear: uniqueActionType('[model] Load list clear'),
  changeParams: uniqueActionType('[model] Load list change params'),
  clearParams: uniqueActionType('[model] Load list clear params'),
  clearIds: uniqueActionType('[model] Load list clear ids')
};

export class ModelLoadListAction implements PayloadAction {
  readonly type = ModelLoadListActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class ModelLoadListFailAction implements PayloadAction {
  readonly type = ModelLoadListActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class ModelLoadListSuccessAction implements PayloadAction {
  readonly type = ModelLoadListActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedList>) {
  }
}

export class ModelLoadListClearAction implements PayloadAction {
  readonly type = ModelLoadListActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}

export class ModelLoadListChangeParamsAction implements PayloadAction {
  readonly type = ModelLoadListActionTypes.changeParams;

  constructor(public payload: ChangeParamsByIdPayload<ModelListParams>) {
  }
}

export class ModelLoadListClearParamsAction implements PayloadAction {
  readonly type = ModelLoadListActionTypes.clearParams;

  constructor(public payload: ByIdPayload) {
  }
}


export class ModelLoadListClearIdsAction implements PayloadAction {
  readonly type = ModelLoadListActionTypes.clearIds;

  constructor(public payload: ByIdPayload) {
  }
}
