import { IdType, SetValuePayload, SetValueReducerTypes, uniqueActionType } from '@industi/ngx-common';

export const ArticleBrandImageSelectLastAddedIdActionTypes: SetValueReducerTypes = {
  setValue: uniqueActionType('[article-brand/image] Select last added id'),
  clearValue: uniqueActionType('[article-brand/image] Clear last added id'),
};

export class ArticleBrandImageSelectLastAddedIdAction {
  readonly type = ArticleBrandImageSelectLastAddedIdActionTypes.setValue;

  constructor(public payload: SetValuePayload<IdType>) {
  }
}

export class ArticleBrandImageClearLastAddedIdAction {
  readonly type = ArticleBrandImageSelectLastAddedIdActionTypes.setValue;
  readonly payload = null;
}
