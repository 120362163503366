import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SendByIdPayload,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { ArticleVideoContentPayload } from '../models/video-content-payload';

export const ArticleVideoContentAddActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[article/video-content] Add'),
  requestFail: uniqueActionType('[article/video-content] Add fail'),
  requestSuccess: uniqueActionType('[article/video-content] Add success'),
  requestClearErrors: uniqueActionType('[article/video-content] Add clear errors'),
  requestClear: uniqueActionType('[article/video-content] Add clear'),
};

export class ArticleVideoContentAddAction implements PayloadAction {
  readonly type = ArticleVideoContentAddActionTypes.request;

  constructor(public payload: SendByIdPayload<ArticleVideoContentPayload>) {
  }
}

export class ArticleVideoContentAddFailAction implements PayloadAction {
  readonly type = ArticleVideoContentAddActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class ArticleVideoContentAddSuccessAction implements PayloadAction {
  readonly type = ArticleVideoContentAddActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class ArticleVideoContentAddClearErrorsAction implements PayloadAction {
  readonly type = ArticleVideoContentAddActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class ArticleVideoContentAddClearAction implements PayloadAction {
  readonly type = ArticleVideoContentAddActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
