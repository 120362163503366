import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SendByIdPayload,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { OfferPayload } from '../models/offer-payload';
import { FilesPayload } from '../../../common/models/files-payload';

export const OfferAddActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[offer] Add'),
  requestFail: uniqueActionType('[offer] Add fail'),
  requestSuccess: uniqueActionType('[offer] Add success'),
  requestClearErrors: uniqueActionType('[offer] Add clear errors'),
  requestClear: uniqueActionType('[offer] Add clear'),
};

export class OfferAddAction implements PayloadAction {
  readonly type = OfferAddActionTypes.request;

  constructor(public payload: SendByIdPayload<FilesPayload<OfferPayload>>) {
  }
}


export class OfferAddFailAction implements PayloadAction {
  readonly type = OfferAddActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class OfferAddSuccessAction implements PayloadAction {
  readonly type = OfferAddActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class OfferAddClearErrorsAction implements PayloadAction {
  readonly type = OfferAddActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class OfferAddClearAction implements PayloadAction {
  readonly type = OfferAddActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
