import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AppMenuItem } from './app-menu-item';
import { AppMenuLevelEnum } from './app-menu-level.enum';
import { Location } from '@angular/common';

@Component({
  selector: 'app-menu',
  templateUrl: './app-menu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppMenuComponent {

  @Input()
  menu: AppMenuItem[];

  @Input()
  menuLevel: AppMenuLevelEnum = AppMenuLevelEnum.ROOT;

  @Input()
  activeItemClass = 'app-menu-item-active';

  get containerClass(): string {
    return 'app-menu-level-' + this.menuLevel;
  }

  get itemClass(): string {
    return 'app-menu-item-level-' + this.menuLevel;
  }

  get iconClass(): string {
    return 'app-menu-item-icon-level-' + this.menuLevel;
  }

  constructor(protected location: Location) {
  }

  trackByPath(item: AppMenuItem): string {
    return item.path as string;
  }

  doesPathMatch(panelPath: string): boolean {
    const queryParamsStart = this.location.path().indexOf('?');
    const locationWithoutQueryParams = queryParamsStart > 0 ? this.location.path().substr(0, queryParamsStart) : this.location.path();
    const locationArr = locationWithoutQueryParams.split('/');
    locationArr.shift();

    let currentPath = '';
    let shouldExpand = false;

    locationArr.map((item) => {
      currentPath += '/' + item;
      if (panelPath === currentPath) {
        shouldExpand = true;
      }
    });

    return shouldExpand;
  }
}
