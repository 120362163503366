import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SendByIdPayload,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { DiscountGroupPayload } from '../models/discount-group-payload';

export const DiscountGroupAddActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[discount-group] Add'),
  requestFail: uniqueActionType('[discount-group] Add fail'),
  requestSuccess: uniqueActionType('[discount-group] Add success'),
  requestClearErrors: uniqueActionType('[discount-group] Add clear errors'),
  requestClear: uniqueActionType('[discount-group] Add clear'),
};

export class DiscountGroupAddAction implements PayloadAction {
  readonly type = DiscountGroupAddActionTypes.request;

  constructor(public payload: SendByIdPayload<DiscountGroupPayload>) {
  }
}

export class DiscountGroupAddFailAction implements PayloadAction {
  readonly type = DiscountGroupAddActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class DiscountGroupAddSuccessAction implements PayloadAction {
  readonly type = DiscountGroupAddActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class DiscountGroupAddClearErrorsAction implements PayloadAction {
  readonly type = DiscountGroupAddActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class DiscountGroupAddClearAction implements PayloadAction {
  readonly type = DiscountGroupAddActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
