import {
  ByIdPayload,
  ChangeParamsByIdPayload,
  FailByIdPayload,
  ListReducerTypes,
  NormalizedList,
  PayloadAction,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { SymfonyPaginationParams } from '../../../../symfony/list/models/symfony-pagination-params';

export const ServiceProjectItemLoadListActionTypes: ListReducerTypes = {
  request: uniqueActionType('[service/project-item] Load list'),
  requestFail: uniqueActionType('[service/project-item] Load list fail'),
  requestSuccess: uniqueActionType('[service/project-item] Load list success'),
  requestClear: uniqueActionType('[service/project-item] Load list clear'),
  changeParams: uniqueActionType('[service/project-item] Load list change params'),
  clearParams: uniqueActionType('[service/project-item] Load list clear params'),
  clearIds: uniqueActionType('[service/project-item] Load list clear ids')
};

export class ServiceProjectItemLoadListAction implements PayloadAction {
  readonly type = ServiceProjectItemLoadListActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class ServiceProjectItemLoadListFailAction implements PayloadAction {
  readonly type = ServiceProjectItemLoadListActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class ServiceProjectItemLoadListSuccessAction implements PayloadAction {
  readonly type = ServiceProjectItemLoadListActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedList>) {
  }
}

export class ServiceProjectItemLoadListClearAction implements PayloadAction {
  readonly type = ServiceProjectItemLoadListActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}

export class ServiceProjectItemLoadListChangeParamsAction implements PayloadAction {
  readonly type = ServiceProjectItemLoadListActionTypes.changeParams;

  constructor(public payload: ChangeParamsByIdPayload<SymfonyPaginationParams>) {
  }
}

export class ServiceProjectItemLoadListClearParamsAction implements PayloadAction {
  readonly type = ServiceProjectItemLoadListActionTypes.clearParams;

  constructor(public payload: ByIdPayload) {
  }
}


export class ServiceProjectItemLoadListClearIdsAction implements PayloadAction {
  readonly type = ServiceProjectItemLoadListActionTypes.clearIds;

  constructor(public payload: ByIdPayload) {
  }
}
