import { ByKey, createListStateIdsSelector, IdType, ListState } from '@industi/ngx-common';
import { createSelector } from '@ngrx/store';
import { createEntitiesSelector } from '../../../entity/entities.reducer';
import { EntityTypes } from '../../../entity/entity-types';
import { EntityState } from '../../../entity/entity.reducer';
import { denormalize } from 'normalizr';
import { Entities } from '../../../entity/entities';
import { OfferImage } from '../models/offer-image';
import { getOfferImageAddPendingList } from '../image.reducer';

const createStateSelector = (id: IdType) => createSelector(
  getOfferImageAddPendingList,
  (state: ByKey<ListState>) => state && state[id]
);

const createIdsSelector = (id: IdType) => createListStateIdsSelector(createStateSelector(id));

export const createOfferImageAddPendingList = (id: IdType) => createSelector(
  createIdsSelector(id),
  createEntitiesSelector([
    EntityTypes.image
  ]),
  (
    ids: IdType[],
    entities: EntityState
  ) => denormalize(ids, [Entities[EntityTypes.image]], entities) as OfferImage[]
);
