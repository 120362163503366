import { Injectable } from '@angular/core';
import { SetStateAdapter } from '@industi/ngx-common';
import { Action, Selector } from '@ngrx/store';
import { InstanceSetLocaleAction, InstanceSetLocaleClearAction } from '../actions/set-locale.actions';
import { getInstanceLocale } from '../instance.reducer';

@Injectable({ providedIn: 'root' })
export class SetLocaleAdapterService extends SetStateAdapter<string> {

  clearAction(): Action {
    return new InstanceSetLocaleClearAction();
  }

  getValueSelector(): Selector<any, string> {
    return getInstanceLocale;
  }

  setValueAction(value: string): Action {
    return new InstanceSetLocaleAction({ value });
  }

}
