import { Inject, Injectable } from '@angular/core';
import { EntityTypes } from '../../entity/entity-types';
import { API_URL } from '../../app-store-providers';
import { OfferImage } from './models/offer-image';
import { Observable } from 'rxjs';
import { EncodeUtil } from '../../../shared/utils/encode.util';
import { Entities } from '../../entity/entities';
import { IdType, NormalizedSingle } from '@industi/ngx-common';
import { OfferImagePayload } from './models/offer-image-payload';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { normalize } from 'normalizr';

@Injectable({
  providedIn: 'root'
})
export class OfferImageService {

  constructor(
    @Inject(API_URL) private apiUrl: string,
    private http: HttpClient
  ) {
  }

  add(locale: string, data: OfferImagePayload): Observable<NormalizedSingle> {
    const url = `${ this.apiUrl }/${ locale }/api/image/offer`;
    return this.http.post<OfferImagePayload>(url, EncodeUtil.prepareMultipartFormData(data)).pipe(
      map((res: OfferImage) => normalize(res, Entities[EntityTypes.image]))
    );
  }

  addItemImage(locale: string, data: OfferImagePayload): Observable<NormalizedSingle> {
    const url = `${ this.apiUrl }/${ locale }/api/offer-image`;
    return this.http.post<OfferImagePayload>(url, data).pipe(
      map((res: OfferImage) => normalize(res, Entities[EntityTypes.image]))
    );
  }

  delete(locale: string, id: IdType): Observable<void> {
    const url = `${ this.apiUrl }/${ locale }/api/offer-image/${ id }`;
    return this.http.delete<void>(url);
  }
}
