import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SendByIdPayload,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { DiagramPayload } from '../models/diagram-payload';
import { FilesPayload } from '../../../common/models/files-payload';

export const DiagramAddActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[diagram] Add'),
  requestFail: uniqueActionType('[diagram] Add fail'),
  requestSuccess: uniqueActionType('[diagram] Add success'),
  requestClearErrors: uniqueActionType('[diagram] Add clear errors'),
  requestClear: uniqueActionType('[diagram] Add clear'),
};

export class DiagramAddAction implements PayloadAction {
  readonly type = DiagramAddActionTypes.request;

  constructor(public payload: SendByIdPayload<FilesPayload<DiagramPayload>>) {
  }
}

export class DiagramAddFailAction implements PayloadAction {
  readonly type = DiagramAddActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class DiagramAddSuccessAction implements PayloadAction {
  readonly type = DiagramAddActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class DiagramAddClearErrorsAction implements PayloadAction {
  readonly type = DiagramAddActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class DiagramAddClearAction implements PayloadAction {
  readonly type = DiagramAddActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
