import { ByKey, listByIdReducer, ListState, operationByIdReducer, SingleState } from '@industi/ngx-common';
import { ActionReducer, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import { InjectionToken, Provider } from '@angular/core';
import { DiagramPartAddActionTypes } from './actions/add.actions';
import { DiagramPartEditActionTypes } from './actions/edit.actions';
import { DiagramPartLoadListActionTypes } from './actions/list.actions';
import { DiagramPartLoadDetailsActionTypes } from './actions/load-details.actions';
import { DiagramPartDeleteActionTypes } from './actions/delete.actions';
import { DiagramPartDetailsRemovePartActionTypes } from './actions/remove-part.actions';

export const rootReducerKey = 'diagramPart';

export interface DiagramPartOperationByIdState {
  add: SingleState;
  edit: SingleState;
  details: SingleState;
  delete: SingleState;
  deletePart: SingleState;
}

export interface DiagramPartState {
  operationsById: ByKey<DiagramPartOperationByIdState>;
  list: ByKey<ListState>;
}

const initialState: DiagramPartState = {
  operationsById: {},
  list: {}
};

const reducer: ActionReducer<DiagramPartState> = combineReducers<DiagramPartState>({
  operationsById: operationByIdReducer<DiagramPartOperationByIdState>({
    mapActionToId: (a) => a && a.payload && a.payload.id,
    operations: {
      add: DiagramPartAddActionTypes,
      edit: DiagramPartEditActionTypes,
      details: DiagramPartLoadDetailsActionTypes,
      delete: DiagramPartDeleteActionTypes,
      deletePart: DiagramPartDetailsRemovePartActionTypes
    }
  }),
  list: listByIdReducer({ types: DiagramPartLoadListActionTypes })
}, initialState);

export function getReducers(): ActionReducer<DiagramPartState> {
  return reducer;
}

export const DIAGRAM_PART_REDUCER_TOKEN = new InjectionToken<ActionReducer<DiagramPartState>>(rootReducerKey);

export const diagramPartReducerProvider: Provider = { provide: DIAGRAM_PART_REDUCER_TOKEN, useFactory: getReducers };

const getState = createFeatureSelector<DiagramPartState>(rootReducerKey);

export const getDiagramPartOperationsById = createSelector(
  getState,
  (state: DiagramPartState) => state && state.operationsById
);

export const getDiagramPartListState = createSelector(
  getState,
  (state: DiagramPartState) => state && state.list
);
