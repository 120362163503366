import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SendByIdPayload,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { ServiceProjectPayload } from '../models/project-payload';

export const ServiceProjectEditActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[service/project] Edit'),
  requestFail: uniqueActionType('[service/project] Edit fail'),
  requestSuccess: uniqueActionType('[service/project] Edit success'),
  requestClearErrors: uniqueActionType('[service/project] Edit clear errors'),
  requestClear: uniqueActionType('[service/project] Edit clear')
};

export class ServiceProjectEditAction implements PayloadAction {
  readonly type = ServiceProjectEditActionTypes.request;

  constructor(public payload: SendByIdPayload<ServiceProjectPayload>) {
  }
}

export class ServiceProjectEditFailAction implements PayloadAction {
  readonly type = ServiceProjectEditActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class ServiceProjectEditSuccessAction implements PayloadAction {
  readonly type = ServiceProjectEditActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class ServiceProjectEditClearErrorsAction implements PayloadAction {
  readonly type = ServiceProjectEditActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class ServiceProjectEditClearAction implements PayloadAction {
  readonly type = ServiceProjectEditActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
