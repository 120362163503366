import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { ByIdPayload, ByKey, IdType, NormalizedList, NormalizedSingle } from '@industi/ngx-common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ServiceProjectPartService } from './part.service';
import { catchError, first, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { getInstanceLocale } from '../../../instance/instance.reducer';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import {
  ServiceProjectPartLoadDetailsAction,
  ServiceProjectPartLoadDetailsActionTypes,
  ServiceProjectPartLoadDetailsFailAction,
  ServiceProjectPartLoadDetailsSuccessAction
} from './actions/details.actions';
import {
  ServiceProjectPartAddAction,
  ServiceProjectPartAddActionTypes,
  ServiceProjectPartAddFailAction,
  ServiceProjectPartAddSuccessAction
} from './actions/add.actions';
import {
  ServiceProjectPartEditAction,
  ServiceProjectPartEditActionTypes,
  ServiceProjectPartEditFailAction,
  ServiceProjectPartEditSuccessAction
} from './actions/edit.actions';
import { ResponseStatus } from '../../../../shared/enums/response-status.enum';
import { LibSnackbarComponent } from '@industi/ngx-modules';
import { AppGlobal } from '../../../../shared/app-global';
import {
  ServiceProjectPartDeleteAction,
  ServiceProjectPartDeleteActionTypes,
  ServiceProjectPartDeleteFailAction,
  ServiceProjectPartDeleteSuccessAction
} from './actions/delete.actions';
import {
  ServiceProjectPartLoadListAction,
  ServiceProjectPartLoadListActionTypes,
  ServiceProjectPartLoadListClearIdsAction,
  ServiceProjectPartLoadListFailAction,
  ServiceProjectPartLoadListSuccessAction
} from './actions/list.actions';
import { createServiceProjectPartListParams } from './selectors/get-list.selectors';
import { AlertUtil } from '../../../../shared/utils/alert.util';
import { ServiceProjectPartListParams } from './models/part-list-params';

@Injectable()
export class ServiceProjectPartEffects {

  @Effect()
  loadList$ = this.actions$.pipe(
    ofType(ServiceProjectPartLoadListActionTypes.request),
    map((action: ServiceProjectPartLoadListAction) => action.payload),
    switchMap((payload: ByIdPayload) => this.store.pipe(
      select(createServiceProjectPartListParams(payload.id)),
      map((params: ServiceProjectPartListParams) => [payload.id, params] as [IdType, ServiceProjectPartListParams]),
      first()
    )),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([[ id, params ], locale]) => this.service.loadList(locale, params).pipe(
      mergeMap((res: NormalizedList) => [
        new ServiceProjectPartLoadListClearIdsAction({ id }),
        new ServiceProjectPartLoadListSuccessAction({ id, res })]),
      catchError((errors: HttpErrorResponse) => of(new ServiceProjectPartLoadListFailAction({ id, errors })))
    ))
  );

  @Effect()
  add$ = this.actions$.pipe(
    ofType(ServiceProjectPartAddActionTypes.request),
    map((action: ServiceProjectPartAddAction) => action.payload),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([{ id, data }, locale]) => this.service.add(locale, data).pipe(
      map((res: NormalizedSingle) => new ServiceProjectPartAddSuccessAction({ res, id })),
      catchError((errors: HttpErrorResponse) => of(new ServiceProjectPartAddFailAction({ errors, id })))
    ))
  );

  @Effect()
  edit$ = this.actions$.pipe(
    ofType(ServiceProjectPartEditActionTypes.request),
    map((action: ServiceProjectPartEditAction) => action.payload),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([{ id, data }, locale]) => this.service.edit(locale, data, id).pipe(
      map((res: NormalizedSingle) => new ServiceProjectPartEditSuccessAction({ res, id })),
      catchError((errors: HttpErrorResponse) => of(new ServiceProjectPartEditFailAction({ errors, id })))
    ))
  );

  @Effect()
  loadDetails$ = this.actions$.pipe(
    ofType(ServiceProjectPartLoadDetailsActionTypes.request),
    map((action: ServiceProjectPartLoadDetailsAction) => action.payload.id),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([id, locale]) => this.service.details(locale, id).pipe(
      map((res: NormalizedSingle) => new ServiceProjectPartLoadDetailsSuccessAction({ id, res })),
      catchError((errors: HttpErrorResponse) => of(new ServiceProjectPartLoadDetailsFailAction({ id, errors })))
    ))
  );

  @Effect()
  delete$ = this.actions$.pipe(
    ofType(ServiceProjectPartDeleteActionTypes.request),
    map((action: ServiceProjectPartDeleteAction) => action.payload.id),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([id, locale]) => this.service.delete(locale, id).pipe(
      map(() => new ServiceProjectPartDeleteSuccessAction({ id })),
      catchError((errors: HttpErrorResponse) => of(new ServiceProjectPartDeleteFailAction({ id, errors })))
    ))
  );

  @Effect({ dispatch: false })
  crudFail$ = this.actions$.pipe(
    ofType(
      ServiceProjectPartAddActionTypes.requestFail,
      ServiceProjectPartEditActionTypes.requestFail
    ),
    map((action: ServiceProjectPartAddFailAction | ServiceProjectPartEditFailAction) => action.payload.errors),
    tap((data: HttpErrorResponse) => {
      if (data?.status === ResponseStatus.BAD_REQUEST || data?.status === ResponseStatus.UNPROCESSABLE_ENTITY) {
        this.snackBar.openFromComponent(LibSnackbarComponent, {
          data: {
            message: AlertUtil.getControlTranslation(data.error.detail),
            type: 'error'
          },
          duration: AppGlobal.defaultSnackBarDuration
        });
      }
    })
  );

  constructor(
    private actions$: Actions,
    private store: Store<ByKey>,
    private service: ServiceProjectPartService,
    private snackBar: MatSnackBar
  ) {
  }
}
