import {
  ByKey,
  IdType,
  listByIdReducer,
  ListState,
  operationByIdReducer,
  setValueReducer,
  SingleState
} from '@industi/ngx-common';
import { ActionReducer, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import { InjectionToken, Provider } from '@angular/core';
import { ItemLoadListActionTypes } from './actions/list.actions';
import { ItemAddActionTypes } from './actions/add.actions';
import { ItemLoadDetailsActionTypes } from './actions/load-details.actions';
import { ItemEditActionTypes } from './actions/edit.actions';
import { ItemDeleteActionTypes } from './actions/delete.actions';
import { ItemSelectLastAddedIdActionTypes } from './actions/select-last-added-id.actions';

export const rootReducerKey = 'item';

export interface ItemOperationByIdState {
  add: SingleState;
  edit: SingleState;
  details: SingleState;
  delete: SingleState;
}

export interface ItemState {
  operationsById: ByKey<ItemOperationByIdState>;
  list: ByKey<ListState>;
  lastAddedId: IdType;
}

const initialState: ItemState = {
  operationsById: {},
  list: {},
  lastAddedId: null
};

const reducer: ActionReducer<ItemState> = combineReducers<ItemState>({
  operationsById: operationByIdReducer<ItemOperationByIdState>({
    mapActionToId: (a) => a?.payload?.id,
    operations: {
      add: ItemAddActionTypes,
      edit: ItemEditActionTypes,
      details: ItemLoadDetailsActionTypes,
      delete: ItemDeleteActionTypes
    }
  }),
  list: listByIdReducer({ types: ItemLoadListActionTypes }),
  lastAddedId: setValueReducer({ types: ItemSelectLastAddedIdActionTypes })
}, initialState);

export function getReducers(): ActionReducer<ItemState> {
  return reducer;
}

export const ITEM_COMMON_REDUCER_TOKEN = new InjectionToken<ActionReducer<ItemState>>(rootReducerKey);

export const itemCommonReducerProvider: Provider = { provide: ITEM_COMMON_REDUCER_TOKEN, useFactory: getReducers };

const getState = createFeatureSelector<ItemState>(rootReducerKey);

export const getItemOperationsById = createSelector(
  getState,
  (state: ItemState) => state && state.operationsById
);

export const getItemListState = createSelector(
  getState,
  (state: ItemState) => state && state.list
);

export const getItemLastAddedId = createSelector(
  getState,
  (state: ItemState) => state && state.lastAddedId
);
