import { Inject, Injectable } from '@angular/core';
import { API_URL } from '../../app-store-providers';
import { HttpClient } from '@angular/common/http';
import { EntityTypes } from '../../entity/entity-types';
import { DiagramPartListParams } from './models/part-list-params';
import { DiagramPart } from './models/part';
import { StoreBaseService } from '../../common/base.service';
import { DiagramPartPayload } from './models/part-payload';
import { IdType, NormalizedSingle } from '@industi/ngx-common';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { normalize } from 'normalizr';
import { Entities } from '../../entity/entities';
import { DiagramPartRemovePayload } from './models/part-remove-payload';

@Injectable({
  providedIn: 'root'
})
export class DiagramPartService
  extends StoreBaseService<DiagramPart, DiagramPartPayload, DiagramPartListParams> {

  get entityType(): string {
    return EntityTypes.diagramPart;
  }

  get resourceName(): string {
    return 'diagram-part';
  }

  constructor(
    @Inject(API_URL) apiUrl: string,
    http: HttpClient
  ) {
    super(apiUrl, http);
  }

  partRemove(locale: string, data: DiagramPartRemovePayload, id: IdType): Observable<NormalizedSingle> {
    const url = this.getDictionaryEditPath(locale, this.resourceName, id) + '/remove';
    return this.http.put<DiagramPart>(url, data).pipe(
      map((res: DiagramPart) => normalize(res, Entities[this.entityType]))
    );
  }
}
