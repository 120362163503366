import { LibTableColumnsMap } from '@industi/ngx-modules';
import { DimensionTableColumnsType } from '../../../shared/components/dimension-data-table/dimension-data-table-columns.type';

export const DimensionTableColumnsMap: { [key in DimensionTableColumnsType]: string } = {
  ...LibTableColumnsMap,
  height: 'height',
  length: 'length',
  width: 'width',
  weight: 'weight'
};
