import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SendByIdPayload,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { Dictionary } from '../../../../shared/models/dictionary';

export const DictionaryBrandAddActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[dictionary/brand] Add'),
  requestFail: uniqueActionType('[dictionary/brand] Add fail'),
  requestSuccess: uniqueActionType('[dictionary/brand] Add success'),
  requestClearErrors: uniqueActionType('[dictionary/brand] Add clear errors'),
  requestClear: uniqueActionType('[dictionary/brand] Add clear'),
};

export class DictionaryBrandAddAction implements PayloadAction {
  readonly type = DictionaryBrandAddActionTypes.request;

  constructor(public payload: SendByIdPayload<Dictionary>) {
  }
}

export class DictionaryBrandAddFailAction implements PayloadAction {
  readonly type = DictionaryBrandAddActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class DictionaryBrandAddSuccessAction implements PayloadAction {
  readonly type = DictionaryBrandAddActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class DictionaryBrandAddClearErrorsAction implements PayloadAction {
  readonly type = DictionaryBrandAddActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class DictionaryBrandAddClearAction implements PayloadAction {
  readonly type = DictionaryBrandAddActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
