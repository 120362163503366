import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, first, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { ByIdPayload, ByKey, IdType, NormalizedList, NormalizedSingle } from '@industi/ngx-common';
import { select, Store } from '@ngrx/store';
import { getInstanceLocale } from '../../../instance/instance.reducer';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import { ResponseStatus } from '../../../../shared/enums/response-status.enum';
import { LibSnackbarComponent } from '@industi/ngx-modules';
import { AlertUtil } from '../../../../shared/utils/alert.util';
import { AppGlobal } from '../../../../shared/app-global';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  ServiceProjectOperationLoadListAction,
  ServiceProjectOperationLoadListActionTypes,
  ServiceProjectOperationLoadListClearIdsAction,
  ServiceProjectOperationLoadListFailAction, ServiceProjectOperationLoadListSuccessAction
} from './actions/list.actions';
import {
  ServiceProjectOperationEditAction,
  ServiceProjectOperationEditActionTypes, ServiceProjectOperationEditFailAction,
  ServiceProjectOperationEditSuccessAction
} from './actions/edit.actions';
import {
  ServiceProjectOperationLoadDetailsAction,
  ServiceProjectOperationLoadDetailsActionTypes, ServiceProjectOperationLoadDetailsFailAction,
  ServiceProjectOperationLoadDetailsSuccessAction
} from './actions/details.actions';
import {
  ServiceProjectOperationDeleteAction, ServiceProjectOperationDeleteActionTypes,
  ServiceProjectOperationDeleteFailAction,
  ServiceProjectOperationDeleteSuccessAction
} from './actions/delete.actions';
import {
  ServiceProjectOperationAddAction,
  ServiceProjectOperationAddActionTypes,
  ServiceProjectOperationAddFailAction,
  ServiceProjectOperationAddSuccessAction
} from './actions/add.actions';
import { ServiceProjectOperationService } from './operation.service';
import { ServiceProjectOperationListParams } from './models/operation-list-params';
import { createServiceProjectOperationListParams } from './selectors/get-list.selectors';

@Injectable()
export class ServiceProjectOperationEffects {

  @Effect()
  loadList$ = this.actions$.pipe(
    ofType(ServiceProjectOperationLoadListActionTypes.request),
    map((action: ServiceProjectOperationLoadListAction) => action.payload),
    switchMap((payload: ByIdPayload) => this.store.pipe(
      select(createServiceProjectOperationListParams(payload.id)),
      map((params: ServiceProjectOperationListParams) => [payload.id, params] as [IdType, ServiceProjectOperationListParams]),
      first()
    )),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([[ id, params ], locale]) => this.service.loadList(locale, params).pipe(
      mergeMap((res: NormalizedList) => [
        new ServiceProjectOperationLoadListClearIdsAction({ id }),
        new ServiceProjectOperationLoadListSuccessAction({ id, res })]),
      catchError((errors: HttpErrorResponse) => of(new ServiceProjectOperationLoadListFailAction({ id, errors })))
    ))
  );

  @Effect()
  add$ = this.actions$.pipe(
    ofType(ServiceProjectOperationAddActionTypes.request),
    map((action: ServiceProjectOperationAddAction) => action.payload),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([{ id, data }, locale]) => this.service.add(locale, data).pipe(
      map((res: NormalizedSingle) => new ServiceProjectOperationAddSuccessAction({ res, id })),
      catchError((errors: HttpErrorResponse) => of(new ServiceProjectOperationAddFailAction({ errors, id })))
    ))
  );

  @Effect()
  edit$ = this.actions$.pipe(
    ofType(ServiceProjectOperationEditActionTypes.request),
    map((action: ServiceProjectOperationEditAction) => action.payload),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([{ id, data }, locale]) => this.service.edit(locale, data, id).pipe(
      map((res: NormalizedSingle) => new ServiceProjectOperationEditSuccessAction({ res, id })),
      catchError((errors: HttpErrorResponse) => of(new ServiceProjectOperationEditFailAction({ errors, id })))
    ))
  );

  @Effect()
  loadDetails$ = this.actions$.pipe(
    ofType(ServiceProjectOperationLoadDetailsActionTypes.request),
    map((action: ServiceProjectOperationLoadDetailsAction) => action.payload.id),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([id, locale]) => this.service.details(locale, id).pipe(
      map((res: NormalizedSingle) => new ServiceProjectOperationLoadDetailsSuccessAction({ id, res })),
      catchError((errors: HttpErrorResponse) => of(new ServiceProjectOperationLoadDetailsFailAction({ id, errors })))
    ))
  );

  @Effect()
  delete$ = this.actions$.pipe(
    ofType(ServiceProjectOperationDeleteActionTypes.request),
    map((action: ServiceProjectOperationDeleteAction) => action.payload.id),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([id, locale]) => this.service.delete(locale, id).pipe(
      map(() => new ServiceProjectOperationDeleteSuccessAction({ id })),
      catchError((errors: HttpErrorResponse) => of(new ServiceProjectOperationDeleteFailAction({ id, errors })))
    ))
  );

  @Effect({ dispatch: false })
  crudFail$ = this.actions$.pipe(
    ofType(
      ServiceProjectOperationAddActionTypes.requestFail,
      ServiceProjectOperationEditActionTypes.requestFail
    ),
    map((action: ServiceProjectOperationAddFailAction | ServiceProjectOperationEditFailAction) => action.payload.errors),
    tap((data: HttpErrorResponse) => {
      if (data?.status === ResponseStatus.BAD_REQUEST || data?.status === ResponseStatus.UNPROCESSABLE_ENTITY) {
        this.snackBar.openFromComponent(LibSnackbarComponent, {
          data: {
            message: AlertUtil.getControlTranslation(data.error.detail),
            type: 'error'
          },
          duration: AppGlobal.defaultSnackBarDuration
        });
      }
    })
  );

  constructor(
    private actions$: Actions,
    private store: Store<ByKey>,
    private service: ServiceProjectOperationService,
    private snackBar: MatSnackBar
  ) {
  }
}
