import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';

export const DictionaryCarbonMaterialTypeLoadDetailsActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[dictionary/carbon-material-type] Load details'),
  requestFail: uniqueActionType('[dictionary/carbon-material-type] Load details fail'),
  requestSuccess: uniqueActionType('[dictionary/carbon-material-type] Load details success'),
  requestClearErrors: uniqueActionType('[dictionary/carbon-material-type] Load details clear errors'),
  requestClear: uniqueActionType('[dictionary/carbon-material-type] Load details clear')
};

export class DictionaryCarbonMaterialTypeLoadDetailsAction implements PayloadAction {
  readonly type = DictionaryCarbonMaterialTypeLoadDetailsActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class DictionaryCarbonMaterialTypeLoadDetailsFailAction implements PayloadAction {
  readonly type = DictionaryCarbonMaterialTypeLoadDetailsActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class DictionaryCarbonMaterialTypeLoadDetailsSuccessAction implements PayloadAction {
  readonly type = DictionaryCarbonMaterialTypeLoadDetailsActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class DictionaryCarbonMaterialTypeLoadDetailsClearAction implements PayloadAction {
  readonly type = DictionaryCarbonMaterialTypeLoadDetailsActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}

export class DictionaryCarbonMaterialTypeLoadDetailsClearErrorsAction implements PayloadAction {
  readonly type = DictionaryCarbonMaterialTypeLoadDetailsActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}
