import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, first, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { ByIdPayload, ByKey, IdType, NormalizedList, NormalizedSingle } from '@industi/ngx-common';
import { select, Store } from '@ngrx/store';
import { getInstanceLocale } from '../instance/instance.reducer';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import {
  WarehouseEditAction,
  WarehouseEditActionTypes,
  WarehouseEditFailAction,
  WarehouseEditSuccessAction
} from './actions/edit.actions';
import {
  WarehouseLoadListAction,
  WarehouseLoadListActionTypes,
  WarehouseLoadListClearIdsAction,
  WarehouseLoadListFailAction,
  WarehouseLoadListSuccessAction
} from './actions/list.actions';
import {
  WarehouseLoadDetailsAction,
  WarehouseLoadDetailsActionTypes,
  WarehouseLoadDetailsFailAction,
  WarehouseLoadDetailsSuccessAction
} from './actions/load-details.actions';
import { createWarehouseListParams } from './selectors/get-load-list.selectors';
import {
  WarehouseAddAction,
  WarehouseAddActionTypes,
  WarehouseAddFailAction,
  WarehouseAddSuccessAction
} from './actions/add.actions';
import { WarehouseService } from './warehouse.service';
import { SymfonyPaginationParams } from '../symfony/list/models/symfony-pagination-params';
import {
  WarehouseDeleteAction,
  WarehouseDeleteActionTypes,
  WarehouseDeleteFailAction,
  WarehouseDeleteSuccessAction
} from './actions/delete.actions';
import { ResponseStatus } from '../../shared/enums/response-status.enum';
import { LibSnackbarComponent } from '@industi/ngx-modules';
import { AppGlobal } from '../../shared/app-global';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlertUtil } from '../../shared/utils/alert.util';
import { AppFile } from '../../shared/models/file';
import { FileUtil } from '../../shared/utils/file.util';
import {
  WarehouseLoadPdfAction,
  WarehouseLoadPdfActionTypes,
  WarehouseLoadPdfFailAction,
  WarehouseLoadPdfSuccessAction
} from './actions/load-pdf.actions';

@Injectable()
export class WarehouseEffects {

  @Effect()
  loadList$ = this.actions$.pipe(
    ofType(WarehouseLoadListActionTypes.request),
    map((action: WarehouseLoadListAction) => action.payload),
    switchMap((payload: ByIdPayload) => this.store.pipe(
      select(createWarehouseListParams(payload.id)),
      map((params: SymfonyPaginationParams) => [payload.id, params] as [IdType, SymfonyPaginationParams]),
      first()
    )),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([[ id, params ], locale]) => this.service.loadList(locale, params).pipe(
      mergeMap((res: NormalizedList) => [
        new WarehouseLoadListClearIdsAction({ id }),
        new WarehouseLoadListSuccessAction({ id, res })]),
      catchError((errors: HttpErrorResponse) => of(new WarehouseLoadListFailAction({ id, errors })))
    ))
  );

  @Effect()
  add$ = this.actions$.pipe(
    ofType(WarehouseAddActionTypes.request),
    map((action: WarehouseAddAction) => action.payload),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([{ id, data }, locale]) => this.service.add(locale, data).pipe(
      map((res: NormalizedSingle) => new WarehouseAddSuccessAction({ res, id })),
      catchError((errors: HttpErrorResponse) => of(new WarehouseAddFailAction({ errors, id })))
    ))
  );

  @Effect()
  edit$ = this.actions$.pipe(
    ofType(WarehouseEditActionTypes.request),
    map((action: WarehouseEditAction) => action.payload),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([{ id, data }, locale]) => this.service.edit(locale, data, id).pipe(
      map((res: NormalizedSingle) => new WarehouseEditSuccessAction({ res, id })),
      catchError((errors: HttpErrorResponse) => of(new WarehouseEditFailAction({ errors, id })))
    ))
  );

  @Effect()
  loadDetails$ = this.actions$.pipe(
    ofType(WarehouseLoadDetailsActionTypes.request),
    map((action: WarehouseLoadDetailsAction) => action.payload.id),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([id, locale]) => this.service.details(locale, id).pipe(
      map((res: NormalizedSingle) => new WarehouseLoadDetailsSuccessAction({ id, res })),
      catchError((errors: HttpErrorResponse) => of(new WarehouseLoadDetailsFailAction({ id, errors })))
    ))
  );

  @Effect()
  delete$ = this.actions$.pipe(
    ofType(WarehouseDeleteActionTypes.request),
    map((action: WarehouseDeleteAction) => action.payload.id),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([id, locale]) => this.service.delete(locale, id).pipe(
      map(() => new WarehouseDeleteSuccessAction({ id })),
      catchError((errors: HttpErrorResponse) => of(new WarehouseDeleteFailAction({ id, errors })))
    ))
  );

  @Effect()
  pdf$ = this.actions$.pipe(
    ofType(WarehouseLoadPdfActionTypes.request),
    map((action: WarehouseLoadPdfAction) => action.payload),
    withLatestFrom(this.store.pipe(select(getInstanceLocale))),
    switchMap(([{ id }, locale]) => this.service.pdf(locale).pipe(
      map((res: any) => new WarehouseLoadPdfSuccessAction({ id, res })),
      catchError((errors: HttpErrorResponse) => of(new WarehouseLoadPdfFailAction({ id, errors })))
    ))
  );

  @Effect({ dispatch: false })
  pdfSuccess$ = this.actions$.pipe(
    ofType(WarehouseLoadPdfActionTypes.requestSuccess),
    map((action: WarehouseLoadPdfSuccessAction) => action.payload.res),
    tap((data: AppFile) => {
      FileUtil.saveFileInSameTab(data.file, 'Miejsca magazynowe.pdf');
      this.snackBar.openFromComponent(LibSnackbarComponent, {
        data: {
          message: 'Pobrano pdf',
          type: 'success'
        },
        duration: AppGlobal.defaultSnackBarDuration
      });
    })
  );

  @Effect({ dispatch: false })
  crudFail$ = this.actions$.pipe(
    ofType(
      WarehouseAddActionTypes.requestFail,
      WarehouseEditActionTypes.requestFail
    ),
    map((action: WarehouseAddFailAction | WarehouseEditFailAction) => action.payload.errors),
    tap((data: HttpErrorResponse) => {
      if (data?.status === ResponseStatus.BAD_REQUEST || data?.status === ResponseStatus.UNPROCESSABLE_ENTITY) {
        this.snackBar.openFromComponent(LibSnackbarComponent, {
          data: {
            message: AlertUtil.getControlTranslation(data.error.detail),
            type: 'error'
          },
          duration: AppGlobal.defaultSnackBarDuration
        });
      }
    })
  );

  constructor(
    private actions$: Actions,
    private store: Store<ByKey>,
    private service: WarehouseService,
    private snackBar: MatSnackBar
  ) {
  }
}
