import { createSelector } from '@ngrx/store';
import {
  ByKey,
  createStateErrorsSelector,
  createStateLoadingSelector,
  createStateSuccessSelector,
  IdType
} from '@industi/ngx-common';
import { denormalize } from 'normalizr';
import { EntityTypes } from '../../../entity/entity-types';
import { createEntitiesSelector } from '../../../entity/entities.reducer';
import { Entities } from '../../../entity/entities';
import { EntityState } from '../../../entity/entity.reducer';
import {
  CarbonPartCommissionOperationByIdState,
  getCarbonPartCommissionOperationsById
} from '../part-commission.reducer';
import { CarbonPartCommission } from '../models/part-commission';

const createState = (id: IdType) => createSelector(
  getCarbonPartCommissionOperationsById,
  (state: ByKey<CarbonPartCommissionOperationByIdState>) => state && state[id] && state[id].details
);

export const createCarbonPartCommissionDetailsLoading = (id: IdType) => createStateLoadingSelector(createState(id));
export const createCarbonPartCommissionDetailsErrors = (id: IdType) => createStateErrorsSelector(createState(id));
export const createCarbonPartCommissionDetailsSuccess = (id: IdType) => createStateSuccessSelector(createState(id));

export const createCarbonPartCommissionDetails = (id: IdType) => createSelector(
  createEntitiesSelector([
    EntityTypes.carbonPartCommission,
    EntityTypes.carbonForm
  ]),
  (entities: EntityState) =>
    denormalize(id, Entities[EntityTypes.carbonPartCommission], entities) as CarbonPartCommission
);

export const createCarbonPartCommissionTasksList = (id: IdType) => createSelector(
  createCarbonPartCommissionDetails(id),
  (details: CarbonPartCommission) => details?.tasks
);
