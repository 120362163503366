import { Inject, Injectable } from '@angular/core';
import { API_URL } from '../../app-store-providers';
import { HttpClient } from '@angular/common/http';
import { IdType, NormalizedSingle } from '@industi/ngx-common';
import { Observable } from 'rxjs';
import { EncodeUtil } from '../../../shared/utils/encode.util';
import { map } from 'rxjs/operators';
import { ItemImage } from './models/item-image';
import { normalize } from 'normalizr';
import { Entities } from '../../entity/entities';
import { EntityTypes } from '../../entity/entity-types';
import { ItemImagePayload } from './models/item-image-payload';

@Injectable({
  providedIn: 'root'
})
export class ItemImageService {

  constructor(
    @Inject(API_URL) private apiUrl: string,
    private http: HttpClient
  ) { }

  add(locale: string, data: ItemImagePayload): Observable<NormalizedSingle> {
    const url = `${this.apiUrl}/${locale}/api/image/item`;
    return this.http.post<ItemImagePayload>(url, EncodeUtil.prepareMultipartFormData(data)).pipe(
      map((res: ItemImage) => normalize(res, Entities[EntityTypes.image]))
    );
  }

  details(locale: string, id: IdType): Observable<NormalizedSingle> {
    const url = `${this.apiUrl}/${locale}/api/itemImage/${id}`;
    return this.http.get<ItemImage>(url).pipe(
      map((res: ItemImage) => normalize(res, Entities[EntityTypes.image]))
    );
  }

  delete(locale: string, id: IdType): Observable<void> {
    const url = `${this.apiUrl}/${locale}/api/itemImage/${id}`;
    return this.http.delete<void>(url);
  }
}
