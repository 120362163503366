import {
  ByKey,
  createListStateIdsSelector, createListStateParamsSelector,
  createStateErrorsSelector,
  createStateLoadingSelector, createStateSuccessSelector,
  IdType,
  ListState
} from '@industi/ngx-common';
import { createSelector } from '@ngrx/store';
import { getClientListState } from '../client.reducer';
import { createEntitiesSelector } from '../../entity/entities.reducer';
import { EntityTypes } from '../../entity/entity-types';
import { EntityState } from '../../entity/entity.reducer';
import { denormalize } from 'normalizr';
import { Entities } from '../../entity/entities';
import { Client } from '../models/client';
import { createSymfonyTotalCountSelector } from '../../symfony/list/selectors/list.selectors';

const createState = (id: IdType) => createSelector(
  getClientListState,
  (state: ByKey<ListState>) => state && state[id]
);

const createStateByContext = (id: IdType) => createListStateIdsSelector(createState(id));

export const createClientListData = (id: IdType) => createSelector(
  createStateByContext(id),
  createEntitiesSelector([
    EntityTypes.client
  ]),
  (
    ids: IdType[],
    entities: EntityState
  ) => denormalize(ids, [Entities[EntityTypes.client]], entities) as Client[]
);

export const createClientListLoading = (id: IdType) => createStateLoadingSelector(createState(id));

export const createClientListErrors = (id: IdType) => createStateErrorsSelector(createState(id));

export const createClientListSuccess = (id: IdType) => createStateSuccessSelector(createState(id));

export const createClientListTotalCount = (id: IdType) => createSymfonyTotalCountSelector(createState(id));

export const createClientListParams = (id: IdType) => createListStateParamsSelector(createState(id));
