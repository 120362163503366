import {
  ByIdPayload,
  FailByIdPayload,
  OperationReducerTypes,
  PayloadAction,
  uniqueActionType
} from '@industi/ngx-common';

export const EbayRefreshConfigurationActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[ebay-configuration] Refresh configuration'),
  requestFail: uniqueActionType('[ebay-configuration] Refresh configuration fail'),
  requestSuccess: uniqueActionType('[ebay-configuration] Refresh configuration success'),
  requestClearErrors: uniqueActionType('[ebay-configuration] Refresh configuration clear errors'),
  requestClear: uniqueActionType('[ebay-configuration] Refresh configuration clear'),
};

export class EbayRefreshConfigurationAction implements PayloadAction {
  readonly type = EbayRefreshConfigurationActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class EbayRefreshConfigurationFailAction implements PayloadAction {
  readonly type = EbayRefreshConfigurationActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class EbayRefreshConfigurationSuccessAction implements PayloadAction {
  readonly type = EbayRefreshConfigurationActionTypes.requestSuccess;

  constructor(public payload: ByIdPayload) {
  }
}

export class EbayRefreshConfigurationClearErrorsAction implements PayloadAction {
  readonly type = EbayRefreshConfigurationActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class EbayRefreshConfigurationClearAction implements PayloadAction {
  readonly type = EbayRefreshConfigurationActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
