import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import {
  ORDER_COMMON_REDUCER_TOKEN,
  orderCommonReducerProvider,
  rootReducerKey
} from './common.reducer';
import { EffectsModule } from '@ngrx/effects';
import { OrderCommonEffects } from './common.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(rootReducerKey, ORDER_COMMON_REDUCER_TOKEN),
    EffectsModule.forFeature([OrderCommonEffects])
  ],
  providers: [
    orderCommonReducerProvider
  ]
})
export class OrderCommonStoreModule { }
