import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SendByIdPayload,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { ArticleTextContentPayload } from '../models/text-content-payload';

export const ArticleTextContentAddActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[article/text-content] Add'),
  requestFail: uniqueActionType('[article/text-content] Add fail'),
  requestSuccess: uniqueActionType('[article/text-content] Add success'),
  requestClearErrors: uniqueActionType('[article/text-content] Add clear errors'),
  requestClear: uniqueActionType('[article/text-content] Add clear'),
};

export class ArticleTextContentAddAction implements PayloadAction {
  readonly type = ArticleTextContentAddActionTypes.request;

  constructor(public payload: SendByIdPayload<ArticleTextContentPayload>) {
  }
}

export class ArticleTextContentAddFailAction implements PayloadAction {
  readonly type = ArticleTextContentAddActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class ArticleTextContentAddSuccessAction implements PayloadAction {
  readonly type = ArticleTextContentAddActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class ArticleTextContentAddClearErrorsAction implements PayloadAction {
  readonly type = ArticleTextContentAddActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class ArticleTextContentAddClearAction implements PayloadAction {
  readonly type = ArticleTextContentAddActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
