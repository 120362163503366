import {
  ByKey,
  createStateErrorsSelector,
  createStateLoadingSelector,
  createStateSuccessSelector,
  IdType
} from '@industi/ngx-common';
import { createSelector } from '@ngrx/store';
import { ApplicationOperationByIdState, getApplicationOperationsById } from '../application.reducer';
import { createEntitiesSelector } from '../../entity/entities.reducer';
import { EntityTypes } from '../../entity/entity-types';
import { EntityState } from '../../entity/entity.reducer';
import { denormalize } from 'normalizr';
import { Entities } from '../../entity/entities';
import { Application } from '../models/application';

const createState = (id: IdType) => createSelector(
  getApplicationOperationsById,
  (state: ByKey<ApplicationOperationByIdState>) => state && state[id] && state[id].current
);

export const createApplicationCurrentLoading = (id: IdType) => createStateLoadingSelector(createState(id));
export const createApplicationCurrentErrors = (id: IdType) => createStateErrorsSelector(createState(id));
export const createApplicationCurrentSuccess = (id: IdType) => createStateSuccessSelector(createState(id));

export const createApplicationCurrentDetails = (id: IdType) => createSelector(
  createEntitiesSelector([
    EntityTypes.application
  ]),
  (entities: EntityState) => denormalize(id, Entities[EntityTypes.application], entities) as Application
);
