import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { rootReducerKey, SERVICE_PROJECT_ITEM_REDUCER_TOKEN, serviceProjectItemReducerProvider } from './item.reducer';
import { EffectsModule } from '@ngrx/effects';
import { ServiceProjectItemEffects } from './item.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(rootReducerKey, SERVICE_PROJECT_ITEM_REDUCER_TOKEN),
    EffectsModule.forFeature([ServiceProjectItemEffects])
  ],
  providers: [
    serviceProjectItemReducerProvider
  ]
})
export class ServiceProjectItemStoreModule { }
