import { Directive, HostBinding, Input, OnInit, Self } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { AppGlobal } from '../../../app-global';

@Directive({
  selector: '[appSeakPagination]'
})
export class SeakPaginationDirective implements OnInit {

  @Input()
  showAll = false;

  @HostBinding('class')
  get paginatorClasses(): string {
    return [
      'bg-transparent',
      'w-100',
      this.showAll ? 'app-hide-arrows' : ''
    ].join(' ');
  }

  constructor(@Self() private matPaginator: MatPaginator) {
  }

  ngOnInit(): void {
    this.matPaginator.pageSize = (this.showAll ? this.matPaginator.length : AppGlobal.listParams.perPage);
    this.matPaginator.hidePageSize = true;
  }
}
