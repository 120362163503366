import { createSelector } from '@ngrx/store';
import { denormalize } from 'normalizr';
import {
  ByKey,
  createListStateIdsSelector,
  createListStateParamsSelector,
  createStateErrorsSelector,
  createStateLoadingSelector,
  createStateSuccessSelector,
  IdType,
  ListState
} from '@industi/ngx-common';
import { Dictionary } from '../../../../shared/models/dictionary';
import { EntityTypes } from '../../../entity/entity-types';
import { getDictionaryCourierListState } from '../courier.reducer';
import { createEntitiesSelector } from '../../../entity/entities.reducer';
import { createSymfonyTotalCountSelector } from '../../../symfony/list/selectors/list.selectors';
import { Entities } from '../../../entity/entities';
import { EntityState } from '../../../entity/entity.reducer';

const createState = (id: IdType) => createSelector(
  getDictionaryCourierListState,
  (state: ByKey<ListState>) => state && state[id]
);

const createStateByContext = (id: IdType) => createListStateIdsSelector(createState(id));

export const createDictionaryCourierListData = (id: IdType) => createSelector(
  createStateByContext(id),
  createEntitiesSelector([
    EntityTypes.courier
  ]),
  (
    ids: IdType[],
    entities: EntityState
  ) => denormalize(ids, [Entities[EntityTypes.courier]], entities) as Dictionary[]
);

export const createDictionaryCourierListLoading = (id: IdType) => createStateLoadingSelector(
  createState(id));

export const createDictionaryCourierListSuccess = (id: IdType) => createStateSuccessSelector(
  createState(id));

export const createDictionaryCourierListErrors = (id: IdType) => createStateErrorsSelector(
  createState(id));

export const createDictionaryCourierListTotalCount = (id: IdType) => createSymfonyTotalCountSelector(
  createState(id));

export const createDictionaryCourierListParams = (id: IdType) => createListStateParamsSelector(
  createState(id));
