import { ByKey, listByIdReducer, ListState, operationByIdReducer, SingleState } from '@industi/ngx-common';
import { ActionReducer, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';
import { InjectionToken, Provider } from '@angular/core';
import { ModelLoadListActionTypes } from './actions/list.actions';
import { ModelAddActionTypes } from './actions/add.actions';
import { ModelEditActionTypes } from './actions/edit.actions';
import { ModelLoadDetailsActionTypes } from './actions/load-details.actions';
import { ModelDeleteActionTypes } from './actions/delete.actions';

export const rootReducerKey = 'model';

export interface ModelOperationByIdState {
  add: SingleState;
  edit: SingleState;
  details: SingleState;
  delete: SingleState;
}

export interface ModelState {
  operationsById: ByKey<ModelOperationByIdState>;
  list: ByKey<ListState>;
}

const initialState: ModelState = {
  operationsById: {},
  list: {}
};

const reducer: ActionReducer<ModelState> = combineReducers<ModelState>({
  operationsById: operationByIdReducer<ModelOperationByIdState>({
    mapActionToId: (a) => a && a.payload && a.payload.id,
    operations: {
      add: ModelAddActionTypes,
      edit: ModelEditActionTypes,
      details: ModelLoadDetailsActionTypes,
      delete: ModelDeleteActionTypes
    }
  }),
  list: listByIdReducer({ types: ModelLoadListActionTypes })
}, initialState);

export function getReducers(): ActionReducer<ModelState> {
  return reducer;
}

export const MODEL_REDUCER_TOKEN = new InjectionToken<ActionReducer<ModelState>>(rootReducerKey);

export const modelReducerProvider: Provider = { provide: MODEL_REDUCER_TOKEN, useFactory: getReducers };

const getState = createFeatureSelector<ModelState>(rootReducerKey);

export const getModelOperationsById = createSelector(
  getState,
  (state: ModelState) => state && state.operationsById
);

export const getModelListState = createSelector(
  getState,
  (state: ModelState) => state && state.list
);
