import {
  ByIdPayload,
  FailByIdPayload,
  OperationReducerTypes,
  PayloadAction,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';

export const CarbonFormLoadPdfActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[carbon/form] Load pdf'),
  requestFail: uniqueActionType('[carbon/form] Load pdf fail'),
  requestSuccess: uniqueActionType('[carbon/form] Load pdf success'),
  requestClear: uniqueActionType('[carbon/form] Load pdf clear')
};

export class CarbonFormLoadPdfAction implements PayloadAction {
  readonly type = CarbonFormLoadPdfActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class CarbonFormLoadPdfFailAction implements PayloadAction {
  readonly type = CarbonFormLoadPdfActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class CarbonFormLoadPdfSuccessAction implements PayloadAction {
  readonly type = CarbonFormLoadPdfActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<any>) {
  }
}

export class CarbonFormLoadPdfClearAction implements PayloadAction {
  readonly type = CarbonFormLoadPdfActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
