import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { DICTIONARY_PROCESS_REDUCER_TOKEN, dictionaryProcessReducerProvider, rootReducerKey } from './process.reducer';
import { EffectsModule } from '@ngrx/effects';
import { DictionaryProcessEffects } from './process.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(rootReducerKey, DICTIONARY_PROCESS_REDUCER_TOKEN),
    EffectsModule.forFeature([DictionaryProcessEffects])
  ],
  providers: [
    dictionaryProcessReducerProvider
  ]
})
export class DictionaryProcessStoreModule { }
