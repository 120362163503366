import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';

export const ApplicationLoadCurrentActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[application] Load current'),
  requestFail: uniqueActionType('[application] Load current fail'),
  requestSuccess: uniqueActionType('[application] Load current success'),
  requestClearErrors: uniqueActionType('[application] Load current clear errors'),
  requestClear: uniqueActionType('[application] Load current clear'),
};

export class ApplicationLoadCurrentAction implements PayloadAction {
  readonly type = ApplicationLoadCurrentActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class ApplicationLoadCurrentFailAction implements PayloadAction {
  readonly type = ApplicationLoadCurrentActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class ApplicationLoadCurrentSuccessAction implements PayloadAction {
  readonly type = ApplicationLoadCurrentActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class ApplicationLoadCurrentClearErrorsAction implements PayloadAction {
  readonly type = ApplicationLoadCurrentActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class ApplicationLoadCurrentClearAction implements PayloadAction {
  readonly type = ApplicationLoadCurrentActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
