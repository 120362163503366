import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';

export const ServiceProjectPartLoadDetailsActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[service/project-part] Load details'),
  requestFail: uniqueActionType('[service/project-part] Load details fail'),
  requestSuccess: uniqueActionType('[service/project-part] Load details success'),
  requestClearErrors: uniqueActionType('[service/project-part] Load details clear errors'),
  requestClear: uniqueActionType('[service/project-part] Load details clear')
};

export class ServiceProjectPartLoadDetailsAction implements PayloadAction {
  readonly type = ServiceProjectPartLoadDetailsActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class ServiceProjectPartLoadDetailsFailAction implements PayloadAction {
  readonly type = ServiceProjectPartLoadDetailsActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class ServiceProjectPartLoadDetailsSuccessAction implements PayloadAction {
  readonly type = ServiceProjectPartLoadDetailsActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class ServiceProjectPartLoadDetailsClearAction implements PayloadAction {
  readonly type = ServiceProjectPartLoadDetailsActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}

export class ServiceProjectPartLoadDetailsClearErrorsAction implements PayloadAction {
  readonly type = ServiceProjectPartLoadDetailsActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}
