import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';

export const ArticleContentLoadDetailsActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[article-content] Load details'),
  requestFail: uniqueActionType('[article-content] Load details fail'),
  requestSuccess: uniqueActionType('[article-content] Load details success'),
  requestClearErrors: uniqueActionType('[article-content] Load details clear errors'),
  requestClear: uniqueActionType('[article-content] Load details clear')
};

export class ArticleContentLoadDetailsAction implements PayloadAction {
  readonly type = ArticleContentLoadDetailsActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class ArticleContentLoadDetailsFailAction implements PayloadAction {
  readonly type = ArticleContentLoadDetailsActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class ArticleContentLoadDetailsSuccessAction implements PayloadAction {
  readonly type = ArticleContentLoadDetailsActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class ArticleContentLoadDetailsClearAction implements PayloadAction {
  readonly type = ArticleContentLoadDetailsActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}

export class ArticleContentLoadDetailsClearErrorsAction implements PayloadAction {
  readonly type = ArticleContentLoadDetailsActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}
