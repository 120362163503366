import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SendByIdPayload,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { Dictionary } from '../../../../shared/models/dictionary';

export const DictionaryCarbonMaterialTypeAddActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[dictionary/carbon-material-type] Add'),
  requestFail: uniqueActionType('[dictionary/carbon-material-type] Add fail'),
  requestSuccess: uniqueActionType('[dictionary/carbon-material-type] Add success'),
  requestClearErrors: uniqueActionType('[dictionary/carbon-material-type] Add clear errors'),
  requestClear: uniqueActionType('[dictionary/carbon-material-type] Add clear'),
};

export class DictionaryCarbonMaterialTypeAddAction implements PayloadAction {
  readonly type = DictionaryCarbonMaterialTypeAddActionTypes.request;

  constructor(public payload: SendByIdPayload<Dictionary>) {
  }
}

export class DictionaryCarbonMaterialTypeAddFailAction implements PayloadAction {
  readonly type = DictionaryCarbonMaterialTypeAddActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class DictionaryCarbonMaterialTypeAddSuccessAction implements PayloadAction {
  readonly type = DictionaryCarbonMaterialTypeAddActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class DictionaryCarbonMaterialTypeAddClearErrorsAction implements PayloadAction {
  readonly type = DictionaryCarbonMaterialTypeAddActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class DictionaryCarbonMaterialTypeAddClearAction implements PayloadAction {
  readonly type = DictionaryCarbonMaterialTypeAddActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
