import { Injectable } from '@angular/core';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class InstanceUtil {

  static get cookieName() {
    return 'aw_locale';
  }

  set locale(locale) {
    const d = new Date();
    const days = 365;
    d.setTime(d.getTime() + (days * 24 * 60 * 60 * 1000));
    const expires = `expires=${d.toUTCString()}`;

    document.cookie = `${InstanceUtil.cookieName}=${locale};${expires};path=/;SameSite=Lax`;
  }

  get locale(): string {
    const ca = document.cookie.split(';');

    for (let i = 0; i < ca.length; i++) {
      const it = ca[i].trim();
      if (it.includes(InstanceUtil.cookieName)) {
        return _.last(it.split('='));
      }
    }

    return '';
  }
}
