import {
  ByIdPayload,
  FailByIdPayload,
  OperationReducerTypes,
  PayloadAction,
  uniqueActionType
} from '@industi/ngx-common';

export const OfferItemDeleteActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[offer/item] Delete'),
  requestFail: uniqueActionType('[offer/item] Delete fail'),
  requestSuccess: uniqueActionType('[offer/item] Delete success'),
  requestClearErrors: uniqueActionType('[offer/item] Delete clear errors'),
  requestClear: uniqueActionType('[offer/item] Delete clear'),
};

export class OfferItemDeleteAction implements PayloadAction {
  readonly type = OfferItemDeleteActionTypes.request;

  constructor(public payload: ByIdPayload) {
  }
}

export class OfferItemDeleteFailAction implements PayloadAction {
  readonly type = OfferItemDeleteActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class OfferItemDeleteSuccessAction implements PayloadAction {
  readonly type = OfferItemDeleteActionTypes.requestSuccess;

  constructor(public payload: ByIdPayload) {
  }
}

export class OfferItemDeleteClearErrorsAction implements PayloadAction {
  readonly type = OfferItemDeleteActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class OfferItemDeleteClearAction implements PayloadAction {
  readonly type = OfferItemDeleteActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
