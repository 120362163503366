import {
  ByIdPayload,
  FailByIdPayload,
  NormalizedSingle,
  OperationReducerTypes,
  PayloadAction,
  SendByIdPayload,
  SuccessByIdPayload,
  uniqueActionType
} from '@industi/ngx-common';
import { ArticleBrandPayload } from '../models/article-brand-payload';

export const ArticleBrandEditActionTypes: OperationReducerTypes = {
  request: uniqueActionType('[article-brand] Edit'),
  requestFail: uniqueActionType('[article-brand] Edit fail'),
  requestSuccess: uniqueActionType('[article-brand] Edit success'),
  requestClearErrors: uniqueActionType('[article-brand] Edit clear errors'),
  requestClear: uniqueActionType('[article-brand] Edit clear')
};

export class ArticleBrandEditAction implements PayloadAction {
  readonly type = ArticleBrandEditActionTypes.request;

  constructor(public payload: SendByIdPayload<ArticleBrandPayload>) {
  }
}

export class ArticleBrandEditFailAction implements PayloadAction {
  readonly type = ArticleBrandEditActionTypes.requestFail;

  constructor(public payload: FailByIdPayload) {
  }
}

export class ArticleBrandEditSuccessAction implements PayloadAction {
  readonly type = ArticleBrandEditActionTypes.requestSuccess;

  constructor(public payload: SuccessByIdPayload<NormalizedSingle>) {
  }
}

export class ArticleBrandEditClearErrorsAction implements PayloadAction {
  readonly type = ArticleBrandEditActionTypes.requestClearErrors;

  constructor(public payload: ByIdPayload) {
  }
}

export class ArticleBrandEditClearAction implements PayloadAction {
  readonly type = ArticleBrandEditActionTypes.requestClear;

  constructor(public payload: ByIdPayload) {
  }
}
