import { Injectable } from '@angular/core';
import { IdType, PayloadAction, SingleStateToComponentAdapter } from '@industi/ngx-common';
import { HttpErrorResponse } from '@angular/common/http';
import { Action, Selector } from '@ngrx/store';
import { Application } from '../models/application';
import {
  ApplicationLoadCurrentAction,
  ApplicationLoadCurrentClearAction,
  ApplicationLoadCurrentClearErrorsAction,
  ApplicationLoadCurrentFailAction,
  ApplicationLoadCurrentSuccessAction
} from '../actions/load-current.actions';
import {
  createApplicationCurrentDetails,
  createApplicationCurrentErrors,
  createApplicationCurrentLoading,
  createApplicationCurrentSuccess
} from '../selectors/get-current.selectors';

@Injectable({
  providedIn: 'root'
})
export class ApplicationLoadCurrentAdapterService
  extends SingleStateToComponentAdapter<Application> {

  apiOutputModelToAppItemModel(apiOutput: Application): Application {
    return apiOutput;
  }

  getDataSelector(id: IdType): Selector<any, Application> {
    return createApplicationCurrentDetails(id);
  }

  getErrorsSelector(id: IdType): Selector<any, HttpErrorResponse> {
    return createApplicationCurrentErrors(id);
  }

  getLoadingSelector(id: IdType): Selector<any, boolean> {
    return createApplicationCurrentLoading(id);
  }

  getSuccessSelector(id: IdType): Selector<any, boolean> {
    return createApplicationCurrentSuccess(id);
  }

  requestAction(id: IdType): Action {
    return new ApplicationLoadCurrentAction({ id });
  }

  requestCancelAction(id: IdType): Action {
    return undefined;
  }

  requestClearAction(id: IdType): Action {
    return new ApplicationLoadCurrentClearAction({ id });
  }

  requestClearErrorsAction(id: IdType): Action {
    return new ApplicationLoadCurrentClearErrorsAction({ id });
  }

  requestFailAction(id: IdType, errors: HttpErrorResponse): PayloadAction {
    return new ApplicationLoadCurrentFailAction({ id, errors });
  }

  requestSuccessAction(id: IdType, res: any): PayloadAction {
    return new ApplicationLoadCurrentSuccessAction({ id, res });
  }
}
