import { Inject, Injectable } from '@angular/core';
import { API_URL } from '../../../app-store-providers';
import { HttpClient } from '@angular/common/http';
import { EntityTypes } from '../../../entity/entity-types';
import { ServiceProjectItem } from './models/item';
import { ServiceProjectItemPayload } from './models/item-payload';
import { ServiceProjectItemListParams } from './models/item-list-params';
import { StoreBaseService } from '../../../common/base.service';

@Injectable({
  providedIn: 'root'
})
export class ServiceProjectItemService
  extends StoreBaseService<ServiceProjectItem, ServiceProjectItemPayload, ServiceProjectItemListParams> {

  get entityType(): string {
    return EntityTypes.projectItem;
  }

  get resourceName(): string {
    return 'service/project-item';
  }

  constructor(
    @Inject(API_URL) apiUrl: string,
    http: HttpClient
  ) {
    super(apiUrl, http);
  }
}
