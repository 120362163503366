export class EntityTypes {
  static readonly acquisition = 'acquisition';
  static readonly application = 'application';
  static readonly article = 'article';
  static readonly articleBrand = 'articleBrand';
  static readonly articleContent = 'articleContent';
  static readonly articleGalleryContent = 'articleGalleryContent';
  static readonly articleImageContent = 'articleImageContent';
  static readonly articleTextContent = 'articleTextContent';
  static readonly articleVideoContent = 'articleVideoContent';
  static readonly brand = 'brand';
  static readonly carbonMaterial = 'carbonMaterial';
  static readonly carbonCommissionTask = 'carbonCommissionTask';
  static readonly carbonMaterialType = 'carbonMaterialType';
  static readonly carbonForm = 'carbonForm';
  static readonly carbonFormCommission = 'carbonFormCommission';
  static readonly carbonPartCommission = 'carbonPartCommission';
  static readonly courier = 'courier';
  static readonly client = 'client';
  static readonly diagram = 'diagram';
  static readonly diagramPart = 'diagramPart';
  static readonly discountGroup = 'discountGroup';
  static readonly ebay = 'ebay';
  static readonly ebayCompatibility = 'ebayCompatibility';
  static readonly ebayCompatibilityValue = 'ebayCompatibilityValue';
  static readonly icsConversation = 'icsConversation';
  static readonly image = 'image';
  static readonly item = 'item';
  static readonly layer = 'layer';
  static readonly materialExploitation = 'materialExploitation';
  static readonly model = 'model';
  static readonly offer = 'offer';
  static readonly offerItem = 'offerItem';
  static readonly order = 'order';
  static readonly orderItem = 'orderItem';
  static readonly operation = 'operation';
  static readonly part = 'part';
  static readonly process = 'process';
  static readonly project = 'project';
  static readonly projectItem = 'projectItem';
  static readonly projectOperation = 'projectOperation';
  static readonly projectPart = 'projectPart';
  static readonly tag = 'tag';
  static readonly timeline = 'timeline';
  static readonly unitState = 'unitState';
  static readonly user = 'user';
  static readonly warehouse = 'warehouse';
  static readonly warehouseHall = 'warehouseHall';
}
